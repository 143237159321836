import React, { useState, useEffect, useRef } from "react";
import "./BrandZoneDeatils.css";
import { Button, Breadcrumb, Input, Modal, Row, Col } from "antd";
import {
  VideoCameraOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  CaretLeftOutlined,
  CaretRightOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  HeartFilled,
  HeartOutlined,
} from "@ant-design/icons";
import { FilePdfOutlined, FileJpgOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation } from "react-router-dom";
import { Chat } from "../../components/svgJS/Chat";
import { Calendar6 } from "../../components/svgJS/Calendar6";
import { Union } from "../../components/svgJS/Union";
import { Download } from "../../components/svgJS/Download";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  getChatbotJson,
  getEventNavigationFlags,
  getZoneDetails,
  saveChatbotHistory,
  sendMessageAPI,
  videoRequestAPI,
} from "../../utils/commonApis";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import {
  checkIfChatbotTimeSlot,
  createTZString,
  getComentChatUser,
  isRouteExistInMenu,
  isValidHttpURL,
} from "../../utils/commonFunctions";
import Survey from "../surveyManagement/surveyscreens";
import {
  DEFAULT_CHATBOT_HISTORY,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { ApiCall } from "../../utils/api";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import renderHTML from "react-render-html";

const BrandZoneDetails = ({
  vistaZoneData,
  isFromVista,
  match,
  socket,
  showReceptionButtons = false,
}) => {
  const { TextArea } = Input;
  var CryptoJS = require("crypto-js");
  const location = useLocation();
  const messageValidator = useRef(new SimpleReactValidator());
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const dispatch = useDispatch();
  const mobileCheckHeightLessThan800 = useMediaQuery({ maxHeight: 800 });
  const mobileCheckHeightLessThan700 = useMediaQuery({ maxHeight: 700 });
  const mobileCheckHeightLessThan600 = useMediaQuery({ maxHeight: 600 });
  const mobileCheckHeightMoreThan800 = useMediaQuery({ minHeight: 800 });
  const mobileCheckHeightMoreThan900 = useMediaQuery({ minHeight: 900 });

  const [zoneData, setZoneData] = useState(null);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [assetModal, setassetModal] = useState({ isOpen: false, data: {} });
  const [surveyModal, setSurveyModal] = useState(false);
  const [message_text, set_message_text] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);
  const isMobile = useMediaQuery({ minWidth: 480 });
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomSize, setZoomSize] = useState(1.5);
  const [fullScreen, setFullScreen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [is_default_zone, set_is_default_zone] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);
  const [zoneAssetId, setZoneAssetId] = useState(null);
  const [chatBotRes, setChatBotRes] = useState(null);
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [navigationFlags, setNavigationFlags] = useState(null);
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) window.location.href = "/pagenotfound";
  }, [CryptoJS.AES]);

  useEffect(async () => {
    setLoader(true);

    if (vistaZoneData) {
      if (!Array.isArray(vistaZoneData)) {
        let availableChatBot = checkForChatBotDateSlotAndModifyTimeZone(
          //Check which date falls between the current date
          vistaZoneData?.zone_chat_bot_setting_details
        );
        let chatBotRes = false;
        if (vistaZoneData?.is_text_bot_enable) {
          chatBotRes = checkIfChatbotTimeSlot(availableChatBot);
        }
        if (showReceptionButtons) {
          //only in concourse reception this buttons will be visible
          getSetEventNavigationFlags(vistaZoneData?.event_id);
        }
        setChatBotRes(chatBotRes);
        setZoneData(vistaZoneData);
        set_is_default_zone(true);
        setLoader(false);
      } else {
        setZoneData(null);
        set_is_default_zone(false);
        setLoader(false);
      }
    } else {
      let brandDataHere = await location?.state?.brandData;
      if (brandDataHere && brandDataHere.brands_default_zone_id) {
        //delete this next single line
        // getSetEventNavigationFlags(
        //   brandDataHere?.brands_default_zone_id?.event_id
        // );
        setZoneData(brandDataHere?.brands_default_zone_id);
        set_is_default_zone(true);
        setLoader(false);
      } else {
        if (
          match?.params?.zone_id === "null" ||
          match?.params?.brand_id === "null"
        ) {
          setLoader(false);
          window.location.href = "/pagenotfound";
        } else if (
          match?.params?.brand_id &&
          match?.params?.zone_id &&
          match?.params?.zone_id !== "null"
        ) {
          await getZoneDetails({
            zone_id: match?.params?.zone_id,
            brand_id: match?.params?.brand_id,
          })
            .then((res) => {
              if (res) {
                if (
                  res?.brand_id &&
                  match?.params?.brand_id &&
                  res?.brand_id.toString() ===
                    match?.params?.brand_id.toString()
                ) {
                  setLoader(false);

                  let availableChatBot =
                    checkForChatBotDateSlotAndModifyTimeZone(
                      //Check which date falls between the current date
                      res?.zone_chat_bot_setting_details
                    );

                  let chatbotRes = false;
                  if (res?.is_text_bot_enable)
                    chatbotRes = checkIfChatbotTimeSlot(availableChatBot);
                  //delete this next single line
                  // getSetEventNavigationFlags(res.event_id);
                  setChatBotRes(chatbotRes);
                  setZoneData(res);
                  set_is_default_zone(res.is_default_zone);
                } else window.location.href = "/pagenotfound";
              }
            })
            .catch((err) => {});
        }
      }
    }
    setLoader(false);
  }, [vistaZoneData]);

  async function getSetEventNavigationFlags(event_id) {
    const eventNavigationFlags = await getEventNavigationFlags({
      event_id,
    });
    if (eventNavigationFlags?.length > 0) {
      console.log("eventNavigationFlags = ", eventNavigationFlags);
      let modifiedNavigationFlags = eventNavigationFlags?.map((elem) => {
        let newStr = elem.svg_icon_url;
        console.log("newStr", newStr);
        return {
          ...elem,
          svg_icon_url: newStr,
        };
      });
      if (modifiedNavigationFlags) setNavigationFlags(modifiedNavigationFlags);
    }
  }

  useEffect(() => {
    if (mobileCheckHeightMoreThan900) setZoomSize(1.7);
    if (mobileCheckHeightMoreThan800) setZoomSize(1.5);
    if (mobileCheckHeightLessThan800) setZoomSize(1.3);
    if (mobileCheckHeightLessThan700) setZoomSize(0.9);
    if (mobileCheckHeightLessThan600) setZoomSize(0.9);
  }, [
    mobileCheckHeightMoreThan900,
    mobileCheckHeightMoreThan800,
    mobileCheckHeightLessThan800,
    mobileCheckHeightLessThan700,
    mobileCheckHeightLessThan600,
  ]);

  function checkForChatBotDateSlotAndModifyTimeZone(chatBotArr) {
    let newChatBotObj = null;
    chatBotArr?.forEach((item) => {
      let start_date = new Date(item?.start_date ?? item?.chat_bot_start_date);
      let end_date = new Date(item?.end_date ?? item?.chat_bot_end_date);
      let current_date = new Date();

      if (current_date > start_date && current_date < end_date) {
        const start_date_time = createTZString(item?.start_date_time);
        const end_date_time = createTZString(item?.end_date_time);

        newChatBotObj = {
          ...item,
          chat_bot_start_time: start_date_time.format("HH:mm:ss A"),
          chat_bot_end_time: end_date_time.format("HH:mm:ss A"),
        };
      }
    });
    return newChatBotObj;
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  function clearAssetModal() {
    setassetModal({ isOpen: false, data: {} });
    const videoElem = document.getElementById("modal_asset_video");
    videoElem?.pause();
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      const response = await sendMessageAPI(channel_id, message_text);
      if (response.data.code === 200) {
        toast.success(response.data.message);
        clearMessageAndModal();
      } else toast.error(response.data.message);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  const handleDownloadCount = async (max, total, assetId) => {
    if (total < max) {
      await ApiCall(
        "PUT",
        {
          zone_asset_id: assetId,
          is_download: true,
        },
        "/admin/zone/assets/viewdownload/count/update"
      );
      const res = await getZoneDetails({
        zone_id: match?.params?.zone_id ?? vistaZoneData?.zone_id,
        brand_id: match?.params?.brand_id ?? vistaZoneData?.brand_id,
      });
      setZoneData(res);
      return true;
    } else return false;
  };

  const handleViewCount = async (assetId) => {
    await ApiCall(
      "PUT",
      {
        zone_asset_id: assetId,
        is_download: false,
      },
      "/admin/zone/assets/viewdownload/count/update"
    );
  };

  const updateLikeCount = async (zone_asset_id) => {
    let body = {
      zone_asset_id: zone_asset_id,
      is_like: !isLiked,
      zone_assert_reaction_id: zoneAssetId,
    };
    setIsLiked(!isLiked);
    const response = await ApiCall(
      "POST",
      body,
      "/admin/zone/assets/reaction/create"
    );

    if (response.data.code === 200) getLikeCount(zone_asset_id);
    else toast.error(response.data.message);
  };

  const getLikeCount = async (zone_asset_id) => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/zone/assets/reaction/get",
      { zone_asset_id }
    );

    if (response?.data?.code === 200) {
      setLikeCount(response?.data?.data?.total_like_count);
      setIsLiked(response?.data?.data?.is_like);
      setZoneAssetId(response?.data?.data?.zone_assert_reaction_id);
    } else toast.error(response.data.message);
  };

  const renderBreadCrumb = () => {
    return (
      <div className="d-flex justify-content-between">
        <div>
          <h4 className="fs-24">Zone</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>
              <Link to="/brand">Brands</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Zone Details</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
    );
  };

  const SvgIcon = ({ svg }) => {
    return svg;
  };

  if (loader) return <CommonLoader />;
  if (!zoneData)
    return (
      <div className="h-100 ms-3">
        {isFromVista ? null : renderBreadCrumb()}
        <div
          className={
            isFromVista
              ? "w-100 text-center font-weight-bold text-danger fs-18 flex_center bg-white h-100"
              : "w-100 text-center font-weight-bold text-danger"
          }
        >
          {isFromVista ? "" : "Default Zone not Assigned"}
        </div>
      </div>
    );
  else
    return (
      <div className="ms-3">
        {isChatBot && chatbot_json ? (
          <CustomChatbot
            onClose={async (conversation_details) => {
              await saveChatbotHistory({
                event_id: zoneData?.event_id,
                conversation_details,
              });
              setIsChatBot(false);
            }}
            responseJson={chatbot_json}
            chatbot_history={chatbot_history}
            onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
            extraProps={{ event_id: zoneData?.event_id }}
            customWrapper={"chat_bot_custom_wrapper"}
          />
        ) : null}

        {isFromVista ? null : renderBreadCrumb()}
        {!is_default_zone ? (
          <div className="h-100">
            <div className={"w-100 text-center font-weight-bold text-danger"}>
              This is not a default zone.
            </div>
          </div>
        ) : (
          <>
            <div className="ms_page_container">
              {zoneData && zoneData?.zone_hero_image_url ? (
                <div className="brand_zone_img_container">
                  <img
                    className="brand_zone_logo_size img-fluid w-100 h-100"
                    src={zoneData?.zone_hero_image_url}
                  />
                </div>
              ) : (
                <div className="brand_zone_img_container">
                  <span className="ms_zero_hero">Zero Hero Image</span>
                </div>
              )}

              <div className="ms_page_content">
                <h6 className="ms_page_title fs-pink">
                  {zoneData?.zone_title}
                </h6>
                <p className="ms_page_description">
                  {zoneData?.zone_description}
                </p>
                <h6 className="ms_interact_live">Interact Live</h6>
                <div className="ms_btns_container d-flex flex-wrap">
                  {zoneData?.is_text_chat_enable ? (
                    <>
                      <Button
                        className="ms_btn"
                        onClick={async () => {
                          if (chatBotRes) {
                            let chat_bot_res = await getChatbotJson({
                              zone_id: zoneData?.zone_id,
                            });

                            if (chat_bot_res && !isEmpty(chat_bot_res)) {
                              set_chatbot_json(chat_bot_res);
                              setIsChatBot(true);
                            } else toast.error("Chatbot details doesn't exist");
                          } else {
                            setMessageModal({
                              isOpen: true,
                              channel_id: zoneData?.channel_id,
                            });
                          }
                        }}
                      >
                        <Chat />
                        <span>Chat</span>
                      </Button>
                    </>
                  ) : null}
                  {messageModal.isOpen ? (
                    <Modal
                      title={<b>Send Message</b>}
                      visible={messageModal.isOpen}
                      onOk={() => clearMessageAndModal()}
                      onCancel={() => clearMessageAndModal()}
                      footer={[
                        <Button
                          className="px-3 button-pink rounded"
                          key="cancel"
                          onClick={() => clearMessageAndModal()}
                        >
                          Cancel
                        </Button>,
                        <Button
                          className="px-3 rounded"
                          key="save"
                          type="primary"
                          onClick={() =>
                            callSendMessageAPI(messageModal?.channel_id)
                          }
                          disabled={disableSendBtn}
                        >
                          Send
                        </Button>,
                      ]}
                      className="message_modal"
                    >
                      <Row className="ticker_configuration_row mt-2">
                        <Col span={6} className="ticker_configuration_label">
                          <p>
                            Message
                            <RedAsterick />
                          </p>
                        </Col>
                        <Col span={12}>
                          <TextArea
                            name="message_text"
                            id="message_text"
                            className="ticker_configuration_inputDescription"
                            placeholder="Enter a message"
                            rows={4}
                            value={message_text}
                            onChange={(e) => set_message_text(e.target.value)}
                            maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                            onBlur={() =>
                              messageValidator.current.showMessageFor(
                                "message_text"
                              )
                            }
                          />
                          {messageValidator.current.message(
                            "Message",
                            message_text,
                            "required",
                            { className: "text-danger py-1" }
                          )}
                        </Col>
                      </Row>
                    </Modal>
                  ) : null}
                  {zoneData?.is_one_to_one_video_calls_enable ? (
                    <Button
                      onClick={async () => {
                        let channel_id = zoneData?.channel_id;

                        const response = await videoRequestAPI(channel_id);
                        if (response.data.code === 200) {
                          // if (socket.connected) {
                          //   let test112 = socket.emit(
                          //     "delegateToAgentVideoCallRequest",
                          //     JSON.stringify({
                          //       channel_id: zoneData?.channel_id,
                          //       // user_id: loginUserId,
                          //       chat_id: localStorage.getItem("chat_uid"),
                          //       // first_name: userName,
                          //     })
                          //   );

                          toast.success(response.data.message);
                        } else toast.error(response.data.message);
                      }}
                      className="ms_btn"
                    >
                      <VideoCameraOutlined />
                      <span>1-to-1 Video Call</span>
                    </Button>
                  ) : null}
                  {zoneData?.zone_demo_room_link &&
                  zoneData?.is_video_demo_room_enable ? (
                    <Button
                      onClick={() => {
                        dispatch(
                          setVideoConferenceData({
                            videoConferenceFlag: true,
                            videoConferenceUrl: zoneData?.zone_demo_room_link,
                            is_demo_room: true,
                          })
                        );
                      }}
                      className="ms_btn"
                    >
                      <VideoCameraOutlined />
                      <span>Join the Demo Room</span>
                    </Button>
                  ) : null}
                  <Link
                    to={
                      !isFromVista
                        ? `/zonecalendar/${zoneData?.event_id}/${zoneData?.brand_id}/${zoneData?.zone_id}`
                        : `/zonecalendar/${zoneData?.event_id}/${zoneData?.brand_id}/${zoneData?.zone_id}`
                    }
                    target={!isFromVista ? "" : "_blank"}
                  >
                    <Button className="ms_btn">
                      <Calendar6 />
                      <span>Zone Calendar</span>
                    </Button>
                  </Link>
                  {zoneData?.survey_id ? (
                    <Button
                      onClick={() => setSurveyModal(true)}
                      className="ms_btn"
                    >
                      <Union />
                      <span>Survey</span>
                    </Button>
                  ) : null}
                </div>
                <div className="navigations_zone_card_btns">
                  {navigationFlags && navigationFlags?.length > 0
                    ? navigationFlags.map((ele) => (
                        <Button
                          className="ms_btn"
                          onClick={() => {
                            console.log(ele?.menu, "menu");
                            if (ele?.route === "/welcomepage") {
                              let preEventUrl =
                                localStorage.getItem("pre_event_msg");
                              let welcomeUrl =
                                localStorage.getItem("welcome_msg");

                              if (isValidHttpURL(welcomeUrl)) {
                                window.location.href = ele.route;
                              } else {
                                toast.error("Welcome Video Not Uploaded");
                              }
                            } else {
                              window.location.href = ele.route;
                            }
                          }}
                        >
                          {ele?.svg_icon_url && renderHTML(ele?.svg_icon_url)}

                          <span>{ele.menu}</span>
                        </Button>
                      ))
                    : null}
                </div>

                {/* {vistaZoneData && isFromVista ? <div className="ms_btns_container d-flex flex-wrap"> */}

                {/* </div>: null} */}
                <h6 className="ms_external_links">External & Social Links</h6>
                <div className="ms_links">
                  {zoneData?.zones_social_media_handles_id?.map((item) => {
                    let { zonesSocialMediaHandles_social_media_id } = item;
                    let { social_media } =
                      zonesSocialMediaHandles_social_media_id;
                    let url = "";
                    if (
                      item?.social_media_url.startsWith("http://") ||
                      item?.social_media_url.startsWith("https://")
                    )
                      url = item?.social_media_url;
                    else url = "https://" + item.social_media_url;

                    if (social_media === "Instagram") {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={(e) => {
                            window.open(url, "_blank");
                          }}
                        >
                          <InstagramOutlined className="zone_card_links" />
                        </div>
                      );
                    } else if (social_media === "LinkedIn") {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <LinkedinOutlined className="zone_card_links" />
                        </div>
                      );
                    } else if (social_media === "Twitter") {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <TwitterOutlined className="zone_card_links" />
                        </div>
                      );
                    } else if (social_media === "Facebook") {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <FacebookOutlined className="zone_card_links" />
                        </div>
                      );
                    }
                  })}
                </div>
                <Modal
                  visible={surveyModal}
                  width={1000}
                  onOk={() => <div></div>}
                  onCancel={() => {
                    setSurveyModal(false);
                  }}
                  footer={false}
                >
                  <Survey
                    isFromBrandZone={true}
                    survey_id={zoneData?.survey_id}
                    event_id={location?.state?.event_id ?? zoneData?.event_id}
                  />
                </Modal>

                <Modal
                  visible={assetModal?.isOpen}
                  onOk={() => <div></div>}
                  onCancel={() => {
                    clearAssetModal();
                    setZoomSize(1.5);
                    setFullScreen(false);
                    setPageNumber(1);
                  }}
                  footer={false}
                  wrapClassName={`${
                    fullScreen
                      ? "fullscreen_brand_zone_asset_modal"
                      : "brand_zone_asset_modal"
                  }  `}
                >
                  <div className="mt-1 w-100 h-100">
                    <div className="img_video_brand_zone_like">
                      <span className="fs-18 mx-1">{likeCount}</span>
                      {isLiked ? (
                        <HeartFilled
                          onClick={() => {
                            updateLikeCount(assetModal?.data?.zone_asset_id);
                          }}
                          className="cursor-pointer pdf_brand_zone_like_red"
                          style={{
                            fontSize: "20px",
                            marginRight: "40px",
                          }}
                        />
                      ) : (
                        <HeartOutlined
                          onClick={() => {
                            updateLikeCount(assetModal?.data?.zone_asset_id);
                          }}
                          className="cursor-pointer"
                          style={{
                            fontSize: "20px",
                            marginRight: "40px",
                          }}
                        />
                      )}
                    </div>

                    {assetModal?.data?.asset_file_type === "image/jpg" ||
                    assetModal?.data?.asset_file_type === "image/jpeg" ||
                    assetModal?.data?.asset_file_type === "image/png" ||
                    assetModal?.data?.asset_file_type === "image" ? (
                      <div className="asset_modal_img_container">
                        <img
                          src={assetModal?.data?.asset_file_url}
                          className="modal_brand_zone_img"
                        />
                      </div>
                    ) : assetModal?.data?.asset_file_type === "video/mp4" ? (
                      <div className="h-100">
                        <div className="asset_modal_video_container">
                          <video
                            id="modal_asset_video"
                            src={assetModal?.data?.asset_file_url}
                            width="100%"
                            controls="controls"
                            className="modal_brand_zone_video"
                            autoplay="true"
                            onEnded={() => clearAssetModal()}
                          />
                        </div>
                      </div>
                    ) : assetModal?.data?.asset_file_type ===
                      "application/pdf" ? (
                      <div className="h-100">
                        <div className="asset_modal_pdf_header">
                          {fullScreen ? (
                            <Button
                              style={{
                                borderRadius: "15px",
                              }}
                              onClick={() => {
                                setFullScreen(!fullScreen);
                                setZoomSize(1.5);
                              }}
                              className=" ms-2"
                            >
                              Small Screen
                            </Button>
                          ) : (
                            <Button
                              style={{
                                borderRadius: "15px",
                              }}
                              onClick={() => {
                                setFullScreen(!fullScreen);
                                setZoomSize(1.2);
                              }}
                              className=" ms-2"
                            >
                              Full Screen
                            </Button>
                          )}

                          <span className="ms-2">Page</span>
                          <CaretLeftOutlined
                            disabled={pageNumber === 1}
                            className="fs-18 ms-2 pdf_arrow"
                            onClick={() => {
                              if (pageNumber === 1) setPageNumber(1);
                              else setPageNumber(pageNumber - 1);
                            }}
                          />

                          <span className="ms-2 fs-16">{pageNumber}</span>

                          <CaretRightOutlined
                            disabled={numPages === pageNumber}
                            className="fs-18 ms-2 pdf_arrow"
                            onClick={() => {
                              if (numPages === pageNumber)
                                setPageNumber(numPages);
                              else setPageNumber(pageNumber + 1);
                            }}
                          />

                          <span className="ms-2 fs-16">of</span>
                          <span className="ms-2 fs-16">{numPages}</span>

                          <PlusCircleOutlined
                            className="ms-2 pdf_arrow"
                            onClick={() => setZoomSize(zoomSize + 0.1)}
                          />
                          <MinusCircleOutlined
                            onClick={() => setZoomSize(zoomSize - 0.1)}
                            className="ms-2 pdf_arrow"
                          />
                        </div>

                        <div className="asset_modal_pdf_container">
                          <Document
                            renderMode="svg"
                            className={
                              fullScreen
                                ? "fullscreen_pdf_document"
                                : "smallscreent_pdf_document"
                            }
                            file={assetModal?.data?.asset_file_url}
                            onLoadSuccess={onDocumentLoadSuccess}
                          >
                            <Page
                              renderAnnotationLayer={false}
                              height={450}
                              className="page_pdf"
                              scale={zoomSize}
                              pageNumber={pageNumber}
                            />
                          </Document>
                        </div>

                        <CaretLeftOutlined
                          disabled={pageNumber === 1}
                          className="fs-18 ms-2 pdf_arrow pdf_side_left_arrow"
                          onClick={() => {
                            if (pageNumber === 1) setPageNumber(1);
                            else setPageNumber(pageNumber - 1);
                          }}
                        />

                        <CaretRightOutlined
                          disabled={numPages === pageNumber}
                          className="fs-18 ms-2 pdf_arrow pdf_side_right_arrow"
                          onClick={() => {
                            if (numPages === pageNumber)
                              setPageNumber(numPages);
                            else setPageNumber(pageNumber + 1);
                          }}
                        />
                      </div>
                    ) : assetModal?.data?.asset_file_type === "web/3d" ? (
                      <div className="asset_modal_web3d_container">
                        <iframe
                          allow="autoplay"
                          className="asset_modal_web3d"
                          src={
                            assetModal?.data?.webviewer_url?.includes(
                              "sketchfab"
                            )
                              ? assetModal?.data?.webviewer_url +
                                "/embed?autostart=1"
                              : assetModal?.data?.webviewer_url
                          }
                        />
                      </div>
                    ) : null}
                  </div>
                </Modal>
              </div>
            </div>

            <div
              style={{
                backgroundColor: "white",
                margin: "-40px 0",
                padding: "4px 20px",
              }}
            >
              <h6 className="ms_assets">Assets</h6>
              <div className=" ">
                <div className="d-flex flex-wrap">
                  {zoneData &&
                    zoneData?.zones_assets_id?.map((item, i) => {
                      if (item?.is_active) {
                        return (
                          <div
                            key={i}
                            className={"zone_card_asset m-2 p-3"}
                            style={{ width: isMobile ? "22%" : "100%" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleViewCount(item?.zone_asset_id);
                              getLikeCount(item?.zone_asset_id);
                              setassetModal({ data: item, isOpen: true });
                            }}
                          >
                            <div className="ms_card_upper_section">
                              {item?.asset_file_type === "video/mp4" ? (
                                <>
                                  <h6 className="ms_card_heading">
                                    {item?.asset_name}.mp4
                                  </h6>
                                  <VideoCameraOutlined className="assets_icons" />
                                </>
                              ) : item.asset_file_type === "application/pdf" ? (
                                <>
                                  <h6 className="ms_card_heading">
                                    {item?.asset_name}.pdf
                                  </h6>
                                  <FilePdfOutlined className="assets_icons" />
                                </>
                              ) : item?.asset_file_type === "image/jpg" ||
                                item.asset_file_type === "image/jpeg" ||
                                item.asset_file_type === "image/png" ||
                                item.asset_file_type === "image" ? (
                                <>
                                  <h6 className="ms_card_heading">
                                    {item?.asset_name}.jpg
                                  </h6>
                                  <FileJpgOutlined className="assets_icons" />
                                </>
                              ) : item?.asset_file_type === "web/3d" ? (
                                <>
                                  <h6 className="ms_card_heading">
                                    {item?.asset_name}
                                  </h6>
                                  <FileJpgOutlined className="assets_icons" />
                                </>
                              ) : null}
                            </div>
                            <div className="ms_card_lower_section">
                              <div className="ms_card_p_wrapper">
                                <p className="ms_card_p">
                                  {item?.asset_description
                                    ? item?.asset_description
                                    : "NA"}
                                </p>
                              </div>

                              {item?.is_downloadable === false ? null : (
                                <Button
                                  className="zone_card_download_btn flex_center p-0"
                                  onClick={async (e) => {
                                    e.stopPropagation();
                                    const isDownload =
                                      await handleDownloadCount(
                                        item?.max_no_of_downloads,
                                        item?.total_downloads,
                                        item?.zone_asset_id
                                      );

                                    if (isDownload)
                                      window.location.href =
                                        item?.asset_file_url;
                                    else
                                      toast.error(
                                        "Max Number of Downloads Reached"
                                      );
                                  }}
                                >
                                  <Download />
                                </Button>
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
};

export default BrandZoneDetails;
