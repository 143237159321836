import React, { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";
import {
  getPeopleForListing,
  editDelegateMeeting,
} from "../../utils/commonApis";
import moment from "moment";
import {
  Button,
  Modal,
  Input,
  DatePicker,
  TimePicker,
  Select,
  Table,
} from "antd";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";
import { getDisabledTime } from "../../utils/commonFunctions";
import { Bin } from "../../components/svgJS/Bin";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { EyeView } from "../../components/svgJS/EyeView";
import { UserDataCard } from "../common/Modal/ProfileModal";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { useDispatch } from "react-redux";
import { ApiCall } from "../../utils/api";
import RedAsterick from "../../components/Widgets/RedAsterick";

const UpdateMeeting = (props) => {
  const { data } = props;
  const { TextArea } = Input;
  const { Option } = Select;
  let attendeesToSend = [];
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(null);
  const [profileModal, setProfileModal] = useState(false);
  const [peopleList, setPeopleList] = useState([]);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectedProfileValue, setSelectedProfileValue] = useState();
  const [fields, setFields] = useState({
    meeting_id: null,
    meeting_title: "NA",
    meeting_description: "NA",
    event_id: localStorage.getItem("event_id"),
    start_date_time: moment(),
    end_date_time: moment(),
    attendees: attendeesToSend,
  });

  const [meetingStartEndTime, setMeetingStartEndTime] = useState({
    startTime: "",
    endTime: "",
    date: "",
  });

  const dispatch = useDispatch();

  const [dataForTableArr, setDataForTableArr] = useState([]);
  const [canJoinSession, setCanJoinSession] = useState(false);

  useEffect(async () => {
    setLoading(true);
    const peopleData = await getPeopleForListing();
    setPeopleList(peopleData.rows);

    let selectAttendeesArr = peopleData?.rows?.filter((item) => {
      return data?.meeting_attendees_details?.some(function (elem) {
        return item?.user_id === elem?.user_id; // return the ones with equal id
      });
    });
    if (data?.meeting_attendees_details?.length > 0) {
      data?.meeting_attendees_details?.forEach((ele) => {
        if (ele?.user_type === "SPEAKER") {
          selectAttendeesArr?.push(ele);
        }
      });
    }

    setSelectedAttendees(selectAttendeesArr);

    let selectedAttendeesIds = selectAttendeesArr?.map((item) => {
      return item?.user_id;
    });

    const dataForTable = selectAttendeesArr?.map((d, i) => {
      return {
        ...d,
        key: i,
        delegate: `${d?.user_name ?? d?.first_name + " " + d?.last_name}`,
        company: `${d?.company}`,
        action: `${d}`,
        remove: `${d?.user_id}`,
      };
    });

    setDataForTableArr(dataForTable);

    const d1 = new Date(data.start_date_time);
    const st = d1.toISOString().replace("Z", "");
    const d2 = new Date(data.end_date_time);
    const et = d2.toISOString().replace("Z", "");

    let currentDate = moment().format("YYYY-MM-DD");
    let currentTime = moment();

    let sessionDate = moment(st).format("YYYY-MM-DD");

    let sessionStartTime = moment(st);
    let sessionEndTime = moment(et);

    setFields({
      meeting_id: data?.session_id ?? null,
      meeting_title: data?.session_title ?? "NA",
      meeting_description: data?.session_description ?? "NA",
      event_id: localStorage.getItem("event_id"),
      session_id: data?.session_id,
      start_date_time: data?.start_date_time ? moment(st) : null,
      end_date_time: data?.end_date_time ? moment(et) : null,
      attendees: selectedAttendeesIds,
    });

    setMeetingStartEndTime({
      startTime: moment(st).format("HH:mm"),
      endTime: moment(et).format("HH:mm"),
      date: moment(st).format("YYYY-MM-DD"),
    });

    setDate(st);

    if (
      currentDate === sessionDate &&
      currentTime.isAfter(sessionStartTime) &&
      currentTime.isBefore(sessionEndTime)
    )
      setCanJoinSession(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [data]);

  const handleCreate = async () => {
    if (validator.current.allValid()) {
      let newStartTime = fields?.start_date_time.format("HH:mm");
      let newEndTime = fields?.end_date_time.format("HH:mm");

      let isTimeModified = false;

      if (
        newStartTime !== meetingStartEndTime?.startTime ||
        newEndTime !== meetingStartEndTime?.endTime ||
        meetingStartEndTime?.date !== moment(date)?.format("YYYY-MM-DD")
      ) {
        isTimeModified = true;
      }

      let finalData = {
        meeting_id: fields?.meeting_id,
        meeting_title: fields?.meeting_title,
        meeting_description: fields?.meeting_description,
        event_id: parseInt(fields?.event_id),

        start_date_time:
          moment(date)?.format("YYYY-MM-DD") +
          " " +
          moment(fields?.start_date_time)?.format("HH:mm"),
        end_date_time:
          moment(date)?.format("YYYY-MM-DD") +
          " " +
          moment(fields?.end_date_time)?.format("HH:mm"),
        attendees: fields?.attendees,
        is_time_modified: isTimeModified,
      };

      const apiResponse = await editDelegateMeeting(finalData);
      if (apiResponse.code === 200) {
        toast.success(apiResponse.message);
        window.location.reload();
      } else {
        toast.error(apiResponse.message);
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const handleOKAddUser = () => {
    setProfileModal(false);
  };

  const handleCancelAddUser = () => {
    setProfileModal(false);
  };

  const handleFieldChange = (val, name) => {
    if (name === "date") {
      setDate(val);
    } else if (name === "end_date_time") {
      if (moment(val) < moment(fields.start_date_time, "HH:mm")) {
        toast.error("Please select correct time");
      } else {
        setFields({
          ...fields,
          [name]: val,
        });
      }
    } else if (name === "start_date_time") {
      if (moment(fields.end_date_time, "HH:mm") < moment(val)) {
        toast.error("Please select correct time");
      } else {
        setFields({
          ...fields,
          [name]: val,
        });
      }
    } else {
      setFields({
        ...fields,
        [name]: val,
      });
    }
  };

  const columns = [
    {
      title: "Delegate",
      dataIndex: "delegate",
    },
    {
      title: "Company",
      dataIndex: "company",
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record, index) => (
        <div onClick={() => handleProfileData(record, index)}>
          <EyeView style={{ color: "pink" }} />
          <span className="fs-pink fs-12">View Profile</span>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "remove",
      render: (text, record, index) => (
        <div onClick={() => handleDeleteAttendees(record.remove)}>
          <Bin />
        </div>
      ),
    },
  ];

  const handleAttendeesChange = (value) => {
    let agents_list = peopleList;
    let selectedAgents_list = selectedAttendees;

    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);

    setSelectedAttendees([...selectedAgents_list]);
    setPeopleList([...agents_list]);

    const dataForTable = selectedAttendees?.map((d, i) => {
      return {
        ...d,
        key: i,
        delegate: `${d.user_name ?? d.first_name + " " + d.last_name}`,
        company: `${d.company}`,
        action: `${d}`,
        remove: `${d.user_id}`,
      };
    });

    setDataForTableArr(dataForTable);

    attendeesToSend = selectedAttendees?.map((data) => data.user_id);
    setFields({ ...fields, attendees: attendeesToSend });
  };

  const handleDeleteAttendees = (value) => {
    let agents_list = cloneDeep(peopleList);
    let selectedAgents_list = cloneDeep(selectedAttendees);
    let index = selectedAgents_list.findIndex(
      (agent) => parseInt(agent.user_id) === parseInt(value)
    );

    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);

    setSelectedAttendees([...selectedAgents_list]);
    setPeopleList([...agents_list]);

    const dataForTable =
      selectedAgents_list?.length > 0
        ? selectedAgents_list?.map((d, i) => {
            return {
              ...d,
              key: i,
              delegate: `${d?.user_name ?? d?.first_name + " " + d?.last_name}`,
              company: `${d?.company}`,
              action: `${d}`,
              remove: `${d?.user_id}`,
            };
          })
        : [];

    setDataForTableArr(dataForTable);

    attendeesToSend = selectedAgents_list?.map((data) => data?.user_id);
    setFields({ ...fields, attendees: attendeesToSend });
  };

  const handleProfileData = (record, index) => {
    setProfileModal(true);
    setSelectedProfileValue(index);
    if (props?.closeParentModal) {
      props?.closeParentModal(selectedAttendees, index);
    }
  };

  const deleteMeeting = async (sessionID, eventID) => {
    let body = {
      session_id: sessionID,
      event_id: parseInt(eventID),
    };

    await ApiCall("DELETE", body, "/admin/meetings/delete");
    window.location.reload();
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <div className="row">
            <div className="col-md-3 col-lg-3 col-xl-3 col-5 text-md-end text-lg-end text-sm-start">
              <p className="text-end opacity-75 fs-14 mt-2">
                Meeting Title
                <RedAsterick />
              </p>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8 col-6">
              <Input
                name="meeting_title"
                placeholder="Enter a title here"
                className="createmeet_input"
                value={fields?.meeting_title}
                onChange={(val) => {
                  handleFieldChange(val.target.value, "meeting_title");
                }}
                onBlur={() => validator.current.showMessageFor("meeting_title")}
              />
              {validator.current.message(
                "meeting_title",
                fields.meeting_title,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 col-lg-3 col-xl-3 col-5 text-md-end text-lg-end text-sm-start">
              <p className="text-end opacity-75 fs-14 mt-2">Select Date :</p>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8 col-6">
              <DatePicker
                name="date"
                id="date"
                format={"YYYY-MM-DD"}
                allowClear={false}
                className="w-100 createmeet_input"
                value={date ? moment(date) : moment()}
                onSelect={(val) => {
                  handleFieldChange(moment(val).format("YYYY-MM-DD"), "date");
                }}
                onChange={(val) => {
                  handleFieldChange(moment(val).format("YYYY-MM-DD"), "date");
                }}
                onBlur={() => validator.current.showMessageFor("date")}
                disabledDate={(current) => {
                  return current && current < moment().startOf("day");
                }}
              />
              {validator.current.message("Date", date, "required", {
                className: "text-danger py-1",
              })}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 col-lg-3 col-xl-3 col-5 text-md-end text-lg-end text-sm-start">
              <p className="text-end opacity-75 mt-2 fs-14">Select Time :</p>
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 col-3">
              <TimePicker
                name="start_date_time"
                id="start_date_time"
                showSecond={false}
                format={"HH:mm"}
                allowClear={false}
                className="w-100 createmeet_input"
                value={moment(fields?.start_date_time)}
                onSelect={(val) => {
                  handleFieldChange(val, "start_date_time");
                }}
                onChange={(val) => {
                  handleFieldChange(val, "start_date_time");
                }}
                onBlur={() =>
                  validator.current.showMessageFor("start_date_time")
                }
              />

              {validator.current.message(
                "Start Time",
                fields?.start_date_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </div>
            <div className="col-md-4 col-lg-4 col-xl-4 col-3">
              <TimePicker
                name="end_date_time"
                id="end_date_time"
                format="HH:mm"
                showTime
                allowClear={false}
                value={fields?.end_date_time}
                disabledTime={() => getDisabledTime(fields?.start_date_time)}
                className="w-100 createmeet_input"
                onSelect={(val) => {
                  handleFieldChange(val, "end_date_time");
                }}
                onChange={(val) => {
                  handleFieldChange(val, "end_date_time");
                }}
                onBlur={() => validator.current.showMessageFor("end_date_time")}
              />
              {validator.current.message(
                "End Time",
                fields.end_date_time,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 col-lg-3 col-xl-3 col-5 text-md-end text-lg-end text-sm-start">
              <p className="text-end mt-2 opacity-75 fs-14">Description :</p>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8 col-6">
              <TextArea
                rows={4}
                placeholder="Enter description here"
                value={fields?.meeting_description}
                onChange={(val) => {
                  handleFieldChange(val.target.value, "meeting_description");
                }}
                onBlur={() =>
                  validator.current.showMessageFor("meeting_description")
                }
              />
              {validator.current.message(
                "meeting_description",
                fields.meeting_description,
                "required",
                {
                  className: "text-danger py-1",
                }
              )}
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-3 col-lg-3 col-xl-3 col-5 text-md-end text-lg-end text-sm-start">
              <p className="text-end opacity-75 fs-14 mt-2">Add Attendees</p>
            </div>
            <div className="col-md-8 col-lg-8 col-xl-8 col-6">
              <Select
                showSearch
                className="w-100 fs-bold-14"
                dropdownStyle={{
                  zIndex: 2000,
                }}
                placeholder="Type attendee name or email address"
                value="Attendee's"
                optionFilterProp="children"
                onChange={(val) => handleAttendeesChange(val)}
                autoComplete="none"
                listHeight={120}
                listItemHeight={4}
              >
                {peopleList &&
                  peopleList?.map((data, i) => (
                    <Option key={"peopleList_" + i} value={data?.user_id}>
                      {data?.user_name}
                    </Option>
                  ))}
              </Select>
            </div>
          </div>
          <div className="row mt-3 px-4">
            <div className="col-12">
              <Table
                className="createmeet_table  border-bottom border-secondary pb-1"
                columns={columns}
                dataSource={dataForTableArr}
                pagination={false}
                scroll={{ y: 120 }}
              />
              {/* <Modal
                title="Profile Details"
                visible={profileModal}
                onOk={handleOKAddUser}
                onCancel={handleCancelAddUser}
                footer={null}
                width={1000}
              >
                {selectedAttendees && (
                  <UserDataCard
                    meetingData={selectedAttendees[selectedProfileValue]}
                    fromMeeting={true}
                  />
                )}
              </Modal> */}
            </div>
          </div>
          <div className="row mt-3 mb-2">
            <div className="col-12 text-end d-flex">
              <Button
                onClick={() => {
                  deleteMeeting(fields?.session_id, fields?.event_id);
                }}
                className="createmeet_saveBtn mx-2 fs-16"
              >
                <Bin />
                <span className="ms-2">Delete Session</span>
              </Button>
              <Button
                onClick={handleCreate}
                className="createmeet_saveBtn mx-2 fs-16"
              >
                <BoothRed />
                <span className="ms-2">Save Meeting</span>
              </Button>

              {canJoinSession ? (
                <Button
                  className="audiEvent_joinBtn mx-4 fs-16"
                  onClick={() => {
                    dispatch(
                      setVideoConferenceData({
                        videoConferenceFlag: true,
                        videoConferenceUrl: data?.join_session_url,
                        is_meeting: true,
                      })
                    );
                    if (props?.closeParentModal) {
                      props?.closeParentModal();
                    }
                  }}
                >
                  <span className="mx-2">
                    <BoothRed />
                  </span>
                  Join Session
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Update Meeting"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCancel();
      }}
      onCancel={() => {
        if (!loading) props.onCancel();
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default UpdateMeeting;
