/**
 * @name Popup
 * Pop up container
 * @param {children} ReactNode
 * @param {onClose} Function
 */

import React, { useContext, useEffect, useState } from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import AppContext, { ACTIONS } from "../../contexts/AppContext";
import ReactPlayer from "react-player/lazy";
import {
  ShadowBox,
  PopupContainer,
  PopupHeader,
  PopupContent,
  CloseButton,
} from "./styles";
import SocialWall from "../../../../socialWall";
import Networking from "../../../../networking";
import LoginBox from "../../../../login/LoginBox";
import Auditorium from "../../../../auditorium";
import EventCalendar from "../../../../EventCalender/index";
import ExpoHalls from "../../../../ExpoMap/ExpoHalls";
import BrandZoneDetails from "../../../../ExpoMap/BrandZoneDetails";
import {
  getAudiSourceDetails,
  getBrandZoneData,
  getConcourseDetails,
  getReceptionZoneDetails,
  getSpecificZoneData,
} from "./Api/api";
import { useDispatch, useSelector } from "react-redux";
import CommonLoader from "../../../../../components/Widgets/CommonLoader";
import moment from "moment";
import { VistaPopupFrame } from "./styles";
import { toast } from "react-toastify";
import { VistaMap } from "../../../../../components/svgJS/VistaMap";
import { getZone } from "../../../../../redux/slice/zoneManagementSlice";
import { useHistory } from "react-router";
import MeetContainer from "../../../../VideoConference/MeetContainer";
import {
  setVideoConferenceCreatePollFlag,
  setVideoConferenceData,
  setVideoConferenceFlag,
  setVideoConferenceUrl,
} from "../../../../../redux/slice/videoConference";
import EntryGatePopup from "../../../../commonPopups/EntryGatePopup";
import VistaZoneShowMessage from "./VistaZoneShowMessage";
import { getCookie } from "../../../../../utils/commonFunctions";

const Popup = (props) => {
  const { children, onClose, name, url } = props;

  console.log("popup props pppp", props);

  let socket = props?.props?.props?.props?.socket;

  const { dispatch } = useContext(AppContext);
  const [isFromVista, setIsFromVista] = useState(true);
  let CryptoJS = require("crypto-js");
  let user_type = "";
  const history = useHistory();

  const concourseData = useSelector(
    (state) => state?.vistaBrand?.concourseData
  );

  if (localStorage.getItem("user_type")) {
    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }

  let event_id = localStorage.getItem("event_id");
  const [zoneData, setZoneData] = useState([]);
  const brandObj = useSelector((state) => state?.vistaBrand?.brandObj); //from header component
  const vistaEventId = useSelector((state) => state?.vistaBrand?.eventId); //from Vista redux
  const menuItemFlag = useSelector((state) => state?.vistaBrand?.menuItemFlag);

  const [showJoinBtn, setShowJoinBtn] = useState(false);
  const [joinSessUrl, setJoinSessUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatchRedux = useDispatch();
  const [hideLogin, setHideLogin] = useState(false);
  const [showZoneMessage, setShowZoneMessage] = useState({
    flag: true,
    message: "",
  });

  const [showNoSessionPopup, setShowNoSessionPopup] = useState(false);

  useEffect(async () => {
    if (name.includes("sponsorbooth") || name.includes("banner")) {
      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        if (vistaEventId) {
          event_id = vistaEventId;
        }
      }
      let timeStamp = moment().format("YYYY-MM-DD HH:mm:ss");

      let response = await getConcourseDetails({
        event_id: event_id,
        current_timestamp: timeStamp,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      if (response && response.data.code === 200) {
        if (response?.data?.data) {
          let respZone = await getSpecificZoneData({
            zone_id: response?.data?.data?.concourse_sponsor_zone_id?.zone_id,
          });
          if (respZone && respZone?.data?.data?.details) {
            setZoneData(respZone?.data?.data?.details);
            setShowZoneMessage({
              flag: false,
              message: "",
            });
          } else {
            setShowZoneMessage({
              flag: true,
              message: "No Sponsor added for this current time",
            });
          }
        } else {
          toast.error(response.data.message);
          setShowZoneMessage({
            flag: true,
            message: "No Sponsor added for this current time",
          });
        }
      } else {
        toast.error(response.data.message);

        setShowZoneMessage({
          flag: true,
          message: "No Sponsor added for this current time",
        });
      }
    }

    if (name.includes(`hallbooth-${name.split("-")[1]}`)) {
      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        if (vistaEventId) {
          event_id = vistaEventId;
        }
      }

      let response = await getBrandZoneData({
        booth_zone_id: name.split("-")[1],
        event_id: event_id,
        brand_id: brandObj?.brand_id || name.split("-")[2],
      });

      if (
        response.data.code === 200 &&
        Object.keys(response.data.data).length !== 0
      ) {
        setZoneData(response.data.data);
        setShowZoneMessage({
          flag: false,
          message: "",
        });
      } else {
        toast.error(response.data.message);
        setShowZoneMessage({
          flag: true,
          message: "Zone not assigned for this location",
        });
        setZoneData(null);
      }
    }

    if (name.includes("audimeet")) {
      setLoading(true);

      let loggedInUserId = null;
      if (getCookie("user_id")) {
        const ciphertext = getCookie("user_id");
        const bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
        loggedInUserId = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        if (vistaEventId) {
          event_id = vistaEventId;
        }
      }

      let data = await getAudiSourceDetails({
        event_id: event_id,
        current_timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        auditorium_type: "MAIN",
      });

      let is_moderator =
        data?.message_board_details?.assigned_agent_ids?.includes(
          loggedInUserId
        );

      if (data?.join_session_url) {
        setJoinSessUrl(data?.join_session_url);
        let obj = {
          videoConferenceFlag: true,
          videoConferenceUrl: data?.join_session_url,
          createPollFlag: data?.is_producer_curator,
          isFromNetworking: false,
          auditorium_session_id: data?.auditorium_session_id,
          auditorium_configuration_id: data?.auditorium_configuration_id,
          is_producer_curator: data?.is_producer_curator,
          is_speaker: data?.is_speaker,
          isPollOptionsVisible: true,
          is_moderator: is_moderator,
          showMessageBoard: data?.message_board_details?.message_board_enabled
            ? true
            : false,
          isFromExternalSourceEnabled: data?.is_from_external_source,
        };

        dispatchRedux(setVideoConferenceData(obj));

        handleOnClose();
      }
      setLoading(false);
    }

    if (name.includes("reception")) {
      setLoading(true);

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        if (vistaEventId) {
          event_id = vistaEventId;
        }
      }
      let response = await getReceptionZoneDetails({
        event_id: event_id,
      });

      if (
        response?.data?.code === 200 &&
        Object.keys(response?.data?.data).length !== 0
      ) {
        setZoneData(response?.data?.data);
        setShowZoneMessage({
          flag: false,
          message: "",
        });
      } else {
        toast.error(response?.data?.message);
        setZoneData(null);
        setShowZoneMessage({
          flag: true,
          message: "Event Default Zone Not Assigned",
        });
      }

      setLoading(false);
    }

    if (name.includes("satmeet")) {
      setLoading(true);

      let loggedInUserId = null;
      if (getCookie("user_id")) {
        const ciphertext = getCookie("user_id");
        const bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
        loggedInUserId = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      }

      if (user_type === "SUPER ADMIN" || user_type === "CUSTOMER") {
        if (vistaEventId) {
          event_id = vistaEventId;
        }
      }
      let data = await getAudiSourceDetails({
        event_id: event_id,
        current_timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        auditorium_type: "SATELLITE",
      });

      let is_moderator =
        data?.message_board_details?.assigned_agent_ids?.includes(
          loggedInUserId
        );

      if (data?.join_session_url) {
        setJoinSessUrl(data?.join_session_url);
        let obj = {
          videoConferenceFlag: true,
          videoConferenceUrl: data?.join_session_url,
          createPollFlag: data?.is_producer_curator,
          isFromNetworking: false,
          auditorium_session_id: data?.auditorium_session_id,
          is_producer_curator: data?.is_producer_curator,
          is_speaker: data?.is_speaker,
          isPollOptionsVisible: true,
          is_moderator: is_moderator,
          showMessageBoard: data?.message_board_details?.message_board_enabled
            ? true
            : false,
          isFromExternalSourceEnabled: data?.is_from_external_source,
        };

        dispatchRedux(setVideoConferenceData(obj));
        handleOnClose();
      }
      setLoading(false);
    }

    if (name.includes("login") && getCookie("Authorization")) {
      setHideLogin(true);
      handleOnClose();
      dispatch({
        type: ACTIONS.SET_VISTA_SRC,
        value: {
          src: concourseData?.event_settings?.concourse_url,
          currentPosition: "concoursePublished",
        },
      });
    }
  }, [name, vistaEventId]);

  const handleOnClose = () => {
    if (onClose) {
      onClose();
      return;
    }

    dispatch({
      type: ACTIONS.SET_ACTIVE_WINDOW,
      // value: { value: "", assetUrl: "" }
      value: "",
    });
  };

  return (
    <>
      <ShadowBox>
        <PopupContainer name={name}>
          <PopupHeader>
            <CloseButton type="button" onClick={handleOnClose}>
              X
            </CloseButton>
          </PopupHeader>
          <PopupContent>
            {name.includes("login") && !hideLogin && (
              <div className="w-100 h-100 d-flex justify-content-center">
                <img
                  className="login_img_logo vistalogin_logo"
                  src={localStorage.getItem("event_logo_url")}
                  alt="logo"
                />

                <LoginBox
                  isRenderLogin={true}
                  loginProps={props.props.props.props}
                />
              </div>
            )}
            {name.includes("socialwall") && (
              <SocialWall
                isFromVista={isFromVista}
                vistaHistory={props?.props?.props?.props?.history}
              />
            )}

            {name.includes("Entry") && <EntryGatePopup />}

            {name.includes("networking") && (
              <Networking
                socket={socket}
                isFromVista={isFromVista}
                onVistaPopUpClose={handleOnClose}
                history={props?.props?.props?.props?.history}
              />
            )}

            {name.includes("auditorium") && (
              <Auditorium
                isFromVista={isFromVista}
                history={props?.props?.props?.props?.history}
                onVistaPopUpClose={handleOnClose}
              />
            )}

            {name.includes("eventcalendar") && (
              <EventCalendar
                isFromVista={isFromVista}
                onVistaPopUpClose={handleOnClose}
                otherVistaProps={props?.props?.props?.props}
              />
            )}

            {name.includes("expomap") && (
              <ExpoHalls isFromVista={isFromVista} />
            )}

            {/* {name.includes("sponsorbooth") && (
              <BrandZoneDetails
                vistaZoneData={zoneData}
                isFromVista={isFromVista}
              />
            )}

            {name.includes("banner") && (
              <BrandZoneDetails
                vistaZoneData={zoneData}
                isFromVista={isFromVista}
              />
            )} */}

            {name.includes("sponsorbooth") &&
              (showZoneMessage?.flag ? (
                <VistaZoneShowMessage
                  flag={showZoneMessage?.flag}
                  message={showZoneMessage?.message}
                />
              ) : (
                <div className="pb-3">
                  <BrandZoneDetails
                    vistaZoneData={zoneData}
                    isFromVista={isFromVista}
                  />
                </div>
              ))}

            {name.includes("banner") &&
              (showZoneMessage?.flag ? (
                <VistaZoneShowMessage
                  flag={showZoneMessage?.flag}
                  message={showZoneMessage?.message}
                />
              ) : (
                <div className="pb-3">
                  <BrandZoneDetails
                    vistaZoneData={zoneData}
                    isFromVista={isFromVista}
                  />
                </div>
              ))}

            {name.includes("reception") &&
              (showZoneMessage?.flag ? (
                <VistaZoneShowMessage
                  flag={showZoneMessage?.flag}
                  message={showZoneMessage?.message}
                />
              ) : (
                <div className="pb-3">
                  <BrandZoneDetails
                    vistaZoneData={zoneData}
                    isFromVista={isFromVista}
                    showReceptionButtons={true}
                  />
                </div>
              ))}

            {name.includes("hallbooth") &&
              (showZoneMessage?.flag ? (
                <VistaZoneShowMessage
                  flag={showZoneMessage?.flag}
                  message={showZoneMessage?.message}
                />
              ) : (
                <div className="pb-3">
                  <BrandZoneDetails
                    vistaZoneData={zoneData}
                    isFromVista={isFromVista}
                  />
                </div>
              ))}

            {/* {name.includes("hallbooth") && (
              <BrandZoneDetails
                vistaZoneData={zoneData}
                isFromVista={isFromVista}
              />
            )} */}

            {name.includes("eventmap") && (
              <div className="w-100 h-100">
                <VistaMap />
              </div>
            )}

            {name.includes("audimeet") && (
              <div className="w-100 d-flex justify-content-center align-items-center">
                {loading ? (
                  <div
                    style={{ height: "200px" }}
                    className="w-25 bg-white d-flex justify-content-center align-items-center"
                  >
                    <h6>Fetching Details...</h6>
                  </div>
                ) : (
                  <div
                    style={{ height: "200px" }}
                    className="w-25 bg-white d-flex justify-content-center align-items-center"
                  >
                    <h6 className="mt-4">No Session</h6>
                  </div>
                )}
              </div>
            )}

            {name.includes("satmeet") && (
              <div className="w-100 d-flex justify-content-center align-items-center">
                {loading ? (
                  <div
                    style={{ height: "200px" }}
                    className="w-25 bg-white d-flex justify-content-center align-items-center"
                  >
                    <h6>Fetching Details...</h6>
                  </div>
                ) : (
                  <div
                    style={{ height: "200px" }}
                    className="w-25 bg-white d-flex justify-content-center align-items-center"
                  >
                    <h6 className="mt-4">No Session</h6>
                  </div>
                )}
              </div>
            )}

            {name.includes("satellite") && (
              <Auditorium
                isFromVista={isFromVista}
                isFromSatelliteAudi={true}
                history={props?.props?.props?.props?.history}
                onVistaPopUpClose={handleOnClose}
              />
            )}

            {/* {name.includes("audimeet") && (
						!loading ? (
							<div className={`w-100 h-100 bg-white ${!joinSessUrl?"flex_center":""}`} >
								{
									joinSessUrl? (
									<VistaPopupFrame src={joinSessUrl}/>
									):(
										<h5 className="text-center">No Session</h5>
									)
								}
							</div>	
						):(
							<CommonLoader/>
						)
							
					)} */}

            {/* {
							name === "image" && <img style={{ width: "100%", height: "100%" }} src={"https://worldfinancialreview.com/wp-content/uploads/2022/03/Eventos-virtuales-1080x675-1.jpg"}></img>
						}
						{
							name === "video" && <ReactPlayer url={"https://www.youtube.com/watch?v=linlz7-Pnvw"} />
						}
						{
							name === "default" && { children }
                	} */}
          </PopupContent>
        </PopupContainer>
      </ShadowBox>
    </>
  );
};

Popup.defaultProps = {
  onClose: null,
};

Popup.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
};

export default Popup;
