import React, { useEffect, useState } from "react";
import { notification, Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./Header.css";

import {
  acceptMessageHelpSupportRequest,
  acceptMessageRequest,
  acceptVideoRequest,
  clearLocationApi,
  oneToOneRespond,
  updateNotificationStatus,
} from "../../utils/commonApis";
import { getNotifications } from "../../redux/slice/notification";

import "./notification.less";

import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { SendRed } from "../../components/svgJS/SendRed";
import { Video } from "../../components/svgJS/Video";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import {
  setCalendarNotificationData,
  setHelpAndSupportData,
} from "../../redux/slice/headerData";

export function oneToOneChatNotification(props) {
  const { newNotification, dispatch } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            dispatch(getNotifications());
            const res = await updateNotificationStatus({
              notification_id: newNotification?.notification_id,
              is_read: true,
            });

            if (newNotification?.data?.chat_id)
              localStorage.setItem(
                "isMessageScreenWithChatUID",
                newNotification?.data?.chat_id
              );
            window?.open("/messages", "_self");
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Respond</span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function oneToOneVideoNotification(props) {
  const { newNotification, dispatch, socket } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div d-flex">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            // if (newNotification.chat_id)
            //   localStorage.setItem(
            //     "isMessageScreenWithChatUID",
            //     requestAPIData.chat_id
            //   );
            // window.open("/messages", "_self");
            const res = await updateNotificationStatus({
              notification_id: newNotification?.notification_id,
              is_read: true,
            });

            dispatch(getNotifications());

            dispatch(
              setVideoConferenceData({
                videoConferenceFlag: true,
                videoConferenceUrl: newNotification.notification_links,
                is_video_call: true,
                is_call_accepted: true,
              })
            );
            notification.destroy();
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Accept</span>
          </span>
        </Button>
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons ms-2"
          onClick={async () => {
            // if (newNotification.chat_id)
            //   localStorage.setItem(
            //     "isMessageScreenWithChatUID",
            //     requestAPIData.chat_id
            //   );
            // window.open("/messages", "_self");
            const res = await updateNotificationStatus({
              notification_id: newNotification?.notification_id,
              is_read: true,
            });

            socket.emit(
              "rejectVideoCall",
              JSON.stringify({
                chat_id: newNotification?.data?.chat_id,
              })
            );

            dispatch(getNotifications());

            notification.destroy();
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Reject</span>
          </span>
        </Button>
      </div>
    ),
    duration: 0,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function chatAgentNotification(props) {
  const { newNotification, dispatch, socket } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
        {newNotification.notification_type === "CHAT_DELEGATE" ||
        newNotification.notification_type === "CHAT" ? (
          <label className="notification_title_text fs-07rem">
            Your chat has started
          </label>
        ) : null}
        {newNotification?.notification_type === "EVENT_HELP_SUPPORT_DELEGATE" ||
        newNotification?.notification_type === "TECHNICAL_HELP_SUPPORT_DELEGATE"
          ? "Your support has started"
          : null}
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            dispatch(getNotifications());
            if (
              newNotification?.notification_type === "CHAT_AGENT" ||
              newNotification?.notification_type ===
                "EVENT_HELP_SUPPORT_AGENT" ||
              newNotification?.notification_type ===
                "TECHNICAL_HELP_SUPPORT_AGENT"
            ) {
              let chatRes = await acceptMessageRequest({
                message_id: newNotification?.data?.message_id,
                user_id: newNotification?.data?.user_id,
                user_type: newNotification?.data?.user_type,
              });
              console.log("chatRes = ", chatRes);
              if (chatRes?.data?.code === 200) {
                if (newNotification?.notification_type === "CHAT_AGENT") {
                  if (newNotification?.data?.chat_id)
                    localStorage.setItem(
                      "isMessageScreenWithChatUID",
                      newNotification?.data?.chat_id
                    );
                  window.open("/messages", "_self");
                } else window.open("/helpAndSupport", "_self");
              }
            } else {
              if (newNotification?.notification_type === "CHAT_AGENT")
                window.open("/messages", "_self");
              else window.open("/helpAndSupport", "_self");
            }
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">
              {newNotification?.notification_type === "CHAT_AGENT" ||
              newNotification?.notification_type ===
                "EVENT_HELP_SUPPORT_AGENT" ||
              newNotification?.notification_type ===
                "TECHNICAL_HELP_SUPPORT_AGENT"
                ? "Respond"
                : "Accept"}
            </span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function videoAgentNotification(props) {
  const { newNotification, dispatch, socket, loginUserId, userType } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
        {newNotification?.notification_type === "VIDEO_DELEGATE" ? (
          <label className="notification_title_text fs-07rem">
            Your video call has started
          </label>
        ) : null}
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            if (newNotification?.notification_type === "VIDEO_AGENT") {
              let videoRes = await acceptVideoRequest({
                call_request_id: newNotification?.data?.call_request_id,
                user_id: newNotification?.data?.user_id,
                video_call_link: newNotification?.data?.video_call_link,
                agent_id: loginUserId,
                user_type: userType,
              });
              console.log("videoRes = ", videoRes);
              if (
                videoRes?.data?.code === 200 &&
                newNotification?.data?.video_call_link
              ) {
                dispatch(
                  setVideoConferenceData({
                    videoConferenceFlag: true,
                    videoConferenceUrl: newNotification?.data?.video_call_link,
                    is_video_call: true,
                    receiver_chat_id: newNotification?.data?.user_id,
                  })
                );
              }
              notification.destroy();
            }
            // else window.open(payload.data.body, "_blank");
          }}
        >
          <span className="ms-3">
            <Video />
            <span className="ms-2">Accept</span>
          </span>
        </Button>
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons ms-2"
          onClick={async () => {
            const res = await updateNotificationStatus({
              notification_id: newNotification?.notification_id,
              is_read: true,
            });

            socket.emit(
              "rejectVideoCall",
              JSON.stringify({
                user_id: newNotification?.data?.user_id,
                notificationFrom: "helpandsupport",
                isAgent: true,
              })
            );

            dispatch(getNotifications());

            notification.destroy();
          }}
        >
          <span className="ms-3">
            <Video />
            <span className="ms-2">Reject</span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 0,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification?.close()}
      />
    ),
  });
}

export function chatDelegateNotification(props) {
  const { newNotification, dispatch, socket } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          onClick={async () => {
            dispatch(getNotifications());
            if (newNotification?.data?.chat_id)
              localStorage.setItem(
                "isMessageScreenWithChatUID",
                newNotification?.data?.chat_id
              );
            window?.open("/messages", "_self");
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Respond</span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function videoDelegateNotification(props) {
  const { newNotification, dispatch, socket } = props;

  console.log("new notification receiver", newNotification.notification_links);
  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            dispatch(getNotifications());

            dispatch(
              setVideoConferenceData({
                videoConferenceFlag: true,
                videoConferenceUrl: newNotification?.notification_links,
                is_video_call: true,
                receiver_chat_id: newNotification?.agent_id,
              })
            );
            notification.destroy();
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Accept</span>
          </span>
        </Button>
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons ms-2"
          onClick={async () => {
            const res = await updateNotificationStatus({
              notification_id: newNotification?.notification_id,
              is_read: true,
            });

            socket.emit(
              "rejectVideoCall",
              JSON.stringify({
                user_id: newNotification?.agent_id,
                notificationFrom: "helpandsupport",
              })
            );

            dispatch(getNotifications());

            notification.destroy();
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Reject</span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 0,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function eventHelpSupportAgentNotification(props) {
  const { newNotification, dispatch, socket, history, userType } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="audi_join_sessionBtn t1_notification_title_buttons"
          onClick={async () => {
            dispatch(getNotifications());

            let chatRes = await acceptMessageHelpSupportRequest({
              message_id: newNotification?.data?.message_id,
              user_id: newNotification?.data?.user_id,
              support_type:
                newNotification?.notification_type ===
                "EVENT_HELP_SUPPORT_AGENT"
                  ? "EVENT SUPPORT"
                  : newNotification?.notification_type ===
                    "TECHNICAL_HELP_SUPPORT_AGENT"
                  ? "EVENT TECHNICAL SUPPORT"
                  : "",
              user_type: newNotification?.notification_from_user_type,
            });
            console.log("chatRes = ", chatRes);
            if (chatRes?.data?.code === 200) {
              dispatch(
                setHelpAndSupportData({
                  supportType: newNotification?.notification_type,
                  userId: newNotification?.data?.user_id,
                })
              );

              history.push("/helpAndSupport");
            }
          }}
        >
          <span className="ms-3">
            <SendRed />
            <span className="ms-2">Respond</span>
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function eventHelpSupportDelegateNotification(props) {
  const { newNotification, dispatch, socket } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
        {newNotification?.notification_type === "EVENT_HELP_SUPPORT_DELEGATE" ||
        newNotification?.notification_type === "TECHNICAL_HELP_SUPPORT_DELEGATE"
          ? "Your support has started"
          : null}
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}

export function allTypesAlert(props) {
  const { newNotification, dispatch, userType } = props;

  notification.open({
    message: (
      <div className="t1_notification_title_div">
        <label className="notification_title_text fs-1rem">
          {newNotification?.notification_text}
        </label>
      </div>
    ),
    description: (
      <div className="t1_notification_description_div">
        <Button
          className="px-3 rounded btn-svg t1_notification_title_buttons"
          onClick={async () => {
            if (newNotification?.notification_type === "MEETING") {
              const res = await updateNotificationStatus({
                notification_id: newNotification?.notification_id,
                is_read: true,
              });

              dispatch(
                setCalendarNotificationData({
                  hallType: newNotification?.data?.hall_type,
                  sessionId: newNotification?.data?.session_id,
                })
              );

              props?.history?.push("/mycalendar");
            } else if (
              newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_NETWORKING_LOUNGE"
            )
              window.open("/networking", "_self");
            else if (
              newNotification?.notification_type ===
              "MY_CALENDAR_ALERTS_MAIN_AUDITORIUM"
            )
              window.open("/auditorium", "_self");
          }}
        >
          <CalenderGrey />
          <span className="mx-2">
            {newNotification?.notification_type === "MEETING"
              ? "My Calendar"
              : "Join Now"}
          </span>
        </Button>
        <label></label>
      </div>
    ),
    duration: 3,
    className: "t1_notification_parent",
    closeIcon: (
      <CloseOutlined
        className="float-right mr-0 notification_close"
        onClick={() => notification.close()}
      />
    ),
  });
}
