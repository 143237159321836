import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Popover,
  Breadcrumb,
  Input,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DownOutlined,
  PlusSquareOutlined,
} from "@ant-design/icons";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { getUsers, changeStatus } from "../../redux/slice/userManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Filter } from "../../components/svgJS/Filter";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  getJobTitles,
  getCountryList,
  getZones,
  getEventsForListing,
  getBrandsForListing,
} from "../../utils/commonApis";
import {
  createTZString,
  getCookie,
  generateHelmet,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import { cloneDeep, isEmpty } from "lodash";
import { useMediaQuery } from "react-responsive";
import placeholder_image from "../../assets/images/profile_placeholder.png";

const UserManagement = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  var CryptoJS = require("crypto-js");
  let loading = useSelector((state) => state.userManagement.loading);
  const userSelector = useSelector((state) => state?.userManagement?.data);
  const [userType, setUserType] = useState("");
  const [searchData, setsearchData] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [jobTitle, setJobTitle] = useState([]);
  const [zoneList, setZoneList] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [userID, setUserID] = useState(null);
  const [fields, setFields] = useState({
    event_id: null,
    brand_id: null,
    zone_id: null,
    user_type: null,
    country_id: null,
    job_title_id: null,
    brand_booth_visited: null,
  });
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  const Tablet = useMediaQuery({ maxWidth: 900 });

  const [isTickerDisplay, setIsTickerDisplay] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("tickerToDisplay")) {
      setIsTickerDisplay(true);
    } else {
      setIsTickerDisplay(false);
    }
  }, [localStorage.getItem("tickerToDisplay")]);

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);

      dispatch(
        getUsers({
          page_no,
          no_of_records,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          current_timestamp: moment().format("YYYY-MM-DD"),
        })
      );
      let eventResponse = await getEventsForListing({ is_filter: true });
      setEventList(eventResponse);
    }

    if (getCookie("user_id")) {
      let user_id = null;
      const ciphertext = getCookie("user_id");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserID(user_id);
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    const brandParams = {
      is_filter: true,
      event_id: fields?.event_id,
    };
    let brandResponse = await getBrandsForListing(brandParams);
    setBrandList(brandResponse);
  }, [fields?.event_id]);

  useEffect(async () => {
    const zoneParams = {
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
    };
    const zonesHere = await getZones(zoneParams);
    setZoneList(zonesHere);
  }, [fields?.event_id, fields?.brand_id]);

  useEffect(async () => {
    const countryParams = {
      is_filter: true,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
    };
    const countryListAPI = await getCountryList(countryParams);
    setCountryList(countryListAPI);

    const Jobparams = {
      is_filter: true,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
    };
    const jobTitlesListAPI = await getJobTitles(Jobparams);
    setJobTitle(jobTitlesListAPI);
  }, [fields?.event_id, fields?.brand_id, fields?.zone_id, fields?.user_type]);

  const tableData =
    userSelector &&
    !isEmpty(userSelector) &&
    userSelector.rows &&
    userSelector.rows.map((elem) => {
      const start_date_time_createdAt = createTZString(elem.created_at);

      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      return {
        user_id: elem.user_id,
        created_at: eleStartDateCreatedAt.format("DD-MM-YYYY"),
        username:
          elem?.first_name !== null && elem?.last_name !== null
            ? elem?.first_name + " " + elem?.last_name
            : "NA",
        brand: elem.brand_title ?? "NA",
        event: elem.event_name ?? "NA",
        emailaddress: elem.email,
        profile: elem?.image_url ? elem?.image_url : placeholder_image,
        user_type: elem.user_type,
        country: elem?.country?.country,
        is_active: elem.is_active,
        is_admin: elem.is_admin,
      };
    });

  const columns = [
    {
      title: "ID",
      dataIndex: "user_id",
      key: "user_id",
      width: 70,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "Profile",
      dataIndex: "profile",
      width: 120,
      align: "center",
      render: (record) =>
        record ? (
          <img src={record} alt="pro pic" className="user-profile-picture" />
        ) : (
          "-"
        ),
    },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "User Type",
      dataIndex: "user_type",
      key: "user_type",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "emailaddress",
      key: "emailaddress",
      width: 200,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Event",
      dataIndex: "event",
      key: "event",
      width: 140,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      width: 110,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Status",
      dataIndex: "is_active",
      key: "is_active",
      width: 120,
      align: "center",
      render: (_, record) => (
        <button
          className={
            record.is_active === true
              ? "bg-button-green active_btns"
              : "bg-button-red inactive_btns"
          }
          onClick={() => handleStatus(record.user_id, record.is_active)}
          shape="round"
        >
          {record.is_active === true ? "Active" : "Inactive"}
        </button>
      ),
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
    },
    {
      title: "",
      width: 40,
      dataIndex: "operation",
      fixed: "right",
      align: "center",
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewuser/${record.user_id}`}>
                <p className="options">
                  <EyeOutlined className="px-1" />
                  <span className="">View Details</span>
                </p>
              </Link>
              {userType && userType === "BRAND ADMIN" ? (
                <Link to={`edituser/${record.user_id}`}>
                  <p className="options">
                    <EditOutlined className="px-1" />
                    <span className="">Edit User</span>
                  </p>
                </Link>
              ) : (
                <Link to={`edituser/${record.user_id}`}>
                  <p className="options">
                    <EditOutlined className="px-1" />
                    <span className="">Edit User</span>
                  </p>
                </Link>
              )}
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      width: 65,
      fixed: "right",
      render: (record) => (
        <div className="px-1">
          <Link to={`edituser/${record.user_id}`}>
            <p className="options mt-2">
              <EditOutlined className="px-1" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleStatus = (id, bool) => {
    dispatch(
      changeStatus({
        user_id: id,
        is_active: !bool,
      })
    );
  };

  const handleFilterChange = (name, val) => {
    let cloneFields = cloneDeep(fields);
    if (name === "event_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        brand_id: null,
        zone_id: null,
        user_type: null,
        country_id: null,
        job_title_id: null,
      };
    } else if (name === "brand_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        zone_id: null,
        user_type: null,
        country_id: null,
        job_title_id: null,
      };
    } else if (name === "zone_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        user_type: null,
        country_id: null,
        job_title_id: null,
      };
    } else if (name === "user_type") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        country_id: null,
        job_title_id: null,
      };
    } else {
      cloneFields = { ...cloneFields, [name]: val };
    }
    setFields({ ...cloneFields });

    const searchObj = {
      search: searchData,
      event_id: cloneFields?.event_id,
      brand_id: cloneFields?.brand_id,
      zone_id: cloneFields?.zone_id,
      user_type: cloneFields?.user_type,
      country_id: cloneFields?.country_id,
      job_title_id: cloneFields?.job_title_id,
      page_no,
      no_of_records,
    };
    dispatch(getUsers(searchObj));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
      country_id: fields?.country_id,
      job_title_id: fields?.job_title_id,
      brand_booth_visited: fields?.brand_booth_visited,
    };
    dispatch(getUsers(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      zone_id: fields?.zone_id,
      user_type: fields?.user_type,
      country_id: fields?.country_id,
      job_title_id: fields?.job_title_id,
      page_no,
      no_of_records,
    };
    dispatch(getUsers(searchObj));
  };

  return (
    <div>
      <div className="d-flex justify-content-between  listing_upper_div">
        {generateHelmet("Gravit8", "User Management")}
        <div>
          <h4 className={`${Tablet} ? "fs-16":"fs-24"`}>User Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>
              <Link to="/usermanagement">Admin Console</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>User Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div className="d-flex">
          <Link to="adduser">
            <Button className="rounded px-3 py-1 mt-2 mb-1">
              <span className="fw-bold"> + Add User </span>
            </Button>
          </Link>
          <Link
            to={{
              pathname: "inviteAttend",
              state: { fromUser: true },
            }}
          >
            <Button className="rounded px-3 py-1 mt-2 mb-1 mx-3">
              <span className="fw-bold"> Invite to Attend </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 listing_lower_div">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50 mt-3">
            <Input
              size="medium"
              value={searchData}
              onChange={handleInputSearch}
              placeholder="Search by User Name, ID, Email ID,  Brand, Event Name, Customer"
              style={{ width: "70%" }}
              prefix={<SearchOutlined />}
            />
          </div>
          {loading ? null : (
            <div className="d-flex mt-3">
              <Button
                className=" rounded"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>
            </div>
          )}
        </div>
        {showFilter && !loading ? (
          <div className="mb-3 mt-1">
            <div className="w-100">
              <div className="d-flex user_filter_select_container">
                <Select
                  allowClear
                  name="brand_id"
                  placeholder="Select Event"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("event_id", val)}
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  optionFilterProp="children"
                  value={fields?.event_id}
                >
                  {eventList &&
                    eventList?.map((data) => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>
                <Select
                  allowClear
                  name="brand_id"
                  placeholder="Select Brand"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("brand_id", val)}
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  optionFilterProp="children"
                  value={fields?.brand_id}
                >
                  {brandList &&
                    brandList?.map((data) => {
                      return (
                        <Option value={data.brand_id}>
                          {data.brand_title}
                        </Option>
                      );
                    })}
                </Select>

                <Select
                  placeholder="Select Zone"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("zone_id", val)}
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  value={fields?.zone_id}
                >
                  {zoneList &&
                    zoneList.map((data) => (
                      <Option value={data.zone_id}>{data.zone_title}</Option>
                    ))}
                </Select>
                <Select
                  placeholder="Select User Type"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("user_type", val)}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  optionFilterProp="children"
                  value={fields?.user_type}
                >
                  <Option value="AGENT">AGENT</Option>
                  <Option value="DELEGATE">DELEGATE</Option>
                  <Option value="SPEAKER">SPEAKER</Option>
                </Select>

                <Select
                  placeholder="Select Country"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("country_id", val)}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  optionFilterProp="children"
                  value={fields?.country_id}
                >
                  {countryList &&
                    countryList?.map((data) => {
                      return (
                        <Option value={data.country_id}>{data.country}</Option>
                      );
                    })}
                </Select>
                <Select
                  placeholder="Select Job"
                  className="filter_select_fields mx-1"
                  onChange={(val) => handleFilterChange("job_title_id", val)}
                  allowClear
                  listHeight={120}
                  listItemHeight={4}
                  showSearch
                  optionFilterProp="children"
                  value={fields?.job_title_id}
                >
                  {jobTitle &&
                    jobTitle?.map((data) => {
                      return (
                        <Option value={data.job_title_id}>
                          {data.job_title}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <Table
          columns={columns}
          dataSource={!loading ? tableData : []}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: userSelector?.count ? userSelector?.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{
            x: 1300,
            y: isTickerDisplay ? (showFilter ? "44vh" : "50vh") : "58vh",
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default UserManagement;
