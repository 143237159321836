import React, { useEffect, useRef, useState } from "react";
import { useStateIfMounted } from "use-state-if-mounted";
import "./index.css";
import { Button, Card, Input } from "antd";
import SimpleReactValidator from "simple-react-validator";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../redux/slice/login";
import { toast } from "react-toastify";
import {
  COMETCHAT_CONSTANTS,
  INPUT_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import { setHeaderImage } from "../../redux/slice/headerData";
import CryptoAES from "crypto-js/aes";
import { generateRandomKey, setCookie } from "../../utils/commonFunctions";
import moment from "moment";
import { CometChat } from "@cometchat-pro/chat";

export const handleLoginAction = async (
  email,
  password,
  props,
  dispatch,
  eventId
) => {
  var CryptoJS = require("crypto-js");

  let loginData = { email, password };

  if (eventId) {
    loginData.event_id = eventId; //Called from Registration module
  } else {
    if (localStorage.getItem("event_id"))
      loginData.event_id = localStorage.getItem("event_id");
  }

  if (localStorage.getItem("fbase_token"))
    loginData.fbase_token = localStorage.getItem("fbase_token");

  let generatedKey = generateRandomKey();

  let ciphertext2 = CryptoAES.encrypt(
    JSON.stringify(loginData),
    generatedKey
  ).toString();

  let obj = {
    data: generatedKey + ":" + ciphertext2,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    current_timestamp: moment().format("YYYY-MM-DD HH:mm:ss"),
  };

  const loginResp = await dispatch(login(obj));

  const createButtonColorObj = (data) => {
    return {
      default_button_background_color: data?.default_button_background_color,
      default_button_background_second_color:
        data?.default_button_background_second_color
          ? data?.default_button_background_second_color
          : data?.default_button_background_color,
      default_button_gradient_angle: data?.default_button_gradient_angle
        ? data?.default_button_gradient_angle + "deg"
        : 0 + "deg",
      default_button_text_color: data?.default_button_text_color,
      highlight_button_background_color:
        data?.highlight_button_background_color,
      highlight_button_background_second_color:
        data?.highlight_button_background_second_color
          ? data?.highlight_button_background_second_color
          : data?.highlight_button_background_color,
      highlight_button_gradient_angle: data?.highlight_button_gradient_angle
        ? data?.highlight_button_gradient_angle + "deg"
        : 0 + "deg",
      highlight_button_text_color: data?.highlight_button_text_color,
    };
  };

  if (loginResp?.payload?.code === 200) {
    const sideMenuData = loginResp.payload.data.menu_acces_details;
    const userType = loginResp.payload.data.userType;
    const userName = loginResp.payload.data.user_name;
    const pre_event_msg =
      loginResp?.payload?.data?.pre_event_msg?.pre_event_media_url ?? null;
    const welcome_msg =
      loginResp?.payload?.data?.welcome_msg?.welcome_media_url ?? null;
    let first_time_login_based_on_event_survey =
      loginResp.payload.data?.first_time_login_based_on_event_survey ?? null;
    let registration_survey_id =
      loginResp.payload.data?.registration_survey_id ?? null;
    let is_profile_access = loginResp?.payload?.data?.is_profile_access;
    let is_social_link_access = loginResp?.payload?.data?.is_social_link_access;
    let user_id =
      loginResp?.payload?.data?.user_id ??
      loginResp?.payload?.data?.customer_id;

    let mediaReactionDetails = loginResp?.payload?.data?.mediaReactionDetails;
    let isPreEvent = loginResp?.payload?.data?.is_preevent;
    let brand_id = loginResp?.payload?.data?.brand_id;
    let event_logo_url = loginResp?.payload?.data?.eventColor?.event_logo_url;

    //Default Button Colors for Super Admin and Customer
    let finalButtonColors = {
      default_button_background_color: "#ffffff",
      default_button_background_second_color: "#ffffff",
      default_button_gradient_angle: 0 + "deg",
      default_button_text_color: "#ef0855",
      highlight_button_background_color: "#ef0855",
      highlight_button_background_second_color: "#ef0855",
      highlight_button_gradient_angle: 0 + "deg",
      highlight_button_text_color: "#ffffff",
    };

    var ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(sideMenuData),
      "side_menu"
    ).toString();
    var ciphertext_user_type = CryptoJS.AES.encrypt(
      JSON.stringify(userType),
      "user_type"
    ).toString();
    var ciphertext_user_name = CryptoJS.AES.encrypt(
      JSON.stringify(userName),
      "user_name"
    ).toString();

    var ciphertext_user_id = CryptoJS.AES.encrypt(
      JSON.stringify(user_id),
      "user_id"
    ).toString();

    // client cookie changes
    setCookie("user_name", ciphertext_user_name, 1);
    setCookie("Authorization", loginResp?.payload?.data?.token, 1);
    setCookie("user_id", ciphertext_user_id, 1);

    localStorage.setItem("brand_id", brand_id);
    localStorage.setItem("side_menu", ciphertext);
    localStorage.setItem("user_type", ciphertext_user_type);
    localStorage.setItem("event_id", loginResp.payload.data.event_id);
    localStorage.setItem("chat_uid", loginResp.payload.data.chat_uid);
    localStorage.setItem("customer_id", loginResp.payload.data.customer_id);
    localStorage.setItem("logo_url", loginResp.payload.data.image_url);
    localStorage.setItem(
      "mediaReactionDetails",
      JSON.stringify(mediaReactionDetails)
    );

    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(COMETCHAT_CONSTANTS.REGION)
      .build();
    CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting);

    dispatch(
      setHeaderImage({
        logo_url: loginResp.payload.data.image_url,
      })
    );
    localStorage.setItem("userType", userType);
    localStorage.setItem(
      "pre_event_msg",
      loginResp.payload.data?.pre_event_msg?.pre_event_media_url ?? " "
    );
    localStorage.setItem(
      "welcome_msg",
      loginResp.payload.data?.welcome_msg?.welcome_media_url ?? " "
    );
    localStorage.setItem(
      "first_time_login",
      loginResp?.payload?.data?.first_time_login
    );

    localStorage.setItem("skip_button", loginResp?.payload?.data?.skip_button);
    localStorage.setItem("isPreEvent", isPreEvent);
    // localStorage.setItem(
    //   "skip_pre_event_video",
    //   loginResp?.payload?.data?.skip_pre_event_video
    // );
    // localStorage.setItem(
    //   "skip_welcome_video",
    //   loginResp?.payload?.data?.skip_welcome_video
    // );
    if (loginResp?.payload?.data?.control_media_url) {
      localStorage.setItem(
        "control_media_url",
        loginResp?.payload?.data?.control_media_url
      );
    }

    if (userType !== "SUPER ADMIN" && userType !== "CUSTOMER") {
      localStorage.setItem("event_logo_url", event_logo_url);
    }

    if (loginResp?.payload?.data?.eventColor?.event_name?.length > 0) {
      localStorage.setItem(
        "event_name",
        loginResp?.payload?.data?.eventColor?.event_name
      );
    }

    if (loginResp?.payload?.data?.color) {
      localStorage.setItem(
        "brand_logo_for_menu",
        loginResp?.payload?.data?.color?.logo_url
      );

      localStorage.setItem(
        "minimized_brand_logo_for_menu",
        loginResp?.payload?.data?.color?.minimized_logo_url
      );
    }

    localStorage.setItem(
      "first_time_login_based_on_event_survey",
      first_time_login_based_on_event_survey
    );
    localStorage.setItem("registration_survey_id", registration_survey_id);
    localStorage.setItem(
      "welcome_title",
      loginResp.payload.data?.message_title ?? " "
    );
    localStorage.setItem(
      "welcome_description",
      loginResp.payload.data?.message_description ?? " "
    );

    if (is_profile_access)
      localStorage.setItem("is_profile_access", is_profile_access);

    if (is_social_link_access)
      localStorage.setItem("is_social_link_access", is_social_link_access);

    let customThemeColorAndRoute = {
      color: "",
      route: "",
    };

    if (userType && (userType === "AGENT" || userType === "BRAND ADMIN")) {
      if (loginResp.payload.data.color) {
        let colorObj = loginResp.payload.data.color;
        customThemeColorAndRoute = {
          color: colorObj.brand_color, //Event Color
          route: "/mycalendar",
        };

        finalButtonColors = createButtonColorObj(colorObj);
      }
    } else if (userType === "DELEGATE") {
      if (loginResp.payload.data.eventColor) {
        let colorObj = loginResp?.payload?.data?.eventColor;

        customThemeColorAndRoute = {
          color: colorObj.event_color_theme ?? "#ef0855", //Event color
          route:
            first_time_login_based_on_event_survey !== false &&
            registration_survey_id !== null
              ? "/registrationsurvey"
              : pre_event_msg !== null
              ? "/welcomepage"
              : welcome_msg !== null
              ? "/welcomepage"
              : isPreEvent === true
              ? "/mycalendar"
              : "/concourse",
        };

        finalButtonColors = createButtonColorObj(colorObj);
      }
    } else if (userType === "SPEAKER") {
      if (loginResp.payload.data.eventColor) {
        let colorObj = loginResp?.payload?.data?.eventColor;

        customThemeColorAndRoute = {
          color: colorObj.event_color_theme ?? "#ef0855",
          route: "/mycalendar",
        };

        finalButtonColors = createButtonColorObj(colorObj);
      }
    } else {
      //Super Admin and Customer
      customThemeColorAndRoute = {
        color: "#ef0855",
        route: "/eventmanagement",
      };
    }
    window.less
      .modifyVars({
        "@primary-color": customThemeColorAndRoute.color, //Event Color
        "@default_button_background_color":
          finalButtonColors.default_button_background_color,
        "@default_button_background_second_color":
          finalButtonColors.default_button_background_second_color,
        "@default_button_text_color":
          finalButtonColors.default_button_text_color,
        "@highlight_button_background_color":
          finalButtonColors.highlight_button_background_color,
        "@highlight_button_background_second_color":
          finalButtonColors.highlight_button_background_second_color,
        "@highlight_button_text_color":
          finalButtonColors.highlight_button_text_color,
        "@default_button_gradient_angle":
          finalButtonColors.default_button_gradient_angle,
        "@highlight_button_gradient_angle":
          finalButtonColors.highlight_button_gradient_angle,
      })
      .then(() => {});

    localStorage.setItem("event_color_theme", customThemeColorAndRoute.color);
    localStorage.setItem("btnColorData", JSON.stringify(finalButtonColors));
    toast.success(loginResp.payload.message);
    if (props.loginProps) {
      props.loginProps.history.push(customThemeColorAndRoute.route);
    } else {
      props.history.push(customThemeColorAndRoute.route); //props from registration page
    }
  }
  if (loginResp?.payload?.code === 403 || loginResp?.payload?.code === 500) {
    localStorage.removeItem("Authorization");
    toast.error(loginResp.payload.message);
    props.loginProps.history.push("/login");
  }
};

const LoginBox = (props) => {
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const isMobile = useMediaQuery({ minWidth: 480 });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forceUpdate, setForceUpdate] = useStateIfMounted(false);
  const validator = useRef(new SimpleReactValidator());
  const { loading } = useSelector((state) => state.login);
  const btnRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        btnRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleEmailInputChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordInputChange = (event) => {
    setPassword(event.target.value);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    if (validator.current.allValid()) {
      handleLoginAction(email, password, props, dispatch);
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  return (
    <>
      {props.isRenderLogin ? (
        <Card
          bordered={true}
          style={{
            position: "absolute",
            top: "150px",
            left: "0",
            right: "0",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className={
            !isMobile
              ? "m-auto w-75 mt-4 rounded login_temp"
              : `m-auto ${Tablet ? "w-50" : "w-25"} mt-4 rounded login_temp`
          }
        >
          <h5 className="text-center mt-3 fs-26 fs-pink"> Sign in </h5>
          <form>
            <div className="form-group text-start mt-4">
              <label className="fs-16">Email</label>
              <Input
                name="email"
                className="form-control py-2"
                maxLength={INPUT_TEXT_LIMITS.MAX}
                key={1}
                value={email}
                placeholder="Enter email address"
                onChange={handleEmailInputChange}
                onBlur={() => validator.current.showMessageFor("email")}
                autoComplete="off"
              />
              {validator.current.message("email", email, "required|email", {
                className: "text-danger",
              })}
            </div>

            <div className="form-group text-start mt-4 ">
              <span className="d-flex justify-content-between">
                <label className="fs-16">Password</label>
                <span
                  className="fs-pink cursor-pointer"
                  onClick={() => {
                    props?.loginProps?.history.push("/forgotpass");
                  }}
                >
                  Forgot Password?
                </span>
              </span>
              <span>
                <Input.Password
                  name="password"
                  type="password"
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                  className="login_passInput form-control py-2"
                  placeholder="Enter password"
                  value={password}
                  onChange={handlePasswordInputChange}
                  onBlur={() => validator.current.showMessageFor("password")}
                  autoComplete="off"
                />
                {validator.current.message(
                  "password",
                  password,
                  "required|min:5",
                  { className: "text-danger" }
                )}
              </span>
            </div>
            <Button
              type="primary"
              className=" my-2 w-100 mt-3 bg-pink"
              loading={loading}
              ref={btnRef}
              onClick={submitForm}
              size="large"
            >
              Sign In
            </Button>
          </form>
        </Card>
      ) : null}
    </>
  );
};

export default LoginBox;
