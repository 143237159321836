import React, { useState, useEffect, useRef } from "react";
import {
  Select,
  Switch,
  Button,
  Breadcrumb,
  Input,
  Modal,
  Tooltip,
} from "antd";
import "./index.css";
import { Spin } from "antd";
import {
  LoadingOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Download } from "../../components/svgJS/Download";
import { addNewBrand } from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { EyeView } from "../../components/svgJS/EyeView";
import FileUpload from "../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import {
  getBrandDropdowns,
  uploadVistaFile,
  uploadVistaFileBrand,
} from "../../utils/commonApis";
import ExpoHalls from "../ExpoMap/ExpoHalls";
import {
  isRouteExistInMenu,
  isValidHttpURL,
  isValidImage,
} from "./../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import { cloneDeep } from "lodash";
import CropImageModal from "../common/Modal/CropImageModal";
import ColorPickerInput from "../common/ColorPickerInput";

const CreateNewBrand = (props) => {
  var CryptoJS = require("crypto-js");
  const { Option } = Select;
  const validator = useRef(new SimpleReactValidator());
  const [forceUpdate, setForceUpdate] = useState(false);
  const [userType, setUserType] = useState("");
  const [allAgentsList, setAllAgentsList] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [brandLogoFileState, setBrandLogoFileState] = useState(null);
  const [brandLogoMinimizedFileState, setBrandLogoMinimizedFileState] =
    useState(null);
  const [wallGraphicsFileState, setWallGraphicsFileState] = useState(null);
  const [wallGraphicsFileUrl, setWallGraphicsFileUrl] = useState(null);
  const [wallGraphicsFileLoader, setWallGraphicsFileLoader] = useState(null);
  const [finalBoothFileState, setFinalBoothFileState] = useState(null);
  const [finalBoothFileUrl, setFinalBoothFileUrl] = useState(null);
  const [finalBoothFileLoader, setFinalBoothFileLoader] = useState(null);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [brandLogo, setBrandLogo] = useState();
  const [brandLogoMinimized, setBrandLogoMinimized] = useState();
  const [expoHallModalVisible, setExpoHallModalVisible] = useState(false);
  const [brandData, setBrandData] = useState([]);
  const [expoHallDetails, setExpoHallDetails] = useState([]);
  const [fields, setFields] = useState({
    is_active: true,
    customer_account: props?.customer_id ?? null,
    event_id: props?.event_id ?? null,
    brand_name: "",
    brand_description: "",
    brand_tier: null,
    brand_color: "#ef0855",
    default_button_background_color: "#ffffff",
    is_default_button_gradient_active: false,
    default_button_gradient_angle: 45,
    default_button_background_second_color: null,
    default_button_text_color: "#ef0855",
    highlight_button_background_color: "#ef0855",
    is_highlight_button_gradient_active: false,
    highlight_button_gradient_angle: 45,
    highlight_button_background_second_color: null,
    highlight_button_text_color: "#ffffff",
    location_id: "",
    default_zone_id: null,
    brand_agent_details: selectedAgents,
    brand_website: "",
    brand_social_media_details: [],
    expo_hall_configuration_id: "",
    sequence: "",
  });

  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const { loading } = useSelector((state) => state.brandManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const dispatch = useDispatch();
  const { TextArea } = Input;

  const [showBrandLogoCropModal, setShowBrandLogoCropModal] = useState(false);
  const [showMinimizeBrandLogoCropModal, setShowMinimizeBrandLogoCropModal] =
    useState(false);

  const [cropImagePreview, setCropImagePreview] = useState();
  const [graphicsDownloadLink, setGraphicsDownloadLink] = useState("");

  useEffect(async () => {
    setLoadingBrand(true);

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(decryptedData);

      getCustomerList();
      getTierList();
      getSocialMediaList();
      setFields({ ...fields, brand_agent_details: selectedAgents });
      setTimeout(() => {
        setLoadingBrand(false);
      }, 1000);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    getEventList();
  }, [fields?.customer_account]);

  useEffect(async () => {
    getAgentsList();
    getExpoHallDetails();
    const brandListForDropdown = await getBrandDropdowns(
      fields?.event_id ? fields?.event_id : ""
    );
    setBrandData(brandListForDropdown);
  }, [fields.event_id]);

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details?.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (checked) {
      brand_social_media_details?.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url:
          idx === 0
            ? "https://www.instagram.com/" //CR point -> To add prefix when toggle is on
            : idx === 1
            ? "https://www.linkedin.com/"
            : idx === 2
            ? "https://twitter.com/"
            : idx === 3
            ? "https://www.facebook.com/"
            : "",
      });
    } else {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details.splice(index, 1);
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const socialMediaHandleChange = (idx, event) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (isAvailable) {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details[index]["social_media_url"] =
        event.target.value;
    } else {
      brand_social_media_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: event.target.value,
      });
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const handleFieldsChange = (val, name) => {
    if (name === "is_default_button_gradient_active") {
      if (val) {
        setFields({
          ...fields,
          [name]: val,
          default_button_background_second_color: "#213cc4",
        });
      } else {
        setFields({
          ...fields,
          [name]: val,
          default_button_background_second_color: null,
        });
      }
    } else if (name === "is_highlight_button_gradient_active") {
      if (val) {
        setFields({
          ...fields,
          [name]: val,
          highlight_button_background_second_color: "#213cc4",
        });
      } else {
        setFields({
          ...fields,
          [name]: val,
          highlight_button_background_second_color: null,
        });
      }
    } else {
      setFields({ ...fields, [name]: val });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const agentsIdArr = selectedAgents.map((elem) => elem.user_id);

    let formData = new FormData();
    fields.brand_website = "www.testweb.com";
    fields.brand_agent_details = agentsIdArr;
    fields["wall_graphics_url"] = wallGraphicsFileUrl;
    fields["final_booth_url"] = finalBoothFileUrl;
    formData.append("data", JSON.stringify(fields));
    formData.append("brand_logo_file", brandLogoFileState);
    formData.append("minimized_logo_file", brandLogoMinimizedFileState);

    if (validator.current.allValid()) {
      let allURLValid = "yes";
      if (fields && fields.brand_social_media_details) {
        let clonedLinks = cloneDeep(fields.brand_social_media_details);
        fields.brand_social_media_details.forEach((ele, index) => {
          if (
            ele.social_media_url === "" ||
            !isValidHttpURL(ele.social_media_url) ||
            ele.social_media_url === "https://www.instagram.com/" || //Cr Point -> User should not be able to save this if its just  https://www.instagram.com/ there should user id after the last "/".
            ele.social_media_url === "https://www.facebook.com/" ||
            ele.social_media_url === "https://twitter.com/" ||
            ele.social_media_url === "https://www.linkedin.com/"
          ) {
            clonedLinks[index]["urlValid"] = "no";
            allURLValid = "no";
          }
        });
        if (allURLValid === "no")
          setFields({
            ...fields,
            brand_social_media_details: clonedLinks,
          });
      }

      if (allURLValid === "no") {
        toast.error("Please check social media links");
        return;
      }

      setLoadingBrand(true);
      const addNewBrandResp = await dispatch(addNewBrand(formData));
      if (addNewBrandResp.payload.data.code === 200) {
        toast.success(addNewBrandResp.payload.data.message);
        setLoadingBrand(false);
        if (props.isFromEvent) props.onSuccessFromModal();
        else props.history.push("brandmanagement");
      }
      if (addNewBrandResp.payload.data.code !== 200) {
        setLoadingBrand(false);
        toast.error(addNewBrandResp.payload.data.message);
      }
    } else {
      validator.current.showMessages();
      setLoadingBrand(false);
      toast.error("Fields are missing");
      setForceUpdate(!forceUpdate);
    }
  };

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);

        if (rows.length < 2) {
          setFields({ ...fields, customer_account: rows[0].customer_id });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : null,
    })
      .then((response) => {
        if (response?.data?.data?.default_graphic_template) {
          setGraphicsDownloadLink(
            response?.data?.data?.default_graphic_template
          );
        }
        const { rows } = response?.data?.data;
        if (rows) {
          setEventList(rows);

          if (rows.length === 1) {
            setFields({ ...fields, event_id: rows[0]?.event_id });
          }
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        setTierList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getAgentsList = () => {
    ApiCall("get", null, "/admin/usertypeagents/get", {
      event_id: fields?.event_id ? fields.event_id : null,
    })
      .then((response) => {
        setAllAgentsList(response?.data?.data?.rows);
        setAgents(response?.data?.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getExpoHallDetails = async () => {
    const response = await ApiCall(
      "GET",
      "",
      "/admin/expohallsmap/details/get",
      { event_id: fields?.event_id ?? "" }
    );
    setExpoHallDetails(response.data.data);
    if (response?.data?.code === 200) {
      return response.data.data;
    } else return [];
  };

  const handleImgChangeLogo = (event) => {
    const isValidLogo = isValidImage(event.target.files[0]);
    if (isValidLogo) {
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
      setShowBrandLogoCropModal(true);
    }
  };
  const handleImgChangeMinimizedLogo = (event) => {
    const isValidLogo = isValidImage(event.target.files[0]);
    if (isValidLogo) {
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
      setShowMinimizeBrandLogoCropModal(true);
    }
  };

  const handleBrandLogoFileDelete = () => {
    setBrandLogoFileState(null);
    setBrandLogo(null);
  };
  const handleBrandLogoMinimizedFileDelete = () => {
    setBrandLogoMinimizedFileState(null);
    setBrandLogoMinimized(null);
  };

  const handleImgChange2 = async (event) => {
    setWallGraphicsFileLoader(true);
    setWallGraphicsFileState(event.target.files[0]);
    const formData = new FormData();
    // upload_graphics
    // wall_graphics_file
    formData.append("upload_graphics", event.target.files[0]);
    formData.append(
      "data",
      JSON.stringify({
        customer_id: fields?.customer_account,
        event_id: fields?.event_id,
      })
    );

    if (process.env.REACT_APP_BASE_URL === "https://api-grv8.dev.brainvire.net")
      toast.error(
        "Please do not upload vista file on dev server, server will crash"
      );
    else {
      const res = await uploadVistaFile(formData);
      if (res && res?.link) {
        setWallGraphicsFileUrl(res?.link);
      } else {
        toast.error("Upload Failed");
        setWallGraphicsFileState(null);
      }
    }
    setWallGraphicsFileLoader(false);
  };

  const handleAllGraphicsFileStateDelete = () => {
    setWallGraphicsFileState(null);
    setWallGraphicsFileUrl(null);
  };

  const handleImgChange3 = async (event) => {
    let tempFile = event.target.files[0];
    setFinalBoothFileLoader(true);
    const formData = new FormData();
    formData.append("final_booth_file", event.target.files[0]);
    formData.append(
      "data",
      JSON.stringify({
        customer_id: fields.customer_account,
        event_id: fields.event_id,
      })
    );
    if (process.env.REACT_APP_BASE_URL === "https://api-grv8.dev.brainvire.net")
      toast.error(
        "Please do not upload vista file on dev server, server will crash"
      );
    else {
      let res = await uploadVistaFileBrand(formData);
      if (res && res?.link) {
        setFinalBoothFileState(tempFile);

        setFinalBoothFileUrl(res.link);
      } else {
        toast.error("Vista Upload Failed");
      }
    }
    setFinalBoothFileLoader(false);
  };

  const handleFinalBoothDelete = () => {
    setFinalBoothFileState(null);
    setFinalBoothFileUrl(null);
  };

  const handleOk = () => {
    setExpoHallModalVisible(false);
  };

  const handleCancel = () => {
    setExpoHallModalVisible(false);
  };

  const getDataFromExpoHall = (expo_hall_configuration_id, elemIndex) => {
    setFields({
      ...fields,
      expo_hall_configuration_id: expo_hall_configuration_id,
      sequence: elemIndex,
    });
  };

  const saveBrandLogoCroppedImage = async (croppedImageFile) => {
    setBrandLogoFileState(croppedImageFile);
    setBrandLogo(URL.createObjectURL(croppedImageFile));
  };

  const saveMinimizeBrandLogoCroppedImage = async (croppedImageFile) => {
    setBrandLogoMinimizedFileState(croppedImageFile);
    setBrandLogoMinimized(URL.createObjectURL(croppedImageFile));
  };

  if (loadingBrand) {
    return <CommonLoader />;
  } else
    return (
      <form onSubmit={handleFormSubmit}>
        <div
          className={
            props?.event_id
              ? "d-flex justify-content-between bg-light w-100"
              : "d-flex justify-content-between bg-light w-100 common-sticky-header"
          }
        >
          {props.isFromEvent ? null : (
            <div>
              <h4 className="fs-24">Add New Brand</h4>

              <Breadcrumb className="my-2" separator=">">
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Admin Console</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/brandmanagement">Brand Management</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <Link to="/createnewbrand">Add New Brand</Link>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          )}
        </div>

        <div className={props?.event_id ? "bg-white" : "bg-white p-3"}>
          <section>
            <div className={props?.event_id ? "d-none" : "row mt-5"}>
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20 px-2">Brand Editor</h6>
              </div>
            </div>
            <div className={props?.event_id ? "row" : "row mt-4"}>
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Active
                </label>
              </div>
              <div className="col-3 text-start">
                <Switch
                  name="is_active"
                  checked={fields.is_active}
                  onChange={(checked) =>
                    handleFieldsChange(checked, "is_active")
                  }
                />
              </div>
            </div>
            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Logo
                </label>
              </div>

              <div className="col-5 image-upload d-flex">
                <FileUpload
                  value=""
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Image Upload"
                  inputType="file"
                  inputOnChange={handleImgChangeLogo}
                  inputId="add_brand_logo"
                  inputName="add_brand_logo"
                  name="logo-brand"
                  onBlur={() => validator.current.showMessageFor("logo-brand")}
                />
                <Tooltip
                  placement="right"
                  title={"Final image shape is wide rectangle ‘inline’"}
                  className="mx-3 mt-2"
                >
                  <InfoCircleOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
                {brandLogoFileState ? (
                  <>
                    <span className="mx-3"> {brandLogoFileState.name} </span>
                    <span onClick={handleBrandLogoFileDelete}>
                      <DeleteOutlined />
                    </span>
                  </>
                ) : (
                  ""
                )}
                {brandLogo ? (
                  <>
                    <img
                      src={brandLogo}
                      alt={brandLogo ? "Uploaded Logo" : ""}
                      className="img-fluid mx-2 brand_logo_img_preview"
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row my-0 py-0">
              <div className="col-2"></div>
              <div className="col-5"></div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Minimized Logo
                </label>
              </div>

              <div className="col-5 image-upload d-flex">
                <FileUpload
                  value=""
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Image Upload"
                  inputType="file"
                  inputOnChange={handleImgChangeMinimizedLogo}
                  inputId="add_brand_logo_minimized"
                  inputName="add_brand_logo_minimized"
                  name="logo-brand_minimized"
                  onBlur={() =>
                    validator.current.showMessageFor("logo-brand_minimized")
                  }
                />
                <Tooltip
                  placement="right"
                  title={"Final image shape is square"}
                  className="mx-3 mt-2"
                >
                  <InfoCircleOutlined style={{ fontSize: "20px" }} />
                </Tooltip>
                {brandLogoMinimizedFileState ? (
                  <>
                    <span className="mx-3">
                      {brandLogoMinimizedFileState.name}
                    </span>
                    <span onClick={handleBrandLogoMinimizedFileDelete}>
                      <DeleteOutlined />
                    </span>
                  </>
                ) : (
                  ""
                )}
                {brandLogoMinimized ? (
                  <>
                    <img
                      src={brandLogoMinimized}
                      alt={brandLogoMinimized ? "Uploaded Logo" : ""}
                      className="img-fluid mx-2 brand_minlogo_img_preview"
                    />
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row my-0 py-0">
              <div className="col-2"></div>
              <div className="col-5"></div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Customer Account
                  <RedAsterick />
                </label>
              </div>

              <div className={Tablet ? "col-4" : "col-3"}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Customer Account"
                  className={
                    validator?.current?.fields?.customer_account === false &&
                    validator?.current?.messagesShown === true
                      ? "w-100 fs-bold-14 border_red"
                      : "w-100 fs-bold-14"
                  }
                  name="customer_account"
                  allowClear
                  value={fields.customer_account}
                  onChange={(val) =>
                    handleFieldsChange(val, "customer_account")
                  }
                  onBlur={() =>
                    validator.current.showMessageFor("customer_account")
                  }
                  listHeight={130}
                  listItemHeight={4}
                  disabled={
                    props?.customer_id || userType === "CUSTOMER" ? true : false
                  }
                >
                  {customerList?.map((data) => {
                    return (
                      <Option value={data.customer_id}>
                        {data.account_name}
                      </Option>
                    );
                  })}
                </Select>
                {validator.current.message(
                  "customer_account",
                  fields.customer_account,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
              {/* )} */}
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Select Event
                  <RedAsterick />
                </label>
              </div>
              <div className={Tablet ? "col-4" : "col-3"}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder="Select Event"
                  className={
                    validator?.current?.fields?.event_id === false &&
                    validator?.current?.messagesShown === true
                      ? "w-100 fs-bold-14 border_red"
                      : "w-100 fs-bold-14 "
                  }
                  allowClear
                  name="event_id"
                  value={fields.event_id}
                  onChange={(val) => handleFieldsChange(val, "event_id")}
                  onBlur={() => validator.current.showMessageFor("event_id")}
                  optionFilterProp="children"
                  listHeight={130}
                  listItemHeight={4}
                  disabled={props?.event_id ? true : false}
                >
                  {eventList &&
                    eventList?.map((data) => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>
                {validator.current.message(
                  "event_id",
                  fields.event_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Brand Name
                  <RedAsterick />
                </label>
              </div>
              <div className={Tablet ? "col-4" : "col-3"}>
                <Input
                  name="brand_name"
                  id=""
                  className={
                    validator?.current?.fields?.brand_name === false &&
                    validator?.current?.messagesShown === true
                      ? "w-100 border-gray rounded fs-bold-14 border_red"
                      : "w-100 border-gray rounded fs-bold-14"
                  }
                  value={fields.brand_name}
                  placeholder="Enter A Title Here"
                  onChange={(e) =>
                    handleFieldsChange(e.target.value, "brand_name")
                  }
                  onBlur={() => validator.current.showMessageFor("brand_name")}
                  maxLength={INPUT_TEXT_LIMITS.MAX}
                />
                {validator.current.message(
                  "brand_name",
                  fields.brand_name,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Brand Description
                </label>
              </div>
              <div className="col-5">
                <TextArea
                  name="brand_description"
                  rows={4}
                  className={
                    validator?.current?.fields?.brand_description === false &&
                    validator?.current?.messagesShown === true
                      ? "fs-bold-14 border_red"
                      : "fs-bold-14"
                  }
                  value={fields.brand_description}
                  onChange={(e) =>
                    handleFieldsChange(e.target.value, "brand_description")
                  }
                  placeholder="Enter A Description Here"
                  maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Brand Tier
                  <RedAsterick />
                </label>
              </div>
              <div className={Tablet ? "col-4" : "col-3"}>
                <Select
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  showSearch
                  placeholder="Select Tier"
                  className={
                    validator?.current?.fields?.brand_tier === false &&
                    validator?.current?.messagesShown === true
                      ? "w-100 fs-bold-14 border_red"
                      : "w-100 fs-bold-14"
                  }
                  allowClear
                  name="brand_tier "
                  value={fields.brand_tier}
                  onChange={(val) => handleFieldsChange(val, "brand_tier")}
                  onBlur={() => validator.current.showMessageFor("brand_tier")}
                  listHeight={130}
                  listItemHeight={4}
                  autoComplete="none"
                  optionFilterProp="children"
                >
                  {tierList &&
                    tierList?.map((data) => {
                      return <Option value={data.tier_id}>{data.tier}</Option>;
                    })}
                </Select>
                {validator.current.message(
                  "brand_tier",
                  fields.brand_tier,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Brand Color
                </label>
              </div>
              <div className={Tablet ? "col-4" : "col-2"}>
                <ColorPickerInput
                  colorCode={fields?.brand_color}
                  onHandleColorChange={(hexColor) => {
                    handleFieldsChange(hexColor, "brand_color");
                  }}
                />
              </div>
            </div>

            {/* ------------------------New ADDed Code ------------------ */}
            <div className="row mt-4 brand_button_color">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Default Button Background
                </label>
              </div>

              <div
                className={`${Tablet ? "flex-column gap-2" : ""} col-9  d-flex`}
              >
                <ColorPickerInput
                  colorCode={fields?.default_button_background_color}
                  onHandleColorChange={(hexColor) => {
                    handleFieldsChange(
                      hexColor,
                      "default_button_background_color"
                    );
                  }}
                />

                <div
                  className={`${
                    Tablet ? "flex-column align-items-start gap-3" : ""
                  }  d-flex ms-2`}
                >
                  <div className="flex_center">
                    <label htmlFor="" className="text-secondary mt-1">
                      Gradient
                    </label>
                    <Switch
                      className="mx-2"
                      onChange={(val) => {
                        handleFieldsChange(
                          val,
                          "is_default_button_gradient_active"
                        );
                      }}
                    />
                  </div>
                  {fields.is_default_button_gradient_active ? (
                    <div className={"flex_center "}>
                      <label htmlFor="" className="text-secondary mt-1">
                        Angle
                      </label>
                      <Input
                        className="ms-2 mx-2"
                        style={{ width: "50px" }}
                        value={fields?.default_button_gradient_angle}
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "default_button_gradient_angle"
                          );
                        }}
                      />

                      <label htmlFor="" className="text-secondary mx-4 mt-1">
                        Color
                      </label>

                      <ColorPickerInput
                        colorCode={
                          fields?.default_button_background_second_color
                        }
                        onHandleColorChange={(hexColor) => {
                          handleFieldsChange(
                            hexColor,
                            "default_button_background_second_color"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Default Button Text Colour
                </label>
              </div>
              <div className="col-3">
                <ColorPickerInput
                  colorCode={fields?.default_button_text_color}
                  onHandleColorChange={(hexColor) => {
                    handleFieldsChange(hexColor, "default_button_text_color");
                  }}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary text-nowrap mt-1">
                  Button Preview
                </label>
              </div>
              <div className="col-3">
                <Button
                  style={{
                    color: fields.default_button_text_color,
                    border: `1px solid ${fields.default_button_text_color}`,
                    background: fields.is_default_button_gradient_active
                      ? `linear-gradient(${fields.default_button_gradient_angle}deg, ${fields.default_button_background_color} 30%,${fields.default_button_background_second_color} 100%)`
                      : `${fields.default_button_background_color}`,
                  }}
                  className="rounded"
                >
                  Example Test
                </Button>
              </div>
            </div>

            <div className="row mt-4 brand_button_color">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Highlight Button Background
                </label>
              </div>
              <div
                className={`${Tablet ? "flex-column gap-2" : ""} col-9  d-flex`}
              >
                <ColorPickerInput
                  colorCode={fields?.highlight_button_background_color}
                  onHandleColorChange={(hexColor) => {
                    handleFieldsChange(
                      hexColor,
                      "highlight_button_background_color"
                    );
                  }}
                />

                <div
                  className={`${
                    Tablet ? "flex-column align-items-start gap-3" : ""
                  }  d-flex ms-2`}
                >
                  <div className="flex_center">
                    <label htmlFor="" className="text-secondary mt-1">
                      Gradient
                    </label>
                    <Switch
                      onChange={(val) => {
                        handleFieldsChange(
                          val,
                          "is_highlight_button_gradient_active"
                        );
                      }}
                      className="mx-2"
                    />
                  </div>

                  {fields?.is_highlight_button_gradient_active ? (
                    <div className="flex_center">
                      <label htmlFor="" className="text-secondary mt-1">
                        Angle
                      </label>
                      <Input
                        className="ms-2 mx-2"
                        value={fields?.highlight_button_gradient_angle}
                        style={{ width: "50px" }}
                        onChange={(e) => {
                          handleFieldsChange(
                            e.target.value,
                            "highlight_button_gradient_angle"
                          );
                        }}
                      />

                      <label htmlFor="" className="text-secondary mx-4 mt-1">
                        Color
                      </label>

                      <ColorPickerInput
                        colorCode={
                          fields?.highlight_button_background_second_color
                        }
                        onHandleColorChange={(hexColor) => {
                          handleFieldsChange(
                            hexColor,
                            "highlight_button_background_second_color"
                          );
                        }}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Highlight Button Text Colour
                </label>
              </div>
              <div className="col-4">
                <ColorPickerInput
                  colorCode={fields?.highlight_button_text_color}
                  onHandleColorChange={(hexColor) => {
                    handleFieldsChange(hexColor, "highlight_button_text_color");
                  }}
                />
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary text-nowrap mt-1">
                  Button Preview
                </label>
              </div>
              <div className="col-3">
                <Button
                  style={{
                    color: fields.highlight_button_text_color,
                    border: `1px solid ${fields.highlight_button_text_color}`,
                    background: fields.is_highlight_button_gradient_active
                      ? `linear-gradient(${fields.highlight_button_gradient_angle}deg, ${fields.highlight_button_background_color} 30%,${fields.highlight_button_background_second_color} 100%)`
                      : `${fields.highlight_button_background_color}`,
                  }}
                  className="rounded"
                >
                  Example Test
                </Button>
              </div>
            </div>

            {/* New Added Code Ends here */}
            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary">
                  Booth Location
                </label>
              </div>
              <div className={Tablet ? "col-4" : "col-3"}>
                <Button
                  className="rounded  btn-svg px-3"
                  onClick={() => setExpoHallModalVisible(true)}
                >
                  Select Expo Hall
                </Button>

                <Modal
                  visible={expoHallModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  width={1000}
                >
                  <ExpoHalls
                    event_id={fields.event_id ?? null}
                    getDataFromExpoHall={getDataFromExpoHall}
                    fromBrand={true}
                  />
                </Modal>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Download Graphics Template
                </label>
              </div>
              <div className={Tablet ? "col-3 text-end" : "col-4 text-end"}>
                <div className="d-flex">
                  <Button className="rounded btn-svg " icon={<EyeView />}>
                    View
                  </Button>
                  <Button
                    // href={downloadGraphics}
                    type="primary"
                    className="ms-3"
                    onClick={() => {
                      window.location.href = graphicsDownloadLink;
                    }}
                  >
                    <Download />
                    <span className="ms-1">Download</span>
                  </Button>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div
                className={
                  Tablet
                    ? "col-3 text-end"
                    : props?.event_id
                    ? "col-3 text-end"
                    : "col-2 text-end"
                }
              >
                <label htmlFor="" className="text-secondary mt-1">
                  Upload Graphics
                </label>
              </div>

              <div className="col-2 image-upload d-flex">
                <div className="h-25">
                  <FileUpload
                    value=""
                    labelClassName="rounded border-pink btn-svg px-3 py-1 "
                    labelTitle="File Upload"
                    inputType="file"
                    inputOnChange={handleImgChange2}
                    inputId="wallgraphicsupload"
                    inputName="wallgraphicsupload"
                    name="wallgraphicsupload"
                    onBlur={() =>
                      validator.current.showMessageFor("wallgraphicsupload")
                    }
                  />
                </div>
              </div>
              <div className="col-6">
                {wallGraphicsFileState && !wallGraphicsFileLoader ? (
                  <>
                    <span className="mx-3">{wallGraphicsFileState?.name}</span>
                    <span onClick={() => handleAllGraphicsFileStateDelete()}>
                      <DeleteOutlined />
                    </span>
                  </>
                ) : wallGraphicsFileLoader ? (
                  <Spin
                    className="spinner"
                    style={{ color: "#ef0855" }}
                    indicator={loadingIcon}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row my-0 py-0">
              <div className="col-2"></div>
            </div>

            <div className="border-bottom-gray pb-4">
              <div className="row mt-4 ">
                <div
                  className={
                    Tablet
                      ? "col-3 text-end"
                      : props?.event_id
                      ? "col-3 text-end"
                      : "col-2 text-end"
                  }
                >
                  <label htmlFor="" className="text-secondary mt-1">
                    360 Booth Upload
                  </label>
                </div>

                <div className="col-2 image-upload d-flex">
                  <div className="h-25">
                    <FileUpload
                      value=""
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="File Upload"
                      inputType="file"
                      inputOnChange={handleImgChange3}
                      inputId="finalboothupload"
                      inputName="finalboothupload"
                      name="finalboothupload"
                      onBlur={() =>
                        validator.current.showMessageFor("finalboothupload")
                      }
                    />
                  </div>
                </div>
                <div className="col-6">
                  {finalBoothFileState && !finalBoothFileLoader ? (
                    <>
                      <span className="mx-3">{finalBoothFileState.name}</span>
                      <span onClick={handleFinalBoothDelete}>
                        <DeleteOutlined />
                      </span>
                    </>
                  ) : finalBoothFileLoader ? (
                    <Spin
                      className="spinner"
                      style={{ color: "#ef0855" }}
                      indicator={loadingIcon}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="row my-0 py-0">
                <div className="col-2"></div>
              </div>
            </div>
          </section>

          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">
                  External & Social Links
                </h6>
              </div>
            </div>

            <div className="row mt-1">
              <div className="col-12 mt-1">
                <span className="fs-16">
                  NB: If you paste your details into these boxes then anyone
                  using this site will be able to view/contact you via these
                  platforms.
                </span>
              </div>
            </div>

            <div className="social-media-div border-bottom-gray pb-4 mb-4">
              {socialMediaList &&
                socialMediaList.map((data, idx) => (
                  <div className="row mt-4 ">
                    <div
                      className={
                        props?.event_id ? "col-3 text-end" : "col-2 text-end"
                      }
                    >
                      {data?.social_media}
                    </div>
                    <div className={`${Tablet ? "col-2" : ""} col-1`}>
                      <Switch
                        checked={
                          fields?.brand_social_media_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? true
                            : false
                        }
                        onChange={(checked) =>
                          onHandleSocialSwitchChange(checked, idx)
                        }
                      />
                    </div>
                    <div
                      className={
                        Tablet
                          ? "col-4"
                          : "col-4 text-start brand_social_media_input_div"
                      }
                    >
                      <Input
                        name="social_media"
                        placeholder="https://webaddress.com"
                        className={props?.event_id ? " w-100 mx-2" : "w-100"}
                        disabled={
                          fields?.brand_social_media_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? false
                            : true
                        }
                        value={
                          fields?.brand_social_media_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          )
                            ? fields?.brand_social_media_details[
                                fields.brand_social_media_details.findIndex(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                              ]["social_media_url"]
                            : ""
                        }
                        onChange={(event) =>
                          socialMediaHandleChange(idx, event)
                        }
                        onBlur={() =>
                          validator.current.showMessageFor("social_media")
                        }
                      />
                      {validator.current.message(
                        "social_media",
                        fields?.brand_social_media_details?.some(
                          (social_media) =>
                            social_media.social_media_id ===
                            data.social_media_id
                        )
                          ? fields?.brand_social_media_details[
                              fields.brand_social_media_details.findIndex(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                            ]["social_media_url"]
                          : "",
                        fields?.brand_social_media_details?.some(
                          (social_media) =>
                            social_media.social_media_id ===
                            data.social_media_id
                        )
                          ? "required|url"
                          : "",
                        { className: "text-danger py-1" }
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </section>
          <section>
            <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
              {props.onCancelFromModal ? (
                <Button
                  onClick={() => props.onCancelFromModal()}
                  className="rounded  px-3"
                >
                  Cancel
                </Button>
              ) : (
                <Link to="brandmanagement">
                  <Button className="rounded px-3">Cancel</Button>
                </Link>
              )}

              <div>
                <Button
                  className="mx-2"
                  htmlType="submit"
                  type="primary"
                  disabled={loading || finalBoothFileLoader ? true : false}
                >
                  {!loading ? (
                    "Save Brand"
                  ) : (
                    <div style={{ width: "70px" }}>
                      <Spin
                        style={{ color: "white" }}
                        indicator={loadingIcon}
                      />
                    </div>
                  )}
                </Button>
              </div>
            </div>
          </section>

          {showBrandLogoCropModal ? (
            <CropImageModal
              imageToBeCropped={cropImagePreview}
              sendCroppedImageFile={(cropImageFile) => {
                saveBrandLogoCroppedImage(cropImageFile);
                setShowBrandLogoCropModal(false);
              }}
              closeCropModalOnCancel={() => {
                setShowBrandLogoCropModal(false);
              }}
              cropSizeData={{
                width: 340,
                height: 100,
              }}
            />
          ) : null}

          {showMinimizeBrandLogoCropModal ? (
            <CropImageModal
              imageToBeCropped={cropImagePreview}
              sendCroppedImageFile={(cropImageFile) => {
                saveMinimizeBrandLogoCroppedImage(cropImageFile);
                setShowMinimizeBrandLogoCropModal(false);
              }}
              closeCropModalOnCancel={() => {
                setShowMinimizeBrandLogoCropModal(false);
              }}
              cropSizeData={{
                width: 100,
                height: 100,
              }}
            />
          ) : null}
        </div>
      </form>
    );
};

export default CreateNewBrand;
