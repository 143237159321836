import React, { useState, useEffect, useRef } from "react";
import { Paper } from "@material-ui/core";
import "./networkTable.css";
import _, { isEmpty } from "lodash";
import { imageURI } from "./SVGData";
import { Download } from "../../components/svgJS/Download";
import { Booth } from "../../components/svgJS/Booth";
import { Chat } from "../../components/svgJS/Chat";
import { Video } from "../../components/svgJS/Video";
import { Button, Modal, Row, Col, Input } from "antd";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";
import SimpleReactValidator from "simple-react-validator";
import { TEXTAREA_TEXT_LIMITS } from "../../components/constsnts/common.constants";
import {
  getNetSessionJoinTables,
  getTiers,
  updateAssetDownloadCount,
  videoRequestAPI,
} from "../../utils/commonApis";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../utils/commonApis";
import { DEFAULT_CHATBOT_HISTORY } from "../../components/constsnts/common.constants";
import {
  fetchChatbotHistory,
  getChatbotJson,
  saveChatbotHistory,
} from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  checkIfChatbotTimeSlot,
  getComentChatUser,
  getCookie,
  vistaRedirectToBooth,
} from "../../utils/commonFunctions";
import CustomChatbot from "../../components/Widgets/CustomChatbot";
import { useDispatch, useSelector } from "react-redux";
import { getNetworkingSessionTableDetails } from "../../redux/slice/networking";
import ProfileModal from "../common/Modal/ProfileModal";
import { User } from "../../components/svgJS/User";
import {
  indicateNetworkingSessionOver,
  setNetTableJitsiModalClosed,
  setVideoConferenceData,
  setVideoConferenceFlag,
} from "../../redux/slice/videoConference";
import moment from "moment";

import { setTimeout } from "worker-timers";

const NetworkTableUI = (props) => {
  const {
    currentTableData,
    loadingTable,
    initialCallOrLoaderDone,
    currentTableIndex,
    clearTableInterval,
    joinTableIDs,
    eventid,
    history,
    brandSponsorData,
    sessionTimings,
    socket,
    sessionData,
  } = props;

  let CryptoJS = require("crypto-js");
  const messageValidator = useRef(new SimpleReactValidator());
  const { TextArea } = Input;
  const [tableData, setTableData] = useState([]);
  let [tableSponsorData, setTableSponsorData] = useState();
  const [isProfileModalVisible, setIsProfileModalVisible] = useState(false);
  const [userID, setUserID] = useState(null);
  const [userData, setUserData] = useState();
  const [tiers, setTiers] = useState();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [isChatBot, setIsChatBot] = useState(false);
  const [chatbot_json, set_chatbot_json] = useState([]);
  const [chatbot_history, set_chatbot_history] = useState(
    DEFAULT_CHATBOT_HISTORY
  );
  const [loader, setLoader] = useState(loadingTable);
  const [tableIndex, setTableIndex] = useState(currentTableIndex);
  const currentTableIndexRef = useRef(currentTableIndex);
  const [userType, setUserType] = useState(null);
  const [loginUserId, setLoginUserId] = useState(null);
  const [timeEndedPopup, setTimeEndedPopup] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(false);

  const dispatch = useDispatch();

  const isNetworkTableJitsiModalClosed = useSelector(
    (state) => state?.videoConference?.isNetworkTableJitsiModalClosed
  );

  useEffect(async () => {
    const tiers = await getTiers();
    setTiers(tiers);

    const cometRes = await getComentChatUser();
    setCometChatUser(cometRes);
    if (eventid) {
      const chatHistory = await fetchChatbotHistory({
        event_id: eventid,
      });
      if (chatHistory && !isEmpty(chatHistory))
        set_chatbot_history(chatHistory);
    }

    const ciphertext = localStorage.getItem("user_type");
    let bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    let user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(user_type);

    if (getCookie("user_id")) {
      let cipher_login_user_id = getCookie("user_id");
      let bytes_login_user_id = CryptoJS.AES.decrypt(
        cipher_login_user_id,
        "user_id"
      );
      let jsonparse_user_id = JSON.parse(
        bytes_login_user_id.toString(CryptoJS.enc.Utf8)
      );

      setLoginUserId(jsonparse_user_id);
    }
    calculateEndTimeToClosePopupIfSessionOver();
  }, []);

  useEffect(() => {
    currentTableIndexRef.current = currentTableIndex;
  });

  useEffect(async () => {
    if (!isEmpty(currentTableData)) {
      setLoader(true);
      setTableIndex(currentTableIndex);
      mapAllData(currentTableData);
    }
  }, [currentTableData, sessionData]);

  useEffect(() => {
    if (userID) {
      let joineeDetails =
        currentTableData?.networking_session_table_joinees?.find((data) => {
          if (data?.user?.user_id === userID) return data;
        });

      if (joineeDetails) {
        let user = joineeDetails?.user;
        let tier_name =
          tiers &&
          tiers?.find((item) => {
            if (user?.tier_type_id === item?.tier_id) {
              return item;
            }
          }).tier;
        //
        setUserData({
          ...user,
          user_id: user?.user_id ?? null,
          user_name: user?.user_name ?? "",
          email: user?.email ?? "",
          company: user?.company ?? "",
          job_title: user?.job_title ?? "",
          tier_name: tier_name ?? "",
          country: user?.country_name ?? "",
          user_type: user?.user_type ?? "",
          bio: user?.bio ?? "",
          image_url: user?.image_url ?? "",
          event_domain_name: user?.event_domain_name ?? "",
          users_users_social_media_handles_user_id:
            user?.users_users_social_media_handles_user_id ?? [],
        });
      }
    }
  }, [userID]);

  useEffect(() => {
    if (isNetworkTableJitsiModalClosed) leaveNetworkTable();
  }, [isNetworkTableJitsiModalClosed]);

  const calculateEndTimeToClosePopupIfSessionOver = () => {
    let currentTime = moment();

    let differenceInSeconds = moment
      .duration(sessionTimings?.endTime?.diff(currentTime))
      .seconds();

    let differenceInMinutes = moment
      .duration(sessionTimings?.endTime?.diff(currentTime))
      .minutes();

    let differenceInMilliseconds =
      differenceInMinutes * 60000 + differenceInSeconds * 1000; //Adding additional 30 seconds because session is getting over 30 seconds  earlier

    setTimeout(function () {
      dispatch(setVideoConferenceFlag(false));
      setTimeEndedPopup(true);
    }, differenceInMilliseconds);
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      if (cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      } else setForceUpdate(!forceUpdate);
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  function mapAllData(currentData) {
    let newUsers = currentData?.networking_session_table_joinees;
    let url = currentData?.networking_session_table_link;
    let tableSponsorData =
      sessionData[0]?.networking_lounge_sponsoring_brands[0];

    setTableSponsorData(tableSponsorData);

    let seatsPerTable = currentData?.seats_per_table;
    let count = 1;
    let newData = [];
    newUsers?.forEach((item) => {
      if (item && count <= seatsPerTable) {
        newData.push({
          id: count,
          position: count,
          details: {
            user_id: item?.user?.user_id,
            name: item?.user?.user_name,
            companyName: item?.user?.company,
          },
        });
        count = count + 1;
      }
    });
    while (count <= seatsPerTable) {
      newData.push({
        id: count,
        position: count,
        details: null,
        url,
      });
      count = count + 1;
    }

    setTableData(newData);
    setTimeout(() => {
      setLoader(false);
    }, 500);
  }

  const joinTableNow = async (url) => {
    let finalData = {
      networking_lounge_session_table_id:
        currentTableData?.networking_lounge_session_table_id,
      networking_session_id: currentTableData?.networking_session_id,
      joined_at: null,
      left_at: null,
      networking_session_id: currentTableData?.networking_session_id,
      startTime: sessionTimings?.startTime,
      user_id: userID,
      shuffleTime: currentTableData?.mins_per_shuffle,
      seats_per_table: currentTableData?.seats_per_table,
    };
    const response = await ApiCall(
      "POST",
      finalData,
      "/admin/networkingsession/jointables"
    );

    if (response?.data?.code === 200) {
      let obj = {
        videoConferenceFlag: true,
        videoConferenceUrl: url,
        createPollFlag: false,
        isFromNetworking: true,
        auditorium_session_id: null,
        shuffleTime: currentTableData?.mins_per_shuffle,
        networking_session_id: currentTableData?.networking_session_id,
        sessionStartTime: sessionTimings?.startTime,
        waterFallTime: null,
      };

      dispatch(setVideoConferenceData(obj));
      // callJointTableApi();

      toast.success(response?.data?.message);
    } else toast.error(response?.data?.message);
  };

  async function callJointTableApi() {
    const res = await getNetSessionJoinTables(joinTableIDs);
    if (res) {
      const currentTblData = res.find((elem, i) => {
        return i === currentTableIndexRef.current;
      });
      if (currentTblData) mapAllData(currentTblData);
    }
  }

  const redirectToNetworkinPage = () => {
    window.location.href = "/networking";
  };

  const ChairData = ({ data }) => {
    return (
      <g
        cursor="pointer"
        className="net_table_join_user_btn"
        onClick={() => {
          if (data?.details?.user_id === loginUserId) {
            toast.error("You cannot view your own profile");
          } else {
            setUserID(data?.details?.user_id);
            setIsProfileModalVisible(true);
          }
        }}
      >
        {data?.details ? (
          <>
            <text
              id={`name${data?.id}`}
              data-name={data?.details?.name}
              transform="translate(1097 751)"
              fontSize="16"
              fontFamily="Jost-Medium, Jost"
              fontWeight="500"
            >
              <tspan x="0" y="0">
                {data?.details?.name}
              </tspan>
            </text>
            <text
              id={`company${data?.id}`}
              data-name={data?.details?.companyName}
              transform="translate(1097 770)"
              fill="#ef0855"
              fontSize="12"
              fontFamily="Jost-Regular, Jost"
            >
              <tspan x="0" y="0">
                {data?.details?.companyName}
              </tspan>
            </text>
            <g
              transform="matrix(1, 0, 0, 1, 594, 677)"
              // filter="url(#Rectangle_566-6)"
              className="netable_join_user"
            >
              <g
                id="Rectangle_566-12"
                data-name="Rectangle 566"
                transform="translate(457 61)"
                // fill="#ef0855"
                // stroke="#ef0855"
                strokeWidth="0"
              >
                <rect width="36" height="36" rx="5" stroke="none" />
                <rect
                  x="0.5"
                  y="0.5"
                  width="35"
                  height="35"
                  rx="4.5"
                  fill="none"
                />
              </g>
            </g>
            <g
              id="_028-user-1-6"
              data-name="028-user-1"
              transform="translate(1059 746)"
            >
              <g
                id="Group_1422-6"
                data-name="Group 1422"
                transform="translate(0)"
              >
                <path
                  id="Path_49273-6"
                  data-name="Path 49273"
                  d="M19.95,17.138a.781.781,0,0,0-1.53.318.816.816,0,0,1-.165.684.794.794,0,0,1-.625.3H2.371a.794.794,0,0,1-.625-.3.816.816,0,0,1-.165-.684,8.632,8.632,0,0,1,8.213-6.835q.1,0,.207,0t.208,0a8.594,8.594,0,0,1,6.941,3.815.781.781,0,1,0,1.3-.87,10.162,10.162,0,0,0-5.266-4,5.312,5.312,0,1,0-6.359,0A10.161,10.161,0,0,0,.051,17.138,2.374,2.374,0,0,0,2.371,20H17.63a2.374,2.374,0,0,0,2.32-2.862ZM6.25,5.313a3.75,3.75,0,1,1,3.94,3.745l-.19,0-.189,0A3.755,3.755,0,0,1,6.25,5.313Z"
                  transform="translate(0)"
                  fill="#fff"
                />
              </g>
            </g>
          </>
        ) : (
          <g
            onClick={(e) => {
              e.stopPropagation();

              joinTableNow(data?.url);
            }}
            cursor="pointer"
          >
            <rect
              id="Rectangle_20478"
              data-name="Rectangle 20478"
              width="36"
              height="36"
              rx="5"
              transform="translate(491 312)"
              fill="#ef0855"
            />
            <g id="Booth" transform="translate(555.225 328.764)">
              <path
                id="Union_16"
                data-name="Union 16"
                d="M16.8,19.219a.78.78,0,0,1,.779-.781.822.822,0,0,0,.82-.822.78.78,0,1,1,1.56,0A2.384,2.384,0,0,1,17.582,20,.78.78,0,0,1,16.8,19.219ZM11.852,20H6.163a.781.781,0,0,1,0-1.562H13.8A.781.781,0,0,1,13.8,20ZM0,17.616a.78.78,0,1,1,1.56,0,.821.821,0,0,0,.82.822.781.781,0,0,1,0,1.562A2.384,2.384,0,0,1,0,17.616ZM9.958,15a.784.784,0,0,1-.006-1.107l2.921-2.964,0,0a.784.784,0,0,0,0-1.107L9.956,6.894a.782.782,0,1,1,1.1-1.107l2.921,2.924a2.354,2.354,0,0,1,0,3.319l-2.92,2.962A.78.78,0,0,1,9.958,15Zm8.483-.977V6.368a.779.779,0,1,1,1.559,0v7.654a.779.779,0,1,1-1.559,0ZM0,14.022V6.368a.78.78,0,1,1,1.56,0v7.654a.78.78,0,1,1-1.56,0Zm5.664-2.867a.783.783,0,0,1,0-1.566h5.7a.783.783,0,0,1,0,1.566ZM18.4,2.384a.822.822,0,0,0-.82-.822.781.781,0,0,1,0-1.562,2.385,2.385,0,0,1,2.38,2.384.78.78,0,1,1-1.56,0ZM0,2.384A2.384,2.384,0,0,1,2.381,0a.781.781,0,0,1,0,1.562.822.822,0,0,0-.821.822A.779.779,0,1,1,0,2.384Zm11.85-.822H6.163A.781.781,0,0,1,6.163,0H13.8a.781.781,0,0,1,0,1.562Z"
                transform="translate(-56.225 -8.764)"
                fill="#fff"
              />
            </g>
          </g>
        )}
      </g>
    );
  };

  const leaveNetworkTable = async () => {
    let body = {};
    const response = await ApiCall(
      "PUT",
      body,
      "/admin/networkingsession/leavetable",
      null
    );

    if (response.data.code === 200) {
      toast.success(response?.data?.message);
      dispatch(setNetTableJitsiModalClosed(false));
      callJointTableApi();
    } else toast.error(response.data.message);
  };

  if (loader) return <CommonLoader />;
  else {
    let chatbotRes = checkIfChatbotTimeSlot(currentTableData);
    return (
      <Paper>
        <ProfileModal
          isProfileModalVisible={isProfileModalVisible}
          userData={userData}
          changeModalFlag={() => {
            setIsProfileModalVisible(false);
          }}
        />
        <div className="d-flex netTable_container">
          {isChatBot && chatbot_json ? (
            <CustomChatbot
              onClose={async (conversation_details) => {
                await saveChatbotHistory({
                  event_id: eventid,
                  conversation_details,
                });
                setIsChatBot(false);
              }}
              responseJson={chatbot_json}
              chatbot_history={chatbot_history}
              onSetHistory={(newHistory) => set_chatbot_history(newHistory)}
              extraProps={{ event_id: eventid }}
            />
          ) : null}
          <div className="left_netTable d-flex flex-column align-items-center">
            <h5 className="fs-18 text-center">Sponsored by</h5>
            <div className="net_hosting_sponsor_img_container">
              <img
                className="sponsored_netTable_img mb-3"
                src={tableSponsorData?.logo_url}
              />
            </div>

            {tableSponsorData?.is_assets_enabled &&
            tableSponsorData?.is_assets_enabled !== "NA" &&
            tableSponsorData?.zone_assets[0]?.asset_file_url ? (
              <Button
                onClick={async () => {
                  if (tableSponsorData?.zone_assets[0]?.can_download_asset) {
                    window.location.href =
                      tableSponsorData?.zone_assets[0]?.asset_file_url;
                    updateAssetDownloadCount(
                      tableSponsorData?.zone_assets[0]?.zone_asset_id
                    );
                  } else toast.error("Maximum Number of Downloads Reached");
                }}
                className="netTable_btn border p-1"
              >
                <div className="text-start ms-4">
                  <Download />
                  <span className="ms-2">
                    {tableSponsorData?.zone_assets[0]?.original_file_name}
                  </span>
                </div>
              </Button>
            ) : null}
            {tableSponsorData?.is_booth_link_enabled &&
            tableSponsorData?.is_booth_link_enabled !== "NA" ? (
              <Button className="netTable_btn border p-1">
                <div
                  onClick={() => {
                    let tier = tableSponsorData?.brand_tier
                      ? tableSponsorData?.brand_tier
                      : tableSponsorData?.brand?.brand_tier;
                    let zones = tableSponsorData?.zones
                      ? tableSponsorData?.zones
                      : tableSponsorData?.brand?.zones;
                    let sequence = null;
                    let brand_id = tableSponsorData?.brand_id;
                    let eventID = eventid;
                    let final_booth_url = tableSponsorData?.final_booth_url
                      ? tableSponsorData?.final_booth_url
                      : tableSponsorData?.brand?.final_booth_url;
                    vistaRedirectToBooth(
                      tier,
                      zones,
                      sequence,
                      userType,
                      brand_id,
                      eventID,
                      history,
                      dispatch,
                      final_booth_url
                    );
                  }}
                  className="text-start ms-4"
                >
                  <Booth />
                  <span className="ms-2">Visit Booth</span>
                </div>
              </Button>
            ) : null}
            {(tableSponsorData?.is_chat_enabled !== "NA" &&
              tableSponsorData?.is_chat_enabled &&
              tableSponsorData?.channel_id) ||
            (chatbotRes && currentTableData.chat_bot_start_date_time) ? (
              <Button
                onClick={async () => {
                  if (chatbotRes && currentTableData.chat_bot_start_date_time) {
                    let chat_bot_res = await getChatbotJson({
                      zone_id: currentTableData.zone_id,
                    });
                    if (chat_bot_res && !isEmpty(chat_bot_res)) {
                      set_chatbot_json(chat_bot_res);
                      setIsChatBot(true);
                    } else toast.error("Chatbot details doesn't exist");
                  } else {
                    setMessageModal({
                      isOpen: true,
                      channel_id: tableSponsorData?.channel_id,
                    });
                  }
                }}
                className="netTable_btn border p-1"
              >
                <div className="text-start ms-4">
                  <Chat />
                  <span className="ms-2">Chat</span>
                </div>
              </Button>
            ) : null}
            {tableSponsorData?.is_demo_room_enabled &&
            tableSponsorData?.is_demo_room_enabled !== "NA" &&
            tableSponsorData?.zone_demo_room_link ? (
              <Button
                onClick={() => {
                  dispatch(
                    setVideoConferenceData({
                      videoConferenceFlag: true,
                      videoConferenceUrl: tableSponsorData?.zone_demo_room_link,
                      is_demo_room: true,
                    })
                  );
                }}
                className="netTable_btn border p-1"
              >
                <div className="text-start ms-4">
                  <Video />
                  <span className="ms-2">Join the Demo Room</span>
                </div>
              </Button>
            ) : null}
            {tableSponsorData?.is_video_call_enabled &&
            tableSponsorData?.is_video_call_enabled !== "NA" ? (
              <Button
                className="netTable_btn border p-1"
                onClick={async () => {
                  let channel_id = tableSponsorData?.channel_id;
                  const response = await videoRequestAPI(channel_id);
                  if (response.data.code === 200)
                    toast.success(response.data.message);
                  else toast.error(response.data.message);
                }}
              >
                <div className="text-start ms-4">
                  <User />
                  <span className="ms-2">Next Available Agent</span>
                </div>
              </Button>
            ) : null}
          </div>
          <div className="right_netTable">
            <div className="conf-table-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                width="700px"
                height="582.087"
                viewBox="0 0 957 582.087"
              >
                <defs>
                  <pattern
                    id="pattern"
                    preserveAspectRatio="none"
                    width="100%"
                    height="100%"
                    viewBox="0 0 1120 800"
                  >
                    <image width="1120" height="800" xlinkHref={imageURI} />
                  </pattern>
                  <filter
                    id="Rectangle_566"
                    x="79"
                    y="269"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Rectangle_566-2"
                    x="241"
                    y="474"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur-2" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur-2" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Rectangle_566-3"
                    x="653"
                    y="474"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur-3" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur-3" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Rectangle_566-4"
                    x="717"
                    y="205"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur-4" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur-4" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Rectangle_566-5"
                    x="618"
                    y="75"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur-5" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur-5" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                  <filter
                    id="Rectangle_566-6"
                    x="427"
                    y="34"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                  >
                    <feOffset dy="3" input="SourceAlpha" />
                    <feGaussianBlur stdDeviation="10" result="blur-6" />
                    <feFlood floodColor="#ef0855" floodOpacity="0.2" />
                    <feComposite operator="in" in2="blur-6" />
                    <feComposite in="SourceGraphic" />
                  </filter>
                </defs>
                <g id="Table" transform="translate(-758 -257)">
                  <rect
                    id="NeXP0s"
                    width="814.121"
                    height="582.087"
                    transform="translate(859.435 257)"
                    fill="url(#pattern)"
                  />
                  {/* 1st chair and empty icon */}
                  {tableData &&
                    tableData?.map((item, i) => {
                      return (
                        <>
                          {item?.position === 1 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12692"
                              transform={
                                item?.details
                                  ? "translate(232 -410)"
                                  : "translate(804, -5)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : (
                            ""
                          )}
                          {item?.position === 2 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12693"
                              transform={
                                item?.details
                                  ? "translate(435 -375)"
                                  : "translate(960, 40)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : (
                            ""
                          )}
                          {item?.position === 3 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12694"
                              transform={
                                item?.details
                                  ? "translate(524 -249)"
                                  : "translate(1080, 200)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                          {item?.position === 4 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12695"
                              transform={
                                item?.details
                                  ? "translate(460 20)"
                                  : "translate(990, 430)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                          {item?.position === 5 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12696"
                              transform={
                                item?.details
                                  ? "translate(150 80)"
                                  : "translate(745, 480)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                          {item?.position === 6 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12697"
                              transform={
                                item?.details
                                  ? "translate(-190 -90)"
                                  : "translate(480, 320)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                          {item?.position === 7 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12698"
                              transform={
                                item?.details
                                  ? "translate(-125 -350)"
                                  : "translate(465, 90)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                          {item?.position === 8 ? (
                            <g
                              key={"netTable_" + i}
                              id="Group_12698"
                              transform={
                                !item?.details
                                  ? "translate(600, 10)"
                                  : "translate(55, -410)"
                              }
                            >
                              <ChairData data={item} />
                            </g>
                          ) : null}
                        </>
                      );
                    })}
                </g>
              </svg>
            </div>
          </div>
        </div>
        {messageModal.isOpen ? (
          <Modal
            title={<b>Send Message</b>}
            visible={messageModal.isOpen}
            onOk={() => clearMessageAndModal()}
            onCancel={() => clearMessageAndModal()}
            footer={[
              <Button
                className="px-3 button-pink rounded"
                key="cancel"
                onClick={() => clearMessageAndModal()}
              >
                Cancel
              </Button>,
              <Button
                className="px-3 rounded"
                key="save"
                type="primary"
                onClick={() => callSendMessageAPI(messageModal?.channel_id)}
                disabled={disableSendBtn}
              >
                Send
              </Button>,
            ]}
            className="message_modal"
          >
            <Row className="ticker_configuration_row mt-2">
              <Col span={6} className="ticker_configuration_label">
                <p>
                  Message
                  <RedAsterick />
                </p>
              </Col>
              <Col span={12}>
                <TextArea
                  name="message_text"
                  id="message_text"
                  className="ticker_configuration_inputDescription"
                  placeholder="Enter a message"
                  rows={4}
                  value={message_text}
                  onChange={(e) => set_message_text(e.target.value)}
                  maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                  onBlur={() =>
                    messageValidator.current.showMessageFor("message_text")
                  }
                />
                {messageValidator.current.message(
                  "Message",
                  message_text,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </Col>
            </Row>
          </Modal>
        ) : null}

        {timeEndedPopup ? (
          <Modal
            visible={timeEndedPopup}
            onOk={() => redirectToNetworkinPage()}
            onCancel={() => redirectToNetworkinPage()}
            footer={null}
            wrapClassName="timeEnded_modal"
          >
            <div className="d-flex flex-column justify-content-center align-content-center h-100">
              <h4 className="fs-pink">This network session has now ended</h4>
              <div className="d-flex justify-content-center">
                <Button
                  className="px-3 rounded mt-3"
                  onClick={() => redirectToNetworkinPage()}
                >
                  OK
                </Button>
              </div>
            </div>
          </Modal>
        ) : null}
      </Paper>
    );
  }
};

export default NetworkTableUI;
