import React, { useState, useEffect, useRef } from "react";
import Paper from "@mui/material/Paper";
import {
  Scheduler,
  DayView,
  Appointments,
  AppointmentForm,
  GroupingPanel,
  Resources,
  AppointmentTooltip,
  Toolbar,
  DateNavigator,
  TodayButton,
} from "@devexpress/dx-react-scheduler-material-ui";
import {
  ViewState,
  EditingState,
  GroupingState,
  IntegratedGrouping,
  CurrentTimeIndicator,
} from "@devexpress/dx-react-scheduler";
import { connectProps } from "@devexpress/dx-react-core";
import moment from "moment";
import { Breadcrumb, Button, Col, Input, Modal, Row } from "antd";
import { ApiCall } from "../../utils/api";
import AppointmentFormContainerBasic from "./AppointmentForm";
import "./zoneCalender.css";
import { Link } from "react-router-dom";
import {
  CloseOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  createTZString,
  isAdminLevelUser,
  isNonAdminLevelUser,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import ProductZoneDemo from "../commonPopups/ProductZoneDemo";
import ProductZoneChat from "../commonPopups/ProductZoneChat";
import { isPastDate } from "../EventCalender/eventCalender.utils";
import { toast } from "react-toastify";
import { dialogs } from "../../components/constsnts/string.constants";
import AudiNetViewAdmins from "../commonPopups/AudiNetViewAdmins";
import AudiViewDelegates from "../commonPopups/AudiViewDelegates";
import NetViewDelegates from "../commonPopups/NetViewDelegates";
import ZoneSessionView from "../commonPopups/ZoneSessionView";
import PastDateDelegate from "../commonPopups/PastDateDelegate";
import { useMediaQuery } from "react-responsive";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { sendMessageAPI } from "../../utils/commonApis";

const ZoneCalender = ({ match }) => {
  var CryptoJS = require("crypto-js");
  const targetDivRef = useRef(null);
  const [currentDateForCalender, setCurrentDateForCalender] = useState(
    moment().format("YYYY-MM-DD")
  );
  const { TextArea } = Input;

  const messageValidator = useRef(new SimpleReactValidator());

  const [appointmentDetails, setAppointmentDetails] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [editingFormVisible, setEditingFormVisible] = useState(false);
  const [editingAppointment, setEditingAppointment] = useState(undefined);
  const [previousAppointment, setPreviousAppointment] = useState(undefined);
  const [addedAppointment, setaddedAppointment] = useState({});
  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [showtooltip, setshowtooltip] = useState(false);
  const [userType, setUserType] = useState();
  const [loader, setLoader] = useState(false);
  const Tablet = useMediaQuery({ maxWidth: 900 });
  const [resourcesAdmin, setResourceAdmin] = useState(null);
  const [resources, setResources] = useState(null);
  const checkHeight = useMediaQuery({ minHeight: 650 });
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (is_route_present === false) window.location.href = "/pagenotfound";
    else {
      const ciphertext = localStorage.getItem("user_type");
      var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
      var user_type = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserType(user_type);
      getZoneCalenderData();
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    var allArr = [];
    (await appointmentDetails.auditorium_sessions) &&
      appointmentDetails.auditorium_sessions.forEach((elem) => {
        const start_date_time = createTZString(elem?.start_date_time);
        const end_date_time = createTZString(elem?.end_date_time);
        allArr.push({
          ...elem,
          startDate: moment(start_date_time, "YYYY-MM-DD HH:mm"),
          endDate: moment(end_date_time, "YYYY-MM-DD HH:mm"),
          title: elem.session_title,
          priorityId: 2,
        });
      });

    (await appointmentDetails.networking_lounge_sessions) &&
      appointmentDetails.networking_lounge_sessions.forEach((elem) => {
        const start_date_time = createTZString(elem?.start_date_time);
        const end_date_time = createTZString(elem?.end_date_time);
        allArr.push({
          ...elem,
          startDate: moment(start_date_time, "YYYY-MM-DD HH:mm"),
          endDate: moment(end_date_time, "YYYY-MM-DD HH:mm"),
          title: elem.session_title,
          priorityId: 2,
        });
      });

    (await appointmentDetails.product_zone_session_demo_details) &&
      appointmentDetails.product_zone_session_demo_details.forEach((elem) => {
        const start_date_time = createTZString(elem?.start_date_time);
        const end_date_time = createTZString(elem?.end_date_time);
        allArr.push({
          ...elem,
          startDate: moment(start_date_time, "YYYY-MM-DD HH:mm"),
          endDate: moment(end_date_time, "YYYY-MM-DD HH:mm"),
          title: elem.session_title,
          priorityId: 1,
        });
      });

    (await appointmentDetails.product_area_session_chat_details) &&
      appointmentDetails.product_area_session_chat_details.forEach((elem) => {
        const start_date_time = createTZString(elem?.start_date_time);
        const end_date_time = createTZString(elem?.end_date_time);
        allArr.push({
          ...elem,
          startDate: moment(start_date_time, "YYYY-MM-DD HH:mm"),
          endDate: moment(end_date_time, "YYYY-MM-DD HH:mm"),
          title: "Zone Staffing",
          priorityId: 3,
        });
      });

    await setAppointments(allArr);
  }, [appointmentDetails]);

  useEffect(() => {
    if (targetDivRef?.current) {
      targetDivRef.current.scrollIntoView({
        behavior: "instant",
        block: "center",
        offset: 10,
      });
    }
  }, [targetDivRef?.current]);

  const Header = ({ children, appointmentData, ...restProps }) => {
    if (isAdminLevelUser(userType)) {
      if (
        appointmentData.priorityId === 1 ||
        appointmentData.priorityId === 2 ||
        appointmentData.priorityId === 3
      ) {
        return (
          <div className="d-flex justify-content-between edit_popup_header ant-modal-header">
            <div className="ant-modal-title">
              {appointmentData.priorityId === 1
                ? "Product Zone - Session"
                : appointmentData.priorityId === 3
                ? "Product/area chat staffing"
                : "Session Details"}
            </div>
            <div
              className="my-auto"
              style={{ cursor: "pointer" }}
              onClick={() => toggleEditingTooltipVisibility()}
            >
              <CloseOutlined />
            </div>
          </div>
        );
      } else return null;
    } else if (isNonAdminLevelUser(userType)) {
      if (appointmentData.priorityId === 1 || appointmentData.priorityId === 2)
        return (
          <div className="d-flex justify-content-between edit_popup_header ant-modal-header">
            <div className="ant-modal-title">
              {appointmentData.priorityId === 2
                ? "Session Details"
                : "Product Zone - Session"}
            </div>
            <div
              className="my-auto"
              style={{ cursor: "pointer" }}
              onClick={() => toggleEditingTooltipVisibility()}
            >
              <CloseOutlined />
            </div>
          </div>
        );
      else return null;
    }
  };

  const Content = ({ children, appointmentData, ...restProps }) => {
    let sessionDateIsPassed = isPastDate(appointmentData?.start_date);
    if (isAdminLevelUser(userType)) {
      return (
        <div>
          {sessionDateIsPassed === "yes" ? (
            <PastDateDelegate isFromCalender="zone" />
          ) : appointmentData.priorityId === 1 ? (
            <ProductZoneDemo
              data={{
                ...appointmentData,
                event_id: parseInt(match?.params?.event_id),
                brand_id: parseInt(match?.params?.brand_id),
                zone_id: parseInt(match?.params?.zone_id),
                visibleChange: toggleEditingTooltipVisibility,
                getZoneCalenderData: getZoneCalenderData,
                currentDateForCalender,
              }}
              isFromCalender="zone"
              editCase={true}
            />
          ) : appointmentData.priorityId === 3 ? (
            <ProductZoneChat
              data={{
                ...appointmentData,
                event_id: parseInt(match?.params?.event_id),
                brand_id: parseInt(match?.params?.brand_id),
                zone_id: parseInt(match?.params?.zone_id),
                visibleChange: toggleEditingTooltipVisibility,
                getZoneCalenderData: getZoneCalenderData,
                currentDateForCalender,
              }}
              isFromCalender="zone"
              editCase={true}
            />
          ) : appointmentData.priorityId === 2 ? (
            <AudiNetViewAdmins
              data={appointmentData}
              visibleChange={toggleEditingTooltipVisibility}
              isFromCalender="zone"
            />
          ) : null}
        </div>
      );
    } else if (isNonAdminLevelUser(userType)) {
      return (
        <div>
          {sessionDateIsPassed === "yes" ? (
            <PastDateDelegate isFromCalender="zone" />
          ) : appointmentData.priorityId === 1 ? (
            <ZoneSessionView
              data={appointmentData}
              visibleChange={toggleEditingTooltipVisibility}
              isFromCalender="zone"
            />
          ) : appointmentData.priorityId === 2 ? (
            appointmentData?.auditorium_configuration_id ? (
              <AudiViewDelegates
                data={appointmentData}
                visibleChange={toggleEditingTooltipVisibility}
                isFromCalender="zone"
                setMessageModalData={setMessageModal}
              />
            ) : appointmentData?.networking_configuration_id ? (
              <NetViewDelegates
                data={appointmentData}
                visibleChange={toggleEditingTooltipVisibility}
                isFromCalender="zone"
                setMessageModalData={setMessageModal}
              />
            ) : null
          ) : null}
        </div>
      );
    }
  };

  const getZoneCalenderData = async (current_date_here = "") => {
    setLoader(true);
    await ApiCall("get", null, "/admin/zonecalendar/details/get", {
      zone_id: parseInt(match?.params?.zone_id),
      brand_id: parseInt(match?.params?.brand_id),
      current_date: current_date_here
        ? current_date_here
        : currentDateForCalender,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((response) => {
        setAppointmentDetails(response?.data?.data ? response?.data?.data : []);

        let brand_color =
          response?.data?.data?.brand_color ?? "rgb(238, 37, 70)";
        setResourceAdmin([
          {
            fieldName: "priorityId",
            title: "Priority",
            instances: [
              { text: "Demo Room", id: 1, color: brand_color },
              {
                text: "Auditorium and Networking Session",
                id: 2,
                color: brand_color,
              },
              { text: "Zone Staffing", id: 3, color: brand_color },
            ],
          },
        ]);

        setResources([
          {
            fieldName: "priorityId",
            title: "Priority",
            instances: [
              { text: "Demo Room", id: 1, color: brand_color },
              {
                text: "Auditorium and Networking Session",
                id: 2,
                color: brand_color,
              },
            ],
          },
        ]);

        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };

  // const resourcesAdmin = [
  //   {
  //     fieldName: "priorityId",
  //     title: "Priority",
  //     instances: [
  //       { text: "Demo Room", id: 1, color: "rgb(238, 37, 70)" },
  //       {
  //         text: "Auditorium and Networking Session",
  //         id: 2,
  //         color: "rgb(238, 37, 70)",
  //       },
  //       { text: "Zone Staffing", id: 3, color: "rgb(238, 37, 70)" },
  //     ],
  //   },
  // ];

  // const resources = [
  //   {
  //     fieldName: "priorityId",
  //     title: "Priority",
  //     instances: [
  //       { text: "Demo Room", id: 1, color: "rgb(0, 0, 0)" },
  //       {
  //         text: "Auditorium and Networking Session",
  //         id: 2,
  //         color: "rgb(0, 0, 0)",
  //       },
  //     ],
  //   },
  // ];

  const grouping = [{ resourceName: "priorityId" }];

  const commitChanges = ({ added, changed, deleted }) => {
    if (added) {
      const startingAddedId =
        appointments.length > 0
          ? appointments[appointments.length - 1].id + 1
          : 0;
      appointments = [...appointments, { id: startingAddedId, ...added }];
    }
    if (changed) {
      appointments = appointments.map((appointment) =>
        changed[appointment.id]
          ? { ...appointment, ...changed[appointment.id] }
          : appointment
      );
    }
    if (deleted !== undefined) {
      setConfirmationVisible(!confirmationVisible);
    }
    return { appointments, addedAppointment: {} };
  };

  const toggleEditingFormVisibility = () => {
    setEditingFormVisible(!editingFormVisible);
  };

  const toggleEditingTooltipVisibility = () => {
    setshowtooltip(!showtooltip);
  };

  const onEditingAppointmentChange = (editingAppointment) => {
    setEditingAppointment(editingAppointment);
  };

  const onAddedAppointmentChange = (addedAppointment) => {
    setaddedAppointment(addedAppointment);
    if (editingAppointment !== undefined) {
      setPreviousAppointment(editingAppointment);
    }
    setEditingAppointment(undefined);
    setIsNewAppointment(true);
  };

  const TimeTableCell = ({ onDoubleClick, ...restProps }) => {
    return (
      <DayView.TimeTableCell
        onClick={() => {
          const isOldDate = isPastDate(currentDateForCalender);
          if (isOldDate === "yes")
            toast.error(dialogs.brandZoneCalender.validation.currentFutureDate);
          else onDoubleClick();
        }}
        {...restProps}
      />
    );
  };

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(channel_id) {
    if (messageValidator.current.allValid()) {
      if (messageModal?.cometChatUser.uid) {
        const response = await sendMessageAPI(channel_id, message_text);
        if (response.data.code === 200) {
          toast.success(response.data.message);
          clearMessageAndModal();
        } else toast.error(response.data.message);
      }
    } else {
      messageValidator.current.showMessages();
    }
  }

  return (
    <div id="zone_calendar_id" className="zone_calendar">
      <div className="d-flex justify-content-between bg-light w-100 common-sticky-header">
        <div>
          <h4 className="fs-24">{appointmentDetails?.zone_title} Calendar</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>
              <Link to="/brand">Brands</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link
                to={`/expomap/brandzonedetails/${parseInt(
                  match?.params?.brand_id
                )}/${parseInt(match?.params?.zone_id)}`}
              >
                Zone Calendar
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{appointmentDetails?.zone_title}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      {loader ? (
        <CommonLoader />
      ) : appointmentDetails ? (
        appointmentDetails?.is_active ? (
          <>
            <Paper
              className="d-flex mx-2"
              style={{ marginTop: Tablet ? "14%" : "7%" }}
            >
              {toggle === true ? (
                <Paper className="w-25 p-3 border border-end d-flex">
                  <span className="w-100">
                    <input />
                  </span>
                  <span className="mx-2">
                    <MenuUnfoldOutlined
                      style={{ fontSize: "18px" }}
                      onClick={() => setToggle(false)}
                    />
                  </span>
                </Paper>
              ) : (
                <Paper className="p-3 border border-end d-flex">
                  <span className="w-100"></span>
                  <span className="mx-2">
                    <SearchOutlined
                      style={{ fontSize: "18px" }}
                      onClick={() => setToggle(true)}
                    />
                  </span>
                </Paper>
              )}
              <Scheduler height={650} data={appointments}>
                <ViewState
                  currentDate={currentDateForCalender}
                  onCurrentDateChange={(r) => {
                    const dateToSet = moment(r.toDateString()).format(
                      "YYYY-MM-DD"
                    );
                    setCurrentDateForCalender(dateToSet);
                    getZoneCalenderData(dateToSet);
                  }}
                />
                <EditingState
                  onEditingAppointmentChange={onEditingAppointmentChange}
                  onAddedAppointmentChange={onAddedAppointmentChange}
                />
                <GroupingState grouping={grouping} />
                <DayView
                  startDayHour={0}
                  endDayHour={24}
                  cellDuration={60}
                  dayScaleRowComponent={() => <div></div>}
                  timeTableCellComponent={TimeTableCell}
                />
                <Appointments />
                <CurrentTimeIndicator
                  indicatorComponent={() => (
                    <div
                      className="current_time_indicator"
                      ref={targetDivRef}
                    ></div>
                  )}
                />

                <Resources
                  data={isAdminLevelUser(userType) ? resourcesAdmin : resources}
                  mainResourceName="priorityId"
                />
                <IntegratedGrouping />
                <GroupingPanel />
                <Toolbar />
                <DateNavigator />
                {moment(currentDateForCalender, "YYYY-MM-DD").isSame(
                  moment(),
                  "day"
                ) ? null : (
                  <TodayButton />
                )}
                <AppointmentTooltip
                  headerComponent={Header}
                  contentComponent={Content}
                  showCloseButton
                  visible={showtooltip}
                  onVisibilityChange={() => setshowtooltip(!showtooltip)}
                />
                <AppointmentForm
                  overlayComponent={connectProps(
                    AppointmentFormContainerBasic,
                    () => {
                      const currentAppointment =
                        appointments.filter(
                          (appointment) =>
                            editingAppointment &&
                            appointment.id === editingAppointment.id
                        )[0] || addedAppointment;
                      const cancelAppointment = () => {
                        if (isNewAppointment) {
                          setIsNewAppointment(false);
                          setEditingAppointment(previousAppointment);
                        }
                      };

                      return {
                        userType,
                        visible: editingFormVisible,
                        appointmentData: currentAppointment,
                        zone_id: parseInt(match?.params?.zone_id),
                        brand_id: parseInt(match?.params?.brand_id),
                        event_id: parseInt(match?.params?.event_id),
                        commitChanges: commitChanges,
                        visibleChange: toggleEditingFormVisibility,
                        onEditingAppointmentChange: onEditingAppointmentChange,
                        cancelAppointment,
                        getZoneCalenderData: getZoneCalenderData,
                        currentDateForCalender,
                      };
                    }
                  )}
                  visible={editingFormVisible}
                  onVisibilityChange={() =>
                    setEditingFormVisible(!editingFormVisible)
                  }
                />
              </Scheduler>
            </Paper>
          </>
        ) : (
          <div className="w-100  text-center font-weight-bold text-danger">
            This Zone is Inactive
          </div>
        )
      ) : null}

      {messageModal.isOpen && window.location.pathname !== "/mycalendar" ? (
        <Modal
          title={<b>Send Message</b>}
          visible={messageModal.isOpen}
          onOk={() => clearMessageAndModal()}
          onCancel={() => clearMessageAndModal()}
          footer={[
            <Button
              className="px-3 button-pink rounded"
              key="cancel"
              onClick={() => clearMessageAndModal()}
            >
              Cancel
            </Button>,
            <Button
              className="px-3 rounded"
              key="save"
              type="primary"
              onClick={() => callSendMessageAPI(messageModal?.channel_id)}
              // disabled={disableSendBtn}
            >
              Send
            </Button>,
          ]}
          className="message_modal"
        >
          <Row className="ticker_configuration_row mt-2">
            <Col span={6} className="ticker_configuration_label">
              <p>
                Message
                <RedAsterick />
              </p>
            </Col>
            <Col span={12}>
              <TextArea
                name="message_text"
                id="message_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a message"
                rows={4}
                value={message_text}
                onChange={(e) => set_message_text(e.target.value)}
                onBlur={() =>
                  messageValidator.current.showMessageFor("message_text")
                }
              />
              {messageValidator.current.message(
                "Message",
                message_text,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </div>
  );
};

export default ZoneCalender;
