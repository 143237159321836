import React, { useEffect, useState, useRef } from "react";
import { Breadcrumb, Select, Input, Button, Switch, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { inviteUser } from "../../redux/slice/userManagementSlice";
import { toast } from "react-toastify";
import { ApiCall } from "../../utils/api";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import RedAsterick from "../../components/Widgets/RedAsterick";
import { useLocation } from "react-router";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { useMediaQuery } from "react-responsive";

const InviteAttend = (props) => {
  const validator = useRef(new SimpleReactValidator());
  var CryptoJS = require("crypto-js");
  const location = useLocation();
  const [forceUpdate, setForceUpdate] = useState(false);
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [userType, setUserType] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [filteredZone, setFilteredZone] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [loadingAttend, setLoadingAttend] = useState(false);
  const [fields, setFields] = useState({
    tier_id: null,
    email: "",
    event_id: props?.event_id ?? null,
    user_type: null,
    brand_id: null,
    is_admin: false,
    is_speaker: false,
    zone_tag_details: [],
  });

  const dispatch = useDispatch();
  const { Option } = Select;
  const { loading } = useSelector((state) => state.userManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const isMobile = useMediaQuery({ minWidth: 480 });
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    // setLoadingAttend(true);
    setFilteredZone(
      zoneList?.filter((data) => data?.brand_id === fields?.brand_id)
    );
  }, [fields.brand_id]);

  useEffect(() => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    getTierList();
    getEventList();
    getUserTypeList();
    getBrandList();
    getZoneList();

    if (location?.state?.fromPeople === true) {
      setFields({ ...fields, user_type: "DELEGATE" });
    } else {
      if (decryptedData === "BRAND ADMIN") {
        setFields({ ...fields, user_type: "AGENT" });
      }
    }
    setTimeout(() => {
      setLoadingAttend(false);
    }, 2000);
  }, []);

  useEffect(() => {
    if (fields?.event_id) {
      getBrandList();
    }
  }, [fields?.event_id]);

  useEffect(() => {
    if (fields?.brand_id && fields?.user_type) {
      getTierList();
    }
  }, [fields?.brand_id, fields?.user_type]);

  const getTierList = () => {
    setLoadingAttend(true);
    ApiCall("get", null, "/admin/tiers/get", {
      brand_id:
        fields?.brand_id && fields?.user_type
          ? fields?.brand_id
          : brandList?.[0]?.brand_id,
    })
      .then((response) => {
        const { data } = response?.data;
        if (data?.length === 1) {
          setTierList(data);
          setFields({ ...fields, tier_id: data[0]?.tier_id });
        } else {
          setTierList(data);
        }
        setLoadingAttend(false);
      })
      .catch((err) => {
        setLoadingAttend(false);
      });
  };

  const getEventList = () => {
    setLoadingAttend(true);
    ApiCall("get", null, "/admin/events/dropdown/get", null)
      .then((response) => {
        const { rows } = response.data.data;
        setEventList(rows);
        if (rows.length === 1) {
          setFields((prev) => ({
            ...prev,
            event_id: rows[0]?.event_id,
          }));
        }
        setLoadingAttend(false);
      })
      .catch((err) => {
        console.error(err);
        setLoadingAttend(false);
      });
  };
  const getUserTypeList = () => {
    ApiCall("get", null, "/admin/user/get", null)
      .then((response) => {
        const { rows } = response.data.data;
      })
      .catch((err) => {});
  };

  const getBrandList = () => {
    setLoadingAttend(true);
    ApiCall("get", null, "/admin/brand/dropdown/get", {
      event_id: fields?.event_id ? fields?.event_id : "",
    })
      .then((response) => {
        const { rows } = response.data.data;
        setBrandList(rows);

        if (rows.length === 1 && fields?.user_type === "AGENT") {
          setFields({ ...fields, brand_id: rows[0].brand_id });
        }
        setLoadingAttend(false);
      })
      .catch((err) => {
        setLoadingAttend(false);
      });
  };

  const getZoneList = () => {
    setLoadingAttend(true);
    ApiCall("get", null, "/admin/zones/get", null)
      .then((response) => {
        let constantData = response.data.data.rows.map((response) => {
          return {
            is_assigned: false,
            is_always_get_messages: false,
            ...response,
          };
        });
        return constantData;
      })

      .then((data) => {
        setZoneList(data);
      })
      .catch((err) => {
        console.error(err);
      });
    setLoadingAttend(false);
  };

  // const handleFieldsChange = (val, name) => {
  //   setFields({ ...fields, [name]: val });
  // };

  const handleFieldsChange = (val, name) => {
    if (val === "DELEGATE") {
      setFields({ ...fields, [name]: val, brand_id: null });
      setBrandList([]);
    } else if (val === "AGENT") {
      getBrandList();
      setFields({ ...fields, [name]: val, brand_id: null });
    } else {
      setFields({ ...fields, [name]: val });
    }
  };

  const handleInviteAgentFormSubmit = async (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);

    const data =
      filteredZone &&
      filteredZone?.map((res) => {
        return {
          zone_id: res.zone_id,
          is_assigned: res.is_assigned,
          is_always_get_messages: res.is_always_get_messages,
        };
      });

    let formData = new FormData();
    formData.append(
      "data",
      JSON.stringify({ ...fields, zone_tag_details: data })
    );

    if (validator.current.allValid()) {
      setLoadingAttend(true);
      const res = await dispatch(
        inviteUser({
          ...fields,
          zone_tag_details: data,
          email: [fields?.email],
        })
      );

      if (res.payload.data.code === 200) {
        toast.success(res.payload.data.message);
        setLoadingAttend(false);
        if (props?.onSuccessFromModal) {
          props.onSuccessFromModal();
        } else {
          if (location?.state?.fromPeople === true) {
            props.history.push("/people");
          } else {
            props.history.push("/usermanagement");
          }
        }
      } else {
        toast.error(res.payload.data.message);
        setLoadingAttend(false);
      }
    } else {
      validator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
  };

  const handleCheckBox1Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_assigned = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  const handleCheckBox2Change = (e, data, idx) => {
    let filtered_zone = filteredZone;
    filtered_zone[idx].is_always_get_messages = e.target.checked;
    setFilteredZone([...filtered_zone]);
  };

  if (loadingAttend) return <CommonLoader />;
  else
    return (
      <form autoComplete="off">
        <div>
          {props?.event_id ? (
            ""
          ) : (
            <section>
              <h4 className="fs-24">Invite to Attend</h4>
              {location?.state?.fromPeople === true ? (
                ""
              ) : (
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item><Link to="/usermanagement">Admin Console</Link></Breadcrumb.Item>
                  <Breadcrumb.Item><Link to="/usermanagement">User Management</Link></Breadcrumb.Item>
                  <Breadcrumb.Item>Invite to Attend</Breadcrumb.Item>
                </Breadcrumb>
              )}
            </section>
          )}
          <div className="bg-white px-5 py-3">
            <div className="row my-3">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Invite User</h6>
              </div>
            </div>
            <div className="row my-3">
              <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                Select Event
                <RedAsterick />
              </div>
              <div className={`${isMobile ? "col-6" : "col-7"} `}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  className={!isMobile ? "w-100" : "w-50"}
                  placeholder="Select Event"
                  name="event_id"
                  value={fields.event_id}
                  onChange={(val) => handleFieldsChange(val, "event_id")}
                  onBlur={() => validator.current.showMessageFor("event_id")}
                  autoComplete="none"
                  listHeight={120}
                  listItemHeight={4}
                >
                  {eventList &&
                    eventList?.map((data) => {
                      return (
                        <Option value={data.event_id}>{data.event_name}</Option>
                      );
                    })}
                </Select>
                {validator.current.message(
                  "event",
                  fields.event_id,
                  "required|text",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            <div className="row my-3">
              <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                Email Address
                <RedAsterick />
              </div>
              <div className={`${isMobile ? "col-6" : "col-7"} `}>
                <Input
                  name="email"
                  id="email"
                  className={isMobile && "w-50 border-gray rounded"}
                  placeholder="Enter Email"
                  value={fields.email}
                  onChange={(e) => handleFieldsChange(e.target.value, "email")}
                  onBlur={() => validator.current.showMessageFor("email")}
                  autoComplete="none"
                />
                {validator.current.message(
                  "email",
                  fields.email,
                  "required|email",
                  { className: "text-danger py-2" }
                )}
              </div>
            </div>

            {/* <div className="row my-3">
              <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                Email Address
                <RedAsterick />
              </div>
              <div className={`${isMobile ? "col-6" : "col-7"} `}>
                <Select
                  mode="tags"
                  className={isMobile && "w-50 border-gray rounded"}
                  onChange={handleChange}
                  tokenSeparators={[","]}
                />
              </div>
            </div> */}

            <div className="row my-3">
              <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                Select User Type
                <RedAsterick />
              </div>
              <div className={`${isMobile ? "col-6" : "col-7"} `}>
                <Select
                  showSearch
                  optionFilterProp="children"
                  className={!isMobile ? "w-100" : "w-50"}
                  placeholder="Select User"
                  name="user_type"
                  value={fields.user_type}
                  onBlur={() => validator.current.showMessageFor("user_type")}
                  onChange={(val) => handleFieldsChange(val, "user_type")}
                  autoComplete="none"
                  disabled={location?.state?.fromPeople === true ? true : false}
                >
                  <Option value="AGENT">Agent</Option>
                  <Option
                    value="DELEGATE"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Delegate
                  </Option>
                  <Option
                    value="SPEAKER"
                    className={userType === "BRAND ADMIN" ? "d-none" : ""}
                  >
                    Speaker
                  </Option>
                </Select>
                {validator.current.message(
                  "usertype",
                  fields.user_type,
                  "required",
                  { className: "text-danger py-1" }
                )}
              </div>
            </div>

            {fields.user_type === "AGENT" && (
              <>
                <div className="row my-3">
                  <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                    Admin
                  </div>
                  <div className="col-7">
                    <Switch
                      name="is_admin"
                      checked={fields.is_admin}
                      onChange={(checked) =>
                        handleFieldsChange(checked, "is_admin")
                      }
                    />
                  </div>
                </div>

                <div className="row my-3">
                  <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                    Brand
                    <RedAsterick />
                  </div>
                  {userType && userType === "BRAND ADMIN" ? (
                    <div className={`${isMobile ? "col-6" : "col-7"} `}>
                      <Input
                        className={isMobile && "w-50"}
                        value={brandList[0]?.brand_title}
                      />
                    </div>
                  ) : (
                    <div className={`${isMobile ? "col-6" : "col-7"} `}>
                      <Select
                        showSearch
                        className={!isMobile ? "w-100" : "w-50"}
                        placeholder="Select Brand"
                        optionFilterProp="children"
                        name="brand_id"
                        value={fields.brand_id}
                        onChange={(val) => handleFieldsChange(val, "brand_id")}
                        onBlur={() =>
                          validator.current.showMessageFor("brand_id")
                        }
                        autoComplete="none"
                        listHeight={120}
                        listItemHeight={4}
                        disabled={
                          userType && userType === "BRAND ADMIN" ? true : false
                        }
                      >
                        {brandList &&
                          brandList?.map((data) => {
                            return (
                              <Option value={data.brand_id}>
                                {data.brand_title}
                              </Option>
                            );
                          })}
                      </Select>
                      {fields.user_type === "Agent" &&
                        validator.current.message(
                          "brand",
                          fields.brand_id
                            ? fields.brand_id
                            : brandList[0].brand_id,
                          "required|text",
                          { className: "text-danger py-1" }
                        )}
                      {brandList && brandList.length === 0 && (
                        <div className="d-block mt-2 fs-14 text-danger">
                          <span>
                            Opps, No brands is available for above selected
                            "Event"
                          </span>
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="row my-3">
                  <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                    Zone Tags
                  </div>
                  <div className="col-8 border-bottom zone-tag-scroll-div">
                    <div className="row border border-gray rounded py-1 sticky-top bg-white">
                      <div className="col-4">
                        <b>Zones</b>
                      </div>
                      <div className="col-4">
                        <b>Assign</b>
                      </div>
                      <div className="col-4">
                        <b>Always get Msgs</b>
                      </div>
                    </div>

                    {filteredZone &&
                      filteredZone?.map((data, idx) => (
                        <div className="row border-start border-end">
                          <div className="col-4">
                            <div className="my-3">{data.zone_title}</div>
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className="mx-3"
                              name="assign"
                              onChange={(e) =>
                                handleCheckBox1Change(e, data, idx)
                              }
                            />
                          </div>

                          <div className="col-4 text-start pt-3">
                            <Checkbox
                              className="mx-3"
                              name="alwaysGetMags"
                              onChange={(e) =>
                                handleCheckBox2Change(e, data, idx)
                              }
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}

            <div className="row my-3">
              <div className={`${isMobile ? "col-3 text-end" : "col-5"} `}>
                Tier
                <RedAsterick />
              </div>

              {tierList && tierList.length === 1 ? (
                <div className={`${isMobile ? "col-6" : "col-7"} `}>
                  <Input
                    className={isMobile && "w-50"}
                    value={tierList[0]?.tier}
                  />
                </div>
              ) : (
                <div className={`${isMobile ? "col-6" : "col-7"} `}>
                  <Select
                    showSearch
                    name="tier_id"
                    className={!isMobile ? "w-100" : "w-50"}
                    placeholder="Select Tier"
                    optionFilterProp="children"
                    value={fields.tier_id}
                    onChange={(val) => handleFieldsChange(val, "tier_id")}
                    onBlur={() => validator.current.showMessageFor("tier_id")}
                    listHeight={120}
                    listItemHeight={4}
                    autoComplete="none"
                    disabled={
                      userType && userType === "BRAND ADMIN" ? true : false
                    }
                  >
                    {tierList &&
                      tierList?.map((data) => {
                        return (
                          <Option value={data.tier_id}>{data.tier}</Option>
                        );
                      })}
                  </Select>
                </div>
              )}
              <div className="row">
                <div
                  className={`${isMobile ? "col-3 text-end" : "col-5"} `}
                ></div>

                <div className={`${isMobile ? "col-6" : "col-7"} `}>
                  {validator.current.message(
                    "tiers",

                    fields?.tier_id,
                    "required",
                    { className: "text-danger py-1" }
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            className="shadow-sm p-3 d-flex justify-content-between"
            style={{ backgroundColor: "#eeeeee" }}
          >
            {props?.onCancelFromModal ? (
              <Button
                className="px-3 d-block button-pink rounded"
                onClick={() => props?.onCancelFromModal()}
              >
                Cancel
              </Button>
            ) : location?.state?.fromPeople === true ? (
              <Link to="/people">
                <Button className="px-3 d-block button-pink rounded">
                  Cancel
                </Button>
              </Link>
            ) : (
              <Link to="/usermanagement">
                <Button className="px-3 d-block button-pink rounded">
                  Cancel
                </Button>
              </Link>
            )}

            {props?.onSuccessFromModal ? (
              <Button
                type="primary"
                onClick={
                  (() => props?.onSuccessFromModal(),
                  handleInviteAgentFormSubmit)
                }
              >
                {!loading ? (
                  "Send Invitation"
                ) : (
                  <div style={{ width: "80px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button>
            ) : (
              <Button type="primary" onClick={handleInviteAgentFormSubmit}>
                {!loading ? (
                  "Send Invitation"
                ) : (
                  <div style={{ width: "80px" }}>
                    <Spin indicator={loadingIcon} style={{ color: "white" }} />
                  </div>
                )}
              </Button>
            )}
          </div>
        </div>
      </form>
    );
};

export default InviteAttend;
