import React, { useState, useEffect } from "react";
import { Button, Steps, Breadcrumb, Spin, Result } from "antd";
import "./event.css";
import { ApiCall } from "../../utils/api";
import EventEditor from "./EditEventDetails/EventEditor";
import EventSettings from "./EditEventDetails/EventSettings";
import RegistrationAndCommunication from "./EditEventDetails/RegistrationAndCommunication";
import Tiers from "./EditEventDetails/Tiers";
import NavigationAndReception from "./EditEventDetails/NavigationAndReception";
import SocialWall from "./EditEventDetails/SocialWall";
import {
  createEvent,
  createEventSettings,
  navigationAndReceptionEvent,
  registerEvent,
  socialWallEvent,
  tierEvent,
} from "../../redux/slice/createEvent";
import {
  updateEvent,
  updateEventSettings,
  updateNavigationAndReceptionEvent,
  updateRegisterEvent,
  updateSocialWallEvent,
  updateTierEvent,
} from "../../redux/slice/updateEvent";
import { useDispatch, useSelector } from "react-redux";
import _, { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { clearEventData, storeEventData } from "../../redux/slice/editEvent.js";
import {
  mapEventEditorSchema,
  mapRegisterationSchema,
  mapRelationsToRegistrationSchema,
  mapDefaultTiersToSchema,
  mapTiersSchema,
  mapNavigationReceptionSchema,
  mapSocialWallSchema,
  mapEventSettingsSchema,
} from "./Event.utils";
import {
  getNavigationSettingsType,
  getSocialMediaLinks,
  getTiers,
  getEventEligibility,
} from "../../utils/commonApis";
import CommonLoader from "../../components/Widgets/CommonLoader";
import { dialogs } from "../../components/constsnts/string.constants";
import {
  createISOTimeString,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";

function EditEvent(props) {
  const { match } = props;
  const { Step } = Steps;
  const Tablet = useMediaQuery({ maxWidth: 1200 });

  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  let history = useHistory();
  const [eventIdInState, setEventIdInState] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [eventEditorData, setEventEditorData] = useState({});
  const [eventEditorValid, setEventEditorValid] = useState(false);
  const [eventSettingsData, setEventSettingsData] = useState({});
  const [eventSettingsValid, setEventSettingsValid] = useState(false);
  const [registerationData, setRegisterationData] = useState({});
  const [registerValid, setRegisterValid] = useState(false);
  const [tiersData, setTiersData] = useState({});
  const [validTiers, setValidTiers] = useState(false);
  const [navReceptionData, setNavReceptionData] = useState({});
  const [navValid, setNavValid] = useState(false);
  const [socialWallData, setSocialWallData] = useState();
  const [socialValid, setSocialValid] = useState(false);
  const { loading } = useSelector((state) => state.updateEvent);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const editEventDataRes = useSelector((state) => state.editEventData);
  const [ckeditor1Data, setEditor1Data] = useState("<p></p>");
  const [ckeditor2Data, setEditor2Data] = useState("<p></p>");
  const [ckeditor3Data, setEditor3Data] = useState("<p></p>");
  const [loader, setLoader] = useState(true);
  const [isNoEventDataFound, setIsNoEventDataFound] = useState(false);
  const [eventEligibility, setEventEligibility] = useState(null);
  const [accessDenied, setAccessDenied] = useState(false);
  useEffect(async () => {
    if (match?.params?.event_id)
      getEventData(match?.params?.event_id, false, false);
    else getDefaultSchemas();

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) window.location.href = "/pagenotfound";
  }, [CryptoJS.AES]);

  function setLoaderOnDemand(value) {
    setLoader(value);
  }

  const getDefaultSchemas = async () => {
    //event editor
    const newEventEditorData = mapEventEditorSchema({});
    setEventEditorData(newEventEditorData);

    //event settings
    const newEventSettingsData = mapEventSettingsSchema({});
    setEventSettingsData(newEventSettingsData);

    //registration
    const newSetRegisterationData = mapRegisterationSchema({});
    setEditor1Data(newSetRegisterationData.term_description_1);
    setEditor2Data(newSetRegisterationData.term_description_2);
    setEditor3Data(newSetRegisterationData.term_description_3);
    setRegisterationData(newSetRegisterationData);

    //tiers data
    const tiersDefaultAPIData = await getTiers();
    const newSetTiersData = mapDefaultTiersToSchema(tiersDefaultAPIData);
    setTiersData({ ...newSetTiersData, event_id: "" });

    //navigation & reception
    const settingTypesCollectionData = await getNavigationSettingsType();
    const mediaLinks = await getSocialMediaLinks();
    const newNavReceptionData = await mapNavigationReceptionSchema(
      {},
      settingTypesCollectionData,
      mediaLinks
    );

    setNavReceptionData({ ...newNavReceptionData, event_id: "" });
    const newSocialWallData = await mapSocialWallSchema({}, mediaLinks);
    setSocialWallData({ ...newSocialWallData, event_id: "" });
    setEventIdInState("");
    setLoader(false);
  };

  const getEventData = async (
    event_id,
    isAfterCreateNewEvent = false,
    isAfterRegistration = false
  ) => {
    const response = await ApiCall(
      "GET",
      "",
      `/admin/events/details/get?event_id=${event_id ?? ""}`,
      ""
    );

    if (response?.data?.code === 200) {
      if (response?.data?.data) {
        const eli = await getEventEligibility({ event_id });
        if (eli) setEventEligibility(eli);

        await dispatch(
          storeEventData({
            data: response.data.data,
            keyToUpdate: "main",
          })
        );

        //event editor
        const newEventEditorData = mapEventEditorSchema(
          response.data.data.editor_details
        );
        setEventEditorData(newEventEditorData);

        //event settings
        const newEventSettingsData = mapEventSettingsSchema(
          response.data.data.event_settings_details
            ? { ...response.data.data.event_settings_details, event_id }
            : { event_id }
        );
        setEventSettingsData(newEventSettingsData);

        //registration
        const newSetRegisterationData = mapRegisterationSchema(
          response.data.data.registration_and_communication_details
        );

        setEditor1Data(newSetRegisterationData.term_description_1);
        setEditor2Data(newSetRegisterationData.term_description_2);
        setEditor3Data(newSetRegisterationData.term_description_3);
        setRegisterationData(newSetRegisterationData);

        //tiers data
        const tiersDefaultAPIData = await getTiers();

        if (isEmpty(response.data.data.tier_details)) {
          const newSetTiersData = mapDefaultTiersToSchema(tiersDefaultAPIData);
          setTiersData({
            ...newSetTiersData,
            event_id: response.data.data.event_id,
          });
        } else {
          const newSetTiersData = mapTiersSchema(
            tiersDefaultAPIData,
            response.data.data.tier_details
          );

          setTiersData({
            ...newSetTiersData,
            event_id: response.data.data.event_id,
          });
        }

        //navigation & reception
        const settingTypesCollectionData = await getNavigationSettingsType();
        const mediaLinks = await getSocialMediaLinks();
        const newNavReceptionData = await mapNavigationReceptionSchema(
          response.data.data.navigation_plus_reception_details,
          settingTypesCollectionData,
          mediaLinks
        );

        setNavReceptionData({
          ...newNavReceptionData,
          event_id: response.data.data.event_id,
        });

        const newSocialWallData = await mapSocialWallSchema(
          response.data.data.social_wall_details,
          mediaLinks
        );
        setSocialWallData({
          ...newSocialWallData,
          event_id: response.data.data.event_id,
        });

        setEventIdInState(response.data.data.event_id);
        if (isAfterCreateNewEvent) {
          setCurrentStep(currentStep + 1);
        }
        if (isAfterRegistration) {
          setCurrentStep(currentStep + 1);
        }
        setLoader(false);
      } else setLoader(false);
    } else if (response?.data?.code === 404) {
      toast.error(response?.data?.message);
      setAccessDenied(true);
      setLoader(false);
    } else {
      if (response.error && response.error === "Network Error")
        toast.error("Please check the internet connection");
      if (response?.data?.message) {
        if (response.data.message === "Something went wrong")
          setIsNoEventDataFound(true);
        toast.error(response.data.message);
      }
      setLoader(false);
    }
  };

  const validEventEditor = (val) => {
    setEventEditorValid(false);
    if (val) {
      setLoader(true);
      let clonedEditorData = cloneDeep(eventEditorData);
      clonedEditorData.events_organizer_brand_id = undefined;
      clonedEditorData.customer = undefined;
      clonedEditorData.survey = undefined;

      if (clonedEditorData.event_shows_details) {
        let final_event_shows_details = [];
        clonedEditorData.event_shows_details.forEach((ele) => {
          const start_date_str =
            ele.start_date +
            " " +
            moment(moment(ele.start_time, TIME_FORMAT)).format("HH:mm");
          const start_utc = createISOTimeString(start_date_str);
          const end_date_str =
            ele.end_date +
            " " +
            moment(moment(ele.end_time, TIME_FORMAT)).format("HH:mm");
          const end_utc = createISOTimeString(end_date_str);
          final_event_shows_details.push({
            show_id: ele.show_id ?? "",
            is_delete: ele.is_delete ?? false,
            start_date_time: start_utc,
            end_date_time: end_utc,
          });
        });
        clonedEditorData.event_shows_details = final_event_shows_details;
      }

      if (clonedEditorData.registration_start_date_time) {
        const start_utc = createISOTimeString(
          clonedEditorData.registration_start_date_time
        );
        const end_utc = createISOTimeString(
          clonedEditorData.registration_end_date_time
        );
        clonedEditorData.registration_start_date_time = start_utc;
        clonedEditorData.registration_end_date_time = end_utc;
      }

      clonedEditorData = JSON.parse(JSON.stringify(clonedEditorData));
      updateEventEditor(clonedEditorData);
    }
  };

  const updateEventEditor = async (dataToUpdate) => {
    let formData = new FormData();

    formData.append("event_logo_file", eventEditorData?.event_logo_file);
    formData.append(
      "minimized_logo_file",
      eventEditorData?.minimized_logo_file
    );
    formData.append("data", JSON.stringify(dataToUpdate));
    formData.append(
      "pre_event_message_media_file",
      eventEditorData?.pre_event_messages?.pre_event_message_media_file
    );
    formData.append(
      "welcome_message_media_file",
      eventEditorData?.welcome_messages?.welcome_message_media_file
    );
    formData.append(
      "controls_help_media_file",
      eventEditorData?.controls_help_media_file
    );

    if (eventIdInState) {
      const updateEventResponse = await dispatch(updateEvent(formData));

      if (updateEventResponse?.payload?.data?.code === 200) {
        toast.success(updateEventResponse?.payload?.data?.message);

        await dispatch(
          storeEventData({
            ...{
              data: dataToUpdate,
              keyToUpdate: "editor_details",
            },
            ...{
              data: updateEventResponse.payload?.data?.data?.event_id,
              keyToUpdate: "event_id",
            },
          })
        );

        setCurrentStep(currentStep + 1);
        setLoader(false);
        setEventIdInState(updateEventResponse.payload?.data?.data?.event_id);
      } else {
        setLoader(false);
        if (updateEventResponse?.payload?.data?.message)
          toast.error(updateEventResponse.payload.data.message);
      }
    } else {
      const createEventResponse = await dispatch(createEvent(formData));

      if (createEventResponse?.payload?.data?.code === 200) {
        toast.success(createEventResponse?.payload?.data?.message);
        if (createEventResponse?.payload?.data?.data?.event_id) {
          await dispatch(
            storeEventData({
              ...{
                data: dataToUpdate,
                keyToUpdate: "editor_details",
              },
              ...{
                data: createEventResponse.payload?.data?.data?.event_id,
                keyToUpdate: "event_id",
              },
            })
          );
          setEventIdInState(createEventResponse?.payload?.data?.data?.event_id);
          setLoader(true);
          getEventData(
            createEventResponse?.payload?.data?.data?.event_id,
            true,
            false
          );
        }
      } else {
        setLoader(false);
        toast.error(createEventResponse?.payload?.data?.message);
      }
    }
  };

  const registerValidation = (val) => {
    setRegisterValid(false);
    if (val) {
      let dataToUpdate = cloneDeep(registerationData);
      let finalDataToUpdate = mapRegisterationSchema(
        dataToUpdate,
        editEventDataRes
      );
      finalDataToUpdate = mapRelationsToRegistrationSchema(finalDataToUpdate);
      setLoader(true);
      updateRegisterForm({
        ...finalDataToUpdate,
        ...{
          term_description_1: ckeditor1Data,
          term_description_2: ckeditor2Data,
          term_description_3: ckeditor3Data,
          event_id: eventIdInState,
        },
      });
    }
  };

  const updateRegisterForm = async (dataToUpdate) => {
    if (dataToUpdate.registration_settings_id) {
    } else {
    }
    if (dataToUpdate.registration_settings_id) {
      const updateRegisterEventResponse = await dispatch(
        updateRegisterEvent(dataToUpdate)
      );

      if (updateRegisterEventResponse?.payload?.data?.code === 200) {
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "registration_and_communication_details",
          })
        );

        toast.success(updateRegisterEventResponse.payload.data.message);
        setLoader(true);
        getEventData(eventIdInState, false, true);
      } else {
        setLoader(false);
        if (updateRegisterEventResponse.payload?.data?.message)
          toast.error(updateRegisterEventResponse.payload.data.message);
      }
    } else {
      const registerEventResponse = await dispatch(registerEvent(dataToUpdate));

      if (registerEventResponse?.payload?.data?.code === 200) {
        const allData = {
          ...registerEventResponse?.payload?.data?.data,
          ...dataToUpdate,
        };
        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "registration_and_communication_details",
          })
        );
        toast.success(registerEventResponse.payload.data.message);
        setLoader(true);
        getEventData(eventIdInState, false, true);
      } else {
        setLoader(false);
        if (registerEventResponse?.payload?.data?.message)
          toast.error(registerEventResponse.payload.data.message);
      }
    }
  };

  const validEventSettings = (val) => {
    setEventSettingsValid(false);
    if (val) {
      setLoader(true);
      let clonedSettingsData = cloneDeep(eventSettingsData);
      updateEventSettingsForm(clonedSettingsData);
    }
  };

  const updateEventSettingsForm = async (dataToUpdate) => {
    let formData = new FormData();

    formData.append("data", JSON.stringify(dataToUpdate));

    formData.append(
      "sponsor_banner_file",
      eventSettingsData?.event_settings_details.sponsor_banner_file
    );
    formData.append(
      "sponsor_booth_right_file",
      eventSettingsData?.event_settings_details.sponsor_booth_right_file
    );
    formData.append(
      "sponsor_booth_center_file",
      eventSettingsData?.event_settings_details.sponsor_booth_center_file
    );
    formData.append(
      "sponsor_booth_left_file",
      eventSettingsData?.event_settings_details.sponsor_booth_left_file
    );

    if (eventSettingsData.event_settings_details.event_settings_id) {
      const updateEventResponse = await dispatch(updateEventSettings(formData));

      if (updateEventResponse?.payload?.data?.code === 200) {
        toast.success(updateEventResponse?.payload?.data?.message);

        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "event_settings_details",
          })
        );
        getEventData(eventIdInState, true, false);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (updateEventResponse?.payload?.data?.message)
          toast.error(updateEventResponse.payload.data.message);
      }
    } else {
      const createEventSettingRes = await dispatch(
        createEventSettings(formData)
      );

      if (createEventSettingRes?.payload?.data?.code === 200) {
        if (createEventSettingRes?.payload?.data?.data?.event_settings_id) {
          const allData = {
            ...createEventSettingRes?.payload?.data?.data,
            ...dataToUpdate,
          };
          await dispatch(
            storeEventData({
              data: allData,
              keyToUpdate: "event_settings_details",
            })
          );

          toast.success(createEventSettingRes.payload.data.message);
          getEventData(eventIdInState, true, false);
          setCurrentStep(currentStep + 1);
          setLoader(false);
        }
      } else {
        setLoader(false);
        toast.error(createEventSettingRes?.payload?.data?.message);
      }
    }
  };

  const tiersValid = (val) => {
    setValidTiers(false);
    if (val) {
      setLoader(true);
      const tiersDataSelected = cloneDeep(tiersData);
      updateTierForm(tiersDataSelected);
    }
  };

  const updateTierForm = async (dataToUpdate) => {
    let tier_settings_id_present = dataToUpdate.event_tier_details.filter(
      (item) => item.tier_settings_id
    );

    if (!isEmpty(tier_settings_id_present)) {
      const updateTierEventResponse = await dispatch(
        updateTierEvent(dataToUpdate)
      );

      if (updateTierEventResponse?.payload?.data?.code === 200) {
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "tier_details",
          })
        );
        toast.success(updateTierEventResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (updateTierEventResponse?.payload?.data?.message)
          toast.error(updateTierEventResponse.payload.data.message);
      }
    } else {
      const tierEventResponse = await dispatch(tierEvent(dataToUpdate));

      if (tierEventResponse?.payload?.data?.code === 200) {
        const allData = {
          ...tierEventResponse?.payload?.data?.data,
          ...dataToUpdate,
        };
        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "tier_details",
          })
        );
        toast.success(tierEventResponse?.payload?.data?.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (tierEventResponse?.payload?.data?.message)
          toast.error(tierEventResponse?.payload.data.message);
      }
    }
  };

  const navReceptionValidation = (val) => {
    setNavValid(false);
    if (val) {
      let clonedNavReceptionData = cloneDeep(navReceptionData);
      clonedNavReceptionData.event_assets_settings = undefined;
      clonedNavReceptionData = JSON.parse(
        JSON.stringify(clonedNavReceptionData)
      );

      setLoader(true);
      updateNavigationReceptionForm(clonedNavReceptionData);
    } else {
    }
  };

  const updateNavigationReceptionForm = async (dataToUpdate) => {
    let formData = new FormData();
    formData.append("data", JSON.stringify(dataToUpdate));

    if (dataToUpdate.navigation_main_settings[0].navigation_main_setting_id) {
      const navigationReceptionResponse = await dispatch(
        updateNavigationAndReceptionEvent(formData)
      );

      if (navigationReceptionResponse?.payload?.data?.code === 200) {
        await dispatch(
          storeEventData({
            data: dataToUpdate,
            keyToUpdate: "navigation_plus_reception_details",
          })
        );

        toast.success(navigationReceptionResponse.payload.data.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        if (navigationReceptionResponse?.payload?.data?.message)
          toast.error(navigationReceptionResponse.payload.data.message);
      }
    } else {
      const navigationReceptionResponse = await dispatch(
        navigationAndReceptionEvent(formData)
      );

      if (navigationReceptionResponse?.payload?.data?.code === 200) {
        const allData = {
          ...navigationReceptionResponse?.payload?.data?.data,
          ...dataToUpdate,
        };

        await dispatch(
          storeEventData({
            data: allData,
            keyToUpdate: "navigation_plus_reception_details",
          })
        );

        toast.success(navigationReceptionResponse?.payload?.data?.message);
        setCurrentStep(currentStep + 1);
        setLoader(false);
      } else {
        setLoader(false);
        toast.error(navigationReceptionResponse?.payload?.data?.message);
      }
    }
  };

  const socialWallValid = (val) => {
    setSocialValid(false);
    if (val) {
      setLoader(true);
      updateSocialWall(socialWallData);
    }
  };

  const updateSocialWall = async (dataToUpdate) => {
    if (dataToUpdate.social_settings_id) {
      const updateSocialWallResponse = await dispatch(
        updateSocialWallEvent(dataToUpdate)
      );

      if (updateSocialWallResponse?.payload?.data?.code === 200) {
        await dispatch(clearEventData());
        history.push("/eventmanagement");
        setLoader(false);
        toast.success(updateSocialWallResponse.payload.data.message);
      } else {
        setLoader(false);
        if (updateSocialWallResponse?.payload?.data?.message)
          toast.error(updateSocialWallResponse.payload.data.message);
      }
    } else {
      const createResponse = await dispatch(socialWallEvent(dataToUpdate));

      if (createResponse?.payload?.data?.code === 200) {
        await dispatch(clearEventData());
        history.push("/eventmanagement");
        setLoader(false);
        toast.success(dialogs.event.eventUpdateSuccess);
      } else {
        setLoader(false);
        if (createResponse?.payload?.data?.message)
          toast.error(createResponse.payload.data.message);
      }
    }
  };

  const steps = [
    {
      title: "Event Editor",
      content: !_.isEmpty(eventEditorData) ? (
        <EventEditor
          data={{
            ...eventEditorData,
            ...{ event_id: eventIdInState },
          }}
          eventEligibility={eventEligibility}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(eventEditorData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setEventEditorData(clonedData);
            } else setEventEditorData({ ...clonedData, ...dataHere });
          }}
          onValid={(val) => validEventEditor(val)}
          initiateValidations={eventEditorValid}
          setLoaderOnDemand={(val) => setLoaderOnDemand(val)}
        />
      ) : null,
    },
    {
      title: "Event Settings",
      content: !_.isEmpty(eventSettingsData) ? (
        <EventSettings
          data={{
            ...eventSettingsData,
            ...{ event_id: eventIdInState },
          }}
          eventEligibility={eventEligibility}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(eventSettingsData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setEventSettingsData(clonedData);
            } else setEventSettingsData({ ...clonedData, ...dataHere });
          }}
          onNavigateToRegistration={() => setCurrentStep(currentStep + 1)}
          onNavigateToTier={() => setCurrentStep(currentStep + 2)}
          onNavigateToReception={() => setCurrentStep(currentStep + 3)}
          onNavigateToSocialWall={() => setCurrentStep(currentStep + 4)}
          onValid={(val) => validEventSettings(val)}
          initiateValidations={eventSettingsValid}
        />
      ) : null,
    },
    {
      title: "Registration & Communication",
      content: !_.isEmpty(registerationData) ? (
        <RegistrationAndCommunication
          registerData={{
            ...registerationData,
            ...{ event_id: eventIdInState },
          }}
          ckeditor1Data={ckeditor1Data}
          ckeditor2Data={ckeditor2Data}
          ckeditor3Data={ckeditor3Data}
          onChangeCKEditorData={(key, value) => {
            if (key === "term_description_1") setEditor1Data(value);
            if (key === "term_description_2") setEditor2Data(value);
            if (key === "term_description_3") setEditor3Data(value);
          }}
          onChangeData={(dataHere) => {
            let oldData = cloneDeep(registerationData);
            setRegisterationData({ ...oldData, ...dataHere });
          }}
          onValid={(val) => registerValidation(val)}
          initiateValidations={registerValid}
        />
      ) : null,
    },
    {
      title: "Tiers",
      content: (
        <Tiers
          tiersData={{
            ...tiersData,
            ...{ event_id: eventIdInState },
          }}
          onChangeData={(data) => {
            let clonedTiers = cloneDeep(tiersData);
            clonedTiers.event_tier_details = data;
            setTiersData(clonedTiers);
          }}
          onValid={(val) => tiersValid(val)}
          initiateValidations={validTiers}
        />
      ),
    },
    {
      title: "Navigation + Reception",
      content: (
        <NavigationAndReception
          navReceptionData={{
            ...navReceptionData,
            ...{ event_id: eventIdInState },
          }}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(navReceptionData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setNavReceptionData(clonedData);
            } else setNavReceptionData({ ...clonedData, ...dataHere });
          }}
          onValid={(val) => {
            navReceptionValidation(val);
          }}
          initiateValidations={navValid}
        />
      ),
    },
    {
      title: "Social Wall",
      content: (
        <SocialWall
          socialWallData={{
            ...socialWallData,
            ...{ event_id: eventIdInState },
          }}
          onChangeData={(dataHere, parentKey) => {
            let clonedData = cloneDeep(socialWallData);
            if (!isEmpty(parentKey)) {
              clonedData[parentKey] = { ...clonedData[parentKey], ...dataHere };
              setSocialWallData(clonedData);
            } else setSocialWallData({ ...clonedData, ...dataHere });
          }}
          onValid={(val) => socialWallValid(val)}
          initiateValidations={socialValid}
        />
      ),
    },
  ];

  const next = () => {
    if (currentStep === 0) {
      setEventEditorValid(true);
    } else if (currentStep === 1) {
      setEventSettingsValid(true);
    } else if (currentStep === 2) {
      setRegisterValid(true);
    } else if (currentStep === 3) {
      setValidTiers(true);
    } else if (currentStep === 4) {
      setNavValid(true);
    }
  };

  const onChange = (current) => {
    if (current === 0) {
      if (match?.params?.event_id) getEventData(match?.params?.event_id, false);
      setCurrentStep(current);
    } else {
      if (eventIdInState) setCurrentStep(current);
      else toast.error("Please complete the first step");
    }
  };

  if (loader) return <CommonLoader />;
  else if (isNoEventDataFound)
    return (
      <Result
        icon={<SmileOutlined />}
        title="Event Data not found, please try again later"
      />
    );
  else {
    let title =
      match && match.params && match.params.event_id
        ? "Edit Event"
        : "Create Event";
    return (
      <div className="site-layout-background">
        {accessDenied === false && (
          <div className="customer-management-form">
            <div className="background-color-gray">
              <div className="position-fixed d-flex justify-content-between bg-light w-100 common-sticky-header height_70px">
                <div>
                  <h4 className="fs-24">{title}</h4>
                  <Breadcrumb separator=">">
                    <Breadcrumb.Item>
                      <Link to="/eventmanagement">Admin Console</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to="/eventmanagement">Event Management</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{title}</Breadcrumb.Item>
                  </Breadcrumb>
                </div>
              </div>
            </div>
            <Steps
              type="navigation"
              current={currentStep}
              onChange={onChange}
              className="site-navigation-steps"
              style={{
                position: "sticky",
                // width: "90%",
                zIndex: "999999",
                top: "68px",
                margin: 0,
                padding: 0,
              }}
            >
              {steps.map((item, stepIndex) => (
                <Step
                  key={item.title}
                  disabled={loader}
                  status={"process"}
                  className="event_steps bg-white"
                  title={
                    // item.title
                    <span
                      className={
                        currentStep === stepIndex
                          ? "custom_step_text_selected text-wrap"
                          : "custom_step_text text-wrap"
                      }
                    >
                      {item.title}
                    </span>
                  }
                  stepNumber={stepIndex + 1}
                  // icon={
                  //   currentStep === stepIndex ?
                  //   <div
                  //     style={{
                  //       backgroundColor: "#EF0855",
                  //       color: "white",
                  //       width: 30,
                  //       height: 30,
                  //       margin: "auto",
                  //       borderRadius: 15
                  //     }}
                  //   >
                  //     {stepIndex+1}
                  //   </div>
                  //   :
                  //   <span style={{color:'black'}}>{stepIndex+1}</span>
                  // }
                />
              ))}
            </Steps>
            <div
              className={`${Tablet ? "px-1" : "px-4"} bg-white`}
              style={{ marginTop: "80px" }}
            >
              <div className="steps-content">{steps[currentStep].content}</div>
              <div
                className="steps-action py-4"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "stretch",
                  // marginTop: 30,
                }}
              >
                <div className="d-flex">
                  {currentStep > 0 && (
                    <Button
                      style={{ margin: "0 8px" }}
                      onClick={() => setCurrentStep(currentStep - 1)}
                    >
                      Previous
                    </Button>
                  )}
                  <Link
                    to="/eventmanagement"
                    className="d-flex"
                    style={{ margin: "0 8px" }}
                  >
                    <Button className="px-3 d-block button-pink">Cancel</Button>
                  </Link>
                </div>
                {currentStep < steps.length - 1 && (
                  <Button
                    onClick={() => next()}
                    type="primary"
                    disabled={loading}
                  >
                    {!loading ? (
                      "Save & Next" //aditya populate  yes no handle for brand
                    ) : (
                      <div style={{ width: "70px" }}>
                        <Spin
                          style={{ color: "white" }}
                          indicator={loadingIcon}
                        />
                      </div>
                    )}
                  </Button>
                )}
                {currentStep === steps.length - 1 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (currentStep === 5) {
                        setSocialValid(true);
                      }
                    }}
                  >
                    Save Event
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}

        {accessDenied === true && (
          <Link to="/eventManagement">
            <Button className="px-3 d-block button-pink rounded mt-2 mx-2">
              Cancel
            </Button>
          </Link>
        )}
      </div>
    );
  }
}

export default EditEvent;
