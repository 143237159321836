import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Select,
  Switch,
  Button,
  Breadcrumb,
  Input,
  Modal,
  Tooltip,
} from "antd";
import { Spin } from "antd";
import {
  LoadingOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { CalenderGrey } from "../../components/svgJS/CalenderGrey";
import { AddGroup } from "../../components/svgJS/AddGroup";
import {
  getBrandDetails,
  editBrand,
} from "../../redux/slice/brandManagementSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { EyeView } from "../../components/svgJS/EyeView";
import FileUpload from "../common/FileUpload";
import SimpleReactValidator from "simple-react-validator";
import ExpoHalls from "../ExpoMap/ExpoHalls";
import CreateNewZone from "../ZoneManagement/CreateNewZone";
import { Download } from "../../components/svgJS/Download";
import {
  isRouteExistInMenu,
  isValidHttpURL,
  isValidImage,
} from "./../../utils/commonFunctions";
import CommonLoader from "../../components/Widgets/CommonLoader";
import AddUser from "../UserManagement/AddUser";
import { uploadVistaFile, uploadVistaFileBrand } from "../../utils/commonApis";
import RedAsterick from "../../components/Widgets/RedAsterick";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../components/constsnts/common.constants";
import { cloneDeep } from "lodash";
import CropImageModal from "../common/Modal/CropImageModal";
import ColorPickerInput from "../common/ColorPickerInput";

const EditBrandDetails = (props) => {
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.brandManagement);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const { TextArea } = Input;
  const validator = useRef(new SimpleReactValidator());
  const { match } = props;
  const [forceUpdate, setForceUpdate] = useState(false);
  const [loadingBrand, setLoadingBrand] = useState(false);
  const [expoHallModalVisible, setExpoHallModalVisible] = useState(false);
  const { Option } = Select;
  const [customerList, setCustomerList] = useState([]);
  const [tierList, setTierList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [socialMediaList, setSocialMediaList] = useState([]);
  const [brandLogoFileState, setBrandLogoFileState] = useState();
  const [brandLogoMinimizedState, setBrandLogoMinimizedState] = useState();
  const [wallGraphicsFileState, setWallGraphicsFileState] = useState(null);
  const [wallGraphicsFileUrl, setWallGraphicsFileUrl] = useState(null);
  const [wallGraphicsFileLoader, setWallGraphicsFileLoader] = useState(null);
  const [finalBoothFileState, setFinalBoothFileState] = useState(null);
  const [finalBoothFileUrl, setFinalBoothFileUrl] = useState(null);
  const [finalBoothFileLoader, setFinalBoothFileLoader] = useState(null);
  const [isModalWallGraphicVisible, setIsModalWallGraphicVisible] =
    useState(false);
  const [isAddUserModalVisible, setIsAddUserModalVisible] = useState(false);
  const [isZoneModalVisible, setIsZoneModalVisible] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const [fields, setFields] = useState({
    brand_id: match.params.brand_id,
    is_active: null,
    logo_url: "",
    minimized_logo_url: "",
    customer_account: null,
    event_id: null,
    brand_name: "",
    brand_description: "",
    brand_tier: null,
    brand_color: "",
    default_button_background_color: "#fa0505",
    is_default_button_gradient_active: true,
    default_button_gradient_angle: 45,
    default_button_background_second_color: null,
    default_button_text_color: "#e61e1e",
    highlight_button_background_color: "#d71919",
    is_highlight_button_gradient_active: false,
    highlight_button_gradient_angle: 45,
    highlight_button_background_second_color: null,
    highlight_button_text_color: "#e16666",
    brand_gradient: "",
    button_text_color_1: "",
    button_text_color_2: "",
    expo_hall_mapping_id: "",
    wall_graphics_url: "",
    final_booth_url: "",
    default_zone_id: null,
    brand_agent_details: [],
    brand_website: "",
    brand_social_media_details: [],
    image_url: "",
    expo_hall_configuration_id: "",
    sequence: "",
  });

  const [showBrandLogoCropModal, setShowBrandLogoCropModal] = useState(false);
  const [showMinimizeBrandLogoCropModal, setShowMinimizeBrandLogoCropModal] =
    useState(false);
  const [cropImagePreview, setCropImagePreview] = useState();
  const [graphicsDownloadLink, setGraphicsDownloadLink] = useState("");

  useEffect(async () => {
    setLoadingBrand(true);

    let is_route_present = await isRouteExistInMenu(CryptoJS);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    )
      window.location.href = "/pagenotfound";
    else {
      getCustomerList();
      getTierList();
      getSocialMediaList();
      await getSelectedBrandDetails();
      setTimeout(() => {
        setLoadingBrand(false);
      }, 1000);
    }
  }, [CryptoJS.AES]);

  useEffect(() => {
    getEventList();
  }, [fields?.customer_account]);

  useEffect(() => {
    getZoneList();
  }, [fields?.event_id, match.params.brand, isZoneModalVisible]);

  const showModal = () => {
    setIsModalWallGraphicVisible(true);
  };

  const handleOk = () => {
    setIsModalWallGraphicVisible(false);
  };

  const handleCancel = () => {
    setIsModalWallGraphicVisible(false);
  };

  const showZoneModal = () => {
    setIsZoneModalVisible(true);
  };

  const handleFieldsChange = (val, name) => {
    if (name === "is_default_button_gradient_active") {
      if (val) {
        setFields({
          ...fields,
          [name]: val,
          default_button_background_second_color: "#213cc4",
        });
      } else {
        setFields({
          ...fields,
          [name]: val,
          default_button_background_second_color:
            fields?.default_button_background_color,
        });
      }
    } else if (name === "is_highlight_button_gradient_active") {
      if (val) {
        setFields({
          ...fields,
          [name]: val,
          highlight_button_background_second_color: "#213cc4",
        });
      } else {
        setFields({
          ...fields,
          [name]: val,
          highlight_button_background_second_color:
            fields?.highlight_button_background_color,
        });
      }
    } else if (name === "customer_account") {
      setFields({
        ...fields,
        [name]: val,
        event_id: null,
        default_zone_id: null,
      });
    } else if (name === "event_id") {
      setFields({ ...fields, [name]: val, default_zone_id: null });
    } else {
      setFields({ ...fields, [name]: val });
    }
  };

  const handleFormSubmitBrand = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    fields.brand_agent_details = [];
    if (wallGraphicsFileUrl) fields["wall_graphics_url"] = wallGraphicsFileUrl;
    else delete fields.wall_graphics_url;

    if (finalBoothFileUrl) fields["final_booth_url"] = finalBoothFileUrl;
    else delete fields.final_booth_url;

    formData.append("data", JSON.stringify(fields));
    formData.append("brand_logo_file", fields?.logo_url);
    formData.append("minimized_logo_file", fields?.minimized_logo_url);

    let allURLValid = "yes";
    if (fields && fields.brand_social_media_details) {
      let clonedLinks = cloneDeep(fields.brand_social_media_details);
      fields.brand_social_media_details.forEach((ele, index) => {
        if (
          ele.social_media_url === "" ||
          !isValidHttpURL(ele.social_media_url) ||
          ele.social_media_url === "https://www.instagram.com/" || //Cr Point -> User should not be able to save this if its just  https://www.instagram.com/ there should user id after the last "/".
          ele.social_media_url === "https://www.facebook.com/" ||
          ele.social_media_url === "https://twitter.com/" ||
          ele.social_media_url === "https://www.linkedin.com/"
        ) {
          clonedLinks[index]["urlValid"] = "no";
          allURLValid = "no";
        }
      });
      if (allURLValid === "no")
        setFields({
          ...fields,
          brand_social_media_details: clonedLinks,
        });
    }

    if (allURLValid === "no") toast.error("Please check social media links");
    else {
      if (validator.current.allValid()) {
        setLoadingBrand(true);
        const updateBrand = await dispatch(editBrand(formData));
        if (updateBrand?.payload?.code === 200) {
          toast.success(updateBrand?.payload?.message);
          setLoadingBrand(false);
          props?.history?.push("/brandmanagement");
        }
        if (updateBrand?.payload?.code !== 200) {
          setLoadingBrand(false);
          toast.error(updateBrand.payload.message);
        }
      } else {
        validator.current.showMessages();
        setForceUpdate(!forceUpdate);
        setLoadingBrand(false);
        toast.error("Fields are missing");
      }
    }
  };

  const customerParams = {
    is_details: true,
  };
  const getCustomerList = async () => {
    ApiCall("get", null, "/website/customer/get", customerParams)
      .then((response) => {
        const { rows } = response?.data?.data;
        setCustomerList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {
        setSocialMediaList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getEventList = () => {
    ApiCall("get", null, "/admin/events/dropdown/get", {
      customer_id: fields?.customer_account ? fields?.customer_account : "",
    })
      .then((response) => {
        const { rows } = response.data.data;
        if (response?.data?.data?.default_graphic_template) {
          setGraphicsDownloadLink(
            response?.data?.data?.default_graphic_template
          );
        }

        setEventList(rows);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getTierList = () => {
    ApiCall("get", null, "/admin/tiers/get", null)
      .then((response) => {
        setTierList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getZoneList = () => {
    ApiCall("get", null, "/admin/zone/dropdown/get", {
      event_id: fields?.event_id,
      brand_id: match?.params?.brand_id,
    })
      .then((response) => {
        setZoneList(response.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleImgChange1 = (event) => {
    const isValidLogo = isValidImage(event.target.files[0]);
    if (isValidLogo) {
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
      setShowBrandLogoCropModal(true);
      // setBrandLogoFileState(URL.createObjectURL(event.target.files[0]));
      // setFields({ ...fields, logo_url: event.target.files[0] });
    }
  };
  const handleImgChangeMinimizedLogo = (event) => {
    const isValidLogo = isValidImage(event.target.files[0]);
    if (isValidLogo) {
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
      setShowMinimizeBrandLogoCropModal(true);
    }
  };

  const handleBrandLogoFileDelete = () => {
    setBrandLogoFileState(null);
  };
  const handleBrandLogoMinimizedFileDelete = () => {
    setBrandLogoMinimizedState(null);
  };

  const handleImgChange2 = async (event) => {
    setWallGraphicsFileLoader(true);
    const formData = new FormData();
    const tempFile = event?.target?.files[0];
    formData.append("upload_graphics", event.target.files[0]);
    formData.append(
      "data",
      JSON.stringify({
        customer_id: fields.customer_account,
        event_id: fields.event_id,
      })
    );
    if (process.env.REACT_APP_BASE_URL === "https://api-grv8.dev.brainvire.net")
      toast.error(
        "Please do not upload vista file on dev server, server will crash"
      );
    else {
      let res = await uploadVistaFile(formData);
      if (res && res.link) {
        setWallGraphicsFileUrl(res.link);
        setWallGraphicsFileState(tempFile);
      } else {
        toast.error("Upload Failed");
      }
    }
    setWallGraphicsFileLoader(false);
  };

  const handleAllGraphicsFileStateDelete = () => {
    setWallGraphicsFileState(null);
  };

  const handleImgChange3 = async (event) => {
    setFinalBoothFileLoader(true);
    const formData = new FormData();
    let tempFile = event.target.files[0];
    formData.append("final_booth_file", event.target.files[0]);
    formData.append(
      "data",
      JSON.stringify({
        customer_id: fields.customer_account,
        event_id: fields.event_id,
      })
    );
    let res = await uploadVistaFileBrand(formData);
    if (res && res.link) {
      setFinalBoothFileUrl(res.link);
      setFinalBoothFileState(tempFile);
    } else {
      toast.error("Vista Upload Failed");
    }
    setFinalBoothFileLoader(false);
  };
  const handleFinalBoothDelete = () => {
    setFinalBoothFileState(null);
  };

  const getSelectedBrandDetails = async () => {
    const response = await dispatch(
      getBrandDetails({ brand_id: match.params.brand_id })
    );

    if (response?.payload?.data?.code === 404) {
      toast.error(response?.payload?.data?.message);
      setAccessDenied(true);
    } else {
      const { details } = response?.payload?.data?.data;

      let selected_agents = [];
      details?.brands_agents?.map((val, idx) => {
        selected_agents.push(val.user_id);
      });
      let brand_social_media_details = [];
      details.brands_social_media_handles_id.map((val, idx) => {
        brand_social_media_details.push({
          brand_social_id: val.brand_social_id,
          social_media_id: val.social_media_id,
          social_media_url: val.social_media_url,
        });
      });

      setFields({
        ...fields,
        brand_id: parseInt(match?.params?.brand_id),
        is_active: details?.is_active,
        customer_account: details?.customer?.customer_id,
        event_id: details?.event_id,
        brand_name: details?.brand_title,
        brand_description: details?.brand_description,
        brand_tier: details?.tier?.tier_id,
        brand_color: details?.brand_color,
        default_button_background_color:
          details?.default_button_background_color,
        is_default_button_gradient_active:
          details?.is_default_button_gradient_active,
        default_button_gradient_angle: details?.default_button_gradient_angle,
        default_button_background_second_color:
          details?.is_default_button_gradient_active
            ? details?.default_button_background_second_color
            : details?.default_button_background_color,
        default_button_text_color: details?.default_button_text_color,
        highlight_button_background_color:
          details?.highlight_button_background_color,
        is_highlight_button_gradient_active:
          details?.is_highlight_button_gradient_active,
        highlight_button_gradient_angle:
          details?.highlight_button_gradient_angle,
        highlight_button_background_second_color:
          details?.is_highlight_button_gradient_active
            ? details?.highlight_button_background_second_color
            : details?.highlight_button_background_color,
        highlight_button_text_color: details?.highlight_button_text_color,
        expo_hall_mapping_id: details?.location_id,
        wall_graphics_url: details?.wall_graphics_url,
        final_booth_url: details.final_booth_url,
        default_zone_id: details?.brands_default_zone_id?.zone_id
          ? details?.brands_default_zone_id?.zone_id
          : null,
        brand_agent_details: selected_agents,
        brand_website: "brand.com",
        brand_social_media_details,
        logo_url: details?.logo_url,
        minimized_logo_url: details?.minimized_logo_url,
      });
      setWallGraphicsFileState({ name: details?.wall_graphics_url_file_name });
      setFinalBoothFileState({ name: details?.final_booth_url_file_name });
    }
  };

  const socialMediaHandleChange = (event, idx) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details?.some(
      (social_media) =>
        social_media?.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );
    if (isAvailable) {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details[index]["social_media_url"] =
        event.target.value;
    } else {
      brand_social_media_details.push({
        brand_social_id: social_media_list[idx].brand_social_id,
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url: social_media_list[idx].social_media_url,
      });
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const onHandleSocialSwitchChange = (checked, idx) => {
    let social_media_list = socialMediaList;
    let brand_social_media_details = fields.brand_social_media_details;
    let isAvailable = brand_social_media_details.some(
      (social_media) =>
        social_media.social_media_id ===
        social_media_list[idx]["social_media_id"]
    );

    if (checked) {
      brand_social_media_details.push({
        social_media_id: social_media_list[idx].social_media_id,
        social_media_url:
          idx === 0
            ? "https://www.instagram.com/" //CR point -> To add prefix when toggle is on
            : idx === 1
            ? "https://www.linkedin.com/"
            : idx === 2
            ? "https://twitter.com/"
            : idx === 3
            ? "https://www.facebook.com/"
            : "",
      });
    } else {
      let index = brand_social_media_details.findIndex(
        (social_media) =>
          social_media.social_media_id ===
          social_media_list[idx]["social_media_id"]
      );
      brand_social_media_details.splice(index, 1);
    }
    setFields({
      ...fields,
      brand_social_media_details: brand_social_media_details,
    });
  };

  const handleOkExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const handleCancelExpoHall = () => {
    setExpoHallModalVisible(false);
  };

  const getDataFromExpoHall = (expo_hall_configuration_id, elemIndex) => {
    setFields({
      ...fields,
      expo_hall_configuration_id: expo_hall_configuration_id,
      sequence: elemIndex,
    });
  };

  const saveBrandLogoCroppedImage = async (croppedImageFile) => {
    setBrandLogoFileState(URL.createObjectURL(croppedImageFile));
    setFields({ ...fields, logo_url: croppedImageFile });
  };

  const saveMinimizeBrandLogoCroppedImage = async (croppedImageFile) => {
    setBrandLogoMinimizedState(URL.createObjectURL(croppedImageFile));
    setFields({ ...fields, minimized_logo_url: croppedImageFile });
  };

  if (loadingBrand) return <CommonLoader />;
  else
    return (
      <Fragment>
        {accessDenied === false && (
          <form
          // onSubmit={handleFormSubmitBrand}
          >
            <div className="d-flex justify-content-between bg-light w-100 common-sticky-header">
              <div>
                <h4 className="fs-24">Edit Brand</h4>
                <Breadcrumb className="my-2" separator=">">
                  <Breadcrumb.Item>
                    <Link to="/brandmanagement">Admin Console</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link to="/brandmanagement">Brand Management</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>Edit Brand Details</Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </div>

            {/* <Main Div */}
            <div className="bg-white p-3">
              <section>
                <div className="row mt-5">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20 px-2">
                      Brand Editor
                    </h6>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Active
                    </label>
                  </div>
                  <div className="col-3 text-start">
                    <Switch
                      name="is_active"
                      checked={fields.is_active}
                      onChange={(checked) =>
                        handleFieldsChange(checked, "is_active")
                      }
                    />
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Logo
                    </label>
                  </div>
                  <div
                    className={
                      Tablet
                        ? "col-5 image-upload d-flex"
                        : "col-3 image-upload d-flex"
                    }
                  >
                    <FileUpload
                      value=""
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={handleImgChange1}
                      inputId="edit_brand_logo"
                      inputName="edit_brand_logo"
                      name="logo"
                    />

                    <Tooltip
                      placement="right"
                      title={"Final image shape is wide rectangle ‘inline’"}
                      className="mx-3 mt-2"
                    >
                      <InfoCircleOutlined style={{ fontSize: "20px" }} />
                    </Tooltip>
                  </div>

                  {brandLogoFileState ? (
                    <div className="col-3">
                      <span className="mx-3"> {fields?.logo_url?.name} </span>
                      <span onClick={handleBrandLogoFileDelete}>
                        <DeleteOutlined />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-4">
                    {brandLogoFileState ? (
                      <div className="row mt-2">
                        <img
                          src={brandLogoFileState}
                          alt={brandLogoFileState === null ? "" : ""}
                          className="img-fluid brand_logo_img_preview"
                        />
                      </div>
                    ) : (
                      <div className="row mt-2">
                        <img
                          src={fields?.logo_url}
                          alt={fields.logo_url === null ? "" : ""}
                          className="img-fluid brand_logo_img_preview"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row my-0 py-0">
                  <div className="col-2"></div>
                  <div className="col-5"></div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Minimized Logo
                    </label>
                  </div>
                  <div
                    className={
                      Tablet
                        ? "col-5 image-upload d-flex"
                        : "col-3 image-upload d-flex"
                    }
                  >
                    <FileUpload
                      value=""
                      labelClassName="rounded border-pink btn-svg px-3 py-1"
                      labelTitle="Image Upload"
                      inputType="file"
                      inputOnChange={handleImgChangeMinimizedLogo}
                      inputId="edit_brand_logo_minimized"
                      inputName="edit_brand_logo_minimized"
                      name="logo_minimized"
                    />

                    <Tooltip
                      placement="right"
                      title={"Final image shape is square"}
                      className="mx-3 mt-2"
                    >
                      <InfoCircleOutlined style={{ fontSize: "20px" }} />
                    </Tooltip>
                  </div>

                  {brandLogoMinimizedState ? (
                    <div className="col-2">
                      <span className="mx-3">
                        {fields?.minimized_logo_url?.name}
                      </span>
                      <span onClick={handleBrandLogoMinimizedFileDelete}>
                        <DeleteOutlined />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="col-2">
                    {brandLogoMinimizedState ? (
                      <div className="row mt-2">
                        <img
                          src={brandLogoMinimizedState}
                          className="img-fluid user-img-preview"
                        />
                      </div>
                    ) : (
                      <div className="row mt-2">
                        <img
                          src={fields?.minimized_logo_url}
                          className="img-fluid user-img-preview"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="row my-0 py-0">
                  <div className="col-2"></div>
                  <div className="col-5"></div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Customer Account
                      <RedAsterick />
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-3"}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Customer Account"
                      className={
                        validator?.current?.fields?.customer_account ===
                          false && validator?.current?.messagesShown === true
                          ? "w-100 fs-bold-14 border_red"
                          : "w-100 fs-bold-14"
                      }
                      name="customer_account"
                      allowClear
                      value={fields.customer_account}
                      onChange={(val) =>
                        handleFieldsChange(val, "customer_account")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("customer_account")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      disabled={true}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {customerList &&
                        customerList?.map((data) => {
                          return (
                            <Option value={data.customer_id}>
                              {data.account_name}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "customer_account",
                      fields.customer_account,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                  {/* )} */}
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Select Event
                      <RedAsterick />
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-3"}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Event"
                      className={
                        validator?.current?.fields?.event_id === false &&
                        validator?.current?.messagesShown === true
                          ? "w-100 fs-bold-14 border_red"
                          : "w-100 fs-bold-14"
                      }
                      allowClear
                      name="event_id"
                      value={fields?.event_id}
                      onChange={(val) => handleFieldsChange(val, "event_id")}
                      onBlur={() =>
                        validator.current.showMessageFor("event_id")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      disabled={true}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {eventList &&
                        eventList?.map((data) => {
                          return (
                            <Option value={data.event_id}>
                              {data?.event_name}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "event_id",
                      fields.event_id,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Brand Name
                      <RedAsterick />
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-3"}>
                    <Input
                      name="brand_name"
                      id=""
                      className={
                        validator?.current?.fields?.brand_name === false &&
                        validator?.current?.messagesShown === true
                          ? "w-100 fs-bold-14 rounded border_red"
                          : "w-100 border-gray fs-bold-14 rounded"
                      }
                      // className="w-100 border-gray fs-bold-14 rounded"
                      value={fields.brand_name}
                      placeholder="Enter A Title Here"
                      onChange={(e) =>
                        handleFieldsChange(e.target.value, "brand_name")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("brand_name")
                      }
                      maxLength={INPUT_TEXT_LIMITS.MAX}
                    />
                    {validator.current.message(
                      "brand_name",
                      fields.brand_name,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Brand Description
                    </label>
                  </div>
                  <div className="col-5">
                    <TextArea
                      name="brand_description"
                      rows={4}
                      className={
                        validator?.current?.fields?.brand_description ===
                          false && validator?.current?.messagesShown === true
                          ? "fs-bold-14 border_red"
                          : "fs-bold-14"
                      }
                      value={fields.brand_description}
                      onChange={(e) =>
                        handleFieldsChange(e.target.value, "brand_description")
                      }
                      placeholder="Enter A Description Here"
                      maxLength={TEXTAREA_TEXT_LIMITS.MAX}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Brand Tier
                      <RedAsterick />
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-3"}>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder="Select Tier"
                      className={
                        validator?.current?.fields?.brand_tier === false &&
                        validator?.current?.messagesShown === true
                          ? "w-100 fs-bold-14 border_red"
                          : "w-100 fs-bold-14"
                      }
                      allowClear
                      name="brand_tier"
                      value={fields.brand_tier}
                      onChange={(val) => handleFieldsChange(val, "brand_tier")}
                      onBlur={() =>
                        validator.current.showMessageFor("brand_tier")
                      }
                      listHeight={120}
                      listItemHeight={4}
                      autoComplete="none"
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    >
                      {tierList &&
                        tierList?.map((data) => {
                          return (
                            <Option value={data.tier_id}>{data.tier}</Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "brand_tier",
                      fields.brand_tier,
                      "required|text",
                      { className: "text-danger py-1" }
                    )}
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Brand Color
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-2"}>
                    <ColorPickerInput
                      colorCode={fields?.brand_color}
                      onHandleColorChange={(hexColor) => {
                        handleFieldsChange(hexColor, "brand_color");
                      }}
                    />
                  </div>
                </div>

                {/* ------------------------New ADDed Code ------------------ */}

                <div className="row mt-4 brand_button_color">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Default Button Background
                    </label>
                  </div>
                  <div className="col-9 d-flex">
                    <ColorPickerInput
                      colorCode={fields?.default_button_background_color}
                      onHandleColorChange={(hexColor) => {
                        handleFieldsChange(
                          hexColor,
                          "default_button_background_color"
                        );
                      }}
                    />

                    <div className="d-flex ms-2">
                      <div className={"flex_center"}>
                        <label htmlFor="" className="text-secondary mt-1">
                          Gradient
                        </label>
                        <Switch
                          className="mx-2"
                          checked={fields?.is_default_button_gradient_active}
                          onChange={(val) => {
                            handleFieldsChange(
                              val,
                              "is_default_button_gradient_active"
                            );
                          }}
                        />
                      </div>

                      {fields.is_default_button_gradient_active ? (
                        <div className={"flex_center"}>
                          <label htmlFor="" className="text-secondary mt-1">
                            Angle
                          </label>
                          <Input
                            className="ms-2 mx-2"
                            style={{ width: "50px" }}
                            value={fields?.default_button_gradient_angle}
                            onChange={(e) => {
                              handleFieldsChange(
                                e.target.value,
                                "default_button_gradient_angle"
                              );
                            }}
                          />
                          <label
                            htmlFor=""
                            className="text-secondary mx-4 mt-1"
                          >
                            Color
                          </label>
                          <ColorPickerInput
                            colorCode={
                              fields?.default_button_background_second_color
                            }
                            onHandleColorChange={(hexColor) => {
                              handleFieldsChange(
                                hexColor,
                                "default_button_background_second_color"
                              );
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Default Button Text Colour
                    </label>
                  </div>
                  <div className="col-3">
                    <ColorPickerInput
                      colorCode={fields?.default_button_text_color}
                      onHandleColorChange={(hexColor) => {
                        handleFieldsChange(
                          hexColor,
                          "default_button_text_color"
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Button Preview
                    </label>
                  </div>
                  <div className="col-3">
                    <Button
                      style={{
                        color: fields.default_button_text_color,
                        background: fields.is_default_button_gradient_active
                          ? `linear-gradient(${fields.default_button_gradient_angle}deg, ${fields.default_button_background_color} 30%,${fields.default_button_background_second_color} 100%)`
                          : `${fields.default_button_background_color}`,
                        border: `1px solid ${fields.default_button_text_color}`,
                      }}
                      className="rounded"
                    >
                      Example Test
                    </Button>
                  </div>
                </div>

                <div className="row mt-4 brand_button_color">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Highlight Button Background
                    </label>
                  </div>
                  <div className="col-9 d-flex">
                    <ColorPickerInput
                      colorCode={fields?.highlight_button_background_color}
                      onHandleColorChange={(hexColor) => {
                        handleFieldsChange(
                          hexColor,
                          "highlight_button_background_color"
                        );
                      }}
                    />

                    <div className="d-flex ms-2">
                      <div className={"flex_center"}>
                        <label htmlFor="" className="text-secondary mt-1">
                          Gradient
                        </label>
                        <Switch
                          checked={fields?.is_highlight_button_gradient_active}
                          onChange={(val) => {
                            handleFieldsChange(
                              val,
                              "is_highlight_button_gradient_active"
                            );
                          }}
                          className="mx-2"
                        />
                      </div>

                      {fields?.is_highlight_button_gradient_active ? (
                        <div className={"flex_center"}>
                          <label htmlFor="" className="text-secondary mt-1">
                            Angle
                          </label>
                          <Input
                            className="ms-2 mx-2"
                            style={{ width: "50px" }}
                            value={fields?.highlight_button_gradient_angle}
                            onChange={(e) => {
                              handleFieldsChange(
                                e.target.value,
                                "highlight_button_gradient_angle"
                              );
                            }}
                          />

                          <label
                            htmlFor=""
                            className="text-secondary mx-4 mt-1"
                          >
                            Color
                          </label>
                          <ColorPickerInput
                            colorCode={
                              fields?.highlight_button_background_second_color
                            }
                            onHandleColorChange={(hexColor) => {
                              handleFieldsChange(
                                hexColor,
                                "highlight_button_background_second_color"
                              );
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Highlight Button Text Colour
                    </label>
                  </div>
                  <div className="col-3">
                    <ColorPickerInput
                      colorCode={fields?.highlight_button_text_color}
                      onHandleColorChange={(hexColor) => {
                        handleFieldsChange(
                          hexColor,
                          "highlight_button_text_color"
                        );
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mt-1"
                    >
                      Button Preview
                    </label>
                  </div>
                  <div className="col-3">
                    <Button
                      style={{
                        color: fields.highlight_button_text_color,
                        background: fields.is_highlight_button_gradient_active
                          ? `linear-gradient(${fields.highlight_button_gradient_angle}deg, ${fields.highlight_button_background_color} 30%,${fields.highlight_button_background_second_color} 100%)`
                          : `${fields.highlight_button_background_color}`,
                        border: `1px solid ${fields.highlight_button_text_color}`,
                      }}
                      className="rounded"
                    >
                      Example Test
                    </Button>
                  </div>
                </div>

                {/* New Added Code Ends here */}

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary">
                      Booth Location
                    </label>
                  </div>
                  <div className={Tablet ? "col-4" : "col-3"}>
                    <Button
                      className="rounded  btn-svg px-3"
                      onClick={() => setExpoHallModalVisible(true)}
                    >
                      Select Expo Hall
                    </Button>

                    <Modal
                      visible={expoHallModalVisible}
                      onOk={handleOkExpoHall}
                      onCancel={handleCancelExpoHall}
                      width={1000}
                    >
                      <ExpoHalls
                        brand_id={fields?.brand_id ?? null}
                        event_id={fields.event_id ?? null}
                        getDataFromExpoHall={getDataFromExpoHall}
                        fromBrand={true}
                      />
                    </Modal>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Download Graphics Template
                    </label>
                  </div>
                  <div className={Tablet ? "col-3" : "col-1"}>
                    <Button
                      className="rounded btn-svg px-3 mx-1"
                      onClick={showModal}
                      icon={<EyeView />}
                    >
                      View
                    </Button>
                    <Modal
                      title="Wall Graphics File "
                      visible={isModalWallGraphicVisible}
                      onOk={handleOk}
                      onCancel={handleCancel}
                      footer={null}
                      width={500}
                    >
                      {fields.wall_graphics_url ? (
                        <img
                          src={fields && fields.wall_graphics_url}
                          alt="wall graphics upload file"
                          className="img-fluid uploaded-image-modal-preview"
                        />
                      ) : (
                        <p className="fs-pink text-center">File not uploaded</p>
                      )}
                    </Modal>
                  </div>
                  <div className="col-2 text-start">
                    <Button
                      onClick={() => {
                        window.location.href = graphicsDownloadLink;
                      }}
                      type="primary"
                      className="px-2 mx-1"
                    >
                      <Download />
                      <span className="ms-2">Download</span>
                    </Button>
                  </div>
                </div>

                <div className="row mt-4">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      Upload Graphics
                    </label>
                  </div>

                  <div className="col-2 image-upload d-flex">
                    <div className="h-25">
                      <FileUpload
                        value=""
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="File Upload"
                        inputType="file"
                        inputOnChange={handleImgChange2}
                        inputId="wallgraphicsupload"
                        inputName="wallgraphicsupload"
                        name="wallgraphicsupload"
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    {wallGraphicsFileState && !wallGraphicsFileLoader ? (
                      <>
                        <span className="mx-3">
                          {wallGraphicsFileState.name}
                        </span>
                        <span onClick={handleAllGraphicsFileStateDelete}>
                          {wallGraphicsFileState.name ? <DeleteOutlined /> : ""}
                        </span>
                      </>
                    ) : wallGraphicsFileLoader ? (
                      <Spin
                        className="spinner"
                        style={{ color: "#ef0855" }}
                        indicator={loadingIcon}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row my-0 py-0">
                  <div className="col-2"></div>
                  <div className="col-9"></div>
                </div>

                <div className="row mt-4 ">
                  <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
                    <label htmlFor="" className="text-secondary mt-1">
                      360 Booth Upload
                    </label>
                  </div>
                  <div className="col-2 image-upload d-flex">
                    <div className="h-25">
                      <FileUpload
                        value=""
                        labelClassName="rounded border-pink btn-svg px-3 py-1"
                        labelTitle="File Upload"
                        inputType="file"
                        inputOnChange={handleImgChange3}
                        inputId="finalboothupload"
                        inputName="finalboothupload"
                        name="finalboothupload"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    {finalBoothFileState && !finalBoothFileLoader ? (
                      <>
                        <span className="mx-3">{finalBoothFileState.name}</span>
                        <span onClick={handleFinalBoothDelete}>
                          {finalBoothFileState.name ? <DeleteOutlined /> : ""}
                        </span>
                      </>
                    ) : finalBoothFileLoader ? (
                      <Spin
                        className="spinner"
                        style={{ color: "#ef0855" }}
                        indicator={loadingIcon}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row my-0 py-0 border-bottom-gray pb-4">
                  <div className="col-2"></div>
                  <div className="col-9"></div>
                </div>
              </section>

              {/* section External & Social Links */}
              <section>
                <div className="row">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20">
                      External & Social Links
                    </h6>
                  </div>
                </div>

                <div className="row mt-1">
                  <div className="col-12 mt-1">
                    <span className="fs-16">
                      NB: If you paste your details into these boxes then anyone
                      using this site will be able to view/contact you via these
                      platforms.
                    </span>
                  </div>
                </div>

                <div className="social-media-div border-bottom-gray pb-4 mb-4">
                  {socialMediaList &&
                    socialMediaList.map((data, idx) => (
                      <div className="row mt-4 ">
                        <div className="col-2 text-end ">
                          {data?.social_media}
                        </div>
                        <div className="col-1">
                          <Switch
                            checked={
                              fields?.brand_social_media_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              ) &&
                              fields?.brand_social_media_details[
                                fields.brand_social_media_details.findIndex(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                              ]["social_media_url"] !== null
                                ? true
                                : false
                            }
                            onChange={(checked) =>
                              onHandleSocialSwitchChange(checked, idx)
                            }
                          />
                        </div>
                        <div
                          className={
                            Tablet
                              ? "col-4 text-start"
                              : "col-4 text-start brand_social_media_input_div"
                          }
                        >
                          <Input
                            placeholder="https://webaddress.com"
                            className="w-100 fs-bold-14"
                            disabled={
                              fields?.brand_social_media_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              ) &&
                              fields?.brand_social_media_details[
                                fields.brand_social_media_details.findIndex(
                                  (social_media) =>
                                    social_media.social_media_id ===
                                    data.social_media_id
                                )
                              ]["social_media_url"] !== null
                                ? false
                                : true
                            }
                            value={
                              fields?.brand_social_media_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? fields?.brand_social_media_details[
                                    fields.brand_social_media_details.findIndex(
                                      (social_media) =>
                                        social_media.social_media_id ===
                                        data.social_media_id
                                    )
                                  ]["social_media_url"]
                                : ""
                            }
                            onChange={(event) =>
                              socialMediaHandleChange(event, idx)
                            }
                            onBlur={() =>
                              validator.current.showMessageFor("social_media")
                            }
                          />
                          {fields?.brand_social_media_details?.some(
                            (social_media) =>
                              social_media.social_media_id ===
                              data.social_media_id
                          ) &&
                            fields?.brand_social_media_details[
                              fields.brand_social_media_details.findIndex(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                            ]["social_media_url"] !== null &&
                            validator.current.message(
                              "social_media",
                              fields?.brand_social_media_details?.some(
                                (social_media) =>
                                  social_media.social_media_id ===
                                  data.social_media_id
                              )
                                ? fields?.brand_social_media_details[
                                    fields.brand_social_media_details.findIndex(
                                      (social_media) =>
                                        social_media.social_media_id ===
                                        data.social_media_id
                                    )
                                  ]["social_media_url"]
                                : "",
                              "required|url",
                              { className: "text-danger py-1" }
                            )}
                        </div>
                      </div>
                    ))}
                </div>
              </section>

              {/* section default zone */}
              <section>
                <div className="row">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20 px-2">
                      Default Zone
                    </h6>
                  </div>
                </div>

                <div className="row ">
                  <div className="col-12 px-3">
                    <span className="fs-16">
                      NB: This zones content (including
                      assets/descriptions/interactivity etc.) will be used when
                      sponsoring areas/events and the brand profile page.
                      <br /> (Unless a different zone is selected when setting
                      up an event/sponsorship etc.)
                    </span>
                  </div>
                </div>

                <div className="row mt-4 border-bottom-gray pb-4">
                  <div className="col-2 text-end mt-1">Default Zone</div>
                  <div className="col-3 text-start">
                    <Select
                      showSearch
                      optionFilterProp="children"
                      placeholder={
                        zoneList && zoneList.length < 1
                          ? "No zones created for this brand"
                          : "Select Default Zone"
                      }
                      className="w-100 fs-bold-14"
                      name="default_zone_id"
                      value={
                        fields?.default_zone_id ? fields?.default_zone_id : null
                      }
                      allowClear
                      onChange={(val) =>
                        handleFieldsChange(val, "default_zone_id")
                      }
                      onBlur={() =>
                        validator.current.showMessageFor("default_zone_id")
                      }
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      listHeight={120}
                      listItemHeight={4}
                    >
                      {zoneList &&
                        zoneList.map((data) => (
                          <Option value={data.zone_id}>
                            {data.zone_title}
                          </Option>
                        ))}
                    </Select>
                  </div>
                  <div className="col-3">
                    <Button
                      onClick={showZoneModal}
                      className="px-2 button-pink rounded"
                    >
                      + Create New Zone
                    </Button>
                  </div>
                </div>
              </section>

              <Modal
                title="Add Zone"
                visible={isZoneModalVisible}
                onCancel={() => setIsZoneModalVisible(false)}
                footer={null}
                width={1100}
              >
                <div className="p-2">
                  <CreateNewZone
                    event_id={fields?.event_id}
                    isFromEvent={true}
                    onCancelFromModal={() => setIsZoneModalVisible(false)}
                    onSuccessFromModal={async () => {
                      setIsZoneModalVisible(false);
                    }}
                  />
                </div>
              </Modal>

              {/* section Assign Agents to this Brand */}

              <section>
                <div className="row">
                  <div className="col-12 mt-4">
                    <h6 className="font-pink fw-bolder fs-20 px-2">
                      Add Agents to this Brand
                    </h6>
                  </div>
                </div>
                <div className="row mt-4 mb-4">
                  <div className="col-2 text-end mt-4">
                    <span className="text-secondary mt-4" />
                  </div>

                  <div
                    className={Tablet ? "col-5" : "col-4"}
                    id="area"
                    style={{ position: "relative" }}
                  >
                    <label className="py-2 mx-2">
                      <Button
                        className="px-3  rounded btn-svg"
                        onClick={(e) => setIsAddUserModalVisible(true)}
                      >
                        <AddGroup fill="#ef0855" />
                        <span className="mx-2">Add Agents</span>
                      </Button>
                      <Modal
                        title="Add User"
                        visible={isAddUserModalVisible}
                        onOk={() => setIsAddUserModalVisible(false)}
                        onCancel={() => setIsAddUserModalVisible(false)}
                        footer={null}
                        width={1000}
                      >
                        <AddUser
                          event_id={fields?.event_id}
                          brand_id={parseInt(match.params.brand_id)}
                          onCancelFromModal={() =>
                            setIsAddUserModalVisible(false)
                          }
                          onSuccessFromModal={() => {
                            setIsAddUserModalVisible(false);
                          }}
                        />
                      </Modal>
                    </label>
                    <br />
                  </div>
                </div>
              </section>
              {/* section Cancel */}
              <section>
                <div className="d-flex justify-content-between py-4 px-4 background-footer-create-customer-account w-100">
                  <Link to="/brandmanagement">
                    <Button className="rounded  px-3">Cancel</Button>
                  </Link>
                  <div>
                    <Button
                      type="primary"
                      disabled={loading || finalBoothFileLoader ? true : false}
                      onClick={handleFormSubmitBrand}
                    >
                      {!loading ? (
                        "Save Changes"
                      ) : (
                        <div style={{ width: "70px" }}>
                          <Spin
                            style={{ color: "white" }}
                            indicator={loadingIcon}
                          />
                        </div>
                      )}
                    </Button>
                  </div>
                </div>
              </section>

              {showBrandLogoCropModal ? (
                <CropImageModal
                  imageToBeCropped={cropImagePreview}
                  sendCroppedImageFile={(cropImageFile) => {
                    saveBrandLogoCroppedImage(cropImageFile);
                    setShowBrandLogoCropModal(false);
                  }}
                  closeCropModalOnCancel={() => {
                    setShowBrandLogoCropModal(false);
                  }}
                  cropSizeData={{
                    width: 340,
                    height: 100,
                  }}
                />
              ) : null}

              {showMinimizeBrandLogoCropModal ? (
                <CropImageModal
                  imageToBeCropped={cropImagePreview}
                  sendCroppedImageFile={(cropImageFile) => {
                    saveMinimizeBrandLogoCroppedImage(cropImageFile);
                    setShowMinimizeBrandLogoCropModal(false);
                  }}
                  closeCropModalOnCancel={() => {
                    setShowMinimizeBrandLogoCropModal(false);
                  }}
                  cropSizeData={{
                    width: 100,
                    height: 100,
                  }}
                />
              ) : null}
            </div>
          </form>
        )}
        {accessDenied === true && (
          <Link to="/brandmanagement">
            <Button className="px-3 d-block button-pink rounded mt-2">
              Cancel
            </Button>
          </Link>
        )}
      </Fragment>
    );
};

export default EditBrandDetails;
