import React, { useState, useRef, useEffect } from "react";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import { Video } from "../../../components/svgJS/Video";
import { Calendar6 } from "../../../components/svgJS/Calendar6";
import { Divider } from "@material-ui/core";
import { Chat } from "../../../components/svgJS/Chat";
import { toast } from "react-toastify";
import { Avatar, Button, Modal, Row, Col, Input } from "antd";
import CreateMeeting from "../../Popups/CreateMeeting";
import "./ProfileModal.css";
import SimpleReactValidator from "simple-react-validator";
import {
  oneToOneMessageAPI,
  oneToOneVideoAPI,
} from "../../../utils/commonApis";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import { getComentChatUser } from "../../../utils/commonFunctions";
import { setVideoConferenceData } from "../../../redux/slice/videoConference";
import { useDispatch } from "react-redux";

import placeholder_image from "../../../assets/images/profile_placeholder.png";

const ProfileModal = ({
  userData,
  isProfileModalVisible,
  changeModalFlag,
  isFromMessages = false,
}) => {
  return (
    <Modal
      visible={isProfileModalVisible}
      onOk={() => changeModalFlag()}
      onCancel={() => changeModalFlag()}
      wrapClassName="profileModal_wrapper_modal"
      className="profileModal_modal"
      footer={null}
    >
      <div className="">
        <Row className="profileModal_profile_header pt-4 ">
          <Col span={2}>
            <Avatar src={userData?.image_url} />
          </Col>
          <Col span={4}>
            <span className="text-black">{userData?.user_name}</span>
          </Col>
          <Col span={6}>
            <span className="text-black">{userData?.email}</span>
          </Col>
          <Col xs={8} sm={8} md={6} lg={6} xl={6}>
            <span className="text-black">{userData?.event_domain_name}</span>
          </Col>

          <Col className="" span={4} xs={12} sm={12} md={4}>
            <div className="d-flex align-items-center ms-5">
              {userData?.users_users_social_media_handles_user_id?.map(
                (item, i) => {
                  let social_media =
                    item?.usersSocialMediaHandles_social_media_id?.social_media?.toLowerCase();
                  let url = "";
                  if (
                    item?.social_media_url.startsWith("http://") ||
                    item?.social_media_url.startsWith("https://")
                  ) {
                    url = item?.social_media_url;
                  } else {
                    url = "https://" + item.social_media_url;
                  }

                  if (social_media === "instagram") {
                    return (
                      <div
                        key={"social_" + i}
                        onClick={(e) => {
                          window.open(url, "_blank");
                        }}
                      >
                        <InstagramOutlined
                          style={{
                            fontSize: "20px",
                            opacity: "0.5",
                          }}
                          className="ms-2 cursor-pointer"
                        />
                      </div>
                    );
                  } else if (social_media === "linkedin") {
                    return (
                      <div
                        key={"social_" + i}
                        onClick={() => window.open(url, "_blank")}
                      >
                        <LinkedinOutlined
                          style={{
                            fontSize: "20px",
                            opacity: "0.5",
                          }}
                          className="ms-2 cursor-pointer"
                        />
                      </div>
                    );
                  } else if (social_media === "twitter") {
                    return (
                      <div
                        key={"social_" + i}
                        onClick={() => window.open(url, "_blank")}
                      >
                        <TwitterOutlined
                          style={{
                            fontSize: "20px",
                            opacity: "0.5",
                          }}
                          className="ms-2 cursor-pointer"
                        />
                      </div>
                    );
                  } else if (social_media === "facebook") {
                    return (
                      <div
                        key={"social_" + i}
                        onClick={() => window.open(url, "_blank")}
                      >
                        <FacebookOutlined
                          style={{
                            fontSize: "20px",
                            opacity: "0.5",
                          }}
                          className="ms-2 cursor-pointer"
                        />
                      </div>
                    );
                  }
                }
              )}
            </div>
          </Col>
        </Row>
        <Divider />
        <UserDataCard userData={userData} isFromMessages={isFromMessages} />
      </div>
    </Modal>
  );
};

export const UserDataCard = (props) => {
  const { userData, meetingData, isMobile } = props;

  const [isCreateMeetingModalVisible, setCreateMeetingModalVisible] =
    useState(false);
  const [messageModal, setMessageModal] = useState({ isOpen: false, data: {} });
  const [message_text, set_message_text] = useState("");
  const messageValidator = useRef(new SimpleReactValidator());
  const [cometChatUser, setCometChatUser] = useState({
    isValidUser: false,
    data: {},
    uid: "",
  });
  const [forceUpdate, setForceUpdate] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(false);
  const { TextArea } = Input;
  const dispatch = useDispatch();

  useEffect(async () => {
    const cometRes = await getComentChatUser();
    setCometChatUser(cometRes);
  }, []);

  function clearMessageAndModal() {
    set_message_text("");
    setMessageModal({ isOpen: false, data: {} });
  }

  async function callSendMessageAPI(data) {
    if (messageValidator.current.allValid()) {
      setDisableSendBtn(true);
      const response = await oneToOneMessageAPI({ ...data, message_text });
      if (response.data.code === 200) {
        toast.success(response.data.message);
        clearMessageAndModal();

        window.open("/messages", "_self");
      } else {
        toast.error(response.data.message);
      }
    } else {
      messageValidator.current.showMessages();
      setForceUpdate(!forceUpdate);
    }
    setDisableSendBtn(false);
  }

  let image_url = "";

  if (userData) {
    image_url = userData?.image_url ? userData?.image_url : placeholder_image;
  } else {
    image_url = meetingData?.image_url?.includes(null)
      ? placeholder_image
      : meetingData?.image_url;
  }
  // console.log("user Data profile modal", userData);

  return (
    <>
      <div className="p-2 user_data_card_container">
        <Row>
          <Col xs={18} sm={24} md={6}>
            <img className="profileModal_profile_image" src={image_url} />
          </Col>
          <Col
            xs={{
              span: 8,
              offset: 0,
            }}
            sm={{
              span: 24,
              offset: 1,
            }}
            md={11}
          >
            <div className="d-flex mt-2 profileModal_user_data_bio">
              <span className="">Bio</span>
              <div className="ms-2">
                <span className="profileModal_user_data_bio_text">
                  {userData?.bio ?? meetingData?.bio}
                </span>
              </div>
            </div>

            <div className={`d-flex mt-5`}>
              {props?.isFromMessages ? (
                ""
              ) : props.isFromPeople && !userData?.is_chat_access ? (
                ""
              ) : (
                <Button
                  className="mobile_view_buttons"
                  onClick={async () => {
                    setMessageModal({
                      isOpen: true,
                      data: {
                        user_id: userData?.user_id ?? meetingData?.user_id,
                        chat_id: userData?.chat_id ?? meetingData?.chat_id,
                        fbase_token:
                          userData?.fbase_token ?? meetingData?.fbase_token,
                        receiver_type: "user",
                        category: "message",
                        type: "text",
                        user_type:
                          userData?.user_type ?? meetingData?.user_type,
                        chat_id: meetingData?.chat_id ?? userData?.chat_id,
                      },
                    });
                  }}
                >
                  <Chat />
                  <span className="ms-2">Chat</span>
                </Button>
              )}

              {props?.isFromMessages ? (
                ""
              ) : props?.isFromPeople && !userData?.is_video_call_access ? (
                ""
              ) : (
                <Button
                  className={
                    "ms-2 profile_modal_video_call mobile_view_buttons"
                  }
                  onClick={async () => {
                    const response = await oneToOneVideoAPI({
                      user_id: userData?.user_id ?? meetingData?.user_id,
                      chat_id: userData?.chat_id ?? meetingData?.chat_id,
                      fbase_token:
                        userData?.fbase_token ?? meetingData?.user_id,
                      user_type: userData?.user_type ?? meetingData?.user_type,
                    });
                    if (response?.data?.code === 200) {
                      if (response?.data?.data) {
                        toast.success(response.data.message);

                        dispatch(
                          setVideoConferenceData({
                            videoConferenceFlag: true,
                            videoConferenceUrl:
                              response?.data?.data.video_call_link,
                            is_video_call: true,
                            receiver_chat_id: userData?.chat_id,
                          })
                        );
                      }
                    } else toast.error(response.data.message);
                  }}
                >
                  <Video />
                  <span className="ms-2">Video Call</span>
                </Button>
              )}

              {props?.fromMeeting ? (
                " "
              ) : props?.isFromPeople && !userData?.is_calendar_access ? (
                ""
              ) : (
                <Button
                  className={
                    "ms-2 profile_modal_book_meeting mobile_view_buttons"
                  }
                  onClick={() => setCreateMeetingModalVisible(true)}
                >
                  <Calendar6 />
                  <span className="ms-2">Book a Meeting</span>
                </Button>
              )}
            </div>
          </Col>

          <Col
            sm={24}
            md={8}
            lg={6}
            xl={6}
            className={`${
              isMobile && window.location.pathname === "/people"
                ? "profileModal_userInfo_container_people"
                : ""
            } profileModal_userInfo_container`}
          >
            <div className="pb-3 profileModal_border_left ">
              <span className="profileModal_userInfoType">User Type</span>
              <span className="text-dark">
                {userData?.user_type ?? meetingData?.user_type}
              </span>
            </div>
            <div className="pb-3 profileModal_border_left">
              <span className="profileModal_userInfoType">Tier</span>
              <span className="text-dark">
                {userData?.tier_name ?? meetingData?.tier}
              </span>
            </div>
            <div className="pb-3 profileModal_border_left">
              <span className="profileModal_userInfoType">Company</span>
              <span className="text-dark">
                {userData?.company ?? meetingData?.company}
              </span>
            </div>
            <div className="pb-3 profileModal_border_left">
              <span className="profileModal_userInfoType">Job Role</span>
              <span className="text-dark">
                {userData?.job_title ?? meetingData?.job_title}
              </span>
            </div>
            <div className="pb-3 profileModal_border_left">
              <span className="profileModal_userInfoType">Country</span>
              <span className="text-dark">
                {userData?.country ?? meetingData?.country}
              </span>
            </div>
            {props && props?.isFromPeople ? (
              <div className="mt-1 d-flex">
                {userData?.users_users_social_media_handles_user_id?.map(
                  (item) => {
                    let { usersSocialMediaHandles_social_media_id } = item;
                    let social_media =
                      usersSocialMediaHandles_social_media_id.social_media.toLowerCase();

                    let url = "";
                    if (
                      item?.social_media_url.startsWith("http://") ||
                      item?.social_media_url.startsWith("https://")
                    ) {
                      url = item?.social_media_url;
                    } else {
                      url = "https://" + item.social_media_url;
                    }
                    if (social_media === "instagram") {
                      return (
                        <div
                          className="cursor-pointer ms-2"
                          onClick={(e) => {
                            window.open(url, "_blank");
                          }}
                        >
                          <InstagramOutlined className="zone_card_links" />
                        </div>
                      );
                    } else if (social_media === "linkedin") {
                      return (
                        <div
                          className="cursor-pointer ms-2"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <LinkedinOutlined className="zone_card_links" />
                        </div>
                      );
                    } else if (social_media === "twitter") {
                      return (
                        <div
                          className="cursor-pointer"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <TwitterOutlined className="zone_card_links ms-2" />
                        </div>
                      );
                    } else if (social_media === "facebook") {
                      return (
                        <div
                          className="cursor-pointer ms-2"
                          onClick={() => window.open(url, "_blank")}
                        >
                          <FacebookOutlined className="zone_card_links" />
                        </div>
                      );
                    }
                  }
                )}
              </div>
            ) : null}
          </Col>
        </Row>
      </div>

      {isCreateMeetingModalVisible ? (
        <CreateMeeting
          isCreateMeetingModalVisible={isCreateMeetingModalVisible}
          closeCreateMeetingModal={() => {
            setCreateMeetingModalVisible(false);
          }}
          userData={userData}
        />
      ) : null}

      {messageModal.isOpen ? (
        <Modal
          title={<b>Send Message</b>}
          visible={messageModal.isOpen}
          onOk={() => clearMessageAndModal()}
          onCancel={() => clearMessageAndModal()}
          footer={[
            <Button
              className="px-3 button-pink rounded"
              key="cancel"
              onClick={() => clearMessageAndModal()}
            >
              Cancel
            </Button>,
            <Button
              className="px-3 rounded"
              key="save"
              type="primary"
              onClick={() => callSendMessageAPI(messageModal.data)}
              disabled={disableSendBtn}
            >
              Send
            </Button>,
          ]}
          className="message_modal"
        >
          <Row className="ticker_configuration_row mt-2">
            <Col span={6} className="ticker_configuration_label">
              <p>
                Message
                <RedAsterick />
              </p>
            </Col>
            <Col span={12}>
              <TextArea
                name="message_text"
                id="message_text"
                className="ticker_configuration_inputDescription"
                placeholder="Enter a message"
                rows={4}
                value={message_text}
                onChange={(e) => set_message_text(e.target.value)}
                onBlur={() =>
                  messageValidator.current.showMessageFor("message_text")
                }
              />
              {messageValidator.current.message(
                "Message",
                message_text,
                "required",
                { className: "text-danger py-1" }
              )}
            </Col>
          </Row>
        </Modal>
      ) : null}
    </>
  );
};

export default ProfileModal;
