import React, { useState, useEffect } from "react";
import CommonLoader from "../../components/Widgets/CommonLoader";
import "./commonPopup.css";

import moment from "moment";
import { Button, Modal } from "antd";
import { BoothRed } from "../../components/svgJS/BoothRed";
import { EyeView } from "../../components/svgJS/EyeView";
import { UserDataCard } from "../common/Modal/ProfileModal";
import { useDispatch } from "react-redux";
import { setVideoConferenceData } from "../../redux/slice/videoConference";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { getCookie } from "../../utils/commonFunctions";

const ViewMeetingDelegate = (props) => {
  const { data, handleCloseModal } = props;
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [profileModal, setProfileModal] = useState(false);
  const [selectedAttendees, setSelectedAttendees] = useState([]);
  const [selectedProfileValue, setSelectedProfileValue] = useState();
  const [canJoinSession, setCanJoinSession] = useState(false);
  const [userId, setUserId] = useState(null);
  let CryptoJS = require("crypto-js");

  useEffect(() => {
    let user_id = null;

    if (getCookie("user_id")) {
      const ciphertext = getCookie("user_id");
      let bytes = CryptoJS.AES.decrypt(ciphertext, "user_id");
      user_id = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setUserId(parseInt(user_id));
    }

    setLoading(true);

    let currentDate = moment().format("YYYY-MM-DD");
    let currentTime = moment();

    const d1 = new Date(data?.start_date_time);
    const st = d1.toISOString().replace("Z", "");
    const d2 = new Date(data?.end_date_time);
    const et = d2.toISOString().replace("Z", "");

    let sessionDate = moment(st).format("YYYY-MM-DD");

    let sessionStartTime = moment(st);
    let sessionEndTime = moment(et);

    if (
      currentDate === sessionDate &&
      currentTime.isAfter(sessionStartTime) &&
      currentTime.isBefore(sessionEndTime)
    )
      setCanJoinSession(true);

    setSelectedAttendees(
      data?.meeting_attendees_details?.filter(
        (item) => item?.user_id !== user_id
      )
    );

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [CryptoJS.AES]);

  const handleOKAddUser = () => {
    setProfileModal(false);
  };

  const handleCancelAddUser = () => {
    setProfileModal(false);
  };
  const handleProfileData = (index) => {
    setProfileModal(true);
    setSelectedProfileValue(index);
    if (props?.handleCloseModal) {
      props?.handleCloseModal(selectedAttendees, index);
    }
  };

  const saveResponseData = async (bool) => {
    let objToPass = {};
    if (bool) objToPass = { ...data, rsvp_type: "YES" };
    else objToPass = { ...data, rsvp_type: "NO" };
    data !== null &&
      (await ApiCall("POST", objToPass, "/admin/mycalendarrsvp/save", null)
        .then((response) => {
          if (response.data.code === 200) {
            toast.success(response.data.message);
            window.location.reload();
            // getMyCalendarAppointmentDetails()
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((err) => {
          console.error(err);
        }));
  };

  function renderUI() {
    if (loading) return <CommonLoader />;
    else
      return (
        <div
          className={
            props.isFromCalender && props.isFromCalender === "zone"
              ? "w-100 p-4"
              : "w-100"
          }
        >
          <div className="row">
            <div className="col-1"></div>
            <div className="col-11">
              <div className="row">
                <div className="col-12">
                  <h6 className="opacity-75">Meeting Title :</h6>
                  <p className="font-weight-bold">
                    <b>{data?.session_title ?? "NA"}</b>
                  </p>
                  <h6 className="opacity-75 mt-2">Time :</h6>
                  <p>
                    <b>
                      {" "}
                      {moment.utc(data?.start_date_time).format("HH:mm") ??
                        "NA"}
                      -{moment.utc(data?.end_date_time).format("HH:mm") ?? "NA"}{" "}
                    </b>
                  </p>
                  <h6 className="opacity-75">Meeting Description :</h6>
                  <p>
                    <b>{data?.session_description ?? "NA"}</b>
                  </p>
                </div>
                <div className="col-12">
                  <p className="fs-16 font-bold my-2">Attendees</p>
                  <div className="row mt-1">
                    <div className="col-4 opacity-75 pb-1 border-bottom border-secondary">
                      <b> Delegate </b>
                    </div>
                    <div className="col-4 opacity-75 pb-1 border-bottom border-secondary">
                      <b>Company</b>
                    </div>
                    <div className="col-4 opacity-75 pb-1 border-bottom border-secondary">
                      <b>Action</b>
                    </div>
                  </div>
                  <div className="row mt-2 mb-4">
                    {selectedAttendees &&
                      !isEmpty(selectedAttendees) &&
                      selectedAttendees.map((elem, index) => (
                        <div className="my-2 d-flex">
                          <div className="col-4">
                            {elem?.first_name} {elem?.last_name}
                          </div>
                          <div className="col-4 px-2">{elem?.company}</div>
                          <div
                            className="col-4"
                            onClick={() => handleProfileData(index)}
                          >
                            <EyeView />
                            <span className="fs-pink">View Profile</span>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>

                {profileModal ? (
                  <Modal
                    title="Profile Details"
                    visible={profileModal}
                    onOk={handleOKAddUser}
                    onCancel={handleCancelAddUser}
                    footer={null}
                    width={1000}
                  >
                    {selectedAttendees && !isEmpty(selectedAttendees) && (
                      <UserDataCard
                        meetingData={selectedAttendees[selectedProfileValue]}
                        fromMeeting={true}
                      />
                    )}
                  </Modal>
                ) : null}

                {props?.data?.rsvp_type === "PENDING" ? (
                  <>
                    <div className="d-flex justify-content-end px-3">
                      <Button
                        type="primary"
                        className="button-pink py-1 rounded"
                        onClick={() => saveResponseData(true)}
                      >
                        Accept
                      </Button>
                      <Button
                        type="primary"
                        className="button-pink py-1 mx-3 rounded"
                        onClick={() => saveResponseData(false)}
                      >
                        Reject
                      </Button>
                    </div>
                  </>
                ) : canJoinSession && data?.rsvp_type !== "NO" ? (
                  <div className="w-100 d-flex justify-content-end my-2">
                    <div className="w-50"></div>
                    <Button
                      className="audiEvent_joinBtn mx-4 fs-16"
                      onClick={() => {
                        handleCloseModal();
                        dispatch(
                          setVideoConferenceData({
                            videoConferenceFlag: true,
                            videoConferenceUrl: data?.join_session_url,
                            is_meeting: true,
                          })
                        );
                      }}
                    >
                      <span className="mx-2">
                        <BoothRed />
                      </span>
                      Join Session
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
  }

  if (props.isFromCalender && props.isFromCalender === "zone")
    return renderUI();
  return (
    <Modal
      title={"Update Meeting"}
      visible={true}
      width={950}
      onOk={() => {
        if (!loading) props.onCancel();
      }}
      onCancel={() => {
        if (!loading) props.onCancel();
      }}
      footer={null}
    >
      {renderUI()}
    </Modal>
  );
};

export default ViewMeetingDelegate;
