import React, { useEffect, useState } from "react";
import {
  Table,
  Popover,
  Breadcrumb,
  Input,
  Button,
  Select,
  Tooltip,
} from "antd";
import {
  MoreOutlined,
  EditOutlined,
  EyeOutlined,
  SearchOutlined,
  DeleteOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { onShowSizeChange } from "../../components/helper/pagination";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyList } from "../../redux/slice/surveyManagement";
import "./survey.css";
import { cloneDeep, isEmpty } from "lodash";
import { ApiCall } from "../../utils/api";
import { toast } from "react-toastify";
import { Filter } from "../../components/svgJS/Filter";
import {
  getCustomerListForListing,
  getEventsForListing,
  getBrandsForListing,
  getSurveyCreators,
} from "../../utils/commonApis";
import {
  createTZString,
  generateHelmet,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { PAGINATION_DEFAULT_SETTINGS } from "../../components/constsnts/common.constants";
import CommonLoader from "../../components/Widgets/CommonLoader";
import moment from "moment";

const SurveyManagement = () => {
  const dispatch = useDispatch();
  const surveySelector = useSelector(
    (state) => state.surveyManagement.surveyList
  );
  let loading = useSelector((state) => state.surveyManagement.loading);
  const { Option } = Select;
  var CryptoJS = require("crypto-js");
  const [searchData, setsearchData] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [customerList, setCustomerList] = useState();
  const [eventList, setEventList] = useState();
  const [brandList, setBrandList] = useState();
  const [creatorList, setCreatorList] = useState();
  const [fields, setFields] = useState({
    customer: null,
    event_id: null,
    user_id: null,
    id: null,
    is_customer: null,
    full_name: null,
  });
  const [page_no, set_page_no] = useState(PAGINATION_DEFAULT_SETTINGS.page_no);
  const [no_of_records, set_no_of_records] = useState(
    PAGINATION_DEFAULT_SETTINGS.no_of_records
  );

  const [isTickerDisplay, setIsTickerDisplay] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("tickerToDisplay")) {
      setIsTickerDisplay(true);
    } else {
      setIsTickerDisplay(false);
    }
  }, [localStorage.getItem("tickerToDisplay")]);

  useEffect(async () => {
    let is_route_present = await isRouteExistInMenu(CryptoJS);
    if (is_route_present === false) {
      window.location.href = "/pagenotfound";
    } else {
      window.scrollTo(0, 0);
      let customerResponse = await getCustomerListForListing({
        is_details: false,
      });
      setCustomerList(customerResponse);
      dispatch(getSurveyList({ page_no, no_of_records }));
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    const eventParams = {
      is_filter: true,
      customer_id: fields?.customer,
    };
    let eventResponse = await getEventsForListing(eventParams);
    setEventList(eventResponse);
  }, [fields?.customer]);

  useEffect(async () => {
    const brandParams = {
      is_filter: true,
      event_id: fields?.event_id,
    };

    let brandResponse = await getBrandsForListing(brandParams);
    setBrandList(brandResponse);
  }, [fields?.event_id]);

  useEffect(async () => {
    const creatorParams = {
      customer_id: fields?.customer,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
    };

    let creatorResponse = await getSurveyCreators(creatorParams);
    setCreatorList(creatorResponse);
  }, [fields?.customer, fields?.brand_id, fields?.event_id]);

  const handleDeleteSurvey = async (surveyData) => {
    const body = {
      survey_id: surveyData.survey_id,
      is_delete: true,
    };
    const response = await ApiCall(
      "DELETE",
      body,
      "/admin/survey/delete",
      "",
      ""
    );
    if (response.data.code === 200) {
      toast.success(response.data.message);
      dispatch(getSurveyList());
    } else toast.error(response.data.message);
  };

  const tableData =
    surveySelector &&
    !isEmpty(surveySelector) &&
    surveySelector.rows &&
    surveySelector.rows.map((elem) => {
      const start_date_time_createdAt = createTZString(elem.created_at);

      const eleStartDateCreatedAt = moment(
        start_date_time_createdAt.format("YYYY-MM-DD"),
        "YYYY-MM-DD"
      );

      return {
        // key: "1",
        survey_id: elem.survey_id,
        created_at: eleStartDateCreatedAt?.format("DD-MM-YYYY"),
        surveytitle: elem.survey_title,
        eventdomainname: elem.survey_type,
        survey_type: elem.survey_type,
        count_question: elem.count_question,
        displaychart: "On",
        question_details: elem.surveys_qa_survey_id,
        customername: elem.account_name,
        eventdomain: elem?.sub_domain ?? "NA",
        createdby: elem.created_user_name,
        brand: elem?.brand_title ?? "NA",
        zone: elem?.zone_title ?? "NA",
        is_active: elem.is_active,
      };
    });

  const columns = [
    {
      title: "ID",
      dataIndex: "survey_id",
      key: "survey_id",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 70,
    },
    {
      title: "Survey Type",
      dataIndex: "survey_type",
      key: "survey_type",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 140,
    },
    {
      title: "Survey Title",
      dataIndex: "surveytitle",
      key: "surveytitle",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customername",
      key: "customername",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Event Domain",
      dataIndex: "eventdomain",
      key: "eventdomain",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Zone",
      dataIndex: "zone",
      key: "zone",
      width: 120,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Question",
      dataIndex: "count_question",
      key: "count_question",
      sortDirections: ["ascend", "descend", "ascend"],
      width: 110,
    },
    {
      title: "Created By",
      dataIndex: "createdby",
      key: "createdby",
      width: 170,
      ellipsis: {
        showTitle: false,
      },
      render: (name) => (
        <Tooltip placement="topLeft" title={name}>
          {name}
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      sortDirections: ["ascend", "descend", "ascend"],
      width: 120,
    },
    {
      title: "",
      dataIndex: "operation",
      fixed: "right",
      width: 40,
      render: (text, record, index) => (
        <Popover
          content={
            <div>
              <Link to={`viewsurvey/${record.survey_id}`}>
                <p className="options">
                  <EyeOutlined className="mx-1" />
                  <span className="">View Details</span>
                </p>
              </Link>
              <Link to={`editsurvey/${record.survey_id}`}>
                <p className="options">
                  <EditOutlined className="mx-1" />
                  <span className="">Edit Survey</span>
                </p>
              </Link>
            </div>
          }
          trigger="hover"
        >
          <MoreOutlined />
        </Popover>
      ),
    },
    {
      title: "Action",
      fixed: "right",
      width: 65,
      render: (text, record, index) => (
        <div className="px-1">
          <Link to={`editsurvey/${record.survey_id}`}>
            <p className="options m-0">
              <EditOutlined className="" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    let sortObj = {
      page_no: pagination?.current ?? page_no,
      no_of_records,
    };
    if (
      pagination &&
      pagination.pageSize &&
      no_of_records !== pagination.pageSize
    ) {
      set_page_no(1);
      set_no_of_records(pagination.pageSize);
      sortObj = {
        page_no: 1,
        no_of_records: pagination.pageSize,
      };
    }
    sortObj = {
      ...sortObj,
      sort_type: sorter.columnKey,
      sort_by: sorter.order === "ascend" ? "ASC" : "DSC",
      search: searchData,
      customer: fields?.customer,
      event_id: fields?.event_id,
      id: fields?.id,
      is_customer: fields?.is_customer,
      user_id: fields?.user_id,
      full_name: fields?.full_name,
    };
    dispatch(getSurveyList(sortObj));
  };

  const handleInputSearch = (e) => {
    setsearchData(e.target.value);
    const searchObj = {
      search: e.target.value,
      customer: fields?.customer,
      event_id: fields?.event_id,
      brand_id: fields?.brand_id,
      id: fields?.id,
      is_customer: fields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getSurveyList(searchObj));
  };

  const handleFieldsChange = (val, name) => {
    let cloneFields = cloneDeep(fields);
    if (name === "id") {
      cloneFields = {
        ...cloneFields,
        [name]: val?.id,
        is_customer: val?.is_customer,
        full_name: val?.full_name,
      };
    } else if (name === "customer") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        event_id: null,
        brand_id: null,
        id: null,
        is_customer: null,
        full_name: null,
      };
    } else if (name === "event_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        brand_id: null,
        id: null,
        is_customer: null,
        full_name: null,
      };
    } else if (name === "brand_id") {
      cloneFields = {
        ...cloneFields,
        [name]: val,
        id: null,
        is_customer: null,
        full_name: null,
      };
    } else {
      cloneFields = { ...cloneFields, [name]: val };
    }
    setFields({ ...cloneFields });
    const searchObj = {
      search: searchData,
      customer: cloneFields?.customer,
      event_id: cloneFields?.event_id,
      brand_id: cloneFields?.brand_id,
      id: cloneFields?.id,
      is_customer: cloneFields?.is_customer,
      page_no,
      no_of_records,
    };
    dispatch(getSurveyList(searchObj));
  };

  return (
    <div>
      <div className="d-flex justify-content-between listing_upper_div">
        {generateHelmet("Gravit8", "Survey Management")}
        <div>
          <h4 className="fs-24">Survey Management</h4>
          <Breadcrumb className="my-2" separator=">">
            <Breadcrumb.Item>Admin Console</Breadcrumb.Item>
            <Breadcrumb.Item>Survey Management</Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <div>
          <Link to="/addnewsurvey">
            <Button className="rounded px-3 py-1 mt-2 mb-1">
              <span className="fw-bold"> + Add New Survey </span>
            </Button>
          </Link>
        </div>
      </div>
      <div className="bg-white px-2 listing_lower_div">
        <div className="d-flex justify-content-between mb-4">
          <div className="w-50 mt-3">
            <Input
              size="medium"
              placeholder="Search by Customer Name, Event Name, Brand Name, Created By, Survey Title"
              style={{ width: "130%" }}
              prefix={<SearchOutlined />}
              value={searchData}
              onChange={handleInputSearch}
            />
          </div>
          {loading ? null : (
            <div className="d-flex mt-3">
              <Button
                className=" rounded"
                onClick={() => setShowFilter(!showFilter)}
              >
                <Filter />
                <span className="ms-2"> FILTER</span> <DownOutlined />
              </Button>
            </div>
          )}
        </div>
        <div className="mb-2">
          {showFilter && !loading ? (
            <div>
              <Select
                name="customer"
                placeholder="Customer Name"
                style={{
                  width: 220,
                }}
                onChange={(val) => handleFieldsChange(val, "customer")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.customer}
              >
                {customerList?.map((data) => {
                  return (
                    <Option value={data.customer_id}>
                      {data.account_name}
                    </Option>
                  );
                })}
              </Select>
              <Select
                name="event_id"
                placeholder="Event Name"
                className="ms-2"
                style={{
                  width: 220,
                }}
                onChange={(val) => handleFieldsChange(val, "event_id")}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.event_id}
              >
                {eventList &&
                  eventList?.map((data) => {
                    return (
                      <Option value={data.event_id}>{data.event_name}</Option>
                    );
                  })}
              </Select>
              <Select
                name="brand_id"
                placeholder="Brand"
                className="ms-2"
                onChange={(val) => handleFieldsChange(val, "brand_id")}
                style={{
                  width: 220,
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                showSearch
                optionFilterProp="children"
                value={fields?.brand_id}
              >
                {brandList &&
                  brandList?.map((data) => {
                    return (
                      <Option value={data.brand_id}>{data.brand_title}</Option>
                    );
                  })}
              </Select>
              <Select
                name="eventName"
                placeholder="Creator Name"
                className="ms-2"
                showSearch
                style={{
                  width: 220,
                }}
                value={fields?.full_name ?? null}
                onChange={(id) => {
                  if (id) handleFieldsChange(JSON.parse(id), "id");
                  else handleFieldsChange(null, "id");
                }}
                allowClear
                listHeight={120}
                listItemHeight={4}
                optionFilterProp="children"
              >
                {creatorList &&
                  creatorList?.map((data) => {
                    return (
                      <Option value={JSON.stringify(data)}>
                        {data.full_name}
                      </Option>
                    );
                  })}
              </Select>
            </div>
          ) : null}
        </div>
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={{
            showSizeChanger: true,
            pageSize: no_of_records,
            total: surveySelector?.count ? surveySelector.count : 1,
          }}
          loading={{
            indicator: <CommonLoader />,
            spinning: loading,
          }}
          scroll={{
            x: 1300,
            y: isTickerDisplay ? (showFilter ? "44vh" : "50vh") : "58vh",
          }}
          bordered={false}
          showSizeChange={true}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default SurveyManagement;
