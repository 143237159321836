import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  videoConferenceFlag: false,
  videoConferenceUrl: "",
  createPollFlag: false,
  is_producer_curator: false,
  isFromNetworking: false,
  auditorium_session_id: null,
  auditorium_configuration_id: null,
  myCalendarAppoinmentVisible: false,
  is_speaker: false,
  loading: false,
  shuffleTime: null,
  networking_session_id: null,
  waterFallTime: null,
  isNetworkTableJitsiModalClosed: false,
  is_demo_room: false,
  is_video_call: false,
  is_meeting: false,
  is_moderator: false,
  sessionStartTime: null,
  isPollOptionsVisible: false,
  stream_url: null,
  networkingSessionIsOver: false,
  googleAuthFlag: false,
  videoConferenceObj: null,
  newStreamKey: null,
  newStreamUrl: null,
  receiver_chat_id: null,
  is_call_accepted: false,
  showMessageBoard: false,
  isFromExternalSourceEnabled: false,
};

export const setVideoConferenceData = createAsyncThunk(
  "setVideoConferenceData",

  async ({
    videoConferenceFlag,
    videoConferenceUrl,
    createPollFlag,
    isFromNetworking,
    auditorium_session_id,
    auditorium_configuration_id,
    is_speaker,
    is_producer_curator,
    shuffleTime,
    networking_session_id,
    waterFallTime,
    is_demo_room,
    is_video_call,
    is_meeting,
    is_moderator,
    sessionStartTime,
    isPollOptionsVisible,
    stream_url,
    isFromProductZone,
    stream_key,
    receiver_chat_id,
    is_call_accepted,
    showMessageBoard,
    isFromExternalSourceEnabled,
  }) => {
    return {
      videoConferenceFlag,
      videoConferenceUrl,
      createPollFlag,
      isFromNetworking,
      auditorium_session_id,
      auditorium_configuration_id,
      is_speaker,
      is_producer_curator,
      shuffleTime,
      networking_session_id,
      waterFallTime,
      is_demo_room,
      is_video_call,
      is_meeting,
      is_moderator,
      sessionStartTime,
      isPollOptionsVisible,
      stream_url,
      isFromProductZone,
      stream_key,
      receiver_chat_id,
      is_call_accepted,
      showMessageBoard,
      isFromExternalSourceEnabled,
    };
  }
);

export const myCalendarAppoinmentVisible = createAsyncThunk(
  "myCalendarAppoinmentVisible",

  async () => {
    return true;
  }
);

export const setNetTableJitsiModalClosed = createAsyncThunk(
  "setNetTableJitsiModalClosed",

  async (flag) => {
    return flag;
  }
);

export const setGoogleAuthData = createAsyncThunk(
  "setGoogleAuthData",

  async ({ googleAuthFlag, videoConferenceObj }) => {
    return {
      googleAuthFlag,
      videoConferenceObj,
    };
  }
);

export const setStreamKeyAndUrl = createAsyncThunk(
  "setStreamKeyAndUrl",

  async ({ streamKey, streamUrl }) => {
    return {
      streamKey,
      streamUrl,
    };
  }
);

export const setVideoConferenceReducer = createSlice({
  name: "videoConference",
  initialState,
  reducers: {
    setVideoConferenceFlag(state, action) {
      state.videoConferenceFlag = action.payload;
    },
  },
  extraReducers: {
    [setVideoConferenceData.pending]: (state) => {
      state.loading = true;
    },
    [setVideoConferenceData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.videoConferenceFlag = payload.videoConferenceFlag;
      state.videoConferenceUrl = payload.videoConferenceUrl;
      state.createPollFlag = payload.createPollFlag;
      state.isFromNetworking = payload.isFromNetworking;
      state.auditorium_session_id = payload.auditorium_session_id;
      state.auditorium_configuration_id = payload.auditorium_configuration_id;
      state.is_speaker = payload?.is_speaker;
      state.is_producer_curator = payload?.is_producer_curator;
      state.shuffleTime = payload?.shuffleTime;
      state.networking_session_id = payload?.networking_session_id;
      state.waterFallTime = payload?.waterFallTime;
      state.is_demo_room = payload?.is_demo_room;
      state.is_video_call = payload?.is_video_call;
      state.is_meeting = payload?.is_meeting;
      state.is_moderator = payload?.is_moderator;
      state.sessionStartTime = payload?.sessionStartTime;
      state.isPollOptionsVisible = payload?.isPollOptionsVisible;
      state.stream_url = payload?.stream_url;
      state.isFromProductZone = payload?.isFromProductZone;
      state.stream_key = payload?.stream_key;
      state.receiver_chat_id = payload?.receiver_chat_id;
      state.is_call_accepted = payload?.is_call_accepted;
      state.showMessageBoard = payload?.showMessageBoard;
      state.isFromExternalSourceEnabled = payload?.isFromExternalSourceEnabled;
    },
    [setVideoConferenceData.rejected]: (state) => {
      state.loading = false;
    },
    [myCalendarAppoinmentVisible.pending]: (state) => {
      state.loading = true;
    },
    [myCalendarAppoinmentVisible.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.myCalendarAppoinmentVisible = payload;
    },
    [myCalendarAppoinmentVisible.rejected]: (state) => {
      state.loading = false;
    },
    [setNetTableJitsiModalClosed.pending]: (state) => {
      state.loading = true;
    },
    [setNetTableJitsiModalClosed.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isNetworkTableJitsiModalClosed = payload;
    },
    [setNetTableJitsiModalClosed.rejected]: (state) => {
      state.loading = false;
    },
    [setGoogleAuthData.pending]: (state) => {
      state.loading = true;
    },
    [setGoogleAuthData.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.googleAuthFlag = payload.googleAuthFlag;
      state.videoConferenceObj = payload.videoConferenceObj;
    },
    [setGoogleAuthData.rejected]: (state) => {
      state.loading = false;
    },
    [setStreamKeyAndUrl.pending]: (state) => {
      state.loading = true;
    },
    [setStreamKeyAndUrl.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.streamKey = payload.streamKey;
      state.streamUrl = payload.streamUrl;
    },
    [setStreamKeyAndUrl.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const videoConferenceReducer = setVideoConferenceReducer.reducer;
export const { setVideoConferenceFlag } = setVideoConferenceReducer.actions;
