import React from "react";

import { Colorpicker } from "antd-colorpicker";
import { Input } from "antd";

const ColorPickerInput = (props) => {
  let { colorCode, onHandleColorChange, disabled } = props;
  return (
    <div className="eventtheme_antd_input_style border-gray rounded fs-bold-14 text-end">
      {/* <span className="w-90 text-center">{colorCode}</span> */}
      <Input
        value={colorCode}
        disabled={disabled}
        type="text"
        className="eventtheme_antd_input_style_hex_code"
        onChange={(e) => {
          let inputValue = e.target.value;

          // Remove additional '#' characters from the input
          inputValue = inputValue.replace(/#/g, "");

          // Add single '#' at the beginning
          inputValue = "#" + inputValue;

          onHandleColorChange(inputValue);

          // onHandleChange("event_color_theme", e.target.value);
        }}
      />

      <Input
        disabled={disabled}
        type="color"
        id=""
        defaultValue={colorCode}
        value={colorCode}
        onChange={(e) => {
          onHandleColorChange(e.target.value);
          // onHandleChange("event_color_theme", e.target.value);
        }}
        className="eventtheme_input_style border-gray rounded fs-bold-14"
      />
    </div>
  );
};

export default ColorPickerInput;
