import React, { useState, useEffect } from "react";
import {
  Breadcrumb,
  Select,
  Input,
  Divider,
  Button,
  Switch,
  TimePicker,
  Collapse,
} from "antd";
import "./Zone.css";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useMediaQuery } from "react-responsive";
import { AddGroup } from "../../components/svgJS/AddGroup";
import { CalenderGrey, CalenderRed } from "../../components/svgJS/CalenderRed";
import { EditRed } from "../../components/svgJS/EditRed";
import { Bin } from "../../components/svgJS/Bin";
import { Cube } from "../../components/svgJS/Cube";

import { ApiCall } from "../../utils/api";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { fetchEventShow } from "../../redux/slice/zoneManagementSlice";

import { Link } from "react-router-dom";
import moment from "moment";
import { cloneDeep, isEmpty, isEqual, uniqWith } from "lodash-es";
import CommonLoader from "../../components/Widgets/CommonLoader";
import {
  createTZString,
  getCookie,
  isRouteExistInMenu,
} from "../../utils/commonFunctions";
import { toast } from "react-toastify";
import { TIME_FORMAT } from "../../components/constsnts/common.constants";

const ViewZone = ({ match }) => {
  var CryptoJS = require("crypto-js");

  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentList, setagentList] = useState([]);

  const [fetchedZoneData, setfetchedZoneData] = useState(null);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);

  const [socialMediaList, setSocialMediaList] = useState([]);
  const [viewVisible, setViewVisible] = useState(false);
  const [assetsArray, setassetsArray] = useState([]);
  const [chatBotDetails, setchatBotDetails] = useState([]);
  const [zoneLoading, setZoneLoading] = useState(false);
  const [accessDenied, setAccessDenied] = useState(false);
  const dispatch = useDispatch();
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const { Option } = Select;
  const { TextArea } = Input;
  const { Panel } = Collapse;

  const user_name = getCookie("user_name");
  var bytes = CryptoJS.AES.decrypt(user_name, "user_name");
  var decryptedName = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  useEffect(() => {
    getZoneDetail();
  }, []);

  useEffect(async () => {
    setZoneLoading(true);
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

    let is_route_present = await isRouteExistInMenu(CryptoJS);

    if (
      (is_route_present === false && decryptedData === "AGENT") ||
      decryptedData === "DELEGATE"
    ) {
      window.location.href = "/pagenotfound";
    } else {
      getSocialMediaList();

      setTimeout(() => {
        setZoneLoading(false);
      }, 2000);
    }
  }, [CryptoJS.AES]);

  useEffect(async () => {
    await getZoneDetail();
    getSocialMediaList();
    getAgentsList(fetchedZoneData?.event_id, fetchedZoneData?.brand_id);
  }, [fetchedZoneData?.event_id]);

  useEffect(() => {
    if (agentsCollectionData) {
      setAgentsFromAPIAndUseEffect(fetchedZoneData);
    }
  }, [agentsCollectionData]);

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    let zones_agents = [];
    dataHere &&
      dataHere.zones_agents &&
      dataHere.zones_agents.map((val) => {
        zones_agents.push(val.user_id);
      });
    if (dataHere && !isEmpty(dataHere.zones_agents) && !isEmpty(zones_agents)) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      zones_agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let agents_list = clonedAgents;
          let selectedAgents_list = selectedAgents;
          let index = agents_list.findIndex((agent) => agent.user_id === val);
          selectedAgents_list.push(agents_list[index]);
          agents_list.splice(index, 1);
          selectedAgents_list = uniqWith(selectedAgents_list, isEqual);
          setSelectedAgents([...selectedAgents_list]);
          setAgents([...agents_list]);
        }
      });
    } else setAgents(agentsCollectionData);
  };

  const getZoneDetail = async () => {
    await ApiCall("get", null, "/admin/zones/details/get", {
      zone_id: match.params.zone_id,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      current_timestamp: moment().format("YYYY-MM-DD"),
    })
      .then((response) => {
        if (response?.data?.code === 404) {
          toast.error(response?.data?.message);
          setAccessDenied(true);
        } else {
          const { details } = response.data.data;
          let chatBotArray = details?.zone_chat_bot_setting_details?.map(
            (elem) => {
              const start_date_time = createTZString(elem?.start_date_time);
              const end_date_time = createTZString(elem?.end_date_time);
              const eleStartTime = moment(moment(start_date_time, TIME_FORMAT));
              const eleEndTime = moment(moment(end_date_time, TIME_FORMAT));
              return {
                start_time: eleStartTime,
                end_time: eleEndTime,
                start_day: moment(elem?.start_date).format("DD-MM-YYYY"),
                end_day: moment(elem?.end_date).format("DD-MM-YYYY"),
              };
            }
          );
          setfetchedZoneData(details);
          setSocialMediaList(details.zones_social_media_handles_id);
          setagentList(details?.zones_agents);
          setassetsArray(details?.zones_assets_id);
          addChatbot(details?.event_id);
          setchatBotDetails(chatBotArray);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  var initial_state = {
    zone_hero_image_url:
      fetchedZoneData && fetchedZoneData?.zone_hero_image_url,
    event_id: fetchedZoneData && fetchedZoneData?.zone_event_name,
    brand_id: fetchedZoneData && fetchedZoneData?.zone_brand_name,
    booth_location_id:
      fetchedZoneData && fetchedZoneData.booth_location.booth_location,
    is_active: fetchedZoneData && fetchedZoneData.is_active,
    zone_title: fetchedZoneData && fetchedZoneData.zone_title,
    zone_description: fetchedZoneData && fetchedZoneData.zone_description,
    website_url: fetchedZoneData && fetchedZoneData.website_url,
    is_text_chat_enable: fetchedZoneData && fetchedZoneData.is_text_chat_enable,
    is_message_board_enable:
      fetchedZoneData && fetchedZoneData.is_message_board_enable,
    is_moderated: fetchedZoneData && fetchedZoneData.is_moderated,
    is_one_to_one_video_calls_enable:
      fetchedZoneData && fetchedZoneData.is_one_to_one_video_calls_enable,
    survey_id:
      fetchedZoneData && fetchedZoneData?.zones_survey_id?.survey_title,
    is_on_booth_leads_enable:
      fetchedZoneData && fetchedZoneData.is_on_booth_leads_enable,
    is_video_rep_enable: fetchedZoneData && fetchedZoneData.is_video_rep_enable,
    is_video_demo_room_enable:
      fetchedZoneData && fetchedZoneData.is_video_demo_room_enable,
    is_text_bot_enable: fetchedZoneData && fetchedZoneData.is_text_bot_enable,
    is_individual: fetchedZoneData && fetchedZoneData.is_individual,
    zone_agents: selectedAgents,
    zone_chat_bot_setting_details: chatBotDetails,
    zones_social_media_handles_details: [],
    zones_assets_details: assetsArray,
  };

  const getAgentsList = (event_id, brand_id) => {
    ApiCall("get", "", "/admin/usertypeagents/get", {
      event_id: event_id ? event_id : null,
      brand_id: brand_id ? brand_id : null,
    })
      .then((response) => {
        setAgents(response?.data?.data);
        setAgentsCollectionData(response?.data?.data);
        setSelectedAgents([]);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getSocialMediaList = () => {
    ApiCall("get", null, "/admin/socialmediatype/get", null)
      .then((response) => {})
      .catch((err) => {
        console.error(err);
      });
  };

  const handlechatbotArrChange = (val, key, name) => {
    let chatbot_arr = chatBotDetails;
    chatbot_arr[key][[name]] = val;
  };

  const addChatbot = async (eventId) => {
    await dispatch(fetchEventShow({ event_id: eventId }));
  };

  const DetailComponent = ({ assetData }) => {
    return (
      <div className={Tablet ? "mt-4 py-2" : "col-4 py-2 mx-2"}>
        <div className="row border-gray rounded h-100 py-2">
          <div className="row">
            <div className="col-4 text-end">Active</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_active} disabled />
            </div>
            <div className="col-3 ">Max D/L's</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.max_no_of_downloads}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end">Downloadable</div>
            <div className="col-2">
              <Switch defaultChecked={assetData?.is_downloadable} disabled />
            </div>
            <div className="col-3 ">Whitelist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.whitelisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-4 text-end"></div>
            <div className="col-2"></div>
            <div className="col-3 ">Blacklist</div>
            <div className="col-2">
              <Button className="py-1 px-3 rounded">
                {assetData?.blacklisted_domains}
              </Button>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end">Info</div>
            <div className="col-7">
              <span className="fs-12">
                Filename: {assetData?.original_file_name}
              </span>
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                Date:
                {assetData && assetData?.updated_at
                  ? moment(assetData?.updated_at).format("DD-MM-YYYY")
                  : ""}
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">Uploaded By: {decryptedName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">View Count: {assetData?.view_count}</span>
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-end"></div>
            <div className="col">
              <span className="fs-12">
                D/L Count: {assetData?.total_downloads}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const preSelectedAgents =
    agentList &&
    agentList?.map((data) => {
      return data;
    });

  const selectedAgentNames =
    preSelectedAgents !== null &&
    preSelectedAgents?.map((data) => {
      return (
        data.zonesAgents_user_id.first_name + data.zonesAgents_user_id.last_name
      );
    });
  if (zoneLoading) {
    return <CommonLoader />;
  } else
    return (
      <Formik
        initialValues={initial_state}
        enableReinitialize={true}
        onSubmit={(values, { setSubmitting }) => {}}
      >
        {({
          values,
          handleSubmit,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            {accessDenied === false && (
              <div className="view-all-common">
                <div>
                  <div className="d-flex justify-content-between bg-light w-100 common-sticky-header">
                    <div>
                      <h4 className="fs-24">View Zone</h4>
                      <Breadcrumb className="my-2" separator=">">
                        <Breadcrumb.Item>
                          <Link to="/zonemanagement">Admin Console</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/zonemanagement">Zone Management</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                          <Link to="/createnewzone">View Zone</Link>
                        </Breadcrumb.Item>
                      </Breadcrumb>
                    </div>
                  </div>
                  <div className="bg-white px-5 py-3">
                    <div className="row my-3">
                      <div className="col-12 mt-5">
                        <h6 className="font-pink fw-bolder fs-20">
                          Zone Editor
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Active
                      </div>
                      <div className="col-4">
                        <Switch checked={values.is_active} name="is_active" />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Zone Hero Image
                      </div>
                      <div className="col-4">
                        <img src={values.zone_hero_image_url} height={100} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Event
                      </div>
                      <div className="col-4">
                        <Input
                          disabled
                          name="zone_title"
                          value={values.event_id}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Brand
                      </div>
                      <div className="col-4">
                        <Input
                          disabled
                          name="zone_title"
                          value={values.brand_id}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Location on Wall
                      </div>
                      <div className="col-4">
                        <Input
                          disabled
                          name="zone_title"
                          value={values.booth_location_id}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Zone Title
                      </div>
                      <div className="col-4">
                        <Input
                          disabled
                          name="zone_title"
                          value={values.zone_title}
                          className="w-100 border-gray rounded fs-bold-14"
                          placeholder="Enter a Title Here"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Zone Description
                      </div>
                      <div className="col-4">
                        <TextArea
                          disabled
                          rows={4}
                          className="w-100 fs-bold-14"
                          name="zone_description"
                          value={values.zone_description}
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Interaction
                        </h6>
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Text Chat
                      </div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_text_chat_enable}
                          name="is_text_chat_enable"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        1-1 Video Call
                      </div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_one_to_one_video_calls_enable}
                          name="is_one_to_one_video_calls_enable"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Select Survey
                      </div>
                      <div className="col-4">
                        <Input value={values.survey_id} />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        On Booth Leads
                      </div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_on_booth_leads_enable}
                          name="is_on_booth_leads_enable"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Video Demo Room
                      </div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_video_demo_room_enable}
                          name="is_video_demo_room_enable"
                        />
                      </div>
                    </div>
                    <div className="row my-3">
                      <div
                        className={Tablet ? "col-3 text-end" : "col-2 text-end"}
                      >
                        Chat Bot
                      </div>
                      <div className="col-4">
                        <Switch
                          checked={values.is_text_bot_enable}
                          name="is_text_bot_enable"
                        />
                      </div>
                    </div>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Chat Bot Settings
                        </h6>
                      </div>
                    </div>
                    <b>
                      A Chat Bot can be enabled for this product zone for set
                      dates / times
                    </b>
                    <br />
                    <b>1.Select the days you want the chatbot on for</b>
                    <br />
                    <b>
                      2.Select the times that the chatbot will be running for
                      each of the days selected
                    </b>
                    <div className="row mt-4 border-bottom-gray pb-4">
                      <div className="col-9 event-date-scroll-div">
                        <Collapse
                          bordered={false}
                          expandIcon={({ isActive }) =>
                            (isActive && (
                              <MinusOutlined
                                className="rounded minus-logo"
                                style={{}}
                              />
                            )) || <PlusOutlined className="rounded plus-logo" />
                          }
                          className="site-collapse-custom-collapse"
                        >
                          {chatBotDetails &&
                            chatBotDetails?.map((elem, index) => {
                              return (
                                <>
                                  <Panel
                                    header={
                                      <span>
                                        <span className="fw-bold">
                                          Shows Days/Times - Block {index + 1}
                                        </span>
                                        <span
                                          className="font-pink mx-2
                                  "
                                        >
                                          {"[ " +
                                            chatBotDetails[index]?.start_day +
                                            " - " +
                                            chatBotDetails[index]?.end_day +
                                            " , " +
                                            moment(elem?.start_time).format(
                                              "HH:mm"
                                            ) +
                                            "-" +
                                            moment(elem?.end_time).format(
                                              "HH:mm"
                                            ) +
                                            " ]"}
                                        </span>
                                      </span>
                                    }
                                    key={index}
                                    className="site-collapse-custom-panel"
                                  >
                                    <div className="row mt-4">
                                      <div className="col-3 text-end">
                                        <label className="text-secondary mt-1">
                                          Start & End Time
                                        </label>
                                      </div>
                                      <div className="col-6 d-flex m-0">
                                        <TimePicker
                                          suffixIcon={
                                            <CalenderGrey width="15" />
                                          }
                                          renderExtraFooter={() =>
                                            "extra footer"
                                          }
                                          showTime
                                          format="HH:mm"
                                          value={elem?.start_time}
                                          disabled
                                          className="w-100"
                                          onSelect={(val) =>
                                            handlechatbotArrChange(
                                              moment(val).format("hh:mm"),
                                              index,
                                              "start_time"
                                            )
                                          }
                                        />
                                        <TimePicker
                                          suffixIcon={
                                            <CalenderGrey width="15" />
                                          }
                                          renderExtraFooter={() =>
                                            "extra footer"
                                          }
                                          showTime
                                          disabled
                                          format="HH:mm"
                                          value={elem?.end_time}
                                          className="w-100"
                                          onChange={(val) =>
                                            handlechatbotArrChange(
                                              moment(val).format("hh:mm"),
                                              index,
                                              "end_time"
                                            )
                                          }
                                        />
                                      </div>
                                    </div>
                                  </Panel>
                                </>
                              );
                            })}
                        </Collapse>
                      </div>
                      <div className="col-3"></div>
                    </div>
                    <Divider />
                    <div className="row my-3">
                      <div className="col-12 mt-4">
                        <h6 className="font-pink fw-bolder fs-20">
                          Externals & Social Links
                        </h6>
                      </div>
                    </div>
                    N8: If you paste your details into these boxes then anyone
                    using site will be able to view/contact you via these
                    platform
                    <div className="social-media-div border-bottom-gray pb-4 mb-4">
                      {socialMediaList &&
                        socialMediaList.map((data) => (
                          <div className="row mt-4 ">
                            <div className="col-2 text-end ">
                              {
                                data?.zonesSocialMediaHandles_social_media_id
                                  ?.social_media
                              }
                            </div>
                            <div
                              className={
                                Tablet
                                  ? "col-2 text-center"
                                  : "col-1 text-center "
                              }
                            >
                              <Switch checked={true} />
                            </div>
                            <div className="col-4 text-start ">
                              <Input
                                disabled
                                placeholder="https://webaddress.com"
                                className="w-100 fs-bold-14"
                                value={data?.social_media_url}
                              />
                            </div>
                          </div>
                        ))}
                    </div>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">
                            Assign Agents to this Zone
                          </h6>
                        </div>
                      </div>
                      <div className="row mt-4 event-editor-dropdown-div">
                        <div className="col-2 text-end mt-4">
                          <span className="text-secondary mt-4">Agents</span>
                        </div>

                        <div
                          className={Tablet ? "col-5" : "col-4"}
                          id="area"
                          style={{ position: "relative" }}
                        >
                          <label className="py-2 mx-2">
                            <b>All Agents</b>
                          </label>
                          <br />
                          <Select
                            getPopupContainer={() =>
                              document.getElementById("area")
                            }
                            showSearch
                            style={{ width: Tablet ? 150 : 300 }}
                            placeholder="Search to Select"
                            optionFilterProp="children"
                            value="All Agents"
                            open={true}
                            listItemHeight={4}
                            listHeight={130}
                            className="border border-gray fs-bold-14"
                          >
                            {agents?.map((data) => (
                              <Option value={data.user_id}>
                                {data.first_name} {data.last_name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                        <div className={Tablet ? "col-5" : "col-4"}>
                          <label className="py-2 mx-2">
                            <b>Tagged Agents</b>
                          </label>
                          <br />
                          <Select
                            showSearch
                            optionFilterProp="children"
                            style={{ width: Tablet ? 150 : 300 }}
                            placeholder="Search Tagged Agents"
                            open={true}
                            value="Tagged Agents"
                            listItemHeight={4}
                            listHeight={130}
                            className="border fs-bold-14 border-gray"
                            getPopupContainer={() =>
                              document.getElementById("area")
                            }
                          >
                            {selectedAgentNames?.map((data) => (
                              <Option value={data}>{data}</Option>
                            ))}
                          </Select>
                        </div>
                      </div>

                      <div className="row mt-4 border-bottom-gray pb-4">
                        <div className="col-2"></div>
                        <div className="col-4">
                          <Button className="px-3 fs-pink border-pink rounded btn-svg">
                            <AddGroup fill="#ef0855" />
                            <span className="mx-2">Invite Agents</span>
                          </Button>
                          <Button
                            className={
                              "px-3 fs-pink border-pink rounded btn-svg" +
                              (Tablet ? " mt-2" : " mx-2")
                            }
                          >
                            <CalenderRed />
                            <span className="mx-2">Staffing Calendar</span>
                          </Button>
                        </div>
                      </div>
                    </section>
                    <section>
                      <div className="row mt-4">
                        <div className="col-12 mt-4">
                          <h6 className="font-pink fw-bolder fs-20">Assets</h6>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className={Tablet ? "col-12" : "col-2"}></div>
                        {assetsArray ? (
                          <div
                            className={
                              Tablet ? "col-6" : "col-4 assets-scroll-div"
                            }
                          >
                            {assetsArray &&
                              assetsArray.map((elem) => (
                                <>
                                  <div
                                    className={Tablet ? "mt-4" : " py-2"}
                                    onClick={() => setViewVisible(elem)}
                                  >
                                    <div className="row border-gray rounded py-1">
                                      <div className="col-1 navigation-reception-cube">
                                        <Cube fill="#ef0855" />
                                      </div>
                                      <div className="col-11">
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            <span className="fs-18 mx-2">
                                              <b>{elem.asset_name}</b>
                                            </span>
                                          </div>
                                          <div>
                                            <span className="px-3">
                                              <EditRed />
                                            </span>
                                            <span className="px-3">
                                              <Bin />
                                            </span>
                                          </div>
                                        </div>
                                        <div className="d-flex">
                                          <span className="fs-16 mx-2">
                                            {elem.asset_description}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                          </div>
                        ) : null}

                        {viewVisible ? (
                          <DetailComponent assetData={viewVisible} />
                        ) : null}
                      </div>
                    </section>
                  </div>
                  <div
                    className="shadow-sm p-3 d-flex justify-content-between"
                    style={{ backgroundColor: "#eeeeee" }}
                  >
                    <Button htmlType="submit" type="primary"></Button>
                    <Link to="/zonemanagement">
                      <Button className="rounded button-pink px-3">
                        Cancel
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>
            )}
            {accessDenied === true && (
              <Link to="/zonemanagement">
                <Button className="px-3 d-block button-pink rounded mt-2">
                  Cancel
                </Button>
              </Link>
            )}
          </form>
        )}
      </Formik>
    );
};

export default ViewZone;
