import React, { useState, useEffect } from "react";
import { Select, Switch, Button, Modal, Checkbox } from "antd";
import { CKEditor } from "ckeditor4-react";
import { useMediaQuery } from "react-responsive";
import "../index.css";
import {
  getCountryList,
  getJobTitles,
  getRelations,
} from "../../../utils/commonApis";
import { isEmpty } from "lodash-es";

const RegistrationAndCommunication = ({ data }) => {
  const Tablet = useMediaQuery({ minWidth: 1000, maxWidth: 1025 });
  const [viewData, setViewData] = useState({
    searchesData: [],
    calendarData: [],
    initiateDataText: [],
    initiateDataVideo: [],
    inviteData: [],
    profileData: [],
  });
  const [countryList, setCountryList] = useState([]);
  const [jobTitleList, setJobTitleList] = useState([]);
  const [countryDataCollection, setCountryDataCollection] = useState([]);
  const [jobTitlesCollection, setJobTitlesCollection] = useState([]);
  const [countryModalVisible, setCountryModalVisible] = useState(false);
  const [jobModalVisible, setJobModalVisible] = useState(false);
  const [countryIdsLocal, setCountryIdsLocal] = useState(
    data?.country_collection_ids
  );
  const [jobTitleIdsLocal, setJobTitleIdsLocal] = useState(
    data?.job_title_collection_ids
  );

  useEffect(() => {
    async function stateLoad() {
      window.scrollTo(0, 0);

      const countryData = await getCountryList();
      setCountryDataCollection(countryData);
      const jobTitlesData = await getJobTitles();
      setJobTitlesCollection(jobTitlesData);
      setCountryIdsLocal(data?.country_collection_ids);
      setJobTitleIdsLocal(data?.job_title_collection_ids);
    }
    stateLoad();
  }, []);

  useEffect(async () => {
    const searchesData = data?.events_registration_communications_settings
      ?.filter((item) => item?.part_of === "Searches")
      .map((item) => {
        return item;
      });

    const initiateDataText = data?.events_registration_communications_settings
      ?.filter((item) => item?.part_of === "Initiate 1 to 1 Text Chats")
      .map((item) => {
        return item;
      });
    const initiateDataVideo = data?.events_registration_communications_settings
      ?.filter((item) => item?.part_of === "Initiate 1 to 1 Video Chats")
      .map((item) => {
        return item;
      });
    const calendarData = data?.events_registration_communications_settings
      ?.filter((item) => item.part_of === "Calendar & Meetings")
      .map((item) => {
        return item;
      });
    const inviteData = data?.events_registration_communications_settings
      ?.filter((item) => item?.part_of === "Inviting Delegates")
      .map((item) => {
        return item;
      });

    const profileData = data?.registration_profile_settings
      ?.filter((item) => item.profile_type === "AGENT")
      .map((item) => {
        return item;
      });

    setCountryIdsLocal(data?.country_collection_ids);
    setJobTitleIdsLocal(data?.job_title_collection_ids);

    if (isEmpty(searchesData)) {
      const relationRes = await getRelations();

      setViewData({
        ...viewData,
        searchesData: relationRes.searchesData,
        initiateDataText: relationRes.initiateDataText,
        initiateDataVideo: relationRes.initiateDataVideo,
        calendarData: relationRes.calendarData,
        inviteData: relationRes.inviteData,
        profileData: relationRes.profileData,
      });
    } else {
      setViewData({
        searchesData,
        initiateDataText,
        initiateDataVideo,
        calendarData,
        inviteData,
        profileData,
      });
    }
  }, [data]);

  useEffect(() => {
    setCountryList(countryDataCollection);
  }, [countryDataCollection]);

  useEffect(() => {
    setJobTitleList(jobTitlesCollection);
  }, [jobTitlesCollection]);

  return (
    <div className="container-fluid view-register-and-com mt-80">
      {/* section registration collection data */}

      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">
              Registration Data Collection
            </h6>
          </div>
        </div>

        <div className="row mt-4">
          <div className={Tablet ? "col-7" : "col-6"}>
            <div className="row">
              <div className="col-2"></div>
              <div className="col-4 text-end mt-1">
                <label className="text-secondary">
                  <span>Country</span>
                </label>
                <Switch
                  defaultChecked={data?.country_collection}
                  className="mx-2"
                  disabled
                />
              </div>
              <div className="col-2 text-start">
                <Button
                  disabled={data && data.country_collection ? false : true}
                  className="px-3 button-pink rounded"
                  onClick={() => setCountryModalVisible(true)}
                >
                  View Dropdown
                </Button>
                <Modal
                  title="Countries"
                  visible={countryModalVisible}
                  onOk={() => setCountryModalVisible(false)}
                  onCancel={() => setCountryModalVisible(false)}
                >
                  <ul className="w-100" style={{ listStyleType: "none" }}>
                    {countryList &&
                      countryList.map((ele) => {
                        if (ele.country && ele.is_active)
                          return (
                            <li className="w-100 border my-2 px-2 py-1">
                              <span className="mx-2">
                                <Checkbox
                                  disabled
                                  checked={
                                    countryIdsLocal?.includes(ele.country_id)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              {ele.country}
                            </li>
                          );
                      })}
                  </ul>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        <div className="row mt-4">
          <div className={Tablet ? "col-7" : "col-6"}>
            <div className="row">
              <div className="col-2"></div>
              <div className={Tablet ? "col-5 " : "col-4 text-end mt-1"}>
                <label className="text-secondary">
                  <span>Allow 'other'</span>
                </label>
                <Switch
                  defaultChecked={data?.allow_other_country}
                  className="mx-2"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        <div className="row mt-4">
          <div className={Tablet ? "col-7" : "col-6"}>
            <div className="row">
              <div className="col-2"></div>
              <div className={Tablet ? "col-4" : "col-4 text-end mt-1"}>
                <label className="text-secondary">
                  <span>Job Title</span>
                </label>
                <Switch
                  defaultChecked={data?.job_title_collection}
                  className="mx-2"
                  disabled
                />
              </div>
              <div className="col-2 text-start">
                <Button
                  disabled={data && data.job_title_collection ? false : true}
                  className="px-3 button-pink rounded"
                  onClick={() => setJobModalVisible(true)}
                >
                  View Dropdown
                </Button>
                <Modal
                  title="Job Titles"
                  visible={jobModalVisible}
                  onOk={() => setJobModalVisible(false)}
                  onCancel={() => setJobModalVisible(false)}
                >
                  <ul style={{ listStyleType: "none" }} className="px-4">
                    {jobTitleList &&
                      jobTitleList.map((ele) => {
                        if (ele.job_title && ele.is_active)
                          return (
                            <li className="w-100 border my-2 px-2 py-1">
                              <span className="mx-2">
                                <Checkbox
                                  disabled
                                  checked={
                                    jobTitleIdsLocal?.includes(ele.job_title_id)
                                      ? true
                                      : false
                                  }
                                />
                              </span>
                              {ele.job_title}
                            </li>
                          );
                      })}
                  </ul>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>
        <div className="row mt-4">
          <div className={Tablet ? "col-7" : "col-6"}>
            <div className="row">
              <div className="col-2"></div>
              <div className={Tablet ? "col-5 " : "col-4 text-end mt-1"}>
                <label className="text-secondary">
                  <span>Allow 'other'</span>
                </label>
                <Switch
                  defaultChecked={data?.allow_other_job_title}
                  className="mx-2"
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="col-6"></div>
        </div>

        {data?.event_registration_terms_and_conditions_settings?.map(
          (item, i) => {
            return (
              <>
                <div className="row mt-4">
                  <div className={Tablet ? "col-9" : "col-6"}>
                    <div className="row">
                      <div className={Tablet ? "col-1" : "col-2"}></div>
                      <div
                        className={
                          Tablet
                            ? "col-4 text-start mx-4"
                            : "col-4 text-end mt-1"
                        }
                      >
                        <label className="text-secondary">
                          <span>CheckBox {[i + 1]}</span>
                        </label>
                        <Switch
                          disabled
                          defaultChecked={item?.is_active}
                          className="mx-2"
                        />
                      </div>
                      <div className="col-4 text-start mt-1">
                        <span className="fw-bolder font-size-16">
                          Add Text and Link
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ck editor */}

                <div disabled className="row mt-4">
                  <div className="col-3"></div>
                  <div className="col-9">
                    {item?.is_active ? (
                      <CKEditor
                        disabled
                        readonly
                        data={item?.term_description}
                        initData={item?.term_description}
                        // style={{ cursor: "not-allowed" }}
                        config={{
                          contentsCss: "./registration.css",
                          readOnly: true,
                          resize_enabled: false,
                          disabled: true,
                          toolbarGroups: [
                            { name: "others" },
                            "/",
                            {
                              name: "basicstyles",
                              groups: ["basicstyles", "cleanup"],
                            },
                            { name: "links" },
                            {
                              name: "paragraph",
                              groups: [
                                "list",
                                "indent",
                                "blocks",
                                "align",
                                "bidi",
                              ],
                            },
                            { name: "colors" },
                            { name: "about" },
                          ],
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              </>
            );
          }
        )}

        {data ? (
          data?.event_id ? (
            <>
              <div className="row mt-4 border-bottom-gray pb-5 mb-2">
                <div className={Tablet ? "col-6" : "col-3 text-end mt-1"}>
                  <label className="text-secondary">
                    <span>Registration Survey</span>
                  </label>
                  <Switch
                    checked={
                      data && data.is_registration_survey_active
                        ? data.is_registration_survey_active
                        : false
                    }
                    disabled={true}
                    className="mx-2"
                  />
                </div>
                {data && data?.is_registration_survey_active ? (
                  <>
                    <div className="col-4 text-start mt-1">
                      <span className="fw-bolder font-size-16">
                        <Select
                          placeholder="None"
                          defaultValue={
                            data?.eventRegistrationSettings_survey_id
                              ?.survey_title
                          }
                          className="w-100 fs-bold-14"
                          allowClear
                          disabled
                        ></Select>
                      </span>
                    </div>
                  </>
                ) : null}
                <div className="col-6"></div>
              </div>
            </>
          ) : null
        ) : null}
      </section>

      {/* section profile section */}

      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Profiles</h6>
          </div>
          <div className="col-12 my-2">
            <span className="fs-16">
              NB: Select the MINIMUM tier that each functionality is available
              for, all tiers above the tier selected will also have
              functionality enabled.
            </span>
          </div>

          <div className="col-6 my-3">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Agent Profiles</span>
                </label>
              </div>
              <div className="col-6 text-start mt-1">
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    disabled
                    defaultValue={
                      data &&
                      data?.registration_profile_settings &&
                      data?.registration_profile_settings[0] &&
                      data?.registration_profile_settings[0]?.tier_name
                        ? data?.registration_profile_settings[0]?.tier_name
                        : null
                    }
                    className="w-100 fs-bold-14"
                    listHeight={120}
                    listItemHeight={4}
                  ></Select>
                </span>
              </div>
            </div>
          </div>
          <div className="col-6 my-3">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Agent Social Links</span>
                </label>
              </div>
              <div className="col-6 text-start mt-1">
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    disabled
                    defaultValue={
                      data &&
                      data?.registration_profile_settings &&
                      data?.registration_profile_settings[0] &&
                      data?.registration_profile_settings[0]?.social_link_tier
                        ?.tier
                        ? data?.registration_profile_settings[0]
                            ?.social_link_tier?.tier
                        : null
                    }
                    className="w-100 fs-bold-14"
                    listHeight={120}
                    listItemHeight={4}
                  ></Select>
                </span>
              </div>
            </div>
          </div>
          <div className="col-6 my-2">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Delegate Profiles</span>
                </label>
              </div>
              <div className="col-6 text-start mt-1">
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    disabled
                    defaultValue={
                      data &&
                      data.registration_profile_settings &&
                      data?.registration_profile_settings[1] &&
                      data?.registration_profile_settings[1]?.tier_name
                        ? data?.registration_profile_settings[1]?.tier_name
                        : null
                    }
                    className="w-100 fs-bold-14"
                    listHeight={120}
                    listItemHeight={4}
                  ></Select>
                </span>
              </div>
            </div>
          </div>
          <div className="col-6 my-2">
            <div className="row">
              <div className="col-2"></div>
              <div className="col-3 mt-1 text-end">
                <label className="text-secondary mt-1">
                  <span>Delegate Social Links</span>
                </label>
              </div>
              <div className="col-6 text-start mt-1">
                <span className="fw-bolder font-size-16">
                  <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    placeholder="None"
                    disabled
                    defaultValue={
                      data &&
                      data.registration_profile_settings &&
                      data?.registration_profile_settings[1] &&
                      data?.registration_profile_settings[1]?.social_link_tier
                        ?.tier
                        ? data?.registration_profile_settings[1]
                            ?.social_link_tier?.tier
                        : null
                    }
                    className="w-100 fs-bold-14"
                    listHeight={120}
                    listItemHeight={4}
                  ></Select>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="border-bottom-gray pb-4"></div>
        {/* Old Profile View Code Commented For Back Up */}
        {/* <div className="border-bottom-gray pb-4">
            {data?.registration_profile_settings?.map((item, index) => {
            
              return (
                <div className="row mt-4 mb-4">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-3 mt-1 text-end">
                        <label className="text-secondary mt-1">
                          <span>
                            {item.profile_type === "AGENT"
                              ? "Agent Profiles11"
                              : "Delegate Profiles"}
                          </span>
                        </label>
                      </div>
                      <div className="col-6 text-start mt-1">
                        <span className="fw-bolder font-size-16">
                          <Select
                            placeholder="None"
                            defaultValue={item.tier_name}
                            className="w-100 fs-bold-14"
                            // allowClear
                            disabled
                            readonly
                          ></Select>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="row">
                      <div className="col-2"></div>
                      <div className="col-4 mt-1 text-end">
                        <label className="text-secondary mt-1">
                          <span>
                            {item.profile_type === "AGENT"
                              ? "Agent Social Links"
                              : "Delegate Social Links"}
                          </span>
                        </label>
                      </div>
                      <div className="col-6 text-start mt-1">
                        <span className="fw-bolder font-size-16">
                          <Select
                            placeholder="None"
                            defaultValue={item?.social_link_tier_name}
                            className="w-100 fs-bold-14"
                            // allowClear
                            disabled
                            readonly
                          ></Select>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
      </section>

      {/* section Searches  */}
      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Searches</h6>
          </div>
        </div>

        <div className="row mt-4 pb-4 border-bottom-gray">
          {viewData.searchesData?.map((item, index) => {
            return (
              <div className="col-6" style={{ marginBottom: "20px" }}>
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* Section Initiate 1-1  */}

      <section>
        <div className="row">
          {/* <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Initiate 1-1</h6>
            </div> */}
          <div className="col-6 mt-4">
            <h6 className="font-pink fw-bolder fs-20">
              Initiate 1-1 Text Chats (from Profile/Search )
            </h6>
          </div>
          <div className="col-6 mt-4">
            <h6 className="font-pink fw-bolder fs-20 mx-4">
              Initiate Video Calls (from Profile/Search/Chat)
            </h6>
          </div>
        </div>

        <div className="row">
          <div className="col-6 text-end">
            <span className="mx-5">
              {/* <b>Text Chats </b> (From Profile / Search) */}
            </span>
            <span className="pb-4">
              {/* <Tooltip
                  placement="right"
                  title="Networking only?"
                  color="#ef0855"
                >
                  <img
                    src={info}
                    alt="info"
                    className="registration-and-communication-info-logo p-2"
                  />
                </Tooltip> */}
            </span>
          </div>
          <div className="col-6 text-end">
            <span className="mx-5">
              {/* <b>Video </b> (From Profile / Search) */}
            </span>
            <span className="pb-4">
              {/* <Tooltip
                  placement="right"
                  title="Networking only?"
                  color="#ef0855"
                >
                  <img
                    src={info}
                    alt="info"
                    className="registration-and-communication-info-logo p-2"
                  />
                </Tooltip> */}
            </span>
          </div>
        </div>

        <div className="row mt-4">
          {viewData?.initiateDataText?.slice(0, 1).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}

          {viewData.initiateDataVideo?.slice(0, 1).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-4">
          {viewData?.initiateDataText?.slice(1, 2).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}

          {viewData?.initiateDataVideo?.slice(1, 2).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-4">
          {viewData?.initiateDataText?.slice(2, 3).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}

          {viewData?.initiateDataVideo?.slice(2, 3).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="row mt-4 pb-4 border-bottom-gray">
          {viewData?.initiateDataText?.slice(3, 4).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}

          {viewData?.initiateDataVideo?.slice(3, 4).map((item, index) => {
            return (
              <div className="col-6">
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* section Calender and Meetings */}

      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Calendars & Meetings</h6>
          </div>
        </div>

        <div className="row mt-4 pb-4 border-bottom-gray">
          {viewData?.calendarData?.map((item, index) => {
            return (
              <div className="col-6" style={{ marginBottom: "20px" }}>
                <div className="row">
                  <div className="col-5 text-end mt-1 px-2">
                    <label className="text-secondary">
                      {item?.relation_title}
                    </label>
                    <Switch
                      defaultChecked={item?.is_networking_only}
                      className="mx-2 d-none"
                      disabled
                      readonly
                    />
                  </div>
                  <div className="col-6 mt-1 text-start">
                    <Select
                      placeholder="None"
                      defaultValue={item?.tier}
                      className="w-100 fs-bold-14"
                      // allowClear
                      disabled
                      aria-readonly
                    ></Select>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>

      {/* Section Inviting Delegates */}

      <section>
        <div className="row">
          <div className="col-12 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Inviting Delegates</h6>
          </div>
        </div>

        <div className="row mt-4 mb-4">
          {viewData?.inviteData?.map((item, i) => {
            return (
              <>
                <div className="col-6">
                  <div className="row">
                    <div className="col-5 text-end mt-1 px-2" key={i}>
                      <label className="text-secondary">
                        {item?.relation_title}
                      </label>
                    </div>
                    <div className="col-6 mt-1 text-start" key={i}>
                      <Select
                        // disabled={item.is_networking_only === false ? false : true}
                        defaultValue={item?.tier}
                        placeholder="None"
                        className="w-100 fs-bold-14"
                        disabled
                        readonly
                        listHeight={120}
                        listItemHeight={4}
                      ></Select>
                    </div>
                  </div>
                </div>
              </>
              // <div className="col-6">
              //   <div className="row">
              //     <div className="col-1"></div>
              //     <div className="col-5 mt-1 text-end">
              //       <label className="text-secondary">
              //         <span>{item.relation?.relation_title}</span>
              //       </label>
              //     </div>
              //     <div className="col-6 text-start mt-3">
              //       <span className="fw-bolder font-size-16 mt-2">
              //         <Select
              //           placeholder="Select Minimum Tier"
              //           className="w-100"
              //            allowClear
              //            disabled
              //           aria-readonly
              //           defaultValue={item.tier?.tier}
              //         >

              //         </Select>
              //       </span>
              //     </div>
              //   </div>
              // </div>
            );
          })}
        </div>
      </section>

      {/*end  */}
    </div>
  );
};

export default RegistrationAndCommunication;
