import React, { useEffect, useState } from "react";
import { Table, Switch, Select } from "antd";
import "./Tiers.css";
import { getTiers } from "../../../utils/commonApis";

const Tiers = ({ tierData }) => {
  const { Option } = Select;
  const [tiersData, setTiersData] = useState([]);

  useEffect(() => {
    async function stateLoad() {
      window.scrollTo(0, 0);
      const tiersDefaultAPIData = await getTiers();
      setTiersData(tiersDefaultAPIData);
    }
    stateLoad();
  }, []);

  const columns = [
    {
      title: "",
      dataIndex: "name",
      key: "name",
      className: "text-end",
    },
    {
      title: "Diamond",
      dataIndex: "diamond",
      key: "diamond",
      className: tierData[0]?.is_selected
        ? "column-select text-center font-weight-bold"
        : "text-center",
    },
    {
      title: "Platinum",
      dataIndex: "platinum",
      key: "address",
      className: tierData[1]?.is_selected
        ? "column-select text-center font-weight-bold"
        : "text-center",
    },
    {
      title: "Gold",
      key: "gold",
      dataIndex: "gold",
      className: tierData[2]?.is_selected
        ? "column-select text-center font-weight-bold"
        : "text-center",
    },
    {
      title: "Silver",
      key: "silver",
      dataIndex: "silver",
      className: tierData[3]?.is_selected
        ? "column-select text-center font-weight-bold"
        : "text-center",
    },
    {
      title: "Bronze",
      dataIndex: "bronze",
      key: "bronze",
      className: tierData[4]?.is_selected
        ? "column-select text-center font-weight-bold"
        : "text-center",
    },
  ];

  const getDDL = (index, key, placeHolder) => {
    return (
      <div className="view-all-common">
        <Select
          placeholder={placeHolder}
          className="w-75 fs-bold-14 bg-light"
          style={{ backgroundColor: "white" }}
          defaultValue={tierData[index][key]}
          disabled
        >
          {tiersData.map((item, i) => {
            return <Option value={item.tier_id}>{item.tier}</Option>;
          })}
        </Select>
      </div>
    );
  };

  const renderNumberField = (index, key) => {
    return (
      <div
        // aria-readonly
        disabled
        className="btn-group mx-auto border-gray rounded"
        role="group"
        aria-label="Second group"
      >
        <button type="button" className="btn background-white-btn">
          -
        </button>
        <button disabled type="button" className="btn background-white-btn">
          {tierData[index][key] ?? 0}
        </button>
        <button disabled type="button" className="btn background-white-btn">
          +
        </button>
      </div>
    );
  };

  const renderSwitch = (index, key) => {
    return (
      <span className="mx-4">
        <Switch disabled defaultChecked={tierData[index][key]} />
      </span>
    );
  };

  const data = [
    {
      key: "1",
      name: "Booth Template",
      diamond: getDDL(0, "booth_template_id", "Gold"),
      platinum: getDDL(1, "booth_template_id", "Diamond"),
      gold: getDDL(2, "booth_template_id", "None"),
      silver: getDDL(3, "booth_template_id", "None"),
      bronze: getDDL(4, "booth_template_id", "None"),
    },
    {
      key: "2",
      name: "No. of Agents",
      diamond: renderNumberField(0, "no_of_agents"),
      platinum: renderNumberField(1, "no_of_agents"),
      gold: renderNumberField(2, "no_of_agents"),
      silver: renderNumberField(3, "no_of_agents"),
      bronze: renderNumberField(4, "no_of_agents"),
    },
    {
      key: "3",
      name: "No. of Zones",
      diamond: renderNumberField(0, "no_of_zones"),
      platinum: renderNumberField(1, "no_of_zones"),
      gold: renderNumberField(2, "no_of_zones"),
      silver: renderNumberField(3, "no_of_zones"),
      bronze: renderNumberField(4, "no_of_zones"),
    },
    {
      key: "4",
      name: "No. of Assets per zone",
      diamond: renderNumberField(0, "no_of_assets_per_zone"),
      platinum: renderNumberField(1, "no_of_assets_per_zone"),
      gold: renderNumberField(2, "no_of_assets_per_zone"),
      silver: renderNumberField(3, "no_of_assets_per_zone"),
      bronze: renderNumberField(4, "no_of_assets_per_zone"),
    },
    {
      key: "5",
      name: "Video Demo Room",
      diamond: renderSwitch(0, "open_demo_rooms"),
      platinum: renderSwitch(1, "open_demo_rooms"),
      gold: renderSwitch(2, "open_demo_rooms"),
      silver: renderSwitch(3, "open_demo_rooms"),
      bronze: renderSwitch(4, "open_demo_rooms"),
    },
    {
      key: "6",
      name: "1-1 Video Call",
      diamond: renderSwitch(0, "new_video_rep"),
      platinum: renderSwitch(1, "new_video_rep"),
      gold: renderSwitch(2, "new_video_rep"),
      silver: renderSwitch(3, "new_video_rep"),
      bronze: renderSwitch(4, "new_video_rep"),
    },
    {
      key: "7",
      name: "Text Chat",
      diamond: renderSwitch(0, "chart_boards"),
      platinum: renderSwitch(1, "chart_boards"),
      gold: renderSwitch(2, "chart_boards"),
      silver: renderSwitch(3, "chart_boards"),
      bronze: renderSwitch(4, "chart_boards"),
    },
    {
      key: "8",
      name: "Chat Bot",
      diamond: renderSwitch(0, "chat_bots"),
      platinum: renderSwitch(1, "chat_bots"),
      gold: renderSwitch(2, "chat_bots"),
      silver: renderSwitch(3, "chat_bots"),
      bronze: renderSwitch(4, "chat_bots"),
    },
    {
      key: "9",
      name: "On-Booth Leads",
      diamond: renderSwitch(0, "on_booth_leads"),
      platinum: renderSwitch(1, "on_booth_leads"),
      gold: renderSwitch(2, "on_booth_leads"),
      silver: renderSwitch(3, "on_booth_leads"),
      bronze: renderSwitch(4, "on_booth_leads"),
    },
    {
      key: "10",
      name: "Survey Builder",
      diamond: renderSwitch(0, "survey_builder"),
      platinum: renderSwitch(1, "survey_builder"),
      gold: renderSwitch(2, "survey_builder"),
      silver: renderSwitch(3, "survey_builder"),
      bronze: renderSwitch(4, "survey_builder"),
    },
    {
      key: "11",
      name: "No.of Sponsorship",
      diamond: renderNumberField(0, "no_of_sponserships"),
      platinum: renderNumberField(1, "no_of_sponserships"),
      gold: renderNumberField(2, "no_of_sponserships"),
      silver: renderNumberField(3, "no_of_sponserships"),
      bronze: renderNumberField(4, "no_of_sponserships"),
    },
    {
      key: "12",
      name: "Stream Polling",
      diamond: renderSwitch(0, "stream_polls"),
      platinum: renderSwitch(1, "stream_polls"),
      gold: renderSwitch(2, "stream_polls"),
      silver: renderSwitch(3, "stream_polls"),
      bronze: renderSwitch(4, "stream_polls"),
    },
    {
      key: "14",
      name: "Dashboard Level",
      diamond: getDDL(0, "dashboard_level", "Gold"),
      platinum: getDDL(1, "dashboard_level", "Diamond"),
      gold: getDDL(2, "dashboard_level", "None"),
      silver: getDDL(3, "dashboard_level", "None"),
      bronze: getDDL(4, "dashboard_level", "None"),
    },
  ];

  return (
    <div className="ant-layout-content view-register-and-com mt-80">
      <Table
        className="table-striped mb-4"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    </div>
  );
};

export default Tiers;
