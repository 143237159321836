import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Select,
  Switch,
  Input,
  DatePicker,
  Collapse,
  Tooltip,
} from "antd";
import { Modal, TimePicker } from "antd";
import {
  MinusOutlined,
  PlusOutlined,
  CloseOutlined,
  DeleteOutlined,
  EyeOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { iconSelected, bin } from "../../../components/Images/Images";
import { useMediaQuery } from "react-responsive";
import {
  CalenderGrey,
  TimeCalendar,
} from "../../../components/svgJS/CalenderRed";
import { AddGroup } from "../../../components/svgJS/AddGroup";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getCustomerList } from "../../../redux/slice/customerData";
import { getBrand } from "../../../redux/slice/brandManagementSlice";
import FileUpload from "../../common/FileUpload";
import _, { cloneDeep, differenceWith, isEmpty, isEqual } from "lodash";
import CreateNewBrand from "../../brandManagement/CreateNewBrand";
import SimpleReactValidator from "simple-react-validator";
import { dialogs } from "../../../components/constsnts/string.constants";
import { toast } from "react-toastify";
import {
  compareAllDatesWithEveryArrayElement,
  compareAllStartEndDates,
  compareAllStartEndTimes,
  createTZString,
  getDatesBetweenDates,
  getDisabledTime,
  isImage,
  isValidImage,
} from "../../../utils/commonFunctions";
import {
  getSurveys,
  getBrandDropdowns,
  getUserTypeAgents,
} from "../../../utils/commonApis";
import RedAsterick from "../../../components/Widgets/RedAsterick";
import "./index.css";
import AddNewSurvey from "../../surveyManagement/AddNewSurvey";
import ReactPlayer from "react-player/lazy";
import { LOGO_DEMO } from "../../../components/constsnts/common.constants";
import AddUser from "../../UserManagement/AddUser";
import {
  INPUT_TEXT_LIMITS,
  TEXTAREA_TEXT_LIMITS,
} from "../../../components/constsnts/common.constants";
import CommonLoader from "../../../components/Widgets/CommonLoader";
import CropImageModal from "../../common/Modal/CropImageModal";
import { Colorpicker } from "antd-colorpicker";
import ColorPickerInput from "../../common/ColorPickerInput";

// const ColorPickerInput = (props) => {
//   let { colorCode, onHandleColorChange } = props;
//   return (
//     <div className="eventtheme_antd_input_style border-gray rounded fs-bold-14 text-end">
//       <span className="w-90 text-center">{colorCode}</span>
//       <Colorpicker
//         picker={"ChromePicker"}
//         value={colorCode}
//         onChange={(color) => onHandleColorChange(color?.hex)}
//         popup
//         blockStyles={{
//           width: "25px",
//           height: "22px",
//           borderRadius: "5%",
//         }}
//       />
//     </div>
//   );
// };

const EventEditor = ({
  data,
  eventEligibility,
  onChangeData,
  onValid,
  initiateValidations,
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const { Panel } = Collapse;
  const { TextArea } = Input;
  const chatBotNewObj = {
    start_date: "",
    start_time: "",
    end_date: "",
    end_time: "",
    show_id: "",
    is_delete: false,
  };
  const validator = useRef(new SimpleReactValidator());
  var CryptoJS = require("crypto-js");
  const Tablet = useMediaQuery({ maxWidth: 1200 });
  const Mobile = useMediaQuery({ maxWidth: 500 });

  const [userType, setUserType] = useState("");
  const [customerData, setCustomerData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [customerStartDate, setCustomerStartDate] = useState("");
  const [customerEndDate, setCustomerEndDate] = useState("");
  const [agents, setAgents] = useState([]);
  const [selectedAgents, setSelectedAgents] = useState([]);
  const [agentsTechnical, setAgentsTechnical] = useState([]);
  const [selectedAgentsTechnical, setSelectedAgentsTechnical] = useState([]);
  const [surveyList, setSurveyList] = useState([]);
  const [agentsCollectionData, setAgentsCollectionData] = useState([]);
  const [chatBotDetails, setChatBotDetails] = useState([chatBotNewObj]);
  const [logoFileState, setLogoFileState] = useState(null);
  const [minimizedLogoFileState, setMinimizedLogoFileState] = useState(null);
  const [preEventImg, setPreEventImg] = useState(null);
  const [welcomeImg, setWelcomeImg] = useState(null);
  const [controlImg, setControlImg] = useState(null);
  const [isControlPreview, setIsControlPreview] = useState(false);
  const [isPreEventImgPreview, setIsPreEventImgPreview] = useState(false);
  const [isWelcomePreview, setisWelcomePreview] = useState(false);
  const [isLogoPreview, setIsLogoPreview] = useState(false);
  const [isMinimizedLogoPreview, setIsMinimizedLogoPreview] = useState(false);
  const [newBrandModal, setNewBrandModal] = useState(false);
  const [brandListDropdown, setBrandListDropdown] = useState([]);
  const [isSurveyModalVisible, setIsSurveyModalVisible] = useState(false);
  const [logoThumbNail, setLogoThumbNail] = useState(null);
  const [logoDemoVisible, setLogoDemoVisible] = useState(false);
  const [minimizedDemoLogoVisible, setMinimizedDemoLogoVisible] =
    useState(false);
  const [minimizedLogoThumbNail, setMinimizedLogoThumbNail] = useState(null);
  const [preEventThumbNail, setPreEventThumbNail] = useState(null);
  const [welcomeThumbNail, setWelcomeThumbNail] = useState(null);
  const [controlThumbNail, setControlThumbNail] = useState(null);
  const [isAddUserModalVisible1, setIsAddUserModalIsVisible1] = useState(false);
  const [isAddUserModalVisible2, setIsAddUserModalVisible2] = useState(false);
  const [brand_id, setBrand_id] = useState("");
  const [playing1, setPlaying1] = useState(false);
  const [playing2, setPlaying2] = useState(false);
  const [playing3, setPlaying3] = useState(false);
  const [loader, setLoader] = useState(true);
  const [regStartDate, setRegStartDate] = useState(
    data && data.registration_start_date_time
      ? moment(data.registration_start_date_time)
      : moment()
  );
  const [regEndDate, setRegEndDate] = useState(
    data && data.registration_end_date_time
      ? moment(data.registration_end_date_time)
      : moment()
  );

  const [showEventLogoCropModal, setShowEventLogoCropModal] = useState(false);
  const [cropImagePreview, setCropImagePreview] = useState();
  const [showEventMinimizeLogoCropModal, setShowEventMinimizeLogoCropModal] =
    useState(false);

  const [openUserList1, setOpenUsersList1] = useState(false);

  const [openUserList2, setOpenUsersList2] = useState(false);

  useEffect(async () => {
    const ciphertext = localStorage.getItem("user_type");
    var bytes = CryptoJS.AES.decrypt(ciphertext, "user_type");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    setUserType(decryptedData);

    const brandListForDropdown = await getBrandDropdowns(
      data?.event_id ? data?.event_id : ""
    );
    if (data.event_id) setBrandListDropdown(brandListForDropdown);
    let newParamsForAgents = "";
    if (data?.event_id) {
      newParamsForAgents = {
        event_id: data?.event_id,
      };

      if (data?.organizer_brand_id) {
        newParamsForAgents["brand_id"] = data?.organizer_brand_id;
        setBrand_id(data?.organizer_brand_id);
      }
    }
    const agentListAPI = await getUserTypeAgents(newParamsForAgents);
    setAgentsCollectionData(agentListAPI);

    dispatch(getBrand());
    const surveyData = await getSurveys({
      event_id: data.event_id ?? null,
      for_event: true,
    });
    setSurveyList(surveyData);
    const responseData = await dispatch(getCustomerList({ is_details: true }));
    if (responseData?.payload?.rows) setCustomerData(responseData.payload.rows);
    setChatbotAudiExpoNetworkFlags(data);

    if (data && data.event_logo_url) {
      let logoFileName = data.event_logo_url.split("\\").pop().split("/").pop();

      if (data.event_logo_original_filename)
        logoFileName = data.event_logo_original_filename;

      setLogoFileState({
        name: logoFileName,
        event_logo_url: data.event_logo_url,
      });
    }

    if (data && data.minimized_logo_url) {
      let logoFileName = data.minimized_logo_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data.minimized_logo_original_filename)
        logoFileName = data.minimized_logo_original_filename;

      setMinimizedLogoFileState({
        name: logoFileName,
        minimized_logo_url: data.minimized_logo_url,
      });
    }

    if (data && data.pre_event_messages.pre_event_media_url) {
      let logoFileName = data.pre_event_messages.pre_event_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data.pre_event_messages.pre_event_media_original_filename)
        logoFileName =
          data.pre_event_messages.pre_event_media_original_filename;

      setPreEventImg({
        name: logoFileName,
        pre_event_media_url: data.pre_event_messages.pre_event_media_url,
      });
    }

    if (data && data.welcome_messages.welcome_media_url) {
      let logoFileName = data.welcome_messages.welcome_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data.welcome_messages.welcome_media_original_filename)
        logoFileName = data.welcome_messages.welcome_media_original_filename;

      setWelcomeImg({
        name: logoFileName,
        welcome_media_url: data.welcome_messages.welcome_media_url,
      });
    }

    if (data && data.control_media_url) {
      let logoFileName = data.control_media_url
        .split("\\")
        .pop()
        .split("/")
        .pop();

      if (data && data.control_media_original_filename)
        logoFileName = data.control_media_original_filename;

      setControlImg({
        name: logoFileName,
        control_media_url: data.control_media_url,
      });
    }

    setLoader(false);
  }, []);

  useEffect(() => {
    if (customerData) setLocalCustomerName(data?.customer_id);
  }, [customerData]);

  useEffect(() => {
    if (initiateValidations) {
      handleFormSubmit();
    }
  }, [initiateValidations]);

  useEffect(() => {
    if (data) setChatbotAudiExpoNetworkFlags(data);
  }, [data]);

  const setChatbotAudiExpoNetworkFlags = (data) => {
    if (data && !isEmpty(data.event_shows_details))
      setChatBotDetails(data?.event_shows_details);
  };

  useEffect(() => {
    if (!isEmpty(agentsCollectionData)) setAgentsFromAPIAndUseEffect(data);
  }, [agentsCollectionData]);

  const handleFormSubmit = () => {
    const areEventDatesProper = compareAllStartEndDates(chatBotDetails);
    const areAllDatesDifferent =
      compareAllDatesWithEveryArrayElement(chatBotDetails);
    const areEventTimesProper = compareAllStartEndTimes(chatBotDetails);

    if (validator.current.allValid()) {
      if (areEventDatesProper === "no" || areAllDatesDifferent === "no") {
        toast.error("Please re check event dates");
        onValid(false);
      } else if (areEventTimesProper === "no") {
        toast.error("Please re check event times");
        onValid(false);
      } else if (
        moment(data.registration_end_date_time) <
        moment(data.registration_start_date_time)
      ) {
        toast.error("Please change registration end date");
        onValid(false);
      } else onValid(true);
    } else {
      onValid(false);
      validator.current.showMessages();
      toast.error(dialogs.missingFields);
    }
  };

  const setAgentsFromAPIAndUseEffect = (dataHere) => {
    if (
      dataHere &&
      !isEmpty(dataHere.event_support) &&
      !isEmpty(dataHere.event_support.agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      let leftList = [];
      let rightList = [];

      dataHere.event_support.agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let agents_list = clonedAgents;
          let selectedAgents_list = cloneDeep(rightList);

          let index = agents_list.findIndex((agent) => agent.user_id === val);

          selectedAgents_list.push(agents_list[index]);

          agents_list.splice(index, 1);

          selectedAgents_list = _.uniqWith(selectedAgents_list, _.isEqual);

          leftList = [...agents_list];
          rightList = [...selectedAgents_list];
        }
      });
      if (isEmpty(leftList) && isEmpty(rightList)) {
        setAgents(agentsCollectionData);
      } else {
        setAgents(leftList);
        setSelectedAgents(rightList);
      }
    } else setAgents(agentsCollectionData);

    if (
      dataHere &&
      !isEmpty(dataHere.event_technical_support) &&
      !isEmpty(dataHere.event_technical_support.agents)
    ) {
      let clonedAgents = cloneDeep(agentsCollectionData);
      let leftList = [];
      let rightList = [];

      dataHere.event_technical_support.agents.map((val) => {
        if (
          clonedAgents &&
          clonedAgents.some((agent) => agent.user_id === val)
        ) {
          let technical_agents_list = clonedAgents;
          let technical_selectedAgents_list = rightList;
          let index = technical_agents_list.findIndex(
            (agent) => agent.user_id === val
          );
          technical_selectedAgents_list.push(technical_agents_list[index]);
          technical_agents_list.splice(index, 1);
          technical_selectedAgents_list = _.uniqWith(
            technical_selectedAgents_list,
            _.isEqual
          );
          leftList = [...technical_agents_list];
          rightList = [...technical_selectedAgents_list];
        }
      });

      if (isEmpty(leftList) && isEmpty(rightList)) {
        setAgentsTechnical(agentsCollectionData);
      } else {
        setAgentsTechnical(leftList);
        setSelectedAgentsTechnical(rightList);
      }
    } else {
      setAgentsTechnical(agentsCollectionData);
    }
  };

  const addChatBot = async () => {
    const chatbot_arr = cloneDeep(chatBotDetails);
    let finalArr = [];
    if (!chatbot_arr || !isEmpty(chatbot_arr)) {
      let deletedArr = [];
      chatbot_arr &&
        chatbot_arr.forEach((ele) => {
          if (!ele.is_delete) finalArr.push(ele);
          else deletedArr.push(ele);
        });
      finalArr.push(chatBotNewObj);
      if (deletedArr) finalArr = [...finalArr, ...deletedArr];
    } else finalArr = chatbot_arr;
    await onChangeData({ event_shows_details: finalArr });
  };

  const handleChatbots = async (index, key, value) => {
    const tempArr = cloneDeep(chatBotDetails);
    tempArr[index][key] = value;
    let finalArr = [];
    let deletedArr = [];
    let event_shows_details_arr = [];
    if (key === "is_delete") {
      setChatBotDetails([]);
      tempArr &&
        tempArr.forEach((ele) => {
          if (!ele.is_delete) finalArr.push({ ...ele });
          else deletedArr.push(ele);
        });
      if (deletedArr) event_shows_details_arr = [...finalArr, ...deletedArr];
    } else event_shows_details_arr = tempArr;
    await onChangeData({ event_shows_details: event_shows_details_arr });
  };

  const onHandleChange = (key1, value, parentKey = "") => {
    if (key1 === "is_default_button_gradient_active") {
      if (value) {
        onChangeData(
          { [key1]: value, default_button_background_second_color: "#213cc4" },
          parentKey
        );
      } else {
        onChangeData(
          { [key1]: value, default_button_background_second_color: null },
          parentKey
        );
      }
    } else if (key1 === "is_highlight_button_gradient_active") {
      if (value) {
        onChangeData(
          {
            [key1]: value,
            highlight_button_background_second_color: "#213cc4",
          },
          parentKey
        );
      } else {
        onChangeData(
          { [key1]: value, highlight_button_background_second_color: null },
          parentKey
        );
      }
    } else {
      onChangeData({ [key1]: value }, parentKey);
    }
  };

  const setLocalCustomerName = (id) => {
    const filteredData =
      customerData && customerData.filter((item) => item.customer_id === id);

    const start_date_time = createTZString(
      filteredData[0]?.license_period_start_date
    );
    const end_date_time = createTZString(
      filteredData[0]?.license_period_end_date
    );
    const eleStartDate = moment(
      start_date_time.format("YYYY-MM-DD"),
      "YYYY-MM-DD"
    );

    const eleEndDate = moment(end_date_time.format("YYYY-MM-DD"), "YYYY-MM-DD");

    setCustomerName(filteredData[0]?.customer_name);
    setCustomerStartDate(eleStartDate?.format("DD-MM-YYYY"));
    setCustomerEndDate(eleEndDate?.format("DD-MM-YYYY"));
  };

  const onHandleChangeTemp = async (key1, value) => {
    setLocalCustomerName(value);
    onChangeData({ [key1]: value });
  };

  async function handleAgentsChange(value) {
    let agents_list = cloneDeep(agents);
    let selectedAgents_list = cloneDeep(selectedAgents);
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    const agentsIdArr = selectedAgents_list.map((elem) => elem.user_id);
    setSelectedAgents([...selectedAgents_list]);
    setAgents([...agents_list]);
    onHandleChange("agents", agentsIdArr, "event_support");
  }

  function handleSelectedAgentsChange(value) {
    let agents_list = cloneDeep(agents);
    let selectedAgents_list = cloneDeep(selectedAgents);
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgents([...agents_list]);
    setSelectedAgents([...selectedAgents_list]);
    const agentsIdArr = selectedAgents_list.map((elem) => elem.user_id);
    onHandleChange("agents", agentsIdArr, "event_support");
  }

  async function handleAgentsChangetechnical(value) {
    let agents_list = cloneDeep(agentsTechnical);
    let selectedAgents_list = cloneDeep(selectedAgentsTechnical);
    const filteredData = await agents_list.filter(
      (item) => item.user_id === value
    );
    let index = agents_list.findIndex((agent) => agent.user_id === value);
    selectedAgents_list.push(agents_list[index]);
    agents_list.splice(index, 1);
    const agentsIdArrTechnical = selectedAgents_list.map(
      (elem) => elem.user_id
    );
    onHandleChange("agents", agentsIdArrTechnical, "event_technical_support");
    setSelectedAgentsTechnical([...selectedAgents_list]);
    setAgentsTechnical([...agents_list]);
  }

  function handleSelectedAgentsChangeTechnical(value) {
    let agents_list = cloneDeep(agentsTechnical);
    let selectedAgents_list = cloneDeep(selectedAgentsTechnical);
    let index = selectedAgents_list.findIndex(
      (agent) => agent.user_id === value
    );
    agents_list.push(selectedAgents_list[index]);
    selectedAgents_list.splice(index, 1);
    setAgentsTechnical([...agents_list]);
    setSelectedAgentsTechnical([...selectedAgents_list]);
    const agentsIdArrTechnical = selectedAgents_list.map(
      (elem) => elem.user_id
    );
    onHandleChange("agents", agentsIdArrTechnical, "event_technical_support");
  }

  const handleLogoImageChange = (event) => {
    let file = event.target.files[0];
    const isValidLogo = isValidImage(file);
    if (isValidLogo) {
      setShowEventLogoCropModal(true);
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const deleteEventLogoFile = () => {
    setLogoFileState(null);
    onHandleChange("event_logo_file", null);
    setLogoThumbNail(null);
  };

  const handleMimizedLogoImageChange = (event) => {
    let file = event.target.files[0];
    const isValidLogo = isValidImage(file);
    if (isValidLogo) {
      setShowEventMinimizeLogoCropModal(true);
      setCropImagePreview(URL.createObjectURL(event.target.files[0]));
    }
  };

  const deleteEventMinimizedLogoFile = () => {
    setMinimizedLogoFileState(null);
    onHandleChange("minimized_logo_file", null);
    setMinimizedLogoThumbNail(null);
  };

  const handlePreImgChange = (event) => {
    onChangeData(
      {
        pre_event_message_media_file: event.target.files[0],
        pre_event_media_original_filename: event.target.files[0].name,
        pre_event_media_url: URL.createObjectURL(event.target.files[0]),
      },
      "pre_event_messages"
    );

    setPreEventImg({
      name: event.target.files[0].name,
      pre_event_media_url: URL.createObjectURL(event.target.files[0]),
    });
    setPreEventThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleWelcomeImgChange = (event) => {
    onChangeData(
      {
        welcome_message_media_file: event.target.files[0],
        welcome_media_original_filename: event.target.files[0].name,
        welcome_media_url: URL.createObjectURL(event.target.files[0]),
      },
      "welcome_messages"
    );

    setWelcomeImg({
      name: event.target.files[0].name,
      welcome_media_url: URL.createObjectURL(event.target.files[0]),
    });
    setWelcomeThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const handleControlImgChange = (event) => {
    onChangeData({
      controls_help_media_file: event.target.files[0],
      control_media_original_filename: event.target.files[0].name,
      control_media_url: URL.createObjectURL(event.target.files[0]),
    });

    setControlImg({
      name: event.target.files[0].name,
      control_media_url: URL.createObjectURL(event.target.files[0]),
    });
    setControlThumbNail(URL.createObjectURL(event.target.files[0]));
  };

  const saveEventLogoCroppedImage = async (croppedImageFile) => {
    onChangeData({
      event_logo_file: croppedImageFile,
      event_logo_original_filename: croppedImageFile?.name,
      event_logo_url: URL.createObjectURL(croppedImageFile),
    });
    setLogoFileState(croppedImageFile);
    setLogoThumbNail(URL.createObjectURL(croppedImageFile));
  };

  const saveEventMinimizeLogoCroppedImage = async (croppedImageFile) => {
    onChangeData({
      minimized_logo_file: croppedImageFile,
      minimized_logo_original_filename: croppedImageFile?.name,
      minimized_logo_url: URL.createObjectURL(croppedImageFile),
    });
    setMinimizedLogoFileState(croppedImageFile);
    setMinimizedLogoThumbNail(URL.createObjectURL(croppedImageFile));
  };

  if (loader) return <CommonLoader />;
  else if (data)
    return (
      <div className="container-fluid">
        <Modal
          title="Add Brand"
          footer={null}
          visible={newBrandModal}
          onCancel={() => setNewBrandModal(false)}
          width={1100}
        >
          <div style={{ padding: "20px" }}>
            <CreateNewBrand
              isFromEvent={true}
              event_id={data?.event_id}
              customer_id={data?.customer_id}
              onCancelFromModal={() => setNewBrandModal(false)}
              onSuccessFromModal={async () => {
                setNewBrandModal(false);
                dispatch(getBrand());
                const brandListForDropdown = await getBrandDropdowns(
                  data?.event_id ? data?.event_id : ""
                );
                if (data.event_id) setBrandListDropdown(brandListForDropdown);
              }}
            />
          </div>
        </Modal>
        <section>
          <div className="row border-bottom-gray pb-3 mb-2 mt-4">
            <h6 className="font-pink fw-bolder fs-20">Select Customer</h6>
            {/* <div className="col-1"></div> */}
            {userType && userType === "CUSTOMER" ? (
              <div className="col-5 text-end px-5 d-flex">
                <span className="mt-1">
                  Customer Name <RedAsterick />
                </span>
                <span>
                  <Input
                    name="Customer"
                    disabled
                    value={customerData[0]?.customer_name}
                    className="mx-4"
                    maxLength={INPUT_TEXT_LIMITS.MAX}
                  />
                  {validator.current.message(
                    "Customer",
                    data.customer_id,
                    "required",
                    { className: "text-danger py-1 mx-4" }
                  )}
                </span>
              </div>
            ) : (
              <>
                <div
                  className={
                    Tablet ? "col-3 text-end" : "col-2 text-end d-flex"
                  }
                  style={{ position: "relative" }}
                >
                  <span
                    className="mt-1 px-1 text-end"
                    style={{ position: "absolute", right: "0px" }}
                  >
                    Customer Name <RedAsterick />
                  </span>
                </div>
                <div className="col-3">
                  <span>
                    <Select
                      showSearch
                      optionFilterProp="children"
                      style={{
                        width: Tablet ? "100%" : 300,
                        marginLeft: "20px",
                      }}
                      placeholder="Search to Select"
                      onChange={(e) => {
                        onHandleChangeTemp("customer_id", e);
                      }}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                      defaultValue={data?.customer?.account_name}
                      value={data?.customer_id ? data.customer_id : null}
                      className={
                        validator?.current?.fields?.Customer === false &&
                        validator?.current?.messagesShown === true
                          ? "fs-bold-14 mx-1 border_red"
                          : "fs-bold-14 mx-1"
                      }
                      listHeight={120}
                      listItemHeight={4}
                      disabled={data?.event_id ? true : false}
                    >
                      {customerData &&
                        customerData.map((item) => {
                          return (
                            <Option
                              key={"customer_" + item.customer_id}
                              value={item.customer_id}
                            >
                              {item.customer_name}
                            </Option>
                          );
                        })}
                    </Select>
                    {validator.current.message(
                      "Customer",
                      data.customer_id,
                      "required",
                      { className: "text-danger py-1 mx-4" }
                    )}
                  </span>
                </div>
              </>
            )}

            <div className="col-6">
              <p className="d-flex">
                <span className="text-secondary mx-3">Account Name </span> :
                {userType && userType === "CUSTOMER" ? (
                  <span className="fw-bold mx-3">
                    {customerData[0]?.customer_name}
                  </span>
                ) : (
                  <span className="fw-bold mx-3">
                    {customerName ? customerName : data.customer?.account_name}
                  </span>
                )}
              </p>

              {userType === "CUSTOMER" ? (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {customerStartDate} - {customerEndDate}
                  </span>
                </p>
              ) : (
                <p className="d-flex">
                  <span className="text-secondary mx-3">License Period </span> :
                  <span className="fw-bold mx-3">
                    {`${
                      customerStartDate
                        ? customerStartDate
                        : data?.customer?.license_period_start_date
                        ? moment(
                            data?.customer?.license_period_start_date
                          ).format("DD-MM-YYYY")
                        : ""
                    } - ${
                      customerEndDate
                        ? customerEndDate
                        : data?.customer?.license_period_end_date
                        ? moment(
                            data?.customer?.license_period_end_date
                          ).format("DD-MM-YYYY")
                        : ""
                    }`}
                  </span>
                </p>
              )}
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Details </h6>
            </div>
          </div>
          <div className="row">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary">
                Active
              </label>
            </div>
            <div className="col-3">
              <Switch
                defaultChecked={data.is_active}
                // defaultChecked={true}
                onClick={() => onHandleChange("is_active", !data.is_active)}
              />
            </div>
          </div>
          <div className="row mt-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Event Name
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                id="event_name"
                className={
                  validator?.current?.fields?.EventName === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 border-gray rounded fs-bold-14 border_red"
                    : "w-100 border-gray rounded fs-bold-14"
                }
                defaultValue={data.event_name}
                onChange={(e) => onHandleChange("event_name", e.target.value)}
                onBlur={() => validator.current.showMessageFor("event_name")}
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
              {validator.current.message(
                "EventName",
                data?.event_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Sub domain
                <RedAsterick />
              </label>
            </div>
            <div className="col-3">
              <Input
                name="sub_domain"
                id="sub_domain"
                placeholder=""
                defaultValue={data.sub_domain}
                suffix={<img src={iconSelected} alt="icon" />}
                className={
                  validator?.current?.fields?.Subdomain === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 rounded fs-bold-14 border_red"
                    : "w-100 border-gray rounded fs-bold-14 "
                }
                onChange={(e) => {
                  onHandleChange("sub_domain", e.target.value);
                }}
                onBlur={() => validator.current.showMessageFor("sub_domain")}
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
              {validator.current.message(
                "Subdomain",
                data?.sub_domain,
                "required|alpha_num",
                { className: "text-danger py-1" }
              )}
            </div>
            {data?.event_id && (
              <div className="col-3 mt-1">
                <p
                  onClick={(e) => {
                    e?.stopPropagation()
                    e?.preventDefault()
                    window.open(data?.sub_domain_link, "_blank");
                  }}
                  className="text-primary w-auto d-inline subdomain_text_wrapper"
                >
                  <u>{process.env.REACT_APP_SUB_DOMAIN} </u>
                </p>
              </div>
            )}
          </div>

          <div className={data?.event_id ? "mt-4 row" : "mt-0"}>
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              {data?.event_id ? (
                <label htmlFor="" className="text-secondary">
                  Organiser
                </label>
              ) : null}
            </div>
            {data?.event_id ? (
              <div className="col-3">
                <Select
                  showSearch
                  optionFilterProp="children"
                  placeholder="Select Brand"
                  className="w-100 fs-bold-14"
                  value={
                    data && data.organizer_brand_id
                      ? data.organizer_brand_id
                      : null
                  }
                  getPopupContainer={(triggerNode) => {
                    return triggerNode.parentNode;
                  }}
                  onChange={(e) => {
                    onHandleChange("organizer_brand_id", e);
                    setBrand_id(e);
                  }}
                  listHeight={120}
                  listItemHeight={4}
                >
                  {brandListDropdown?.map((item, i) => {
                    return (
                      <Option
                        key={"brand_" + item.brand_id}
                        value={item.brand_id}
                      >
                        {item.brand_title}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            ) : null}
            {data?.event_id ? (
              <div className="col-6">
                <span>
                  <Switch
                    defaultChecked={data.is_booth_at_event}
                    onClick={(value) =>
                      onHandleChange("is_booth_at_event", value)
                    }
                    className="mx-3"
                  />
                  Do they have a booth at the event?
                </span>
              </div>
            ) : null}
          </div>

          <div className={data?.event_id ? "row mt-4" : ""}>
            <div className={Tablet ? "col-3" : "col-2"}></div>
            <div className="col-3 ">
              {data?.event_id && (
                <Button
                  className="px-2 button-pink rounded"
                  onClick={() => setNewBrandModal(true)}
                >
                  + Create New Brand
                </Button>
              )}
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Event Admin Name
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-3 pt-2" : "col-3"}>
              <Input
                id="main_contact_name"
                className={
                  validator?.current?.fields?.MainContactName === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 rounded fs-bold-14 border_red"
                    : "w-100 border-gray rounded fs-bold-14"
                }
                defaultValue={data.main_contact_name}
                onChange={(e) =>
                  onHandleChange("main_contact_name", e.target.value)
                }
                onBlur={() =>
                  validator.current.showMessageFor("main_contact_name")
                }
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
              {validator.current.message(
                "MainContactName",
                data?.main_contact_name,
                "required",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Event Admin Email Address
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-3 pt-2" : "col-3"}>
              <Input
                id="email"
                className={
                  validator?.current?.fields?.email === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 rounded fs-bold-14 border_red"
                    : "w-100 border-gray rounded fs-bold-14"
                }
                defaultValue={data.email_address}
                onChange={(e) =>
                  onHandleChange("email_address", e.target.value)
                }
                onBlur={() => validator.current.showMessageFor("email")}
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
              {validator.current.message(
                "email",
                data?.email_address,
                "required|email",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>
        </section>

        {/* section registration details*/}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">
                Registration Details
              </h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className={Tablet ? "col-5 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary">
                Start Date & Time
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-6" : "col-3"}>
              <DatePicker
                placement="center"
                allowClear={false}
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                suffixIcon={<CalenderGrey width="15" />}
                id="registration_start_date_time"
                showTime
                className={
                  validator?.current?.fields?.StartDateAndTime === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 border_red"
                    : "w-100 "
                }
                disabledDate={(d) => !d || d.isSameOrBefore(moment())}
                disabledTime={(d) => !d || d.isSameOrBefore(moment())}
                onChange={(e) => {
                  setRegStartDate(moment(e));

                  onHandleChange(
                    "registration_start_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                value={regStartDate}
                format={"DD/MM/YYYY HH:mm"}
                onSelect={(e) => {
                  setRegStartDate(moment(e));
                  onHandleChange(
                    "registration_start_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor(
                    "registration_start_date_time"
                  )
                }
              />
              {validator.current.message(
                "StartDateAndTime",
                data?.registration_start_date_time,
                "required",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>

          <div className="row mt-4 pb-3 border-bottom-gray">
            <div className={Tablet ? "col-5 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary">
                End Date & Time
                <RedAsterick />
              </label>
            </div>
            <div className={Tablet ? "col-6" : "col-3"}>
              <DatePicker
                getPopupContainer={(triggerNode) => {
                  return triggerNode.parentNode;
                }}
                allowClear={false}
                id="registration_end_date_time"
                disabledDate={(d) =>
                  !d ||
                  d.isSameOrBefore(moment(data?.registration_start_date_time))
                }
                disabledTime={(d) =>
                  !d || d.isSameOrBefore(data?.registration_start_date_time)
                }
                suffixIcon={<CalenderGrey width="15" />}
                showTime
                className={
                  validator &&
                  validator.current &&
                  validator.current.fields &&
                  validator.current.fields.EndDateAndTime === false &&
                  validator?.current?.messagesShown === true
                    ? "w-100 fs-bold-14 border_red"
                    : "w-100 fs-bold-14 "
                }
                value={regEndDate}
                format={"DD/MM/YYYY HH:mm"}
                onChange={(e) => {
                  setRegEndDate(moment(e));

                  onHandleChange(
                    "registration_end_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                onSelect={(e) => {
                  setRegEndDate(moment(e));
                  onHandleChange(
                    "registration_end_date_time",
                    moment(e).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
                onBlur={() =>
                  validator.current.showMessageFor("registration_end_date_time")
                }
              />
              {validator.current.message(
                "EndDateAndTime",
                data?.registration_end_date_time,
                "required",
                { className: "text-danger py-2" }
              )}
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 my-4">
              <h6 className="font-pink fw-bolder fs-20">Event Theme</h6>
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Event Colour Theme</div>
            <div className="button_color_fields">
              <ColorPickerInput
                colorCode={data?.event_color_theme}
                onHandleColorChange={(hexColor) => {
                  onHandleChange("event_color_theme", hexColor);
                }}
              />
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable ">Default Button Background</div>
            <div className="button_color_fields">
              <ColorPickerInput
                colorCode={data?.default_button_background_color}
                onHandleColorChange={(hexColor) => {
                  onHandleChange("default_button_background_color", hexColor);
                }}
              />

              <div className="flex_center">
                <label htmlFor="" className="text-secondary mt-1">
                  Gradient
                </label>
                <Switch
                  className="mx-2"
                  checked={data.is_default_button_gradient_active}
                  onChange={(val) => {
                    onHandleChange("is_default_button_gradient_active", val);
                  }}
                />
              </div>
              {data.is_default_button_gradient_active ? (
                <>
                  <div className="flex_center">
                    <label htmlFor="" className="text-secondary">
                      Angle
                    </label>
                    <Input
                      className="ms-2 event_input_color_angle"
                      value={data.default_button_gradient_angle}
                      onChange={(e) => {
                        onHandleChange(
                          "default_button_gradient_angle",
                          e.target.value
                        );
                      }}
                    />

                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap  mx-2"
                    >
                      Color
                    </label>

                    <ColorPickerInput
                      colorCode={data?.default_button_background_second_color}
                      onHandleColorChange={(hexColor) => {
                        onHandleChange(
                          "default_button_background_second_color",
                          hexColor
                        );
                      }}
                    />
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Default Button Text Colour</div>
            <div className="button_color_fields">
              <ColorPickerInput
                colorCode={data?.default_button_text_color}
                onHandleColorChange={(hexColor) => {
                  onHandleChange("default_button_text_color", hexColor);
                }}
              />
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Button Preview</div>
            <div className="button_color_fields">
              <Button
                style={{
                  color: data.default_button_text_color,
                  background: data.is_default_button_gradient_active
                    ? `linear-gradient(${data.default_button_gradient_angle}deg, ${data.default_button_background_color} 30%,${data.default_button_background_second_color} 100%)`
                    : `${data.default_button_background_color}`,
                  border: `1px solid ${data.default_button_text_color}`,
                }}
                className="rounded"
              >
                Example Test
              </Button>
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">
              Highlight Button Background
            </div>
            <div className="button_color_fields">
              <ColorPickerInput
                colorCode={data?.highlight_button_background_color}
                onHandleColorChange={(hexColor) => {
                  onHandleChange("highlight_button_background_color", hexColor);
                }}
              />

              <div className="flex_center">
                <label htmlFor="" className="text-secondary mt-1">
                  Gradient
                </label>
                <Switch
                  checked={data.is_highlight_button_gradient_active}
                  onChange={(val) => {
                    onHandleChange("is_highlight_button_gradient_active", val);
                  }}
                  className="mx-2"
                />
              </div>

              {data?.is_highlight_button_gradient_active ? (
                <>
                  <div className="flex_center">
                    <label htmlFor="" className="text-secondary mt-1">
                      Angle
                    </label>
                    <Input
                      className="ms-2"
                      style={{ width: "50px" }}
                      value={data.highlight_button_gradient_angle}
                      onChange={(e) => {
                        onHandleChange(
                          "highlight_button_gradient_angle",
                          e.target.value
                        );
                      }}
                    />

                    <label
                      htmlFor=""
                      className="text-secondary text-nowrap mx-2 mt-1"
                    >
                      Color
                    </label>

                    <div className="eventtheme_antd_input_style border-gray rounded fs-bold-14 text-end">
                      {/* <span className="w-90 text-center">{colorCode}</span> */}
                      <Input
                        value={data?.highlight_button_background_second_color}
                        type="text"
                        className="eventtheme_antd_input_style_hex_code"
                        onChange={(e) => {
                          // onHandleColorChange(e.target.value);

                          let inputValue = e.target.value;

                          // Remove additional '#' characters from the input
                          inputValue = inputValue.replace(/#/g, "");

                          // Add single '#' at the beginning
                          inputValue = "#" + inputValue;

                          onHandleChange(
                            "highlight_button_background_second_color",
                            inputValue
                          );
                        }}
                      />

                      <Input
                        type="color"
                        id=""
                        defaultValue={
                          data?.highlight_button_background_second_color
                        }
                        value={data?.highlight_button_background_second_color}
                        onChange={(e) => {
                          // onHandleColorChange(e.target.value);

                          onHandleChange(
                            "highlight_button_background_second_color",
                            e.target.value
                          );
                        }}
                        className="eventtheme_input_style border-gray rounded fs-bold-14"
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">
              Highlight Button Text Colour
            </div>
            <div className="button_color_fields">
              <ColorPickerInput
                colorCode={data?.highlight_button_text_color}
                onHandleColorChange={(hexColor) => {
                  onHandleChange("highlight_button_text_color", hexColor);
                }}
              />
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Button Preview</div>
            <div className="button_color_fields">
              <Button
                style={{
                  color: data.highlight_button_text_color,
                  background: data.is_highlight_button_gradient_active
                    ? `linear-gradient(${data.highlight_button_gradient_angle}deg, ${data.highlight_button_background_color} 30%,${data.highlight_button_background_second_color} 100%)`
                    : `${data.highlight_button_background_color}`,
                }}
                className="rounded"
              >
                Example Test
              </Button>
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">
              Logo
              <RedAsterick />
            </div>
            <div className="button_color_fields">
              {data?.event_id && (
                <div>
                  <Button
                    className="d-flex align-items-center"
                    onClick={() => setIsLogoPreview(true)}
                  >
                    <EyeOutlined className="fs-20" />
                    <span>View</span>
                  </Button>
                  <Modal
                    title="Event Theme Logo"
                    visible={isLogoPreview}
                    onCancel={() => setIsLogoPreview(false)}
                    footer={null}
                    width={400}
                    style={{ padding: "0px" }}
                  >
                    {data && data.event_logo_url ? (
                      <img
                        src={data.event_logo_url}
                        alt="uploaded logo"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <h4 className="fs-pink py-3 text-center">
                        File not uploaded
                      </h4>
                    )}
                  </Modal>
                </div>
              )}
              <FileUpload
                labelClassName="rounded border-pink btn-svg px-3 py-1"
                labelTitle="Image Upload"
                inputType="file"
                inputOnChange={handleLogoImageChange}
                inputId="logo"
                inputName="logo"
                value=""
                onBlur={() => validator.current.showMessageFor("logo")}
              />

              <Tooltip
                placement="right"
                title={"Final image shape is wide rectangle ‘inline’"}
                className="mx-2 mt-1"
              >
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>

              {logoFileState ? (
                <>
                  <span className="mx-3"> {logoFileState.name} </span>
                  <span onClick={deleteEventLogoFile}>
                    <DeleteOutlined />
                  </span>
                  <img
                    src={
                      logoThumbNail
                        ? logoThumbNail
                        : logoFileState.event_logo_url
                    }
                    alt="Pre Event File"
                    className="user-img-preview"
                  />
                </>
              ) : null}

              {validator.current.message(
                "logo",
                logoFileState,
                "required|text",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Logo Demo Size</div>
            <div className="button_color_fields">
              <div>
                <img width={340} height={180} src={LOGO_DEMO?.URL} />
              </div>
              {/* <Button
                onClick={() => {
                  // window.open(LOGO_DEMO.URL, "_blank");
                  setLogoDemoVisible(true);
                }}
                // download={LOGO_DEMO.FILENAME}
              >
                Preview
              </Button> */}
            </div>
            {/* <Modal
              title="Logo Preview"
              footer={null}
              visible={logoDemoVisible}
              onCancel={() => setLogoDemoVisible(false)}
            >
             
            </Modal> */}
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">
              Minimized Logo
              <RedAsterick />
            </div>
            <div className="button_color_fields">
              {data?.event_id && (
                <div>
                  <Button
                    className="d-flex align-items-center"
                    onClick={() => setIsMinimizedLogoPreview(true)}
                  >
                    <EyeOutlined className="fs-20" />
                    <span>View</span>
                  </Button>
                  <Modal
                    title="Event Theme Logo"
                    visible={isMinimizedLogoPreview}
                    onCancel={() => setIsMinimizedLogoPreview(false)}
                    footer={null}
                    width={400}
                    style={{ padding: "0px" }}
                  >
                    {data && data.minimized_logo_url ? (
                      <img
                        src={data.minimized_logo_url}
                        alt="Minimized logo uploaded"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <h4 className="fs-pink py-3 text-center">
                        File not uploaded
                      </h4>
                    )}
                  </Modal>
                </div>
              )}

              <FileUpload
                labelClassName="rounded border-pink btn-svg px-3 py-1"
                labelTitle="Image Upload"
                inputType="file"
                inputOnChange={handleMimizedLogoImageChange}
                inputId="minimizedLogo"
                inputName="minimizedLogo"
                value=""
                onBlur={() => validator.current.showMessageFor("minimizedLogo")}
              />
              <Tooltip
                placement="right"
                title={"Final image shape is square"}
                className="mx-2 mt-1"
              >
                <InfoCircleOutlined style={{ fontSize: "20px" }} />
              </Tooltip>

              {minimizedLogoFileState ? (
                <>
                  <span className="mx-3"> {minimizedLogoFileState.name} </span>
                  <span onClick={deleteEventMinimizedLogoFile}>
                    <DeleteOutlined />
                  </span>
                  <img
                    src={
                      minimizedLogoThumbNail
                        ? minimizedLogoThumbNail
                        : minimizedLogoFileState.minimized_logo_url
                    }
                    alt="Pre Event File"
                    className="user-img-preview"
                  />
                </>
              ) : null}

              {validator.current.message(
                "minimizedLogo",
                minimizedLogoFileState,
                "required|text",
                { className: "text-danger py-1" }
              )}
            </div>
          </div>

          <div className="button_color_container">
            <div className="button_color_lable">Minimized Logo Demo Size</div>
            <div className="button_color_fields">
              <div>
                <img
                  width={100}
                  height={100}
                  className="mx-auto"
                  src={
                    "https://brainvire-grv8-staging-static.s3.eu-west-2.amazonaws.com/staging/asset/Group+13027.png"
                  }
                />
              </div>
              {/* <Button
                onClick={() => {
                  // window.open(LOGO_DEMO.URL, "_blank");
                  setMinimizedDemoLogoVisible(true);
                }}
                // download={LOGO_DEMO.FILENAME}
              >
                Preview
              </Button> */}
              {/* <Modal
                title="Minimized Logo Preview"
                footer={null}
                visible={minimizedDemoLogoVisible}
                onCancel={() => setMinimizedDemoLogoVisible(false)}
              >
                
              </Modal> */}
            </div>
          </div>
        </section>
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Event Dates</h6>
            </div>
          </div>

          <div
            className={`${
              Tablet ? "d-flex flex-column" : "row"
            } mt-4 border-bottom-gray pb-4`}
          >
            <div className="col-9 event-date-scroll-div">
              <Collapse
                bordered={false}
                defaultActiveKey={["2"]}
                expandIcon={({ isActive }) =>
                  (isActive && (
                    <MinusOutlined className="rounded minus-logo" />
                  )) || <PlusOutlined className="rounded plus-logo" />
                }
                className="site-collapse-custom-collapse"
              >
                {chatBotDetails &&
                  chatBotDetails.map((elem, index) => {
                    if (elem && !elem.is_delete) {
                      return (
                        <Panel
                          header={
                            <span>
                              <span className="fw-bold">
                                Shows Days/Times - Block {index + 1}
                              </span>
                              <span className="font-pink mx-2">
                                {elem.start_date &&
                                elem.end_date &&
                                elem.start_time &&
                                elem.end_time
                                  ? "[ " +
                                    elem.start_date +
                                    " - " +
                                    elem.end_date +
                                    " , " +
                                    elem.start_time +
                                    " - " +
                                    elem.end_time +
                                    " ]"
                                  : null}
                              </span>
                            </span>
                          }
                          key={index}
                          className="site-collapse-custom-panel"
                          extra={
                            index > 0 ? (
                              <img
                                src={bin}
                                onClick={() => {
                                  if (
                                    data &&
                                    data.event_id &&
                                    eventEligibility
                                  ) {
                                    const datesOfShow = getDatesBetweenDates(
                                      elem?.start_date,
                                      elem.end_date
                                    );
                                    let isAllValidDates = "yes";
                                    if (datesOfShow) {
                                      eventEligibility.show_dates &&
                                        eventEligibility.show_dates.forEach(
                                          (eli_ele) => {
                                            if (
                                              datesOfShow.includes(
                                                eli_ele.date
                                              ) &&
                                              eli_ele.session_exists
                                            )
                                              isAllValidDates = "no";
                                          }
                                        );
                                      if (isAllValidDates === "yes")
                                        handleChatbots(
                                          index,
                                          "is_delete",
                                          !elem?.is_delete
                                        );
                                      else {
                                        toast.error(
                                          "You cannot delete the dates as Sessions are already created on selected dates"
                                        );
                                      }
                                    } else
                                      handleChatbots(
                                        index,
                                        "is_delete",
                                        !elem?.is_delete
                                      );
                                  } else
                                    handleChatbots(
                                      index,
                                      "is_delete",
                                      !elem?.is_delete
                                    );
                                }}
                                alt="bin"
                              />
                            ) : null
                          }
                        >
                          <div className="row">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                Start Date
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-6">
                              <DatePicker
                                allowClear={false}
                                id="start_date"
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                suffixIcon={<CalenderGrey width="15" />}
                                value={
                                  elem.start_date
                                    ? moment(elem?.start_date, "YYYY-MM-DD")
                                    : null
                                }
                                onChange={(e, i) => {
                                  if (
                                    data &&
                                    data.event_id &&
                                    eventEligibility
                                  ) {
                                    const datesOfShow = getDatesBetweenDates(
                                      elem?.start_date,
                                      elem.end_date
                                    );
                                    const newDatesOfShow = getDatesBetweenDates(
                                      moment(e).format("YYYY-MM-DD"),
                                      elem.end_date
                                    );
                                    const difOfDates = differenceWith(
                                      datesOfShow,
                                      newDatesOfShow,
                                      isEqual
                                    );
                                    let isAllValidDates = "yes";
                                    if (difOfDates) {
                                      eventEligibility.show_dates &&
                                        eventEligibility.show_dates.forEach(
                                          (eli_ele) => {
                                            if (
                                              difOfDates.includes(
                                                eli_ele.date
                                              ) &&
                                              eli_ele.session_exists
                                            ) {
                                              isAllValidDates = "no";
                                            }
                                          }
                                        );
                                      if (isAllValidDates === "yes")
                                        handleChatbots(
                                          index,
                                          "start_date",
                                          moment(e).format("YYYY-MM-DD")
                                        );
                                      else {
                                        toast.error(
                                          "You cannot change the dates as Sessions are already created on selected dates"
                                        );
                                        handleChatbots(
                                          index,
                                          "start_date",
                                          moment(elem?.start_date).format(
                                            "YYYY-MM-DD"
                                          )
                                        );
                                      }
                                    } else
                                      handleChatbots(
                                        index,
                                        "start_date",
                                        moment(e).format("YYYY-MM-DD")
                                      );

                                    // array.forEach((element) => {});
                                  } else
                                    handleChatbots(
                                      index,
                                      "start_date",
                                      moment(e).format("YYYY-MM-DD")
                                    );
                                }}
                                onBlur={() =>
                                  validator.current.showMessageFor("start_date")
                                }
                                format="DD-MM-YYYY"
                                className={
                                  validator?.current?.fields?.StartDate ===
                                    false &&
                                  validator?.current?.messagesShown === true
                                    ? "w-75 fs-bold-14 border_red"
                                    : "w-75 fs-bold-14 "
                                }
                              />
                              {validator.current.message(
                                "StartDate",
                                elem?.start_date,
                                "required",
                                { className: "text-danger py-2" }
                              )}
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                Start & End Time
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-4 d-flex m-0">
                              <div style={{ flexDirection: "column" }}>
                                <TimePicker
                                  id="start_time"
                                  format={"HH:mm"}
                                  suffixIcon={<TimeCalendar width="15" />}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  allowClear={false}
                                  value={
                                    elem?.start_time
                                      ? moment(elem?.start_time, "HH:mm")
                                      : moment(
                                          moment().format("HH:mm"),
                                          "HH:mm"
                                        )
                                  }
                                  showTime
                                  className={
                                    validator?.current?.fields?.StartTime ===
                                      false &&
                                    validator?.current?.messagesShown === true
                                      ? "w-100 border_red"
                                      : "w-100 "
                                  }
                                  onSelect={(val) => {
                                    handleChatbots(
                                      index,
                                      "start_time",
                                      moment(val).format("HH:mm")
                                    );
                                  }}
                                  onBlur={() =>
                                    validator.current.showMessageFor(
                                      "start_time"
                                    )
                                  }
                                />
                                {validator.current.message(
                                  "StartTime",
                                  elem?.start_time,
                                  "required",
                                  { className: "text-danger py-2" }
                                )}
                              </div>
                              <div
                                style={{
                                  flexDirection: "column",
                                  marginLeft: "5px",
                                }}
                              >
                                <TimePicker
                                  id="end_time"
                                  suffixIcon={<TimeCalendar width="15" />}
                                  getPopupContainer={(triggerNode) => {
                                    return triggerNode.parentNode;
                                  }}
                                  allowClear={false}
                                  format={"HH:mm"}
                                  disabledTime={() =>
                                    getDisabledTime(
                                      moment(
                                        chatBotDetails[index].start_time,
                                        "HH:mm"
                                      )
                                    )
                                  }
                                  disabled={!elem?.start_time}
                                  value={
                                    elem?.end_time
                                      ? moment(elem?.end_time, "HH:mm")
                                      : moment(
                                          moment().format("HH:mm"),
                                          "HH:mm"
                                        )
                                  }
                                  showTime
                                  className={
                                    validator?.current?.fields?.EndTime ===
                                      false &&
                                    validator?.current?.messagesShown === true
                                      ? "w-100 border_red"
                                      : "w-100 "
                                  }
                                  onSelect={(val) => {
                                    if (
                                      moment(val) <
                                      moment(elem?.start_time, "HH:mm")
                                    ) {
                                      handleChatbots(
                                        index,
                                        "end_time",
                                        elem?.end_time
                                      );
                                      toast.error("Please select correct time");
                                    } else
                                      handleChatbots(
                                        index,
                                        "end_time",
                                        moment(val).format("HH:mm")
                                      );
                                  }}
                                />
                                {validator.current.message(
                                  "EndTime",
                                  elem?.end_time,
                                  "required",
                                  { className: "text-danger py-2" }
                                )}
                              </div>
                            </div>
                            <div className="col-5 text-start mt-1">
                              <Switch defaultChecked className=" mr-0" />
                              <span className="mx-2">
                                Use these times for all the days?
                              </span>
                            </div>
                          </div>

                          <div className="row mt-4">
                            <div className="col-3 text-end">
                              <label className="text-secondary mt-1">
                                End Date
                                <RedAsterick />
                              </label>
                            </div>
                            <div className="col-6">
                              <DatePicker
                                allowClear={false}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode;
                                }}
                                disabledDate={(d) =>
                                  !d ||
                                  d.isSameOrBefore(moment(elem?.start_date))
                                }
                                value={
                                  elem?.end_date
                                    ? moment(elem?.end_date, "YYYY-MM-DD")
                                    : null
                                }
                                suffixIcon={<CalenderGrey width="15" />}
                                onChange={(e, i) => {
                                  if (
                                    data &&
                                    data.event_id &&
                                    eventEligibility
                                  ) {
                                    const datesOfShow = getDatesBetweenDates(
                                      elem?.start_date,
                                      elem.end_date
                                    );
                                    const newDatesOfShow = getDatesBetweenDates(
                                      elem?.start_date,
                                      moment(e).format("YYYY-MM-DD")
                                    );
                                    const difOfDates = differenceWith(
                                      datesOfShow,
                                      newDatesOfShow,
                                      isEqual
                                    );
                                    let isAllValidDates = "yes";
                                    if (difOfDates) {
                                      eventEligibility.show_dates &&
                                        eventEligibility.show_dates.forEach(
                                          (eli_ele) => {
                                            if (
                                              difOfDates.includes(
                                                eli_ele.date
                                              ) &&
                                              eli_ele.session_exists
                                            )
                                              isAllValidDates = "no";
                                          }
                                        );
                                      if (isAllValidDates === "yes")
                                        handleChatbots(
                                          index,
                                          "end_date",
                                          moment(e).format("YYYY-MM-DD")
                                        );
                                      else {
                                        handleChatbots(
                                          index,
                                          "end_date",
                                          moment(elem.end_date).format(
                                            "YYYY-MM-DD"
                                          )
                                        );
                                        toast.error(
                                          "You cannot change the dates as Sessions are already created on selected dates"
                                        );
                                      }
                                    } else
                                      handleChatbots(
                                        index,
                                        "end_date",
                                        moment(e).format("YYYY-MM-DD")
                                      );

                                    // array.forEach((element) => {});
                                  } else
                                    handleChatbots(
                                      index,
                                      "end_date",
                                      moment(e).format("YYYY-MM-DD")
                                    );
                                }}
                                format="DD-MM-YYYY"
                                className={
                                  validator?.current?.fields?.EndDate ===
                                    false &&
                                  validator?.current?.messagesShown === true
                                    ? "w-75 border_red"
                                    : "w-75 "
                                }
                              />
                              {validator.current.message(
                                "EndDate",
                                elem?.end_date,
                                "required",
                                { className: "text-danger py-2" }
                              )}
                            </div>
                          </div>
                        </Panel>
                      );
                    } else return null;
                  })}
              </Collapse>
            </div>
            <div className={`${Tablet ? "mt-3" : ""} col-3`}>
              <Button className="px-2 button-pink rounded" onClick={addChatBot}>
                + Add Days with different times
              </Button>
            </div>
            {validator.current.message(
              "Event Date",
              data?.event_shows_details,
              "required",
              { className: "text-danger py-2" }
            )}
          </div>
        </section>

        {/* section Pre-event message */}
        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Pre-event Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "pre_event_message_active",
                      value,
                      "pre_event_messages"
                    );
                  }}
                  checked={
                    data.pre_event_messages &&
                    data.pre_event_messages.pre_event_message_active
                      ? data.pre_event_messages.pre_event_message_active
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: This video will switch OFF at the start of the event (even
                if set to ‘Active’)
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "pre_event_message_forced",
                      value,
                      "pre_event_messages"
                    );
                  }}
                  checked={
                    data.pre_event_messages &&
                    data.pre_event_messages.pre_event_message_forced
                      ? data.pre_event_messages.pre_event_message_forced
                      : false
                  }
                  disabled={!data?.pre_event_messages?.pre_event_message_active}
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                placeholder="Enter Title Here"
                defaultValue={
                  data.pre_event_messages &&
                  data.pre_event_messages.pre_event_message_title
                }
                className="w-50 fs-bold-14"
                onChange={(e) =>
                  onHandleChange(
                    "pre_event_message_title",
                    e.target.value,
                    "pre_event_messages"
                  )
                }
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data.pre_event_messages &&
                  data.pre_event_messages.pre_event_message_description
                }
                onChange={(e) =>
                  onHandleChange(
                    "pre_event_message_description",
                    e.target.value,
                    "pre_event_messages"
                  )
                }
                maxLength={TEXTAREA_TEXT_LIMITS.MAX}
              />
            </div>
          </div>

          <div className="row mt-4 border-bottom-gray pb-4">
            <div className="col-2 text-end">
              <label htmlFor="" className="text-secondary mt-1">
                Video/Image Upload
              </label>
            </div>
            {data && data.event_id && (
              <div className={Tablet ? "col-3 " : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setIsPreEventImgPreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span>View</span>
                </Button>

                <Modal
                  title="Pre-event Message File"
                  visible={isPreEventImgPreview}
                  className="d-flex justify-content-center"
                  onCancel={() => {
                    setPlaying1(false);
                    setTimeout(() => {
                      setIsPreEventImgPreview(false);
                    }, 500);
                  }}
                  footer={null}
                  width={1100}
                >
                  {preEventImg?.pre_event_media_url ? (
                    isImage(preEventImg.name) ? (
                      <img
                        src={preEventImg?.pre_event_media_url}
                        alt="Pre Event File"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center player-wrapper">
                        <ReactPlayer
                          stopOnUnmount={playing1}
                          playing={playing1}
                          onPause={() => setPlaying1(false)}
                          onPlay={() => setPlaying1(true)}
                          controls={isPreEventImgPreview}
                          url={preEventImg?.pre_event_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="text-center fs-pink py-3">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className={`${Tablet ? "ms-2" : ""} h-25`}>
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handlePreImgChange}
                  inputId="pre"
                  inputName="pre"
                  value=""
                />
              </div>
              {preEventImg ? (
                <>
                  <span className="mx-3">{preEventImg.name}</span>
                  <span
                    onClick={() => {
                      setPreEventImg(null);
                      setPreEventThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(preEventThumbNail || preEventImg.pre_event_media_url) &&
                  (isImage(preEventThumbNail) || isImage(preEventImg.name)) ? (
                    <img
                      src={preEventImg.pre_event_media_url}
                      alt="Pre Event File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>

        {/* section Welcome message */}

        <section>
          <div className="row">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Welcome Message</h6>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Active</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "welcome_message_active",
                      value,
                      "welcome_messages"
                    );
                  }}
                  checked={
                    data.welcome_messages &&
                    data.welcome_messages.welcome_message_active
                      ? data.welcome_messages.welcome_message_active
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: This video will switch ON at the start of the event (if set
                to 'Active')
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary">Force on 1st visit</span>
            </div>
            <div className="col-10 ">
              <span>
                <Switch
                  onChange={(value) => {
                    onHandleChange(
                      "welcome_message_forced",
                      value,
                      "welcome_messages"
                    );
                  }}
                  checked={
                    data.welcome_messages &&
                    data.welcome_messages.welcome_message_forced
                      ? data.welcome_messages.welcome_message_forced
                      : false
                  }
                  disabled={
                    data &&
                    data.welcome_messages &&
                    data.welcome_messages.welcome_message_active === false
                      ? true
                      : false
                  }
                  className=" mr-0"
                />
              </span>
              <span className="mx-3">
                NB: If on, the user will not be able to skip the first view of
                the video
              </span>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Title</span>
            </div>
            <div className="col-10 ">
              <Input
                onChange={(e) =>
                  onHandleChange(
                    "welcome_message_title",
                    e.target.value,
                    "welcome_messages"
                  )
                }
                defaultValue={
                  data.welcome_messages &&
                  data.welcome_messages.welcome_message_title
                }
                placeholder="Enter Title Here"
                className="w-50 fs-bold-14"
                maxLength={INPUT_TEXT_LIMITS.MAX}
              />
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-2 text-end">
              <span className="text-secondary ">Description</span>
            </div>
            <div className="col-10 ">
              <TextArea
                rows={4}
                className="w-50 fs-bold-14"
                placeholder="Enter a description here"
                defaultValue={
                  data.welcome_messages &&
                  data.welcome_messages.welcome_message_description
                }
                onChange={(e) =>
                  onHandleChange(
                    "welcome_message_description",
                    e.target.value,
                    "welcome_messages"
                  )
                }
                maxLength={TEXTAREA_TEXT_LIMITS.MAX}
              />
            </div>
          </div>

          <div className="row mt-4 pb-4 border-bottom-gray">
            <div className="col-2 text-end">
              <span className="text-secondary ">Video/Image Upload</span>
            </div>
            {data?.event_id && (
              <div className={Tablet ? "col-3" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setisWelcomePreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span> View</span>
                </Button>

                <Modal
                  title="Welcome Message File"
                  visible={isWelcomePreview}
                  className="d-flex justify-content-center"
                  onCancel={() => {
                    setPlaying2(false);
                    setTimeout(() => {
                      setisWelcomePreview(false);
                    }, 500);
                  }}
                  footer={null}
                  width={1100}
                >
                  {welcomeImg?.welcome_media_url ? (
                    isImage(welcomeImg?.name) ? (
                      <img
                        src={welcomeImg?.welcome_media_url}
                        alt="Welcome upload file"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center  player-wrapper">
                        <ReactPlayer
                          playing={playing2}
                          onPause={() => setPlaying2(false)}
                          onPlay={() => setPlaying2(true)}
                          on
                          controls={true}
                          url={welcomeImg?.welcome_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="text-center fs-pink py-3">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className={`${Tablet ? "ms-2" : ""} h-25`}>
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handleWelcomeImgChange}
                  inputId="welcome"
                  inputName="welcome"
                  value=""
                />
              </div>
              {welcomeImg ? (
                <>
                  <span className="mx-3"> {welcomeImg.name} </span>
                  <span
                    onClick={() => {
                      setWelcomeImg(null);
                      setWelcomeThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(welcomeThumbNail || welcomeImg.welcome_media_url) &&
                  (isImage(welcomeThumbNail) || isImage(welcomeImg.name)) ? (
                    <img
                      src={welcomeImg.welcome_media_url}
                      alt="Welcome Media File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>

        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Event Support</h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Chat</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange("is_chat_active", value, "event_support");
                    }}
                    checked={
                      data.event_support && data.event_support.is_chat_active
                        ? data.event_support.is_chat_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>

            <div className="row mt-4 pb-4">
              <div className="col-2 text-end">
                <span className="text-secondary">Video Call</span>
              </div>
              <div className="col-10  ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_video_call_active",
                        value,
                        "event_support"
                      );
                    }}
                    checked={
                      data.event_support &&
                      data.event_support.is_video_call_active
                        ? data.event_support.is_video_call_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>

            <div className="row mt-4 ">
              <div className="col-2 text-end mt-4">
                <span className="text-secondary mt-4">Agents</span>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="supportEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>All Agents</b>
                </label>
                <br />
                <Select
                  id="agents"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search to Select"
                  onChange={handleAgentsChange}
                  className="border border-gray fs-bold-14 select_agents"
                  getPopupContainer={() =>
                    document.getElementById("supportEdit")
                  }
                  open={openUserList1}
                  onFocus={() => setOpenUsersList1(true)}
                  onBlur={() => {
                    setOpenUsersList1(false);
                    validator.current.showMessageFor("agents");
                  }}
                  onSearch={() => setOpenUsersList1(true)}
                  listHeight={100}
                  listItemHeight={4}
                  value="All Agents"
                >
                  {agents?.map((data, index) => (
                    <Option
                      key={"all_agents_" + index.toString()}
                      className="select-text-dark"
                      value={data?.user_id}
                    >
                      {data?.first_name} {data?.last_name}
                    </Option>
                  ))}
                  {agents.length === 0 && (
                    <Option disabled>
                      No agents created for organiser brand
                    </Option>
                  )}
                </Select>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="selectedAgentsEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>Tagged Agents</b>
                </label>
                <br />
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search Tagged Agents"
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selectedAgentsEdit")
                  }
                  open={openUserList1}
                  onFocus={() => setOpenUsersList1(true)}
                  onBlur={() => {
                    setOpenUsersList1(false);
                  }}
                  onSearch={() => setOpenUsersList1(true)}
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {selectedAgents?.map((data) => {
                    return (
                      <Option
                        key={"tag_agents_" + data.user_id}
                        value={data.user_id}
                      >
                        {data.first_name} {data.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChange(data.user_id)
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-2"></div>
              <div className="col-4">
                {data?.event_id && (
                  <>
                    <Button
                      className="px-3  rounded btn-svg"
                      onClick={() => setIsAddUserModalIsVisible1(true)}
                    >
                      <AddGroup fill="#ef0855" />
                      <span className="mx-2">Add Agents</span>
                    </Button>
                    <Modal
                      title="Add User"
                      visible={isAddUserModalVisible1}
                      onOk={() => setIsAddUserModalIsVisible1(false)}
                      onCancel={() => setIsAddUserModalIsVisible1(false)}
                      footer={null}
                      width={1100}
                    >
                      <AddUser
                        event_id={data?.event_id}
                        onCancelFromModal={() =>
                          setIsAddUserModalIsVisible1(false)
                        }
                        onSuccessFromModal={() => {
                          setIsAddUserModalIsVisible1(false);
                        }}
                      />
                    </Modal>
                  </>
                )}
                <Button
                  className={
                    "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                  }
                  onClick={() => {
                    if (brand_id)
                      window.open(
                        `/brandCalendar/${data?.event_id}/${brand_id}`,
                        "_blank"
                      );
                    else
                      toast.error(
                        "Brand Selection is Necessary for this action"
                      );
                  }}
                >
                  <CalenderGrey />
                  <span className="mx-2">Staffing Calendar</span>
                </Button>
              </div>
            </div>
          </section>
        ) : null}
        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Technical Support</h6>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Chat</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_chat_active",
                        value,
                        "event_technical_support"
                      );
                    }}
                    checked={
                      data.event_technical_support &&
                      data.event_technical_support.is_chat_active
                        ? data.event_technical_support.is_chat_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-2 text-end">
                <span className="text-secondary ">Video Call</span>
              </div>
              <div className="col-10 ">
                <span>
                  <Switch
                    onChange={(value) => {
                      onHandleChange(
                        "is_video_call_active",
                        value,
                        "event_technical_support"
                      );
                    }}
                    checked={
                      data.event_technical_support &&
                      data.event_technical_support.is_video_call_active
                        ? data.event_technical_support.is_video_call_active
                        : false
                    }
                  />
                </span>
              </div>
            </div>
            <div className="row mt-4 ">
              <div className="col-2 text-end mt-4">
                <span className="text-secondary mt-4">Agents</span>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4 mt-2"}
                id="techEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>All Agents</b>
                </label>
                <br />
                <Select
                  id="agents_technical"
                  showSearch
                  optionFilterProp="children"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search to Select"
                  onChange={handleAgentsChangetechnical}
                  listItemHeight={4}
                  listHeight={130}
                  open={openUserList2}
                  onFocus={() => setOpenUsersList2(true)}
                  onBlur={() => {
                    setOpenUsersList2(false);
                    validator.current.showMessageFor("agents_technical");
                  }}
                  onSearch={() => setOpenUsersList2(true)}
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() => document.getElementById("techEdit")}
                  value="All Agents"
                >
                  {agentsTechnical?.map((data, idx) => (
                    <Option
                      className="select-text-dark"
                      key={"techni_agents_" + idx.toString()}
                      value={data.user_id}
                    >
                      {data?.first_name} {data?.last_name}
                    </Option>
                  ))}
                  {agentsTechnical.length === 0 && (
                    <Option disabled>
                      No agents created for organiser brand
                    </Option>
                  )}
                </Select>
              </div>
              <div
                className={Tablet ? "col-5" : "col-4"}
                id="selectedAgentsTechnicalEdit"
                style={{ position: "relative" }}
              >
                <label className="py-2 mx-2">
                  <b>Tagged Agents</b>
                </label>
                <br />
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: Tablet ? "100%" : 300 }}
                  dropdownStyle={{ width: Tablet ? "100%" : 300 }}
                  placeholder="Search Tagged Agents"
                  className="border border-gray fs-bold-14"
                  getPopupContainer={() =>
                    document.getElementById("selectedAgentsTechnicalEdit")
                  }
                  open={openUserList2}
                  onFocus={() => setOpenUsersList2(true)}
                  onBlur={() => setOpenUsersList2(false)}
                  onSearch={() => setOpenUsersList2(true)}
                  listHeight={100}
                  listItemHeight={4}
                  value="Tagged Agents"
                >
                  {selectedAgentsTechnical?.map((data, idx) => {
                    return (
                      <Option
                        key={"selected_techni_agents_" + idx.toString()}
                        value={data?.user_id}
                      >
                        {data?.first_name} {data?.last_name}
                        <span className="float-end">
                          <CloseOutlined
                            className="float-right text-danger mr-0"
                            onClick={() =>
                              handleSelectedAgentsChangeTechnical(data?.user_id)
                            }
                          />
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="row mt-4 border-bottom-gray pb-4">
              <div className="col-2"></div>
              <div className="col-4">
                <span className={data?.event_id ? "" : "d-none"}>
                  <Button
                    className="px-3  rounded btn-svg"
                    onClick={() => setIsAddUserModalVisible2(true)}
                  >
                    <AddGroup fill="#ef0855" />
                    <span className="mx-2">Add Agents</span>
                  </Button>
                  <Modal
                    title="Add User"
                    visible={isAddUserModalVisible2}
                    onOk={() => setIsAddUserModalVisible2(false)}
                    onCancel={() => setIsAddUserModalVisible2(false)}
                    footer={null}
                    width={1100}
                  >
                    <AddUser
                      event_id={data?.event_id}
                      onCancelFromModal={() => setIsAddUserModalVisible2(false)}
                      onSuccessFromModal={() => {
                        setIsAddUserModalVisible2(false);
                      }}
                    />
                  </Modal>
                </span>
                <Button
                  className={
                    "px-3  rounded btn-svg" + (Tablet ? " mt-2" : " mx-2")
                  }
                  onClick={() => {
                    if (brand_id) {
                      window.open(
                        `/brandCalendar/${data?.event_id}/${brand_id}`,
                        "_blank"
                      );
                    } else
                      toast.error(
                        "Brand Selection is Necessary for this action"
                      );
                  }}
                >
                  <CalenderGrey />
                  <span className="mx-2">Staffing Calendar</span>
                </Button>
              </div>
            </div>
          </section>
        ) : null}

        <section>
          <div className="row ">
            <div className="col-12 mt-4">
              <h6 className="font-pink fw-bolder fs-20">Controls Help</h6>
            </div>
          </div>
          <div className="row mt-4 border-bottom-gray pb-4">
            <div className={Tablet ? "col-3 text-end" : "col-2 text-end"}>
              <label htmlFor="" className="text-secondary mt-1">
                Upload
              </label>
            </div>
            {data?.event_id && (
              <div className={Tablet ? "col-2" : "col-1"}>
                <Button
                  className="d-flex align-items-center"
                  onClick={() => setIsControlPreview(true)}
                >
                  <EyeOutlined className="fs-20" />
                  <span> View</span>
                </Button>
                <Modal
                  title="Controls Help File"
                  visible={isControlPreview}
                  onCancel={() => {
                    setPlaying3(false);
                    setTimeout(() => {
                      setIsControlPreview(false);
                    }, 500);
                  }}
                  className="wall-graphics-modal"
                  footer={null}
                  width={1100}
                >
                  {controlImg?.control_media_url ? (
                    isImage(controlImg?.name) ? (
                      <img
                        src={controlImg?.control_media_url}
                        alt="control media upload file"
                        className="img-fluid uploaded-image-modal-preview"
                      />
                    ) : (
                      <div className="d-flex justify-content-center align-content-center player-wrapper">
                        <ReactPlayer
                          stopOnUnmount={playing3}
                          playing={playing3}
                          onPause={() => setPlaying3(false)}
                          onPlay={() => setPlaying3(true)}
                          controls={true}
                          url={controlImg?.control_media_url}
                          className="react-player-class"
                          width="100%"
                          height="100%"
                        />
                      </div>
                    )
                  ) : (
                    <h4 className="fs-pink py-3 text-center">
                      File not uploaded
                    </h4>
                  )}
                </Modal>
              </div>
            )}
            <div className="col-5 image-upload d-flex">
              <div className="h-25">
                <FileUpload
                  labelClassName="rounded border-pink btn-svg px-3 py-1"
                  labelTitle="Upload"
                  inputType="file"
                  inputOnChange={handleControlImgChange}
                  inputId="control"
                  inputName="control"
                />
              </div>
              {controlImg ? (
                <>
                  <span className="mx-3"> {controlImg.name} </span>
                  <span
                    onClick={() => {
                      setControlImg(null);
                      setControlThumbNail(null);
                    }}
                  >
                    <DeleteOutlined />
                  </span>
                  {(controlThumbNail || controlImg.control_media_url) &&
                  (isImage(controlThumbNail) || isImage(controlImg.name)) ? (
                    <img
                      src={controlImg.control_media_url}
                      alt="Control Image File"
                      className="user-img-preview"
                    />
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </section>
        {data && data.event_id ? (
          <section>
            <div className="row">
              <div className="col-12 mt-4">
                <h6 className="font-pink fw-bolder fs-20">Feedback Survey</h6>
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-12 mt-1 pb-4">
                <span className="fs-16">
                  NB: This survey will be available to all users in the
                  "Feedback" section.
                </span>
              </div>
              <div className="col-2 text-end">
                <label className="text-secondary">Survey</label>
              </div>
              <div className="col-4">
                <Select
                  showSearch
                  optionFilterProp="children"
                  defaultValue={
                    data?.event_survey_id ? data?.event_survey_id : null
                  }
                  placeholder="Select Survey"
                  className="w-100 fs-bold-14"
                  onChange={(e) => onHandleChange("event_survey_id", e)}
                  listHeight={120}
                  listItemHeight={4}
                >
                  {surveyList &&
                    surveyList.map((item, i) => {
                      return (
                        <Option
                          key={"survey_" + i.toString()}
                          value={item.survey_id}
                        >
                          {item.survey_title}
                        </Option>
                      );
                    })}
                </Select>
              </div>
              <div className="col-4 text-start">
                {data?.event_id && (
                  <Button
                    onClick={() => setIsSurveyModalVisible(true)}
                    className="px-3 button-pink rounded"
                  >
                    + Create New Survey
                  </Button>
                )}
              </div>
            </div>
          </section>
        ) : null}
        <Modal
          title="Add Survey"
          visible={isSurveyModalVisible}
          onCancel={() => setIsSurveyModalVisible(false)}
          footer={null}
          width={1100}
        >
          <div className="p-2">
            <AddNewSurvey
              event_id={data?.event_id}
              isFromEvent={true}
              onCancelFromModal={() => setIsSurveyModalVisible(false)}
              onSuccessFromModal={async () => {
                const surveyData = await getSurveys();
                setSurveyList(surveyData);
                setIsSurveyModalVisible(false);
              }}
            />
          </div>
        </Modal>

        {showEventLogoCropModal ? (
          <CropImageModal
            imageToBeCropped={cropImagePreview}
            sendCroppedImageFile={(cropImageFile) => {
              saveEventLogoCroppedImage(cropImageFile);
              setShowEventLogoCropModal(false);
            }}
            closeCropModalOnCancel={() => {
              setShowEventLogoCropModal(false);
            }}
            cropSizeData={{
              width: 340,
              height: 100,
            }}
          />
        ) : null}

        {showEventMinimizeLogoCropModal ? (
          <CropImageModal
            imageToBeCropped={cropImagePreview}
            sendCroppedImageFile={(cropImageFile) => {
              saveEventMinimizeLogoCroppedImage(cropImageFile);
              setShowEventMinimizeLogoCropModal(false);
            }}
            closeCropModalOnCancel={() => {
              setShowEventMinimizeLogoCropModal(false);
            }}
            cropSizeData={{
              width: 100,
              height: 100,
            }}
          />
        ) : null}
      </div>
    );
  else return null;
};

export default EventEditor;
